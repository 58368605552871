var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"modal",staticStyle:{"padding":"15px"},attrs:{"size":"lg","id":"AddTestimonialModal","title":"Add Testimonial","ok-title":"Submit","cancel-title":"Close","ok-disabled":this.$refs.AddPackageForm != undefined
        ? !this.$refs.AddPackageForm.checkValidity()
        : true},on:{"show":function($event){return _vm.resetModal()},"hidden":function($event){return _vm.resetModal()},"ok":_vm.handleOk}},[_c('form',{ref:"AddPackageForm",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name","autocomplete":"off"}},[_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.requestModel.name.$error,
              },attrs:{"id":"name","type":"text","required":""},model:{value:(_vm.requestModel.name),callback:function ($$v) {_vm.$set(_vm.requestModel, "name", $$v)},expression:"requestModel.name"}}),(_vm.submitted && !_vm.$v.requestModel.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Name is required ")]):_vm._e(),(!_vm.$v.requestModel.name.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Name must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.requestModel.name.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 100 characters is reached ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Designation","label-for":"designation","required":"","autocomplete":"off"}},[_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.requestModel.designation.$error,
              },attrs:{"id":"designation","type":"text","required":""},model:{value:(_vm.requestModel.designation),callback:function ($$v) {_vm.$set(_vm.requestModel, "designation", $$v)},expression:"requestModel.designation"}}),(_vm.submitted && !_vm.$v.requestModel.designation.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Designation is required ")]):_vm._e(),(!_vm.$v.requestModel.designation.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Designation must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.requestModel.designation.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 100 characters is reached ")]):_vm._e()],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description","autocomplete":"off"}},[_c('b-form-textarea',{class:{
                'is-invalid': _vm.submitted && _vm.$v.requestModel.description.$error,
              },attrs:{"id":"description","type":"text","rows":"5","required":""},model:{value:(_vm.requestModel.description),callback:function ($$v) {_vm.$set(_vm.requestModel, "description", $$v)},expression:"requestModel.description"}}),(_vm.submitted && !_vm.$v.requestModel.description.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Description is required ")]):_vm._e(),(!_vm.$v.requestModel.description.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Description must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.requestModel.description.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 120 characters is reached ")]):_vm._e()],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Picture","label-for":"Address","autocomplete":"off"}},[_c('b-form-file',{class:{
                'is-invalid': _vm.submitted && _vm.$v.requestModel.picture.$error,
              },attrs:{"accept":"image/jpeg, image/png, image/gif","state":Boolean(_vm.requestModel.picture),"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.requestModel.picture),callback:function ($$v) {_vm.$set(_vm.requestModel, "picture", $$v)},expression:"requestModel.picture"}}),(_vm.submitted && !_vm.$v.requestModel.picture.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Picture is required ")]):_vm._e()],1)],1)])])]),_c('Toaster',{ref:"toaster"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }