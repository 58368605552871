  <template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="ViewModal"
      ref="modal"
      :title="modalTitle"
      ok-only
      cancel-title="Close"
      @show="updateModel()"
      @hidden="resetModal()"
      @ok='$bvModal.hide("ViewModal")'
    >
      <div class="row">
        <div class = "col-md-12">
          <span v-html="text">{{text}}</span>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ViewModal",
  data() {
    return {
      moduleName: "View",
      requestModel: null,
    };
  },

  props: {
    text: {
      type: String,
      default: null,
    },
    modalTitle:{
      type:String,
      default:"View"
    }
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.userDetailsForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    updateModel() {
      console.log(this.userDetails);
      this.requestModel = this.userDetails;
    },
    resetModal() {},
   
  },
};
</script>
