

<script>
// import VueCharts from 'vue-chartjs'
import {Bar,mixins} from 'vue-chartjs';
export default {
  name:'BarGraph',
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'options'],
  mounted () {
    // console.log("chart")
    this.renderChart(this.chartData, this.options)
  }
}
</script>