<template>
  <div>
    <div class="row justify-content-between align-items-center">
      <div class="col-md-9 customHeading">
        <h3>{{ moduleName }}</h3>
      </div>

      <!--==========Class dropdown============-->

      <!---SEARCH BOX-->
      <div class="col-md-2">
        <div class="searchbox input-group" role="group">
          <input
            class="form-control search-box my-search-box"
            type="text"
            placeholder="Name"
            aria-label="search"
            v-model="filteredText"
          />

          <button
            class="search-button btn btn-primary filter"
            @click="getCompanies()"
          >
            <i class="fa fa-search search-icon"></i>
          </button>
        </div>
      </div>
      <div class="col-md-1 Add-button" style="text-align: center">
        <a href="javascript:void(0)">
          <div class="AddButton">
            <div class="icon">
              <i
                class="fas fa-arrow-alt-circle-right fa-1x"
                style="color: rgb(255, 50, 55)"
              >
              </i>
            </div>
            <div data-text="Add" class="name add-btn" v-b-modal.AddVoucherModal>
              Add
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <hr
          color="#CACACA"
          style="border-top: 2px solid rgb(202, 202, 202); margin-top: 5px"
        />
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col">
          <table
            class="
              table table-striped table-borderless
              cutom-table
              table-striped table-sm
            "
            style="font-size: 85%"
          >
            <thead>
              <tr>
                <th role="columnheader">Price</th>
                <th role="columnheader">Expiry</th>
                <th role="columnheader">Status</th>
                <th role="columnheader">Actions</th>
              </tr>
            </thead>
            <tbody>
              <!--IF STUDENTS ARRAY HAS NO VALUE THEN SHOW WITHOUT SHOWING DATA-->
              <tr v-if="jobsRequests == null">
                <td class="center-td" colspan="5">No Data</td>
              </tr>

              <tr v-else v-for="record in jobsRequests" :key="record.Id">
                <td role="cell">{{ record.price }}</td>

                <td role="cell">{{ record.expiry }}</td>
                
              
                <td role="cell">
                  <b-form-checkbox
                    v-model="record.companyStatus"
                    @input="updateCompanyFeaturedandStatusState(record)"
                    value="Active"
                    unchecked-value="InActive"
                  >
                    {{
                      record.companyStatus != 0
                        ? record.companyStatus
                        : "In Active"
                    }}
                  </b-form-checkbox>
                </td>
                <td>
                <span @click="editRecord(record)">
                    <i
                      role="button"
                      style="color: #ea216c"
                      class="fas fa-edit"
                    ></i>
                  </span>
                <!-- <span @click="deleteRecord(record.id)">
                    <i
                      role="button"
                      style="color: #ea216c"
                      class="far fa-trash-alt"
                    ></i>
                  </span> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--PAGINATION START-->
      <div class="row justify-content-between align-items-center">
        <div class="col-md-2">
          <div class="showPages">
            <select
              class="form-control"
              id="selClass"
              v-model="perPage"
              @change="getCompanies()"
            >
              <option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-8 d-flex justify-content-center">
          <div class="overflow-auto">
            <b-pagination
              v-model="page"
              :total-rows="rows"
              :per-page="perPage"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              @change="getPage"
            ></b-pagination>
          </div>
        </div>

        <div class="col-md-2">
          <input
            class="form-control search-box page-no-search"
            type="text"
            v-bind:placeholder="page"
            aria-label="search"
            v-model.number="pageNumOnSearch"
            style="height: 38px"
          />

          <button
            class="search-button btn btn-primary search page-no-search-btn"
            @click="search()"
          >
            GO
          </button>
        </div>
      </div>
      <!--/PAGINATION END-->
      <!--SPINNER-->
      <Spinner
        v-if="isLoading"
        message="Loading..."
        size="medium"
        line-fg-color="rgb(222 224 219"
        line-bg-color="rgb(68 66 62)"
      >
      </Spinner>
      <!--/SPINNER-->
    </div>
    <!-- <AddClientModal /> -->
    <Toaster ref="toaster" />
    <EditCompanyModal
      :packageToEdit="recordToEdit"
      v-if="recordToEdit != null"
    />
    <AddCompanyModal />
    <DeleteModal
      :msgForModal="msgForModal"
      :apiEndPoint="apiEndPoint"
      :heading="deleteModalHeading"
      :entityId="recordToDelete"
    />
  </div>
</template>

<script>
import axios from "axios";
import Toaster from "../../common/pages/toaster.vue";
// import config from '../config.js';
import Spinner from "vue-simple-spinner";
import AddCompanyModal from "./modals/add-discount-voucher.vue";
import DeleteModal from "../../common/modals/delete-modal.vue";
import EditCompanyModal from "./modals/edit-discount-voucher-modal.vue";

export default {
  name: "Companies",
  data() {
    return {
      moduleName: "Vouchers",
      jobsRequests: [],
      isLoading: false,
      recordToEdit: null,
      recordToDelete: null,
      msgForModal: null,
      apiEndPoint: null,
      deleteModalHeading: null,

      jobPostPackageId: null,
      requestModel: {
        employerCompanyId: null,
        employeeId: null,
        industryTitle: null,
        jobsTitle: null,
        companyLogoAddress: null,
        ownerShipTypeTitle: null,
        countryName: null,
        cityName: null,
        companyEmail: null,
        companyName: null,
        ceoName: null,
        industryId: null,
        ownerShipTypeId: null,
        companyDesciption: null,
        countryId: null,
        cityId: null,
        address: null,
        establishedInYear: null,
        websiteUrl: null,
        contactNumber: null,
        googleMapIframe: null,
        linkedInLink: null,
        facebookLink: null,
        googlePlusLink: null,
        twitterLink: null,
        employeeImage: null,
        fullName: null,
        email: null,
        mobileNumber: null,
        jobTitle: null,
        isFeature: null,
        companyStatus: null,
        employee: null,
        jobs: null,
        packages: null,
      },
      pageNumOnSearch: "",
      filteredText: "",
      rows: 0,
      page: 1,
      options: [
        { text: "Show 2", value: 2 },
        { text: "Show 10", value: 10 },
        { text: "Show 25", value: 25 },
        { text: "Show 50", value: 50 },
        { text: "Show 100", value: 100 },
      ],
      perPage: 25,
    };
  },

  methods: {
    getPage(page) {
      this.page = page;
      this.getCompanies();
    },
    updateCompanyFeaturedandStatusState(record) {
      this.isLoading = true;
      let req = {
        employeeCompanyId: record.employerCompanyId,
        isFeature: record.isFeature,
        companyStatus: record.companyStatus == "Active" ? 2: 1
      };
      console.log(req);
      axios.post("/EmployerCompany/UpdateCompanyStatus", req).then((res) => {
        this.isLoading = false;
        if (res.data.status == "Success") {
          console.log("success");
          // this.rows = res.data.totalRecords;
        } else {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        }
      });
    },
    editRecord(comp) {
      this.recordToEdit = comp;
      setTimeout(() => {
        this.$bvModal.show("EditVoucherModal");
      }, 100);
    },
    deleteRecord(id) {
      this.recordToDelete = id;
      this.msgForModal = "Confirm to delete !";
      this.apiEndPoint = "client/Delete/";
      this.deleteModalHeading = "Delete";
      this.$bvModal.show("DeleteModal");
    },

    getCompanies() {
      // this.jobsRequests.push({Id:1, Title: "Standard", JobsAllowed:100, Price: 5000,Status:1 })
      this.isLoading = true;
      axios.get("/EmployerCompany/GetAllCompany").then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          console.log(res);
          this.jobsRequests = res.data.data;

          // this.rows = res.data.totalRecords;
        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        } else {
          this.jobsRequests = null;
        }
      });
    },

    search() {
      this.page = this.pageNumOnSearch;
      this.getCompanies();
    },
  },
  created() {
    this.getCompanies();
    // this.getStudents();
    //localStorage.setItem("mytime", Date.now());
  },
  components: {
    Spinner,
    EditCompanyModal,
    DeleteModal,
    Toaster,
    AddCompanyModal,
  },
};
</script>

<style scoped>
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
select#class-dropdown {
  width: 80%;
  margin-left: 16%;
}
</style>