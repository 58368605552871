<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="EditBrandModal"
      ref="modal"
      title="Edit Brand"
      ok-title="Submit"
      cancel-title="Close"
      @show="updateModel()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.EditBannerForm != undefined
          ? !this.$refs.EditBannerForm.checkValidity()
          : true
      "
    >
      <form ref="EditBannerForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <p class="text-danger">
            <b>Note:</b> It will not get submitted unless there's some change
          </p>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Link" label-for="Link" autocomplete="off">
              <b-form-input
                id="Link"
                v-model="recordToEdit.brandUrl"
                type="url"
                required
              ></b-form-input>
              <div
                v-if="submitted && !$v.recordToEdit.brandUrl.required"
                class="invalid-feedback"
              >
                Brand URL is required!
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" autocomplete="off">
              <label label-for="Image">Image</label>
              <span class="ml-1 text-muted small">
                <i
                  >(Brand image must be square, and at least 512 pixels wide and
                  tall.)</i
                >
                <!-- <b-icon icon="question-circle"></b-icon> -->
              </span>
              <b-form-file
                accept="image/jpeg, image/png, image/gif"
                v-model="file1"
                :state="Boolean(file1)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-2"></div>
          <div class="col-md-8 image-with-border">
            <zoom-on-hover
              :img-normal="this.siteUrl + this.imageUrl"
            ></zoom-on-hover>
          </div>
          <div class="col-md-2"></div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
import Toaster from "../../../common/pages/toaster.vue";
import { required } from "vuelidate/lib/validators";

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  name: "EditBrand",
  data() {
    return {
      moduleName: "Edit Brand",
      file1: null,
      siteUrl: "",
      IsImageUpdated: false,
      imageUrl: "",
      submitted: false,
    };
  },
  validations: {
    recordToEdit: {
      brandUrl: {
        required,
      },
    },
  },
  components: {
    Toaster,
  },
  props: {
    recordToEdit: {
      type: Object,
      default: null,
    },
  },
  watch: {
    file1(newValue, oldValue) {
      this.IsImageUpdated = true;
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.siteUrl = "";
              this.imageUrl = value;
            })
            .catch(() => {
              this.imageUrl = null;
            });
        } else {
          this.imageUrl = null;
        }
      }
    },
  },
  methods: {
    EditBanner() {
      console.log(this.recordToEdit);
      this.checkImageSize();

      const formData = new FormData();

      formData.append("BrandId", this.recordToEdit.brandId);
      formData.append("BrandUrl", this.recordToEdit.brandUrl);
      formData.append("IsFeature", this.recordToEdit.isFeature);


      // formData.append("IsImageUpdated", this.IsImageUpdated);
      if (this.siteUrl === "") {
        formData.append("BrandImage", this.file1);
      } else {
         formData.append("BrandImage", this.recordToEdit.brandImage);
      }

      console.log(formData);
      this.isLoading = true;
      axios.post("/Adds/UpdateBrands", formData).then((res) => {
        this.isLoading = false;
        if (res.data.status == "Success") {
          this.$refs.toaster.showToast(
            "Success",
            "success",
            "Successfully Updated"
          );
          this.$parent.getPage(1);
          this.$bvModal.hide("EditBrandModal");
        } else {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        }
      });
    },

    checkImageSize() {
      if (this.file1) {
        var fileSize = 209715;
        if (this.file1.size > fileSize) {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "File Size Limit is 200 KB"
          );
          this.file1 = null;
          return false;
        }
      }
    },

    checkFormValidity() {
      const valid = this.$refs.EditBannerForm.checkValidity();
      // if (this.file1 == null) {
      //   this.$refs.toaster.showToast(
      //     "Invalid Data",
      //     "danger",
      //     "Please select a file"
      //   );
      //   return;
      // }

      // this.usernameState = valid
      return valid;
    },
    updateModel() {
      this.file1 = null;
      // this.siteUrl = "https://smw.demo.itrooscloud.net/";
      this.siteUrl = "https://smw-live.azurewebsites.net/";
      this.imageUrl = this.recordToEdit.brandImage;
    },
    resetModal() {},
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.$v.$touch();

      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }
      this.EditBanner();
    },
  },
};
</script>
