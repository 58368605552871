<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="EditStoryModal"
      ref="modal"
      :title="type==='stories'?'Edit Story':'Edit Update'"
      ok-title="Submit"
      cancel-title="Close"
      @show="showModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.AddStoryForm != undefined
          ? !this.$refs.AddStoryForm.checkValidity()
          : true
      "
    >
      <form ref="AddStoryForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
         <p class="text-danger"><b>Note:</b> It will not get submitted unless there's some change</p>
        </div>
        <div class="row">
          <div class="col-md-12" v-if="type==='stories'">
            <!--NAME INPUT-->
            <b-form-group
              label="Category"
              label-for="category"
              autocomplete="off"
            >
              <b-form-select
                id="category"
                v-model="requestModel.categoryId"
                :options="cmsCategoryOptions"
                required
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select Category</b-form-select-option
                >
              </b-form-select>
              <!-- <div
                v-if="submitted && !$v.requestModel.categoryId.required"
                class="invalid-feedback"
              >
                Category is required
              </div> -->
            </b-form-group>
          </div>
          
        </div>
        <div class="row">
          <div  :class="type==='stories'? 'col-md-6' : 'col-md-6'">
            <b-form-group label="Title" label-for="title" autocomplete="off">
              <b-form-input
                id="title"
                v-model="requestModel.descriptionTitle"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.descriptionTitle.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.descriptionTitle.required"
                class="invalid-feedback"
              >
                Title is required
              </div>
              <div
                v-if="!$v.requestModel.descriptionTitle.minLength"
                class="invalid-feedback"
              >
                Title must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.descriptionTitle.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>
          </div>
          <div  :class="type==='stories'? 'col-md-6' : 'col-md-6'" dir="rtl">
            <b-form-group label="عنوان" label-for="title" autocomplete="off">
              <b-form-input
                id="title"
                v-model="requestModel.urduDescriptionTitle"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.descriptionTitle.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.descriptionTitle.required"
                class="invalid-feedback"
              >
                Title is required
              </div>
              <div
                v-if="!$v.requestModel.descriptionTitle.minLength"
                class="invalid-feedback"
              >
                Title must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.descriptionTitle.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <!-- <div class="col-md-6">

            <b-form-group label="Type" label-for="type" autocomplete="off">
              <b-form-select
                id="type"
                v-model="requestModel.typeId"
                :class="{
                  'is-invalid': submitted && $v.requestModel.typeId.$error,
                }"
                :options="shehriTypeOptions"
                required
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select Type</b-form-select-option
                >
              </b-form-select>

              <div
                v-if="submitted && !$v.requestModel.typeId.required"
                class="invalid-feedback"
              >
                Type is required
              </div>
            </b-form-group>
          </div> -->
          <div class="col-md-6">
            <!--NAME INPUT-->
            <b-form-group
              label="Post Type"
              label-for="title"
              autocomplete="off"
            >
              <b-form-select
                id="posttype"
                v-model="requestModel.postType"
                :class="{
                  'is-invalid': submitted && $v.requestModel.postType.$error,
                }"
                 :options="type==='stories' ? postTypeOptions : postTypeOptionsUpdates"
                required
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select Post Type</b-form-select-option
                >
              </b-form-select>
              <div
                v-if="submitted && !$v.requestModel.postType.required"
                class="invalid-feedback"
              >
                Post type is required
              </div>
            </b-form-group>
          </div>
           <div class="col-md-6">
            <b-form-group
              label="Expiry Date"
              label-for="datepicker-1"
              autocomplete="off"
            >
              <b-form-input
                type="date"
                v-model="requestModel.expiryDate"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <!--NAME INPUT-->
            <b-form-group
              label="Youtube Link"
              label-for="youtube"
              autocomplete="off"
            >
              <b-form-input
                id="youtube"
                v-model="requestModel.youtubeLink"
                placeholder="https://www.youtube.com/embed/Rt_UqUm38BI"
                type="url"
                :disabled="
                  requestModel.videoOrImage ? (youtubeDisabled = true) : false
                "
              ></b-form-input>
            </b-form-group>
          </div>

        </div>
        <div class="row">
           <div class="col-md-12">
            <!--NAME INPUT-->
            <b-form-group
              label="Short Description"
              label-for="shortDescription"
              autocomplete="off"
            >
              <b-form-input
                id="shortDescription"
                v-model="requestModel.shortDescription"
                type="text"
                :class="{
                  'is-invalid': submitted && $v.requestModel.shortDescription.$error,
                }"
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.shortDescription.required"
                class="invalid-feedback"
              >
                Short Description is required
              </div>
              <div
                v-if="!$v.requestModel.shortDescription.minLength"
                class="invalid-feedback"
              >
                Short Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.shortDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row" dir="rtl">
           <div class="col-md-12">
            <!--NAME INPUT-->
            <b-form-group
              label="مختصر تفصیل"
              label-for="shortDescription"
              autocomplete="off"
            >
              <b-form-input
                id="shortDescription"
                v-model="requestModel.urduShortDescription"
                type="text"
                :class="{
                  'is-invalid': submitted && $v.requestModel.shortDescription.$error,
                }"
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.shortDescription.required"
                class="invalid-feedback"
              >
                Short Description is required
              </div>
              <div
                v-if="!$v.requestModel.shortDescription.minLength"
                class="invalid-feedback"
              >
                Short Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.shortDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <!--NAME INPUT-->
            <b-form-group
              label="Long Description"
              label-for="description"
              autocomplete="off"
            >
            <ckeditor  v-model="requestModel.categoryDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.categoryDescription.$error,
                }"></ckeditor>
              <!-- <b-textarea
                id="description"
                v-model="requestModel.categoryDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.categoryDescription.$error,
                }"
                type="text"
                required
              ></b-textarea> -->
              <div
                v-if="
                  submitted && !$v.requestModel.categoryDescription.required
                "
                class="invalid-feedback"
              >
                Long Description is required
              </div>
              <div
                v-if="!$v.requestModel.categoryDescription.minLength"
                class="invalid-feedback"
              >
                Long Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.categoryDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 5000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row" dir="rtl">
          <div class="col-md-12">
            <!--NAME INPUT-->
            <b-form-group
              label="لمبی تفصیل"
              label-for="description"
              autocomplete="off"
            >
            <ckeditor  v-model="requestModel.urduCategoryDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.categoryDescription.$error,
                }"></ckeditor>
              <!-- <b-textarea
                id="description"
                v-model="requestModel.categoryDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.categoryDescription.$error,
                }"
                type="text"
                required
              ></b-textarea> -->
              <div
                v-if="
                  submitted && !$v.requestModel.categoryDescription.required
                "
                class="invalid-feedback"
              >
                Long Description is required
              </div>
              <div
                v-if="!$v.requestModel.categoryDescription.minLength"
                class="invalid-feedback"
              >
                Long Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.categoryDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 5000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Image" label-for="Address" autocomplete="off">
              <b-form-file
                accept="image/jpeg, image/png, image/gif, image/webp"
                v-model="attachedFile"
                :state="Boolean(attachedFile)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                :disabled="
                  requestModel.youtubeLink ? (imageDisbaled = true) : false
                "
              ></b-form-file>
            </b-form-group>
          </div>
          <div class="col-md-2" v-if="imageUrl!=null"></div>
          <div class="col-md-8 image-with-border" v-if="imageUrl!=null && isImageSelected">
            <zoom-on-hover
              :img-normal="this.siteUrl + this.imageUrl"
            ></zoom-on-hover>
          </div>
          <div class="col-md-2"></div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import moment from "moment";

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default {
  name: "EditStory",
  data() {
    return {
      moduleName: "Add Story",
      imageUrl: "",
      isImageSelected: true,
      siteUrl: "",
      attachedFile: null,
      requestModel: {
        category: null,
        categoryDescription: null,
        urduCategoryDescription: null,
        urduDescriptionTitle: null,
        urduShortDescription:"",
        descriptionTitle: null,
        numberofDays: null,
        postType: null,
        // typeId: null,
        categoryId: null,
        type: null,
        videoOrImage: null,
        categoryTitle: "",
        Id: null,
        youtubeLink: null,
        expiryDate:new Date(),
        // longDescription:null
      },
      youtubeDisabled: false,
      imageDisbaled: false,
      submitted: false,
      cmsCategoryOptions: null,
      shehriTypeOptions: null,
      postTypeOptions: [
        { text: "Featured Post", value: 1, textvalue: "FeaturedPost" },
        { text: "Latest Articles", value: 2, textvalue: "LatestArticles" }
      ],
       postTypeOptionsUpdates: [
        { text: "News of the Day", value: 3 , textvalue: "NewsofDay"},
        { text: "Featured Stories", value: 4, textvalue: "FeaturedStories" },
        { text: "Articles & Blogs", value: 5, textvalue: "ArticlesAndBlogs" },
      ],
    };
  },

  validations: {
    requestModel: {
      descriptionTitle: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(350),
      },
      // typeId: {
      //   required,
      // },
      // categoryId: {
      //   required,
      // },
      postType: {
        required,
      },
      categoryDescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(5000),
      },
       shortDescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(200),
      },
    },
  },

  components: {
    Toaster,
  },
  created() {
    this.getFiltersDropdowns();
  },
  props: {
    storyToEdit: {
      type: Object,
      default: null,
    },
    type:String
  },
  watch: {
    attachedFile(newValue, oldValue) {
      // console.log(newValue);
      if (newValue.type.includes("video")) this.isImageSelected = false;
      else this.isImageSelected = true;
      this.IsImageUpdated = true;
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.siteUrl = "";
              this.imageUrl = value;
              // console.log(value);
            })
            .catch(() => {
              this.imageUrl = null;
            });
        } else {
          this.imageUrl = null;
        }
      }
    },
  },
  methods: {
    getFiltersDropdowns() {
      this.getDropDownValues("CmsCategory");
      this.getDropDownValues("ShehriType");
    },
    getDropDownValues(id) {
      this.isLoading = true;
      axios
        .post("/KeyValuePair/GetDropDownListById", {
          type: id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            console.log(res);
            let options = res.data.data.map((x) => {
              return {
                text: x.value,
                value: x.key,
              };
            });
            if (id == "ShehriType") {
              this.shehriTypeOptions = options;
            } else if (id == "CmsCategory") {
              this.cmsCategoryOptions = options;
            }
          } else {
            return null;
          }
        });
    },
    AddStory() {
      this.isLoading = true;

      if (this.attachedFile) {
        var fileSize = 209715;
        if (this.attachedFile.size > fileSize) {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "File Size Limit is 200 KB"
          );
          this.attachedFile = null;
          return false;
        }
      }

      const formData = new FormData();
      console.log(this.requestModel);
      formData.append("Id", this.storyToEdit.id);

      formData.append("CategoryTitle", this.requestModel.descriptionTitle);
      formData.append("DescriptionTitle", this.requestModel.descriptionTitle);
      formData.append("ShortDescription", this.requestModel.shortDescription);
      formData.append("urducategoryDescription", this.requestModel.urduCategoryDescription);
      formData.append("urdushortDescription", this.requestModel.urduShortDescription);
      formData.append("urdudescriptionTitle", this.requestModel.urduDescriptionTitle);
      if(this.type==="stories"){
        formData.append("CategoryId", this.requestModel.categoryId);
      }

      // formData.append("TypeId", this.requestModel.typeId);
      formData.append("PostType", this.requestModel.postType);
      formData.append(
        "CategoryDescription",
        this.requestModel.categoryDescription
      );
      // formData.append("IsImageUpdated", this.IsImageUpdated);
      formData.append("LongDescription", this.requestModel.categoryDescription);
      // formData.append("Id", this.requestModel.Id);
      formData.append("expiryDate", this.requestModel.expiryDate);
      if (this.requestModel.youtubeLink) {
        formData.append("YoutubeLink", this.requestModel.youtubeLink);
      }

      if(!this.requestModel.youtubeLink){
        if (this.siteUrl === "" ) {
        formData.append("VideoOrImage", this.attachedFile);
      } else {
        formData.append("VideoOrImage", this.requestModel.videoOrImage);
      }
      }


      axios.post("/Shehri/UpdatePost", formData).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          this.$parent.getPage(1);
          this.$bvModal.hide("EditStoryModal");
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Story has been edited successfully"
          );
        } else {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "unable to edit story"
          );
        }
      });
    },

    checkFormValidity() {
      const valid = this.$refs.AddStoryForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {},
    showModal() {
      this.requestModel = JSON.parse(JSON.stringify(this.storyToEdit));
      this.attachedFile = null;
      // this.siteUrl = "https://smw.demo.itrooscloud.net/";
      this.siteUrl = "https://smw-live.azurewebsites.net";
      this.imageUrl = this.storyToEdit.videoOrImage;
      this.imageUrl = this.storyToEdit.videoOrImage;

      if(this.type==="stories"){
         let postTypeValue = this.postTypeOptions.find(
        (x) => x.textvalue == this.storyToEdit.postType
      );
      if (postTypeValue != null)
        this.requestModel.postType = postTypeValue.value;

        this.requestModel.expiryDate =
        this.storyToEdit.expiryDate === "" ||
        this.storyToEdit.expiryDate == null
          ? null
          : moment(String(this.storyToEdit.expiryDate)).format("YYYY-MM-DD");
      }else{
         let postTypeValue = this.postTypeOptionsUpdates.find(
        (x) => x.textvalue == this.storyToEdit.postType
      );
      if (postTypeValue != null)
        this.requestModel.postType = postTypeValue.value;

        this.requestModel.expiryDate =
        this.storyToEdit.expiryDate === "" ||
        this.storyToEdit.expiryDate == null
          ? null
          : moment(String(this.storyToEdit.expiryDate)).format("YYYY-MM-DD");
      }

    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }
      if(this.requestModel.categoryId===null && this.type==="stories"){
         this.$refs.toaster.showToast(
            "Error",
            "danger",
            "Please add Category"
          );
          return;
      }
      this.AddStory();
    },
  },
};
</script>
