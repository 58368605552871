<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="xl"
      id="AddVenturePackageModal"
      ref="modal"
      title="Add Video and Webinar"
      ok-title="Submit"
      cancel-title="Close"
      @show="resetModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.AddForm != undefined
          ? !this.$refs.AddForm.checkValidity()
          : true
      "
    >
      <form ref="AddForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Description"
              label-for="description"
              autocomplete="off"
            >
              <b-form-input
                id="description"
                v-model="requestModel[0].description"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div style="display: flex; justify-content: flex-end">
          <a href="javascript:void(0)" @click="addRow" title="Add Videos">
            <i class="fa fa-plus green-color icon-size"></i>
          </a>
        </div>

        <div class="row" v-for="(video, index) in requestModel" :key="index">
          <div class="col-md-5">
            <b-form-group
              label="Video Title"
              label-for="VideoTitle"
              autocomplete="off"
            >
              <b-form-input
                id="VideoTitle"
                v-model="video.vedioTitle"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-5">
            <b-form-group
              label="Video Link"
              label-for="Title"
              autocomplete="off"
            >
              <b-form-input
                id="ventureTitle"
                v-model="video.yutubeLink"
                placeholder="https://www.youtube.com/embed/Rt_UqUm38BI"
                type="url"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col">
            <b-form-group label="" label-for="icon" autocomplete="off">
              <div class="d-flex bd-highlight mb-3">
                <div class="ml-auto p-2 bd-highlight">
                  <a
                    href="javascript:void(0)"
                    @click="removeVideos(index)"
                    title="Remove Video"
                  >
                    <i
                      class="fa fa-trash icon-size"
                      aria-hidden="true"
                      style="color:red"
                    ></i>
                  </a>
                </div>
              </div>
            </b-form-group>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Cover"
              label-for="logo"
              autocomplete="off"
            >
              <b-form-file
                accept="image/jpeg, image/png, image/gif, video/mp4"
                @change="onSelectImage"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </div>
        </div> -->
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
export default {
  name: "AddVenturePackage",
  data() {
    return {
      moduleName: "Add Venture Package",
      file1: null,
      requestModel: [
        {
          description: null,
          vedioTitle: null,
          yutubeLink: null,
          ViiLearnType:10,
          Id:0,
         
        },
      ],
      submitted: false,
      // courseTypeOptions: null,
      courseCategoryOptions: null,
      courseTypeOptions: [
        { text: "Train Me", value: 1 },
        { text: "Train The Team", value: 2 },
        { text: "Skill Her", value: 3 },
      ],
      item:null
    };
  },
  // validations: {
  //   requestModel: {
  //     vedioTitle: {
  //       required,
  //       minLength: minLength(3),
  //       maxLength: maxLength(350),
  //     },
  //     description: {
  //       required,
  //       minLength: minLength(3),
  //       maxLength: maxLength(1000),
  //     },
  //     yutubeLink: {
  //       required,
  //     },
  //   },
  // },

  components: {
    Toaster,
  },
  created() {
    this.getDropdowns();
    this.getVlearnId();
  
  },
  methods: {
    addRow() {
      this.requestModel.push({
        description: null,
        vedioTitle: null,
        yutubeLink: null,
        type: 0,
      });
    },
    removeVideos(index){
      this.requestModel.splice(index,1);
    },
    onSelectImage(e) {
      console.log("ON SELECT CALLED");
      this.requestModel.logo = null;
      this.requestModel.logo = e.target.files[0];
      console.log("uploadFile : ", e.target.files[0].size);
      console.log("uploadFileName : ", this.requestModel.logo.name);

      // Allowing file Size
      var fileSize = 209715;

      if (this.requestModel.logo.size > fileSize) {
        // alert('File Size Limit is 200 KB');
        this.$refs.toaster.showToast(
          "Error",
          "danger",
          "File Size Limit is 200 KB"
        );
        e.target.value = "";
        this.requestModel.logo = null;
        return false;
      }
    },
    getDropdowns() {
      this.getCourseCategories();
    },
    getCourseCategories() {
      axios.get("/V_Learn/GetCourseCategory").then((res) => {
        this.isLoading = false;
        if (res.data.status == "Success") {
          let options = res.data.data.map((x) => {
            return {
              text: x.courseCategoryName,
              value: x.courseCategoryId,
            };
          });
          this.courseCategoryOptions = options;
        }
      });
    },
    getDropDownValues(id) {
      this.isLoading = true;
      axios
        .post("/KeyValuePair/GetDropDownListById", {
          type: id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            console.log(res);
            let options = res.data.data.map((x) => {
              return {
                text: x.value,
                value: x.key,
              };
            });
            if (id == "ShehriType") {
              this.shehriTypeOptions = options;
            } else if (id == "CmsCategory") {
              this.cmsCategoryOptions = options;
            }
          } else {
            return null;
          }
        });
    },
    Add() {
      this.isLoading = true;
      const formData = new FormData();
      console.log(this.requestModel);
      formData.append("Type", 10);
      formData.append("Description", this.requestModel.description);
      formData.append("longDescription", this.requestModel.longDescription);
      formData.append("Trial", this.requestModel.trial);
      console.log(formData);

     
     

      // this.requestModel[0].Id= this.item[0].id;
      // console.log(this.requestModel);

      for (const video of this.requestModel) {
        video.Id = this.item!=null ?  this.item[0].id : 0;
      }
      

      axios
        .post("/V_Learn/InsertVLearnVedios", this.requestModel)
        .then((res) => {
          this.isLoading = false;

          if (res.data.status == "Success") {
            this.$parent.getPage(1);
            this.resetModal();
            this.$bvModal.hide("AddVenturePackageModal");
            console.log(res);
            this.$refs.toaster.showToast(
              "Sucess",
              "success",
              "Videos and Webinar has been added successfully"
            );
          } else {
            this.$refs.toaster.showToast(
              "Error",
              "danger",
              "Unable to add"
            );
          }
        });
    },

    getVlearnId(){
       axios
        .post("/V_Learn/GetAll", {
          PageSize: this.perPage,
          PageNumber: this.page,
          Text: this.filteredText,
          viiLearnType: 10
        })
        .then((res) => {
          this.isLoading = false;

          if (res.data.status == "Success") {
          
            this.item = res.data.data;

          } 
        });
    },

    checkFormValidity() {
      const valid = this.$refs.AddForm.checkValidity();
      if(this.requestModel[0].vedioTitle===null || this.requestModel[0].yutubeLink===null){
         this.$refs.toaster.showToast(
              "Error",
              "danger",
              "Please fill all required fields"
            );
            return;
      }
      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      this.requestModel.title = "";
      this.requestModel.description = "";
      this.requestModel.longDescription = "";
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      this.Add();
    },
  },
};
</script>

<style scoped>
.icon-size {
  font-size: 20px;
  vertical-align: text-top;
}
.ml-auto.p-2.bd-highlight {
  margin-top: 15%;
}
.green-color {
  color: green;
}
svg {
  cursor: pointer;
}
</style>
