var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"modal",staticStyle:{"padding":"15px"},attrs:{"size":"xl","id":"AddVentureModal","title":"Add Venture","ok-title":"Submit","cancel-title":"Close","ok-disabled":this.$refs.AddForm != undefined
        ? !this.$refs.AddForm.checkValidity()
        : true},on:{"show":function($event){return _vm.resetModal()},"hidden":function($event){return _vm.resetModal()},"ok":_vm.handleOk}},[_c('form',{ref:"AddForm",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"What We Do","label-for":"WhatWeDo","autocomplete":"off"}},[_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.requestModel.whatWeDo.$error,
              },attrs:{"id":"WhatWeDo","type":"text","required":""},model:{value:(_vm.requestModel.whatWeDo),callback:function ($$v) {_vm.$set(_vm.requestModel, "whatWeDo", $$v)},expression:"requestModel.whatWeDo"}}),(_vm.submitted && !_vm.$v.requestModel.whatWeDo.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" WhatWeDo is required ")]):_vm._e(),(!_vm.$v.requestModel.whatWeDo.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" It must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.requestModel.whatWeDo.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 350 characters is reached ")]):_vm._e()],1)],1)]),_c('div',{staticClass:"row"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Image","label-for":"logo","autocomplete":"off"}},[_c('b-form-file',{class:{
                'is-invalid': _vm.submitted && _vm.$v.requestModel.image.$error,
              },attrs:{"accept":"image/jpeg, image/png, image/gif","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"change":_vm.onSelectImage}}),(_vm.submitted && !_vm.$v.requestModel.image.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Image is required ")]):_vm._e()],1)],1)])])]),_c('Toaster',{ref:"toaster"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }