<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="xl"
      id="JobDetailsModal"
      ref="modal"
      title="Job Details"
      ok-title="Close"
      ok-only
      @hidden="resetModal()"
      @show="getJobDetails()"
      @ok="$bvModal.hide('JobDetailsModal')"
    >
      <form ref="JobDetailsForm" @submit.stop.prevent="handleSubmit">
        <div class="custom-background" v-if="jobDetails != null">
          <div class="container">
            <div class="pt-3 pb-3 w3-container">
              <div class="row align-items-center">
                <div class="col-md-1">
                  <img
                    :src="
                      siteUrl +
                        jobDetails.companyLogoAddress
                    "
                    alt="Avatar"
                    class="w3-left w3-circle w3-margin-right"
                    style="width: 60px"
                  />
                </div>
                <div class="col-md-7">
                  <div class="">
                    <h2 class="custom-job-title text-uppercase mb-2">
                      <span v-if="jobDetails.jobTitle != null">
                        {{ jobDetails.jobTitle }}
                      </span>
                      <span v-else>--</span>
                    </h2>
                    <div class="row no-gutters custom-muted-text mb-2">
                      <div class="col-sm-4">
                        <div class="custom-row">
                          <span class="mr-2"
                            ><i class="fas fa-map-marker-alt"></i
                          ></span>
                          <span
                            >{{ jobDetails.cityName }},
                            {{ jobDetails.countryName }}</span
                          >
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="custom-row">
                          <span class="mr-2"
                            ><b-icon icon="clock-history"></b-icon
                          ></span>
                          <span>{{
                            jobDetails.jobExpiryDate | moment("MMMM Do YYYY")
                          }}</span>
                        </div>
                      </div>
                      <div class="col-sm-5">
                        <div class="custom-row">
                          <span class="mr-2"
                            ><i class="fas fa-money-bill"></i
                          ></span>
                          <span
                            >Rs {{ jobDetails.packageFrom }} -
                            {{ jobDetails.packageTo }}/month
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="custom-row mb-2">
                      <span>
                        <b-badge
                          class="custom-status-badge"
                          variant="primary"
                          >{{ jobDetails.category }}</b-badge
                        >
                      </span>
                      <div class="pl-1 pr-1"></div>
                      <span>
                        <b-badge class="custom-status-badge" variant="primary">
                          Min {{ jobDetails.experienceRequired }}
                          <span v-if="jobDetails.experienceRequired > 1">
                            years
                          </span>
                          <span v-else> year </span>
                        </b-badge>
                      </span>
                      <div class="pl-1 pr-1"></div>
                      <span>
                        <b-badge
                          class="custom-status-badge"
                          variant="primary"
                          >{{ jobDetails.jobTypeTitle }}</b-badge
                        >
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="text-right">
                    <span class="custom-heading-text text-black mb-2"
                      >Application ends:
                    </span>
                    <i class="text-danger">{{
                      jobDetails.jobExpiryDate | moment("MMMM Do YYYY")
                    }}</i>
                  </div>
                  <div style="float: right">
                    <div class="custom-row mb-3">
                      <!-- <span class="flex-grow-1">
                        <span v-if="isApplyNow_btn">
                          <a
                            @click="showOrHideModal(jobId)"
                            class="
                              btn btn-primary
                              custom-employer-btn custom-btn-apply-now
                              mb-2
                            "
                            >Apply Now</a
                          >
                        </span>
                        <span v-else>
                          <a
                            type="button"
                            class="
                              btn btn-primary
                              custom-employer-btn custom-btn-apply-now
                              mb-2
                              custom-btn-bookmark
                            "
                            >Applied</a
                          >
                        </span>
                      </span> -->

                      <!--                <div class=" flex-grow-1">
                  <button class="btn btn-primary custom-employer-btn custom-btn-apply-now mb-2">Apply Now</button>
                </div>-->
                      <div class="pl-2 pr-2"></div>
                      <!-- <div class="">
                        <button
                          class="
                            btn btn-primary
                            custom-employer-btn
                            custom-btn-apply-now
                            custom-btn-bookmark
                            mb-2
                          "
                        >
                          <b-icon icon="bookmark"></b-icon>
                        </button>
                      </div> -->
                    </div>

                    <div class="custom-row" v-if="isPending">
                      <div class="flex-grow-0">
                        <button
                          class="btn btn-primary btn-apply mb-3 cstm-btn-width"
                          @click="UpdateStatus(3)"
                        >
                          Accept
                        </button>
                      </div>
                      <div class="pl-1 pr-1"></div>
                      <div class="flex-grow-1">
                        <button
                          class="
                            btn btn-secondary btn-apply
                            mb-3
                            cstm-btn-width
                          "
                          @click="UpdateStatus(4)"
                        >
                          Reject
                        </button>
                      </div>
                      <div class="pl-1 pr-1"></div>
                      <div class="flex-grow-1"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--   Job Title Section End   -->
          <div class="row" v-if="enableRemarks">
            <div class="col-md-1"></div>
            <div class="col-md-9">
              <!--NAME INPUT-->
              <b-form-group label="" label-for="Remarks" autocomplete="off">
                <b-form-textarea
                  id="remarks"
                  v-model="remarks"
                  rows="2"
                  placeholder="Enter the reason or remarks here..."
                  max-rows="6"
                  required
                ></b-form-textarea>
              </b-form-group>
            </div>
            <div class="col-md-2">
              <button
                class="btn btn-primary btn-apply mb-3 cstm-btn-width"
                @click="SaveRemarks(4)"
              >
                Save
              </button>
            </div>
          </div>

          <!--  Job Detail Section Start   -->
          <div class="bg-white pt-2 mb-4">
            <div class="container">
              <div class="row">
                <div class="col-lg-8">
                  <div class=" pt-2 pb-4  mb-4">
                    <div class="">
                      <!--                <ShareNetwork
                    network="twitter"
                    :url="domainUrl+'/#'+jobUrl"
                    title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                    description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                    quote="The hot reload is so fast it\'s near instant. - Evan You"

                >
                  <i class="fab fah fa-lg fa-twitter"></i>
                  <span>Share on Twitter</span>
                </ShareNetwork>
                <ShareNetwork
                    network="facebook"
                    url="https://harshehar.com/#/post-detail/239"
                    title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                    description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                    quote="The hot reload is so fast it\'s near instant. - Evan You"
                    media="https://img.youtube.com/vi/QvaazAV6n60/0.jpg"

                >
                  Share on Facebook
                </ShareNetwork>
                <ShareNetwork
                    network="LinkedIn"
                    url="https://news.vuejs.org/issues/180"
                    title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                    description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                    quote="The hot reload is so fast it\'s near instant. - Evan You"


                >
                  Share on linkedIn
                </ShareNetwork>
                <ShareNetwork
                    network="WhatsApp"
                    url="https://harshehar.com/#/post-detail/239"
                    title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                    description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                    quote="The hot reload is so fast it\'s near instant. - Evan You"

                >
                  Share on whatsapp
                </ShareNetwork>-->
                      <div
                        class="custom-heading-text custom-job-detail-heading mb-4"
                      >
                        <span class=" custom-underline w-100 pb-2">
                          Category:
                        </span>
                      </div>
                      <div class="w3-container bg-white">
                        <div class="custom-job-detail-text">
                          <span class="custom-heading-text">{{
                            jobDetails.category
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="jobDetails.category != 'Labour Man Power'">
                    <div
                      class="custom-heading-text custom-job-detail-heading mb-4"
                    >
                      <span class=" custom-underline w-100 pb-2">
                        Job Specifications:
                      </span>
                    </div>
                    <div class="custom-job-detail-text">
                      <div class="text-justify">
                        {{ jobDetails.jobSpecification }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      jobDetails.category !=
                        'Graduate - Remote and Flexible Project & Internship'
                    "
                  >
                    <div
                      class="custom-heading-text custom-job-detail-heading mb-4"
                    >
                      <span class=" custom-underline w-100 pb-2">
                        Job Description:
                      </span>
                    </div>
                    <div class="custom-job-detail-text">
                      <div class="text-justify">
                        {{ jobDetails.jobDescription }}
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="
                      jobDetails.category ==
                        'Graduate - Remote and Flexible Project & Internship'
                    "
                  >
                    <div
                      class="custom-heading-text custom-job-detail-heading mb-4"
                    >
                      <span class=" custom-underline w-100 pb-2">
                        Project Details:
                      </span>
                    </div>
                    <div class="custom-job-detail-text">
                      <span>{{ jobDetails.projectDescription }}</span>
                    </div>
                  </div>
                  <div>
                    <div
                      class="custom-heading-text custom-job-detail-heading mb-4"
                    >
                      <span class=" custom-underline w-100 pb-2">
                        Roles & Responsibilities:
                      </span>
                    </div>
                    <div class="custom-job-detail-text">
                      <span>{{ jobDetails.jobResponsibility }}</span>
                    </div>
                  </div>

                  <div>
                    <div
                      class="custom-heading-text custom-job-detail-heading mb-4"
                    >
                      <span class=" custom-underline w-100 pb-2">
                        Rewards And Benefits:
                      </span>
                    </div>
                    <div class="custom-job-detail-text">
                      <span>{{ jobDetails.reward }}</span>
                    </div>
                  </div>
                  <div v-if="jobDetails.category == 'Person with Disability'">
                    <div>
                      <div
                        class="custom-heading-text custom-job-detail-heading mb-4"
                      >
                        <span class=" custom-underline w-100 pb-2">
                          Suitable disability as per job required:
                        </span>
                      </div>
                      <div class="custom-job-detail-text">
                        <span>{{ jobDetails.disabilityType }}</span>
                      </div>
                    </div>
                    <div>
                      <div
                        class="custom-heading-text custom-job-detail-heading mb-4"
                      >
                        <span class=" custom-underline w-100 pb-2">
                          Disability Certificate Obtained:
                        </span>
                      </div>
                      <div class="custom-job-detail-text">
                        <span v-if="jobDetails.fitToWorkCertificate == 1"
                          >Yes</span
                        >
                        <span v-else>No</span>
                      </div>
                    </div>
                    <div>
                      <div
                        class="custom-heading-text custom-job-detail-heading mb-4"
                      >
                        <span class=" custom-underline w-100 pb-2">
                          Facilities available for PWD's on premises:
                        </span>
                      </div>
                      <div class="custom-job-detail-text">
                        <span>{{ jobDetails.specialFacilityAvailable }}</span>
                      </div>
                    </div>
                    <div>
                      <div
                        class="custom-heading-text custom-job-detail-heading mb-4"
                      >
                        <span class=" custom-underline w-100 pb-2">
                          Special Instructions:
                        </span>
                      </div>
                      <div class="custom-job-detail-text">
                        <span>{{ jobDetails.specialInstruction }}</span>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      class="custom-heading-text custom-job-detail-heading mb-4"
                    >
                      <span class=" custom-underline w-100 pb-2">
                        Vii Learn Courses:
                      </span>
                    </div>
                    <div class="custom-job-detail-text">
                      <span>{{ jobDetails.vLearnCourseRequired }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="bg-light">
                    <div class="pt-4 pb-4 pl-4 pr-4">
                      <div
                        class="custom-heading-text custom-job-overview-heading mb-4"
                      >
                        <span class=" custom-underline w-100 pb-2">
                          Job Overview
                        </span>
                      </div>

                      <div>
                        <!--                <div class="custom-row">
                                    <div class="custom-job-icons"><i class="fas fa-border-all"></i></div>
                                    <span>Department</span>
                                  </div>
                                  <div class="custom-row">
                                    <div class="custom-job-icons"></div>
                                    <span class="custom-muted-text">{{ jobDetails.department }}</span>
                                  </div>-->
                        <div class="custom-row">
                          <div class="custom-job-icons">
                            <i class="far fa-calendar"></i>
                          </div>
                          <span>Date Posted</span>
                        </div>
                        <div class="custom-row">
                          <div class="custom-job-icons"></div>
                          <span class="custom-muted-text">{{
                            jobDetails.postedDate | formatDate
                          }}</span>
                        </div>
                        <div class="custom-row">
                          <div class="custom-job-icons">
                            <i class="fas fa-map-marker-alt"></i>
                          </div>
                          <span>Location</span>
                        </div>
                        <div class="custom-row">
                          <div class="custom-job-icons"></div>
                          <span class="custom-muted-text">{{
                            jobDetails.cityName
                          }}</span>
                        </div>
                        <div class="custom-row">
                          <div class="custom-job-icons">
                            <i class="fas fa-money-bill"></i>
                          </div>
                          <span>Offered Salary</span>
                        </div>
                        <div class="custom-row">
                          <div class="custom-job-icons"></div>
                          <span class="custom-muted-text"
                            >Rs {{ jobDetails.packageFrom }} -
                            {{ jobDetails.packageTo }}/Month
                          </span>
                        </div>
                        <div class="custom-row">
                          <div class="custom-job-icons">
                            <i class="fas fa-hourglass-end"></i>
                          </div>
                          <span>Expiration Date</span>
                        </div>
                        <div class="custom-row">
                          <div class="custom-job-icons"></div>
                          <span class="custom-muted-text">{{
                            jobDetails.jobExpiryDate | formatDate
                          }}</span>
                        </div>
                        <div>
                          <div class="custom-row">
                            <div class="custom-job-icons">
                              <i class="fas fa-user-tie"></i>
                            </div>
                            <span>Experience</span>
                          </div>
                          <div class="custom-row">
                            <div class="custom-job-icons"></div>
                            <span class="custom-muted-text">
                              {{ jobDetails.experiencelevel }}
                            </span>
                          </div>
                        </div>

                        <div class="custom-row">
                          <div class="custom-job-icons">
                            <i class="far fa-user"></i>
                          </div>
                          <span>Gender</span>
                        </div>
                        <div class="custom-row">
                          <div class="custom-job-icons"></div>
                          <span class="custom-muted-text">
                            {{ jobDetails.gender }}
                          </span>
                        </div>
                        <div class="custom-row">
                          <div class="custom-job-icons">
                            <i class="fas fa-sitemap"></i>
                          </div>
                          <span>Degree Level</span>
                        </div>
                        <div class="custom-row">
                          <div class="custom-job-icons"></div>
                          <span class="custom-muted-text">
                            {{ jobDetails.degreeLevels }}
                          </span>
                        </div>
                        <div class="custom-row">
                          <div class="custom-job-icons">
                            <i class="fas fa-sitemap"></i>
                          </div>
                          <span>Career Level</span>
                        </div>
                        <div class="custom-row">
                          <div class="custom-job-icons"></div>
                          <span class="custom-muted-text">
                            {{ jobDetails.careerLevel }}
                          </span>
                        </div>
                        <div class="custom-row">
                          <div class="custom-job-icons">
                            <i class="fab fa-telegram-plane"></i>
                          </div>
                          <span>Traveling Required</span>
                        </div>
                        <div class="custom-row">
                          <div class="custom-job-icons"></div>
                          <span class="custom-muted-text">
                            <span v-if="jobDetails.jobRequireTraveling === true"
                              >Yes</span
                            >
                            <span
                              v-else-if="
                                jobDetails.jobRequireTraveling === false
                              "
                              >No</span
                            >
                            <span v-else>--</span>
                          </span>
                        </div>
                        <div class="custom-row">
                          <div class="custom-job-icons">
                            <i class="far fa-building"></i>
                          </div>
                          <span>Industry</span>
                        </div>
                        <div class="custom-row">
                          <div class="custom-job-icons"></div>
                          <span class="custom-muted-text">
                            {{ jobDetails.industry }}
                          </span>
                        </div>
                        <div class="custom-row">
                          <div class="custom-job-icons">
                            <i class="far fa-clock"></i>
                          </div>
                          <span>Shift</span>
                        </div>
                        <div class="custom-row">
                          <div class="custom-job-icons"></div>
                          <span class="custom-muted-text">{{
                            jobDetails.shift
                          }}</span>
                        </div>
                        <div class="custom-row">
                          <div class="custom-job-icons">
                            <i class="fas fa-grip-horizontal"></i>
                          </div>
                          <span>Total Vacancies</span>
                        </div>
                        <div class="custom-row">
                          <div class="custom-job-icons"></div>
                          <span class="custom-muted-text">{{
                            jobDetails.noOfPosition
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="bg-light mt-2">
                    <div class="pt-4 pb-4 pl-4 pr-4">
                      <div
                        class="custom-heading-text custom-job-overview-heading mb-4"
                      >
                        <span class="custom-underline w-100 pb-2">
                          Skills:
                        </span>
                      </div>
                      <div class="w3-container bg-white">
                        <div class="custom-job-detail-text">
                          <span class="custom-heading-text">{{
                            jobDetails.skill
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
export default {
  name: "EditJobRequest",
  data() {
    return {
      siteUrl : "https://smw-live.azurewebsites.net",
      moduleName: "Job Details",
      jobDetails: null,
      remarks: null,
      enableRemarks: false,
      selectedGenderType: 0,
      genderType: [
        { value: 1, text: "Male" },
        { value: 2, text: "Female" },
        { value: 3, text: "Other" },
      ],
      statusOptions: [
        { text: "Accept", value: 2 },
        { text: "Reject", value: 3 },
      ],
    };
  },
  components: {
    Toaster,
  },

  props: {
    jobRequestToEdit: {
      type: Number,
      default: null,
    },
    isPending: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    UpdateStatus(status) {
      if (status == 4) {
        this.enableRemarks = true;
      } else {
        this.enableRemarks = false;
        this.remarks = null;
        this.CallUpdateStatus(status);
      }
    },
    SaveRemarks(status) {
      if (!this.remarks) {
        this.$refs.toaster.showToast(
          "Error",
          "danger",
          "Please enter the reason"
        );
        return;
      }
      this.CallUpdateStatus(status);
    },
    CallUpdateStatus(status) {
      axios
        .post("/job/AdminUpdateJobPost", {
          jobId: this.jobRequestToEdit,
          remarks: this.remarks,
          jobStatus: status,
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == "Success") {
            this.$refs.toaster.showToast(
              "Sucess",
              "success",
              "Status has been updated successfully"
            );
            this.$bvModal.hide("JobDetailsModal");
            this.enableRemarks = false;
            this.$parent.getPage();
          } else {
            this.$refs.toaster.showToast("Error", "danger", res.data.msg);
          }
        })
        .catch((err) => {
          console.warn("error during http call", err);
        });
    },
    checkFormValidity() {
      const valid = this.$refs.JobDetailsForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      this.Address = null;
      this.PhoneNumber = null;
      this.Name = null;
    },
    getJobDetails() {
      console.log("injobDetails");
      axios
        .get("/Job/Get?jobId=" + this.jobRequestToEdit)
        .then((res) => {
          console.log(res);
          if (res.data.status == "Success") {
            this.jobDetails = res.data.data;
          } else this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        })
        .catch((err) => {
          console.warn("error during http call", err);
        });
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      this.$bvModal.hide("JobDetailsModal");

      // Trigger submit handler
      // this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      this.$bvModal.hide("JobDetailsModal");
    },
  },
};
</script>
