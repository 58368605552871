  <template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="CandidateDetailsModal"
      ref="modal"
      title="Candidate Details"
      ok-only
      cancel-title="Close"
      @show="updateModel()"
      @hidden="resetModal()"
      @ok="$bvModal.hide('CandidateDetailsModal')"
    >
      <div v-if="candidateDetails != null">
        <div class="row details-heading">
          <div class="col-md-12">
            <span> Personal Information</span>
          </div>
        </div>
        <div class="row details-font">
          <div class="col-md-4">
            <b>Name &nbsp;</b><br /><span>{{ candidateDetails.fullName }}</span>
          </div>
          <div class="col-md-4">
            <b>Email &nbsp;</b><br /><span>{{ candidateDetails.email }}</span>
          </div>
          <div class="col-md-4">
            <b>Father Name &nbsp;</b><br /><span>{{
              candidateDetails.fatherName
            }}</span>
          </div>
          <div class="col-md-4">
            <b>Cnic &nbsp;</b><br /><span>{{ candidateDetails.cnic }}</span>
          </div>
          <div class="col-md-4">
            <b>Languages &nbsp;</b><br /><span>{{
              candidateDetails.languages
            }}</span>
          </div>
        </div>

        <div class="row details-heading">
          <div class="col-md-12">
            <span> Contact Information</span>
          </div>
        </div>
        <div class="row details-font">
          <div class="col-md-4">
            <b>Mobile# &nbsp;</b><br /><span>{{
              candidateDetails.mobileNumber
            }}</span>
          </div>

          <div class="col-md-4">
            <b>Domicile &nbsp;</b><br /><span>{{
              candidateDetails.domicile
            }}</span>
          </div>
          <div class="col-md-4">
            <b>Area &nbsp;</b><br /><span>{{ candidateDetails.area }}</span>
          </div>
          <div class="col-md-4">
            <b>City &nbsp;</b><br /><span>{{ candidateDetails.city }}</span>
          </div>
          <div class="col-md-4">
            <b>Country &nbsp;</b><br /><span>{{
              candidateDetails.country
            }}</span>
          </div>
          <div class="col-md-12">
            <b>Address &nbsp;</b><br /><span>{{
              candidateDetails.address
            }}</span>
          </div>
          <div class="col-md-12">
            <b>Address1 &nbsp;</b><br /><span>{{
              candidateDetails.address1
            }}</span>
          </div>
        </div>

        <div class="row details-heading">
          <div class="col-md-12">
            <span> Salary & Resume Information</span>
          </div>
        </div>
        <div class="row details-font">
          <div class="col-md-4">
            <b>Expected Salary &nbsp;</b><br /><span>{{
              candidateDetails.expectedSalary
            }}</span>
          </div>
          <div class="col-md-4">
            <b>Current Salary &nbsp;</b><br /><span>{{
              candidateDetails.currentSalary
            }}</span>
          </div>
          <div class="col-md-4">
            <b>Resume &nbsp;</b><br /><a
              style="text-decoration: none"
              :href="
                candidateDetails.resume != null &&
                candidateDetails.resume != '' &&
                candidateDetails.resume != undefined
                  ? siteUrl + candidateDetails.resume
                  : 'Javascript:void(0)'
              "
              target="_blank"
            >
              <span title="Download CV">
                <i
                  role="button"
                  style="color: #ea216c"
                  class="fas fa-download"
                ></i>
              </span>
            </a>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

      <script>
import axios from "axios";
export default {
  name: "CandidateDetails",

  data() {
    return {
      siteUrl:"https://smw-live.azurewebsites.net",
      moduleName: "Candidate Details",

      candidateDetails: null,
    };
  },

  props: {
    candidateId: {
      type: Number,
      default: null,
    },
  },
  methods: {
    getCandidateDetails() {
      axios
        .get("/Job/CandidateDetail?candidateid=" + this.candidateId)
        .then((res) => {
          if ((res.data.status == "Success")) {
            this.candidateDetails = res.data.data;
          } else this.candidateDetails = null;
        });
    },
    updateModel() {
      this.getCandidateDetails();
    },
    resetModal() {},
  },
};
</script>
