<template>
  <div>
    <div class="row justify-content-between align-items-center">
      <div class="col-md-9 customHeading">
        <h3>{{ moduleName }}</h3>
      </div>

      <!--==========Class dropdown============-->

      <!---SEARCH BOX-->
      <div class="col-md-2">
        <div class="searchbox input-group" role="group">
          <input
            class="form-control search-box my-search-box"
            type="text"
            placeholder="Title"
            aria-label="search"
            v-model="filteredText"
            @keydown.enter.prevent="getJobsRequests()"
          />

          <button
            class="search-button btn btn-primary filter"
            @click="getJobsRequests()"
          >
            <i class="fa fa-search search-icon"></i>
          </button>
        </div>
      </div>
      <div class="col-md-1 Add-button" style="text-align: center">
        <a href="javascript:void(0)">
          <div class="AddButton">
            <div class="icon">
              <i
                class="fas fa-arrow-alt-circle-right fa-1x"
                style="color: rgb(255, 50, 55)"
              >
              </i>
            </div>
            <div data-text="Add" class="name add-btn" v-b-modal.AddPackageModal>
              Add
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <hr
          color="#CACACA"
          style="border-top: 2px solid rgb(202, 202, 202); margin-top: 5px"
        />
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col">
          <table
            class="
              table table-striped table-borderless
              cutom-table
              table-striped table-sm
            "
            style="font-size: 85%"
          >
            <thead>
              <tr>
                <th role="columnheader">Title</th>
                <th role="columnheader">Regular Price</th>
                <th role="columnheader">Sale Price</th>
                <th role="columnheader">Jobs Limit</th>
                <th role="columnheader">Featured Jobs Limit</th>
                <th role="columnheader">Expiry</th>
                <!-- <th role="columnheader">Actions</th> -->
              </tr>
            </thead>
            <tbody>
              <!--IF STUDENTS ARRAY HAS NO VALUE THEN SHOW WITHOUT SHOWING DATA-->
              <tr v-if="jobsRequests == null">
                <td class="center-td" colspan="5">No Data</td>
              </tr>

              <tr v-else v-for="record in jobsRequests" :key="record.Id">
                <td role="cell">{{ record.packageName }}</td>

                <td role="cell">{{ record.regularPrice }}</td>
                <td role="cell">{{ record.salePrice }}</td>
                <td v-if="record.unlimitedPosting" role="cell">
                  Unlimited Jobs
                </td>
                <td v-else role="cell">{{ record.jobPostingLimit }}</td>
                <td role="cell">{{ record.featuredJobLimit }}</td>
                <td role="cell">
                  {{ record.futureDate | moment("MMMM Do YYYY") }}
                </td>
                <td role="cell">
                  {{ record.status ? "Active" : "InActive" }}
                </td>
                <td>
                  <span @click="editRecord(record)">
                    <i
                      role="button"
                      style="color: #ea216c"
                      class="fas fa-edit"
                    ></i>
                  </span>
                  &nbsp;|&nbsp;
                  <span @click="deleteRecord(record.packageId)">
                    <i
                      role="button"
                      style="color: #ea216c"
                      class="far fa-trash-alt"
                    ></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--PAGINATION START-->
      <div class="row justify-content-between align-items-center">
        <div class="col-md-2">
          <div class="showPages">
            <select
              class="form-control"
              id="selClass"
              v-model="perPage"
              @change="getJobsRequests()"
            >
              <option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-8 d-flex justify-content-center">
          <div class="overflow-auto">
            <b-pagination
              v-model="page"
              :total-rows="rows"
              :per-page="perPage"
              limit="3"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              @change="getPage"
            ></b-pagination>
          </div>
        </div>

        <div class="col-md-2">
          <input
            class="form-control search-box page-no-search"
            type="text"
            v-bind:placeholder="page"
            aria-label="search"
            v-model.number="pageNumOnSearch"
            style="height: 38px"
          />

          <button
            class="search-button btn btn-primary search page-no-search-btn"
            @click="search()"
          >
            GO
          </button>
        </div>
      </div>
      <!--/PAGINATION END-->
      <!--SPINNER-->
      <Spinner
        v-if="isLoading"
        message="Loading..."
        size="medium"
        line-fg-color="rgb(222 224 219"
        line-bg-color="rgb(68 66 62)"
      >
      </Spinner>
      <!--/SPINNER-->
    </div>
    <!-- <AddClientModal /> -->
    <Toaster ref="toaster" />
    <EditPackageModal
      :packageToEdit="recordToEdit"
      v-if="recordToEdit != null"
    />
    <AddPackageModal />
    <DeleteModal
      :msgForModal="msgForModal"
      :apiEndPoint="apiEndPoint"
      :heading="deleteModalHeading"
      :entityId="recordToDelete"
      :isHttpPost="true"
    />
  </div>
</template>

<script>
import axios from "axios";
import Toaster from "../../common/pages/toaster.vue";
// import config from '../config.js';
import Spinner from "vue-simple-spinner";
import AddPackageModal from "./modals/add-package-modal.vue";
import DeleteModal from "../../common/modals/delete-modal.vue";
import EditPackageModal from "./modals/edit-package-modal.vue";

export default {
  name: "Packages",
  data() {
    return {
      moduleName: "Packages",
      jobsRequests: [],
      isLoading: false,
      recordToEdit: null,
      recordToDelete: null,
      msgForModal: null,
      apiEndPoint: null,
      deleteModalHeading: null,

      jobPostPackageId: 1,
      dateConvert: null,
      expiredAfter: null,
      featuredJobLimit:null,
      futureDate: null,
      jobPostingLimit: null,
      packageName: null,
      regularPrice: null,
      salePrice: null,
      unlimitedPosting: false,
      packageId: null,
      pageNumOnSearch: "",
      filteredText: "",
      rows: 0,
      page: 1,
      options: [
        { text: "Show 2", value: 2 },
        { text: "Show 10", value: 10 },
        { text: "Show 25", value: 25 },
        { text: "Show 50", value: 50 },
        { text: "Show 100", value: 100 },
      ],
      perPage: 25,
    };
  },

  methods: {
    getPage(page) {
      this.page = page;
      this.getJobsRequests();
    },

    editRecord(record) {
      this.recordToEdit = record;
      setTimeout(() => {
        this.$bvModal.show("EditPackageModal");
      }, 100);
    },
    deleteRecord(id) {
      this.recordToDelete = id;
      this.msgForModal = "Confirm to delete !";
      this.apiEndPoint = "/job/DeletePackage?id=";
      this.deleteModalHeading = "Delete Package";
      this.$bvModal.show("DeleteModal");
    },

    getJobsRequests() {
      // this.jobsRequests.push({Id:1, Title: "Standard", JobsAllowed:100, Price: 5000,Status:1 })
      this.isLoading = true;
      axios.post("/Job/GetAllPackage",{
          PageSize:this.perPage,
          PageNumber:this.page,
          Text:this.filteredText
        }).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          
          this.jobsRequests = res.data.data;
          this.rows = res.data.totalCount;
        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        } else {
          this.jobsRequests = null;
        }
      });
    },

    search() {
      this.page = this.pageNumOnSearch;
      this.getJobsRequests();
    },
  },
  created() {
    this.getJobsRequests();
    // this.getStudents();
    //localStorage.setItem("mytime", Date.now());
  },
  components: {
    Spinner,
    EditPackageModal,
    DeleteModal,
    Toaster,
    AddPackageModal,
  },
};
</script>

<style scoped>
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
select#class-dropdown {
  width: 80%;
  margin-left: 16%;
}
</style>