<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="md"
      id="EditJobStatusModal"
      ref="modal"
      title="Edit Job Status"
      ok-title="Submit"
      cancel-title="Close"
      @hidden="resetModal()"
      @show="updateModel()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.EditClientForm != undefined
          ? !this.$refs.EditClientForm.checkValidity()
          : true
      "
    >
      <form ref="EditClientForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
         <p class="text-danger"><b>Note:</b> It will not get submitted unless there's some change</p>
        </div>
        <div class="row">
          <div class="col-md-4"></div>
          <div class="col-md-4">
            <b-form-group
              label="Status"
              label-for="jobStatus"
              autocomplete="off"
            >
              <b-form-select
                @change="changeRemarks()"
                v-model="jobStatus"
                :options="statusOptions"
                value-field="value"
                text-field="text"
              >
                <!-- <b-form-select-option :value="null" disabled hidden
                  >Select</b-form-select-option
                > -->
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-4"></div>
        </div>
        <div class="row">
          <div v-if="enableRemarks" class="col-md-12">
            <!--NAME INPUT-->
            <b-form-group
              label="Remarks"
              label-for="Remarks"
              autocomplete="off"
            >
              <b-form-textarea
                id="remarks"
                v-model="remarks"
                :class="{'is-invalid':submitted && $v.remarks.$error}"
                rows="3"
                max-rows="6"
                required
              ></b-form-textarea>

              <div v-if="submitted && !$v.remarks.required" class="invalid-feedback">
               Remarks is required
             </div>
             <div v-if="!$v.remarks.minLength" class="invalid-feedback">
                Remarks must contain at least 3 characters
             </div>
              <div v-if="!$v.remarks.maxLength" class="invalid-feedback">
               The maximum length of 50 characters is reached
             </div>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "EditJobRequest",
  data() {
    return {
      moduleName: "Edit Job Status",
      enableRemarks: false,
      jobId:null,
      cityName: null,
      packageFrom: null,
      packageTo: null,
      noOfJobs: null,
      preference: null,
      jobExpiryDate: null,
      jobStatus: null,
      remarks: null,
      jobTitle: null,
      companyName: null,
      countryName: null,

      degreeLevelTitle: null,
      requiredJobExperience: null,
      jobTypeTitle: null,
      jobSubTypeTitle: null,
      weeklyWorkingHour: null,
      workLocation: null,

      travelMiles: null,
      travelTimePercent: null,
      assistedHiringType: null,
      jobHiringType: null,
      isSignLanguageRequrired: null,
      projectDescription: null,
      submitted: false,

      statusOptions: [
        { text: "Select", value: null || 0, disabled: true },
        //  { text: "Select", value: 0 },
        { text: "Accept", value: 3 },
        { text: "Reject", value: 4 },
      ],
    };
  },
  validations: {
    remarks: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(50),
    },
  },

  components: {
    Toaster,
  },

  props: {
    jobRequestToEdit: {
      type: Object,
      default: null,
    },
  },
  methods: {
    changeRemarks() {
      if (this.jobStatus == 4) {
        this.enableRemarks = true;
      } else {
        this.enableRemarks = false;
        this.remarks = null;
      }
    },
    AddClient() {
      let req = {
        jobId: this.jobId, 
        remark: this.remarks,
        jobStatus: this.jobStatus,
      };
      console.log(req);
      axios
        .post("/job/AdminUpdateJobPost", {
          jobId: this.jobId,
          remark: this.remarks,
          jobStatus: this.jobStatus,
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == "Success") {
            this.$refs.toaster.showToast(
              "Sucess",
              "success",
              "Status has been updated successfully"
            );
            this.$bvModal.hide("EditJobStatusModal");
            this.$parent.getPage(1);
          } else if (res.data.status == -1) {
            this.$refs.toaster.showToast("Error", "danger", res.data.msg);
          }
        })
        .catch((err) => {
          console.warn("error during http call", err);
        });
    },

    checkFormValidity() {
      const valid = this.$refs.EditClientForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      this.enableRemarks = false;
      this.jobStatus = null;
    },
    updateModel() {
      console.log(this.jobRequestToEdit);
      this.jobId = this.jobRequestToEdit.jobId;
      this.jobStatus = this.jobRequestToEdit.jobsStatus;
      this.remarks = this.jobRequestToEdit.remarks;
      this.jobTitle = this.jobRequestToEdit.jobTitle;
      this.companyName = this.jobRequestToEdit.companyName;
      this.countryName = this.jobRequestToEdit.countryName;

      this.cityName = this.jobRequestToEdit.cityName;
      this.packageFrom = this.jobRequestToEdit.packageFrom;
      this.packageTo = this.jobRequestToEdit.packageTo;
      this.noOfJobs = this.jobRequestToEdit.noOfJobs;
      this.preference = this.jobRequestToEdit.preference;
      this.jobExpiryDate = this.jobRequestToEdit.jobExpiryDate;

      this.degreeLevelTitle = this.jobRequestToEdit.degreeLevelTitle;
      this.requiredJobExperience = this.jobRequestToEdit.requiredJobExperience;
      this.jobTypeTitle = this.jobRequestToEdit.jobTypeTitle;
      this.jobSubTypeTitle = this.jobRequestToEdit.jobSubTypeTitle;
      this.weeklyWorkingHour = this.jobRequestToEdit.weeklyWorkingHour;
      this.workLocation = this.jobRequestToEdit.workLocation;

      this.travelMiles = this.jobRequestToEdit.travelMiles;
      this.travelTimePercent = this.jobRequestToEdit.travelTimePercent;
      this.assistedHiringType = this.jobRequestToEdit.assistedHiringType;
      this.jobHiringType = this.jobRequestToEdit.jobHiringType;
      this.isSignLanguageRequrired = this.jobRequestToEdit.isSignLanguageRequrired;
      this.projectDescription = this.jobRequestToEdit.projectDescription;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      this.$v.$touch();
      if(this.enableRemarks===true){
        if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }
      }
     
      this.AddClient();
    },
  },
};
</script>
