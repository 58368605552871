var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"modal",staticStyle:{"padding":"15px"},attrs:{"size":"xl","id":"AddVenturePartnerModal","title":'Add ' + _vm.pageType,"ok-title":"Submit","cancel-title":"Close","ok-disabled":this.$refs.AddForm != undefined
        ? !this.$refs.AddForm.checkValidity()
        : true},on:{"show":function($event){return _vm.resetModal()},"hidden":function($event){return _vm.resetModal()},"ok":_vm.handleOk}},[_c('form',{ref:"AddForm",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(_vm.isTitle)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Title","label-for":"Title","autocomplete":"off"}},[_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.requestModel.title.$error,
              },attrs:{"id":"ventureTitle","type":"text","required":""},model:{value:(_vm.requestModel.title),callback:function ($$v) {_vm.$set(_vm.requestModel, "title", $$v)},expression:"requestModel.title"}}),(_vm.submitted && !_vm.$v.requestModel.title.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Title is required ")]):_vm._e(),(!_vm.$v.requestModel.title.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Title must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.requestModel.title.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 350 characters is reached ")]):_vm._e()],1)],1)]):_vm._e(),(_vm.isShortDescription)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Short Description","label-for":"description","autocomplete":"off"}},[_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.requestModel.description.$error,
              },attrs:{"id":"description","type":"text","required":""},model:{value:(_vm.requestModel.description),callback:function ($$v) {_vm.$set(_vm.requestModel, "description", $$v)},expression:"requestModel.description"}}),(_vm.submitted && !_vm.$v.requestModel.description.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Description is required ")]):_vm._e(),(!_vm.$v.requestModel.description.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Description must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.requestModel.description.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 1000 characters is reached ")]):_vm._e()],1)],1)]):_vm._e(),(_vm.isLongDescription)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Long Description","label-for":"longDescription","autocomplete":"off"}},[_c('ckeditor',{class:{
                'is-invalid':
                  _vm.submitted && _vm.$v.requestModel.longDescription.$error,
              },model:{value:(_vm.requestModel.longDescription),callback:function ($$v) {_vm.$set(_vm.requestModel, "longDescription", $$v)},expression:"requestModel.longDescription"}}),(_vm.submitted && !_vm.$v.requestModel.longDescription.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Long Description is required ")]):_vm._e(),(!_vm.$v.requestModel.longDescription.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Long Description must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.requestModel.longDescription.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 5000 characters is reached ")]):_vm._e()],1)],1)]):_vm._e(),(_vm.isImage)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group",attrs:{"autocomplete":"off"}},[_c('label',{attrs:{"label-for":"Image"}},[_vm._v("Image")]),(_vm.pageType === 'Our Mentor Sessions')?_c('span',{staticClass:"ml-1 text-muted small"},[_c('i',[_vm._v("(Image must be square, and at least 512 pixels wide and tall.)")])]):_vm._e(),_c('b-form-file',{class:{
                'is-invalid': _vm.submitted && _vm.$v.requestModel.image.$error,
              },attrs:{"accept":"image/jpeg, image/png, image/gif, image/webp","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"change":_vm.onSelectImage}}),(_vm.submitted && !_vm.$v.requestModel.image.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Image is required ")]):_vm._e()],1)])]):_vm._e()])]),_c('Toaster',{ref:"toaster"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }