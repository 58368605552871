<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="AddEventModal"
      ref="modal"
      title="Add Event"
      ok-title="Submit"
      cancel-title="Close"
      @show="resetModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.AddEventForm != undefined
          ? !this.$refs.AddEventForm.checkValidity()
          : true
      "
    >
      <form ref="AddEventForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <!--NAME INPUT-->
            <b-form-group label="Title" label-for="title" autocomplete="off">
              <b-form-input
                id="title"
                v-model="requestModel.title"
                 :class="{
                  'is-invalid': submitted && $v.requestModel.title.$error,
                }"
                type="text"
                required
              ></b-form-input>
                <div
                v-if="submitted && !$v.requestModel.title.required"
                class="invalid-feedback"
              >
                Title is required
              </div>
              <div
                v-if="!$v.requestModel.title.minLength"
                class="invalid-feedback"
              >
                Title must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.title.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>
          </div>
           <div class="col-md-6">
            <b-form-group
              label="Date"
              label-for="datepicker-1"
              autocomplete="off"
            >
              <b-form-input
                type="date"
                v-model="requestModel.date"
              ></b-form-input>
            </b-form-group>
          </div>
          <!-- <div class="col-md-6">
            <b-form-group
              label="Date"
              label-for="date"
              required
              autocomplete="off"
            >
              <b-form-input
                id="designation"
                v-model="requestModel.date"
                 :class="{
                  'is-invalid': submitted && $v.requestModel.date.$error,
                }"
                type="text"
                required
              ></b-form-input>
              <div
                v-if="submitted && !$v.requestModel.date.required"
                class="invalid-feedback"
              >
                Date is required
              </div>
            </b-form-group>
          </div> -->
        </div>
        
      
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Picture"
              label-for="Address"
              autocomplete="off"
            >
              <b-form-file
                accept="image/jpeg, image/png, image/gif"
                v-model="requestModel.image"
                 :class="{
                  'is-invalid': submitted && $v.requestModel.image.$error,
                }"
                :state="Boolean(requestModel.image)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
              <div v-if="submitted && !$v.requestModel.image.required" class="invalid-feedback">
                Image is required
              </div>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "AddPackage",
  data() {
    return {
      moduleName: "Add Event",
      statusOptions: [
        { text: "Select", value: null || 0, disabled: true },
        //  { text: "Select", value: 0 },
        { text: "Days", value: 1 },
        { text: "Month", value: 2 },
        { text: "Year", value: 3 },
      ],
      requestModel: {
        title: null,
        date: new Date().toISOString().slice(0, 10),
        image: null
      },
      submitted: false,
    };
  },

  validations: {
    requestModel: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(350),
      },
      image: {
        required,
      },
    },
  },

  components: {
    Toaster,
  },
  methods: {
    Add() {
      this.isLoading = true;

       if (this.requestModel.image) {
        var fileSize = 209715;
        if (this.requestModel.image.size > fileSize) {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "File Size Limit is 200 KB"
          );
          this.requestModel.image = null;
          return false;
        }
      }


      const formData = new FormData();
      console.log(this.requestModel);

      formData.append("Title", this.requestModel.title);
      formData.append("Date", this.requestModel.date);
      formData.append("Image", this.requestModel.image);
      formData.append("Type", 2);
      
      axios.post("/Employee/Insert", formData).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          this.$parent.getPage(1);
          this.$bvModal.hide("AddEventModal");
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Testimonial has been added successfully"
          );
        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        } else {
          this.jobsRequests = null;
        }
      });
    },

    checkFormValidity() {
      const valid = this.$refs.AddEventForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      this.Address = null;
      this.PhoneNumber = null;
      this.Name = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }
      this.Add();
    },
  },
};
</script>
