<template>
  <div id="app" v-if="$auth.ready()">
     <!-- v-if="$auth.ready()" -->
  <!--  v-if = $auth.check() -->
   <!-- v-if = $auth.check() -->
    <div v-if = $auth.check()>
      <Header ></Header>
      <Sidebar :collapsed = isCollapsed @sideBarToggled= "changeContentWidth"  />
    </div>
    <Content :paddingClass = contentClass />

  </div>
</template>

<script>
import Content from './components/content.vue'
import Sidebar from './components/sidebar.vue'
import Header from './components/header.vue'
export default {
  name: 'App',
  components: {
    Content,
    Header,
    Sidebar,
  },
  data() {
    return {
      isCollapsed:false,
      contentClass:""

  }
  },
  mounted()
  {
    this.handleMobile();
  },
  created() {

        window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      changeContentWidth(value)
      {
        console.log('padding-done')
        if(value)
        {
          this.contentClass = 'less-padding';
        }
        else
        {
          this.contentClass = "more-padding"
        }
      },
        handleResize() {
            if(window.innerWidth <= 991)
            {
              this.isCollapsed = true;
              this.contentClass = 'less-padding';
            }
            else
            {
              this.isCollapsed = false;
              this.contentClass = 'more-padding';
            }

            // this.window.height = window.innerHeight;
        },
        handleMobile()
        {
           if(window.innerWidth <= 991)
            {
              this.isCollapsed = true;
            }
        }
    }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}

</style>
