  <template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="AddVoucherModal"
      ref="modal"
      title="Add Webinar"
      ok-title="Submit"
      cancel-title="Close"
      @show="resetModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.AddPackageForm != undefined
          ? !this.$refs.AddPackageForm.checkValidity()
          : true
      "
    >
      <form ref="AddPackageForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <!--NAME INPUT-->
            <b-form-group
              label="Price"
              label-for="packageName"
              autocomplete="off"
            >
              <b-form-input
                id="packageName"
                v-model="requestModel.packageName"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Expiry Date"
              label-for="regularPrice"
              autocomplete="off"
            >
            <b-form-datepicker id="datepicker-1"  placeholder="Choose a date" local="en" required></b-form-datepicker>
            </b-form-group>
          </div>
        
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

      <script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
export default {
  name: "AddPackage",
  data() {
    return {
      moduleName: "Add Package",
      Name: null,
      statusOptions: [
        { text: "Select", value: null || 0, disabled: true },
        //  { text: "Select", value: 0 },
        { text: "Days", value: 1 },
        { text: "Month", value: 2 },
        { text: "Year", value: 3 },
      ],
      PhoneNumber: null,
      Address: null,
      requestModel: {
        packageName: null,
        regularPrice: null,
        salePrice: null,
        expiredAfter: null,
        jobPostingLimit: null,

        featuredJobLimit: null,
        unlimitedPosting: false,
        dateConvert: 1,
      },
    };
  },

  components: {
    Toaster,
  },
  methods: {
    AddPackage() {
      this.isLoading = true;
      this.requestModel.regularPrice = Number(this.requestModel.regularPrice);
      this.requestModel.salePrice = Number(this.requestModel.salePrice);
      this.requestModel.expiredAfter = Number(this.requestModel.expiredAfter);
      this.requestModel.jobPostingLimit = Number(
        this.requestModel.jobPostingLimit
      );
      this.requestModel.featuredJobLimit = Number(
        this.requestModel.featuredJobLimit
      );
      console.log(this.requestModel);
      axios.post("/Job/AddPackage", this.requestModel).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          this.$parent.getPage(1);
          this.$bvModal.hide("AddVoucherModal");
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Package has been added successfully"
          );
        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        } else {
          this.jobsRequests = null;
        }
      });
    },

    checkFormValidity() {
      const valid = this.$refs.AddPackageForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      this.Address = null;
      this.PhoneNumber = null;
      this.Name = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.AddPackage();
    },
  },
};
</script>
