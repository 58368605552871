  <template>
  <div>
    <b-modal
      style="padding: 15px"
      size="md"
      id="EditCompanyModal"
      ref="modal"
      title="Edit Company Status"
      ok-title="Submit"
      cancel-title="Close"
      @show="updateModel()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.EditCompanyForm != undefined
          ? !this.$refs.EditCompanyForm.checkValidity()
          : true
      "
    >
      <form ref="EditCompanyForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
         <p class="text-danger"><b>Note:</b> It will not get submitted unless there's some change</p>
        </div>
        <div class="row">
          <div class="col-md-4">
            <b-form-group
              label="Status"
              label-for="jobStatus"
              autocomplete="off"
            >
              <b-form-select
                @change="changeRemarks()"
                v-model="jobStatus"
                :options="statusOptions"
                value-field="value"
                text-field="text"
              >
                <!-- <b-form-select-option :value="null" disabled hidden
                  >Select</b-form-select-option
                > -->
              </b-form-select>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

      <script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
export default {
  name: "EditPackage",
  data() {
    return {
      moduleName: "Edit Company Status",

      requestModel: {
        companyId: null,
        status: null,
      },
      statusOptions: [
        { text: "Active", value: 1 },
        { text: "Suspend", value: 2 },
      ],
    };
  },

  components: {
    Toaster,
  },
  props: {
    companyToEdit: {
      type: Object,
      default: null,
    },
  },
  methods: {
    EditPackage() {
      this.isLoading = true;
      axios.post("/Job/UpdatePackage", this.requestModel).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Package has been added successfully"
          );
        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        } else {
          this.jobsRequests = null;
        }
      });

      this.$bvModal.hide("EditCompanyModal");
    },

    checkFormValidity() {
      const valid = this.$refs.EditCompanyForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    updateModel() {
      console.log(this.companyToEdit);
      this.requestModel = this.companyToEdit;
    },
    resetModal() {},
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.EditPackage();
    },
  },
};
</script>
