  <template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="ApplicantDetailsModal"
      ref="modal"
      title="Applicant Details"
      ok-only
      cancel-title="Close"
      @show="updateModel()"
      @hidden="resetModal()"
      @ok="$bvModal.hide('ApplicantDetailsModal')"
    >
      <div v-if="applicantDetails != null">
        <div class="row details-heading">
          <div class="col-md-12">
            <span> Personal Information</span>
          </div>
        </div>
        <div class="row details-font">
          <div class="col-md-4">
            <b>Name &nbsp;</b><br /><span>{{ applicantDetails.name }}</span>
          </div>
          <div class="col-md-4">
            <b>Email &nbsp;</b><br /><span>{{ applicantDetails.email }}</span>
          </div>
          <div class="col-md-4">
            <b>Father Name &nbsp;</b><br /><span>{{
              applicantDetails.fatherName
            }}</span>
          </div>
          <div class="col-md-4">
            <b>Cnic &nbsp;</b><br /><span>{{ applicantDetails.cnic }}</span>
          </div>
          <div class="col-md-4">
            <b>Languages &nbsp;</b><br /><span>{{
              applicantDetails.languages
            }}</span>
          </div>
        </div>

        <div class="row details-heading">
          <div class="col-md-12">
            <span> Contact Information</span>
          </div>
        </div>
        <div class="row details-font">
          <div class="col-md-4">
            <b>Mobile# &nbsp;</b><br /><span>{{
              applicantDetails.mobileNumber
            }}</span>
          </div>

          <div class="col-md-4">
            <b>Domicile &nbsp;</b><br /><span>{{
              applicantDetails.domicile
            }}</span>
          </div>
          <div class="col-md-4">
            <b>Area &nbsp;</b><br /><span>{{ applicantDetails.area }}</span>
          </div>
          <div class="col-md-4">
            <b>City &nbsp;</b><br /><span>{{ applicantDetails.city }}</span>
          </div>
          <div class="col-md-4">
            <b>Country &nbsp;</b><br /><span>{{
              applicantDetails.country
            }}</span>
          </div>
          <div class="col-md-12">
            <b>Address &nbsp;</b><br /><span>{{
              applicantDetails.address
            }}</span>
          </div>
          <div class="col-md-12">
            <b>Address1 &nbsp;</b><br /><span>{{
              applicantDetails.address1
            }}</span>
          </div>
        </div>

        <div class="row details-heading">
          <div class="col-md-12">
            <span> Salary & Resume Information</span>
          </div>
        </div>
        <div class="row details-font">
          <div class="col-md-4">
            <b>Expected Salary &nbsp;</b><br /><span>{{
              applicantDetails.expectedSalary
            }}</span>
          </div>
          <div class="col-md-4">
            <b>Current Salary &nbsp;</b><br /><span>{{
              applicantDetails.currentSalary
            }}</span>
          </div>
          <div class="col-md-4">
            <b>Resume &nbsp;</b><br /><a
              style="text-decoration: none"
              :href="
                applicantDetails.resume != null &&
                applicantDetails.resume != '' &&
                applicantDetails.resume != undefined
                  ? siteUrl + applicantDetails.resume
                  : 'Javascript:void(0)'
              "
              target="_blank"
            >
              <span title="Download CV">
                <i
                  role="button"
                  style="color: #ea216c"
                  class="fas fa-download"
                ></i>
              </span>
            </a>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

      <script>
import axios from "axios";
export default {
  name: "ApplicantDetails",

  data() {
    return {
      siteUrl: "https://smw-live.azurewebsites.net",
      moduleName: "Applicant Details",

      applicantDetails: null,
    };
  },

  props: {
    applicantId: {
      type: Number,
      default: null,
    },
    employeeId: {
      type: Number,
      default: null,
    },
  },
  methods: {
    getApplicantDetails() {
      let model= {

        jobapplicantId: this.applicantId,
        employeeId: this.employeeId

      }
          // .get("/Job/JobApplicantDetail?jobapplicantid=" + this.applicantId)
      axios
        .post("/Job/JobApplicantDetail", model)
        .then((res) => {
          if (res.data.status == "Success") {
            this.applicantDetails = res.data.data;
          } else this.applicantDetails = null;
        });
    },
    updateModel() {
      this.getApplicantDetails();
    },
    resetModal() {},
  },
};
</script>
