<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="EditVenturePartnerModal"
      ref="modal"
      :title="title"
      ok-title="Submit"
      cancel-title="Close"
      @show="showModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.EditForm != undefined
          ? !this.$refs.EditForm.checkValidity()
          : true
      "
    >
      <form ref="EditForm" @submit.stop.prevent="handleSubmit">
         <div class="row">
         <p class="text-danger"><b>Note:</b> It will not get submitted unless there's some change</p>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="Title" label-for="Title" autocomplete="off">
              <b-form-input
                id="ventureTitle"
                v-model="ventureToEdit.title"
                :class="{
                  'is-invalid': submitted && $v.ventureToEdit.title.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.ventureToEdit.title.required"
                class="invalid-feedback"
              >
                Title is required
              </div>
              <div
                v-if="!$v.ventureToEdit.title.minLength"
                class="invalid-feedback"
              >
                Title must contain at least 3 characters
              </div>
              <div
                v-if="!$v.ventureToEdit.title.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Urdu Title" label-for="Title" autocomplete="off">
              <b-form-input
                id="ventureTitle"
                v-model="ventureToEdit.urduTitle"
                :class="{
                  'is-invalid': submitted && $v.ventureToEdit.urduTitle.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.ventureToEdit.urduTitle.required"
                class="invalid-feedback"
              >
                Title is required
              </div>
              <div
                v-if="!$v.ventureToEdit.urduTitle.minLength"
                class="invalid-feedback"
              >
                Title must contain at least 3 characters
              </div>
              <div
                v-if="!$v.ventureToEdit.urduTitle.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Type" label-for="Type" autocomplete="off">
              <b-form-select
                id="Type"
                v-model="ventureToEdit.type"
                :options="typeOptions"
                :disabled="IsWhyVenture"
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select Venture Type</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Short Description"
              label-for="description"
              autocomplete="off"
            >
              <b-form-input
                id="description"
                v-model="ventureToEdit.description"
                :class="{
                  'is-invalid':
                    submitted && $v.ventureToEdit.description.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.ventureToEdit.description.required"
                class="invalid-feedback"
              >
                Description is required
              </div>
              <div
                v-if="!$v.ventureToEdit.description.minLength"
                class="invalid-feedback"
              >
                Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.ventureToEdit.description.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Urdu Short Description"
              label-for="description"
              autocomplete="off"
            >
              <b-form-input
                id="description"
                v-model="ventureToEdit.urduDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.ventureToEdit.urduDescription.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.ventureToEdit.urduDescription.required"
                class="invalid-feedback"
              >
                Description is required
              </div>
              <div
                v-if="!$v.ventureToEdit.urduDescription.minLength"
                class="invalid-feedback"
              >
                Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.ventureToEdit.urduDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Long Description"
              label-for="longDescription"
              autocomplete="off"
            >
              <!-- <b-textarea
                id="longDescription"
                v-model="ventureToEdit.longDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.ventureToEdit.longDescription.$error,
                }"
                type="text"
                required
              ></b-textarea> -->

              <ckeditor  v-model="ventureToEdit.longDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.ventureToEdit.longDescription.$error,
                }"></ckeditor>

              <div
                v-if="submitted && !$v.ventureToEdit.longDescription.required"
                class="invalid-feedback"
              >
                Long Description is required
              </div>
              <div
                v-if="!$v.ventureToEdit.longDescription.minLength"
                class="invalid-feedback"
              >
                Long Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.ventureToEdit.longDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 5000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Urdu Long Description"
              label-for="longDescription"
              autocomplete="off"
            >
              <!-- <b-textarea
                id="longDescription"
                v-model="ventureToEdit.longDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.ventureToEdit.longDescription.$error,
                }"
                type="text"
                required
              ></b-textarea> -->

              <ckeditor  v-model="ventureToEdit.urduLongDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.ventureToEdit.urduLongDescription.$error,
                }"></ckeditor>

              <div
                v-if="submitted && !$v.ventureToEdit.urduLongDescription.required"
                class="invalid-feedback"
              >
                Long Description is required
              </div>
              <div
                v-if="!$v.ventureToEdit.urduLongDescription.minLength"
                class="invalid-feedback"
              >
                Long Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.ventureToEdit.urduLongDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 5000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Cover" label-for="Image" autocomplete="off">
              <!-- <b-form-file
                accept="image/jpeg, image/png, image/gif, video/mp4"
                :state="Boolean(attachedFile)"
                @change="onSelectImage"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file> -->
              <b-form-file
                accept="image/jpeg, image/png, image/gif, image/webp"
                v-model="attachedFile"
                :state="Boolean(attachedFile)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-8 image-with-border" v-if="isImageSelected">
            <zoom-on-hover
              :img-normal="this.siteUrl + this.imageUrl"
            ></zoom-on-hover>
          </div>
          <div class="col-md-2"></div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default {
  name: "EditVenture",
  data() {
    return {
      moduleName: "Edit Venture",
      imageUrl: "",
      isImageSelected: true,
      siteUrl: "",
      attachedFile: null,
      requestModel: {
        title: null,
        whatWeDo: null,
        description: null,
        longDescription: null,
        urduTitle:null,
        urduDescription:null,
        urduLongDescription: null,
        logo: null,
      },
      submitted: false,
      courseCategoryOptions: null,
      typeOptions: [
        { text: "Business Services and Partners", value: 1 },
        { text: "Investment and Funding", value: 2 },
        { text: "Advocates", value: 4 }
      ],
    };
  },
  validations: {
    ventureToEdit: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(350),
      },
      urduTitle:{
        required,
        minLength: minLength(3),
        maxLength: maxLength(350),
      },
      description: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(1000),
      },
      urduDescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(1000),
      },
      longDescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(5000),
      },
      urduLongDescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(5000),
      }
    },
  },

  components: {
    Toaster,
  },
  created() {
    this.getCourseCategories();
     if (this.IsWhyVenture) {
      this.requestModel.type = 3;
    }
    // this.ventureToEdit.title = this.requestModel.title;
    // this.ventureToEdit.whatWeDo = this.requestModel.whatWeDo;
    // this.ventureToEdit.description = this.requestModel.description;
    // this.ventureToEdit.longDescription = this.requestModel.description;
    // this.ventureToEdit.logo = this.requestModel.logo;
  },
  props: {
    ventureToEdit: {
      type: Object,
    },
     IsWhyVenture: {
      type: Boolean,
      default: false,
    },
    IsVenturePartner: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
    },
  },
  watch: {
    attachedFile(newValue, oldValue) {
      // console.log(newValue);
      if (newValue.type.includes("video")) this.isImageSelected = false;
      else this.isImageSelected = true;
      this.IsImageUpdated = true;
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.siteUrl = "";
              this.imageUrl = value;
              // console.log(value);
            })
            .catch(() => {
              this.imageUrl = null;
            });
        } else {
          this.imageUrl = null;
        }
      }
    },
  },
  methods: {
    onSelectImage(e) {
      var reader = new FileReader();
      reader.onload = function() {
        var output = document.getElementById("output");
        output.src = reader.result;
      };
      reader.readAsDataURL(e.target.files[0]);

      console.log("ON SELECT CALLED");
      this.ventureToEdit.logo = null;
      this.ventureToEdit.logo = e.target.files[0];
      console.log("uploadFile : ", e.target.files[0].size);
      console.log("uploadFileName : ", this.ventureToEdit.logo.name);
      this.siteUrl = "";

      // Allowing file Size
      var fileSize = 209715;

      if (this.requestModel.logo.size > fileSize) {
        alert("File Size Limit is 200 KB");
        e.target.value = "";
        this.ventureToEdit.logo = null;
        return false;
      }
    },
    getCourseCategories() {
      axios.get("/V_Learn/GetCourseCategory").then((res) => {
        this.isLoading = false;
        if (res.data.status == "Success") {
          let options = res.data.data.map((x) => {
            return {
              text: x.courseCategoryName,
              value: x.courseCategoryId,
            };
          });
          this.courseCategoryOptions = options;
        }
      });
    },
    getFiltersDropdowns() {
      this.getDropDownValues("CmsCategory");
      this.getDropDownValues("ShehriType");
    },
    getDropDownValues(id) {
      this.isLoading = true;
      axios
        .post("/KeyValuePair/GetDropDownListById", {
          type: id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            console.log(res);
            let options = res.data.data.map((x) => {
              return {
                text: x.value,
                value: x.key,
              };
            });
            if (id == "ShehriType") {
              this.shehriTypeOptions = options;
            } else if (id == "CmsCategory") {
              this.cmsCategoryOptions = options;
            }
          } else {
            return null;
          }
        });
    },
    Edit() {
      this.isLoading = true;

      if (this.attachedFile) {
        var fileSize = 209715;
        if (this.attachedFile.size > fileSize) {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "File Size Limit is 200 KB"
          );
          this.attachedFile = null;
          return false;
        }
      }

      const formData = new FormData();
      formData.append("id", this.ventureToEdit.id);
      formData.append("title", this.ventureToEdit.title);
      formData.append("UrduTitle", this.ventureToEdit.urduTitle);

      formData.append("type", this.ventureToEdit.type);
      formData.append("UrduDescription", this.ventureToEdit.urduDescription);
      formData.append("description", this.ventureToEdit.description);
      formData.append("longDescription", this.ventureToEdit.longDescription);
      formData.append("UrduLongDescription", this.ventureToEdit.urduLongDescription);
      if (this.siteUrl === "") {
        formData.append("image", this.attachedFile);
      } else {
        formData.append("image", this.ventureToEdit.image);
      }

      axios.post("/Shehri/UpdateVenturePartner", formData).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          this.$parent.getPage(1);
          this.$bvModal.hide("EditVenturePartnerModal");
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Venture has been edited successfully"
          );
        } else {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "Unable to edit Venture"
          );
        }
      });
    },

    checkFormValidity() {
      const valid = this.$refs.EditForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {},
    showModal() {
      this.requestModel = JSON.parse(JSON.stringify(this.ventureToEdit));
      this.attachedFile = null;
      // this.siteUrl = "https://smw.demo.itrooscloud.net/";
      this.siteUrl = "https://smw-live.azurewebsites.net/";
      this.imageUrl = this.ventureToEdit.image;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.$v.$touch();

      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }

      this.Edit();
    },
  },
};
</script>
