<template>
  <div>
    <div class="row justify-content-between align-items-center">
      <div class="col-md-7 customHeading">
        <h3>{{ moduleName }}</h3>
      </div>
      <div class="col-md-2 icon-theme-color">
        <div class="row">
          <div class="col-md-8 center-adv-srch" v-b-toggle.filters-collpased>
            <details style="display: inline">
              <summary title="Advanced Search" class="adv-search-p">
                Advanced Search
              </summary>
            </details>
          </div>
          <div
            class="col-md-4 center-adv-srch"
            role="button"
            @click="clearFilters"
          >
            <em
              title="Reset Filters"
              class="fas fa-times fa-sm clear-filter-btn"
            ></em>
          </div>
        </div>
      </div>

      <!---SEARCH BOX-->
      <div class="col-md-2">
        <div class="searchbox input-group" role="group">
          <input
            class="form-control search-box my-search-box"
            type="text"
            placeholder="Title"
            aria-label="search"
            v-model="filteredText"
            @keydown.enter.prevent="getJobsRequests()"
          />

          <button
            class="search-button btn btn-primary filter"
            @click="getJobsRequests()"
          >
            <i class="fa fa-search search-icon"></i>
          </button>
        </div>
      </div>
      <!-- <div class="col-md-1 Add-button" style="text-align: center">
        <a href="javascript:void(0)">
          <div class="AddButton">
            <div class="icon">
              <i
                class="fas fa-arrow-alt-circle-right fa-1x"
                style="color: rgb(255, 50, 55)"
              >
              </i>
            </div>
            <div data-text="Add" class="name add-btn" v-b-modal.AddClientModal>
              Add
            </div>
          </div>
        </a>
      </div> -->
    </div>
    <!-- Filters -->
    <b-collapse id="filters-collpased">
      <div class="row margin-filters">
        <div class="col-md-2">
          <b-form-group
            label=""
            label-for="datepicker-2"
            autocomplete="off"
            style="margin-bottom: 0px"
          >
            <b-form-datepicker
              id="datepicker-2"
              v-model="filters.from"
              @input="getWheatReceives()"
              placeholder="Choose Start Date"
              local="en"
            ></b-form-datepicker>
          </b-form-group>
        </div>
        <div class="col-md-2">
          <b-form-group
            label=""
            style="margin-bottom: 0px"
            label-for="datepicker-1"
            autocomplete="off"
          >
            <b-form-datepicker
              id="datepicker-1"
              v-model="filters.to"
              placeholder="Choose End Date"
              local="en"
            ></b-form-datepicker>
          </b-form-group>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.companyId"
            :options="companyOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Company</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.categoryId"
            :options="categoryOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Category</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.cityId"
            :options="cityOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select City</b-form-select-option
            >
          </b-form-select>
        </div>
        <!-- <div class="col-md-2">
          <b-form-select
            v-model="filters.candidateId"
            :options="candidateOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Candidate</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.degreeLevelId"
            :options="degreeOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Education</b-form-select-option
            >
          </b-form-select>
        </div>
      </div>
      <div class="row margin-filters">
        <div class="col-md-2">
          <b-form-select
            v-model="filters.experienceId"
            :options="experienceOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Experience</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.genderId"
            :options="genderOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Gender</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.jobId"
            :options="jobOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Job</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.countryId"
            :options="countryOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Country</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-group label="" label-for="Age" autocomplete="off">
            <b-form-input
              id="Age"
              v-model="filters.age"
              placeholder="Enter Age"
              type="text"
            ></b-form-input>
          </b-form-group>
        </div> -->
      </div>
      <div class="row margin-filters">
        <div class="col-md-12" style="text-align: center">
          <button class="btn filter-search-btn" @click="getJobsRequests()">
            Search
          </button>
        </div>
      </div>
    </b-collapse>
    <!-- end -->
    <div class="row">
      <div class="col">
        <hr
          color="#CACACA"
          style="border-top: 2px solid rgb(202, 202, 202); margin-top: 5px"
        />
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col">
          <table
            class="
              table table-striped table-borderless
              cutom-table
              table-striped table-sm
            "
            style="font-size: 85%; overflow:auto"
          >
            <thead>
              <tr>
                <th role="columnheader">Title</th>
                <th role="columnheader">Company</th>
                <th role="columnheader">Posted</th>
                <th role="columnheader">Expiry</th>
                <th role="columnheader">Category</th>
                <th role="columnheader">Location</th>
                <th role="columnheader">Type</th>
                <th role="columnheader">Status</th>
                <th role="columnheader">Actions</th>
              </tr>
            </thead>

            <tbody>
              <!--IF STUDENTS ARRAY HAS NO VALUE THEN SHOW WITHOUT SHOWING DATA-->
              <tr v-if="jobsRequests == null">
                <td class="center-td" colspan="9">No Data</td>
              </tr>

              <tr v-else v-for="(record, index) in jobsRequests" :key="index">
                <td role="cell">{{ record.jobTitle }}</td>
                <td role="button" @click="viewCompanyDetails(record.companyId)">
                  {{ record.companyName }}
                </td>
                <td role="cell">
                  {{ record.postedDate | moment("MMMM Do YYYY") }}
                </td>
                <td role="cell">
                  {{ record.jobExpiryDate | moment("MMMM Do YYYY") }}
                </td>
                <td>{{ record.category }}</td>
                <td>{{ record.cityName }}</td>
                  <td role="cell">
                  {{ record.typeJob }}
                </td>
                <td role="cell">
                  {{ record.jobsStatus == 0 ? "Not Found" : record.jobsStatus }}
                </td>
                <td>
                  <!-- <span @click="editRecord(record)">
                    <i
                      role="button"
                      style="color: #EA216C"
                      class="fas fa-edit"
                    ></i
                    >
                  </span>&nbsp;|&nbsp; -->
                  <span @click="passRecordToDetails(record.jobId)">
                    <i
                      role="button"
                      style="color: #ea216c"
                      class="fas fa-eye"
                    ></i> </span
                  >&nbsp;|&nbsp;
                  <span
                    title="View Applicants"
                    @click="viewApplicants(record.jobId)"
                  >
                    <i
                      role="button"
                      style="color: #ea216c"
                      class="fas fa-user-graduate"
                    ></i>
                  </span>&nbsp;|&nbsp;
                  <span @click="deleteRecord(record.jobId)">
                    <i
                      role="button"
                      style="color: #ea216c"
                      class="far fa-trash-alt"
                    ></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--PAGINATION START-->
      <div class="row justify-content-between align-items-center">
        <div class="col-md-2">
          <div class="showPages">
            <select
              class="form-control"
              id="selClass"
              v-model="perPage"
              @change="getJobsRequests()"
            >
              <option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-8 d-flex justify-content-center">
          <div class="overflow-auto">
            <b-pagination
              v-model="page"
              :total-rows="rows"
              :per-page="perPage"
              limit="3"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              @change="getPage"
            ></b-pagination>
          </div>
        </div>

        <div class="col-md-2">
          <input
            class="form-control search-box page-no-search"
            type="text"
            v-bind:placeholder="page"
            aria-label="search"
            v-model.number="pageNumOnSearch"
            style="height: 38px"
          />

          <button
            class="search-button btn btn-primary search page-no-search-btn"
            @click="search()"
          >
            GO
          </button>
        </div>
      </div>
      <!--/PAGINATION END-->
      <!--SPINNER-->
      <Spinner
        v-if="isLoading"
        message="Loading..."
        size="medium"
        line-fg-color="rgb(222 224 219"
        line-bg-color="rgb(68 66 62)"
      >
      </Spinner>
      <!--/SPINNER-->
    </div>
    <!-- <AddClientModal /> -->
    <Toaster ref="toaster" />
    <JobDetailsModal
      :jobRequestToEdit="jobToGetDetails"
      :isPending="isPendingJobs"
      v-if="jobToGetDetails != null"
    />
    <EditJobStatus
      :jobRequestToEdit="recordToEdit"
      v-if="recordToEdit != null"
    />
    <JobApplicants :jobId="chosenJobId" v-if="chosenJobId != null" />
    <CompanyDetails
      :CompanyId="chosenCompanyId"
      v-if="chosenCompanyId != null"
    />
    <DeleteModal
      :msgForModal="msgForModal"
      :apiEndPoint="apiEndPoint"
      :heading="deleteModalHeading"
      :entityId="recordToDelete"
      :isHttpPost="true"
    />
  </div>
</template>

<script>
import axios from "axios";
import Toaster from "../../common/pages/toaster.vue";
// import config from '../config.js';
import Spinner from "vue-simple-spinner";
// import AddClientModal from "./modals/AddClientModal";
import DeleteModal from "../../common/modals/delete-modal.vue";
import JobDetailsModal from "./modals/job-details-modal.vue";
import EditJobStatus from "./modals/edit-job-status-modal.vue";
import JobApplicants from "./modals/job-applicants-modal.vue";
import CompanyDetails from "../company-management/modals/company-details-modal.vue";
import _ from "underscore";

export default {
  name: "JobsPosted",
  data() {
    return {
      moduleName: "Posted Jobs",
      jobsRequests: [],
      isLoading: false,
      recordToEdit: null,
      jobToGetDetails: null,
      isPendingJobs: false,
      recordToDelete: null,
      msgForModal: null,
      apiEndPoint: null,
      deleteModalHeading: null,
      chosenJobId: null,
      chosenCompanyId: null,

      clients: null,
      filters: {
        countryId: null,
        cityId: null,
        companyName: null,
        to: null,
        from: null,
        categoryId: null,
        genderId: null,
        experienceId: null,
        jobId: null,
        age: null,
        jobStatus: 1,
        candidateId: null,
        degreeLevelId: null,
        companyId: null,
        PageSize: null,
        PageNumber: null,
        Text: null,
      },
      companyOptions: null,
      cityOptions: null,
      countryOptions: null,
      genderOptions: null,
      degreeOptions: null,
      experienceOptions: null,
      Options: null,
      categoryOptions: null,
      jobOptions: null,
      candidateOptions: null,

      pageNumOnSearch: "",
      filteredText: "",
      rows: 0,
      page: 1,
      options: [
        { text: "Show 2", value: 2 },
        { text: "Show 10", value: 10 },
        { text: "Show 25", value: 25 },
        { text: "Show 50", value: 50 },
        { text: "Show 100", value: 100 },
      ],

      perPage: 25,
    };
  },

  methods: {
    clearFilters() {
      for (var key in this.filters) {
        console.log(key);
        this.filters[key] = null;
      }
    },
    getPage(page) {
      this.page = page;
      this.getJobsRequests();
    },
    getFiltersDropdowns() {
      this.getDropDownValues("city");
      this.getDropDownValues("country");
      this.getDropDownValues("Category");
      this.getDropDownValues("DegreeLevel");
      this.getDropDownValues("ExperienceLevel");
      this.getDropDownValues("Gender");
    },
    getDropDownValues(id) {
      this.isLoading = true;
      axios
        .post("/KeyValuePair/GetDropDownListById", {
          type: id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            let options = res.data.data.map((x) => {
              return {
                text: x.value,
                value: x.key,
              };
            });

            let sortedData=[];
            sortedData = _.sortBy( options, 'text');

            if (id == "city") {
              this.cityOptions = sortedData;
            } else if (id == "country") {
              this.countryOptions = sortedData;
            } else if (id == "Gender") {
              this.genderOptions = options;
            } else if (id == "ExperienceLevel") {
              this.experienceOptions = options;
            } else if (id == "DegreeLevel") {
              this.degreeOptions = options;
            } else if (id == "Category") {
              this.categoryOptions = options;
            }
          } else {
            return null;
          }
        });
    },
    viewApplicants(id) {
      this.chosenJobId = id;
      console.log("view applicants");
      setTimeout(() => {
        this.$bvModal.show("JobApplicantsModal");
      }, 100);
    },
    viewCompanyDetails(id) {
      this.chosenCompanyId = id;
      setTimeout(() => {
        this.$bvModal.show("CompanyDetailsModal");
      }, 100);
    },
    passRecordToDetails(id) {
      this.jobToGetDetails = id;
      setTimeout(() => {
        this.$bvModal.show("JobDetailsModal");
      }, 100);
    },
    editRecord(comp) {
      this.recordToEdit = comp;
      setTimeout(() => {
        this.$bvModal.show("EditJobStatusModal");
      }, 100);
    },
    deleteRecord(id) {
      this.recordToDelete = id;
      this.msgForModal = "Confirm to delete !";
      this.apiEndPoint = "/Job/RemovePostedJobs?id=";
      this.deleteModalHeading = "Delete";
      this.$bvModal.show("DeleteModal");
    },

    getJobsRequests() {
      // this.jobsRequests = null;
      // this.rows=0;
      // let a = axios;
      // a;
      // this.jobsRequests.push({Id:1, Title: "IT Engineer", CreationDate: new Date(),DTA:10,Status:1 })
      this.isLoading = true;
      this.filters.PageSize = this.perPage;
      this.filters.PageNumber = this.page;
      this.filters.Text = this.filteredText;

      axios.post("/Job/JobPostedFilter", this.filters).then((res) => {
        this.isLoading = false;
        if (res.data.status == "Success") {
          console.log(res);
          this.jobsRequests = res.data.data;
          this.rows = res.data.totalCount;
          console.log("Rows is",this.rows);

        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        } else {
          // this.jobsRequests = null;
        }
      });
    },
    getCompanyOptions() {
      this.isLoading = true;

      axios.get("/EmployerCompany/GetAllCompany").then((res) => {
        this.isLoading = false;
        if (res.data.status == "Success") {
          console.log(res);
          let companiesList = res.data.data.map((x) => {
            return {
              text: x.companyName,
              value: x.employerCompanyId,
            };
          });
          this.companyOptions = _.sortBy( companiesList, 'text');
          // this.rows = res.data.totalRecords;
        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        } else {
          // this.jobsRequests = null;
        }
      });
    },

    search() {
      this.page = this.pageNumOnSearch;
      this.getJobsRequests();
    },
  },
  created() {
    this.getJobsRequests();
    this.getFiltersDropdowns();
    this.getCompanyOptions();
    // this.getStudents();
    //localStorage.setItem("mytime", Date.now());
  },
  components: {
    Spinner,
    EditJobStatus,
    JobDetailsModal,
    DeleteModal,
    Toaster,
    JobApplicants,
    CompanyDetails
    // AddClientModal,
  },
};
</script>

<style scoped>
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
select#class-dropdown {
  width: 80%;
  margin-left: 16%;
}
</style>
