var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row justify-content-between align-items-center"},[_c('div',{staticClass:"col-md-9 customHeading"},[_c('h3',[_vm._v(_vm._s(_vm.moduleName))])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"searchbox input-group",attrs:{"role":"group"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filteredText),expression:"filteredText"}],staticClass:"form-control search-box my-search-box",attrs:{"type":"text","placeholder":"Name","aria-label":"search"},domProps:{"value":(_vm.filteredText)},on:{"input":function($event){if($event.target.composing)return;_vm.filteredText=$event.target.value}}}),_c('button',{staticClass:"search-button btn btn-primary filter",on:{"click":function($event){return _vm.getAdds()}}},[_c('i',{staticClass:"fa fa-search search-icon"})])])])]),_vm._m(0),_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('table',{staticClass:"table table-striped table-borderless cutom-table table-striped table-sm",staticStyle:{"font-size":"85%"}},[_vm._m(1),_c('tbody',[(_vm.adds == null)?_c('tr',[_c('td',{staticClass:"center-td",attrs:{"colspan":"5"}},[_vm._v("No Data")])]):_vm._l((_vm.adds),function(record){return _c('tr',{key:record.Id},[_c('td',{attrs:{"role":"cell"}},[_vm._v(_vm._s(record.title))]),_c('td',{attrs:{"role":"cell"}},[_c('a',{attrs:{"href":record.bannerUrl,"target":"_blank"}},[_c('i',{staticClass:"fas fa-globe-asia"})])]),_c('td',{attrs:{"role":"cell"}},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":record.addImage != null &&
                    record.addImage != '' &&
                    record.addImage != undefined
                      ? _vm.siteUrl + record.addImage
                      : 'Javascript:void(0)',"target":"_blank"}},[_vm._m(2,true)])]),_c('td',[_c('span',{on:{"click":function($event){return _vm.editRecord(record)}}},[_c('i',{staticClass:"fas fa-edit",staticStyle:{"color":"#ea216c"},attrs:{"role":"button"}})])])])})],2)])])]),(_vm.isLoading)?_c('Spinner',{attrs:{"message":"Loading...","size":"medium","line-fg-color":"rgb(222 224 219","line-bg-color":"rgb(68 66 62)"}}):_vm._e()],1),_c('Toaster',{ref:"toaster"}),(_vm.recordToEdit != null)?_c('EditAddsModal',{attrs:{"bannerToEdit":_vm.recordToEdit}}):_vm._e(),_c('AddBannerModal'),_c('DeleteModal',{attrs:{"msgForModal":_vm.msgForModal,"apiEndPoint":_vm.apiEndPoint,"heading":_vm.deleteModalHeading,"entityId":_vm.recordToDelete}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('hr',{staticStyle:{"border-top":"2px solid rgb(202, 202, 202)","margin-top":"5px"},attrs:{"color":"#CACACA"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"role":"columnheader"}},[_vm._v("Page")]),_c('th',{attrs:{"role":"columnheader"}},[_vm._v("Link")]),_c('th',{attrs:{"role":"columnheader"}},[_vm._v("Image")]),_c('th',{attrs:{"role":"columnheader"}},[_vm._v("Actions")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{attrs:{"title":"View Media"}},[_c('i',{staticClass:"fas fa-download",staticStyle:{"color":"#ea216c"},attrs:{"role":"button"}})])
}]

export { render, staticRenderFns }