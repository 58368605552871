<template>
  <div>
    <div class="row justify-content-between align-items-center">
      <div class="col-md-7 customHeading">
        <h3>{{ title }}</h3>
      </div>
      <div class="col-md-2 icon-theme-color">
        <div class="row" style="display: none">
          <div class="col-md-8 center-adv-srch" v-b-toggle.filters-collpased>
            <details style="display: inline">
              <summary title="Advanced Search" class="adv-search-p">
                Advanced Search
              </summary>
            </details>
          </div>
          <div
            class="col-md-4 center-adv-srch"
            role="button"
            @click="clearFilters"
          >
            <em
              title="Reset Filters"
              class="fas fa-times fa-sm clear-filter-btn"
            ></em>
          </div>
        </div>
      </div>

      <!---SEARCH BOX-->
      <div class="col-md-2" v-if="$route.params.type==='our-programs'">
        <div class="searchbox input-group" role="group">
          <input
            class="form-control search-box my-search-box"
            type="text"
            placeholder="Title"
            aria-label="search"
            v-model="filteredText"
            @keydown.enter.prevent="getAll()"
          />

          <button
            class="search-button btn btn-primary filter"
            @click="getAll()"
          >
            <i class="fa fa-search search-icon"></i>
          </button>
        </div>
      </div>
      <div class="col-md-1 Add-button" style="text-align: center">
        <a href="javascript:void(0)">
          <div v-bind:class="[(list.length>0 && list.length===1 && $route.params.type!=='our-mentor-sessions' &&
          ($route.params.type!=='be-a-mentor'
          || $route.params.type!=='our-programs'))?'disabled'
          : (list.length!=null && list.length===2 && $route.params.type==='be-a-mentor')? 'disabled'
          :'', 'AddButton']">
            <div class="icon">
              <i
                class="fas fa-arrow-alt-circle-right fa-1x"
                style="color: rgb(255, 50, 55)"
              >
              </i>
            </div>
            <div
              data-text="Add"
              class="name add-btn"
              v-b-modal.AddVenturePartnerModal

            >
              Add
            </div>
          </div>
        </a>
      </div>
    </div>
    <b-collapse id="filters-collpased" style="display: none">
      <div class="row margin-filters">
        <div class="col-md-2">
          <b-form-group
            label=""
            label-for="datepicker-2"
            autocomplete="off"
            style="margin-bottom: 0px"
          >
            <b-form-datepicker
              id="datepicker-2"
              v-model="filters.from"
              placeholder="Choose Start Date"
              local="en"
            ></b-form-datepicker>
          </b-form-group>
        </div>
        <div class="col-md-2">
          <b-form-group
            label=""
            style="margin-bottom: 0px"
            label-for="datepicker-1"
            autocomplete="off"
          >
            <b-form-datepicker
              id="datepicker-1"
              v-model="filters.to"
              placeholder="Choose End Date"
              local="en"
            ></b-form-datepicker>
          </b-form-group>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.categoryId"
            :options="categoryOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Category</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.cityId"
            :options="cityOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select City</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.canidateId"
            :options="candidateOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Candidate</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.educationId"
            :options="degreeOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Education</b-form-select-option
            >
          </b-form-select>
        </div>
      </div>
      <div class="row margin-filters">
        <div class="col-md-2">
          <b-form-select
            v-model="filters.experienceLevelId"
            :options="experienceOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Experience</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.genderId"
            :options="genderOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Gender</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.jobId"
            :options="jobOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Job</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.countryId"
            :options="countryOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Country</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-group label="" label-for="Age" autocomplete="off">
            <b-form-input
              id="Age"
              v-model="filters.age"
              placeholder="Enter Age"
              type="text"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row margin-filters">
        <div class="col-md-12" style="text-align: center">
          <button class="btn filter-search-btn" @click="getAll()">
            Search
          </button>
        </div>
      </div>
    </b-collapse>
    <div class="row">
      <div class="col">
        <hr
          color="#CACACA"
          style="border-top: 2px solid rgb(202, 202, 202); margin-top: 5px"
        />
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col">
          <table
            class="
              table table-striped table-borderless
              cutom-table
              table-striped table-sm
            "
            style="font-size: 85%"
          >
            <thead>
              <tr>
                <th role="columnheader"  v-if="IstableTitle">
                  Title
                </th>
                <th role="columnheader" v-if="IsShortDescription">
                  Short Description
                </th>
                <th role="columnheader" v-if="IsLongDescription">Long Description</th>
                <th role="columnheader"  v-if="IsTableImage">
                  Image
                </th>
                <th role="columnheader">Actions</th>
              </tr>
            </thead>
            <tbody>
              <!--IF STUDENTS ARRAY HAS NO VALUE THEN SHOW WITHOUT SHOWING DATA-->
              <tr v-if="list == null">
                <td class="center-td" colspan="11">No Data</td>
              </tr>

              <tr
                v-else
                v-for="(record, index) in list"
                :key="index"
              >
                <td role="button"
                v-if="IstableTitle">
                  {{ record.title }}
                </td>
                <td role="columnheader"  v-if="IsShortDescription">
                   <b-button
                    variant="outline-dark"
                    size="sm"
                    @click="
                      ViewText(record.shortDescription, 'View Short Description')
                    "
                  >
                    View Short Description
                  </b-button>
                  <!-- {{record.shortDescription}} -->
                </td>
                <td role="columnheader" v-if="IsLongDescription">
                  <b-button
                    variant="outline-dark"
                    size="sm"
                    @click="
                      ViewText(
                        record.longDescription,
                        'View Long Description'
                      )
                    "
                  >
                    View Long Description
                  </b-button>
                  <!-- <span v-html="record.longDescription"></span> -->

                </td>
                <td role="columnheader"  v-if="IsTableImage">
                  <a
                    style="text-decoration: none"
                    :href="
                      record.image != null &&
                      record.image != '' &&
                      record.image != undefined
                        ? siteUrl + record.image
                        : 'Javascript:void(0)'
                    "
                    target="_blank"
                  >
                    <span title="View Media">
                      <i
                        role="button"
                        style="color: #ea216c"
                        class="fas fa-download"
                      ></i>
                    </span>
                  </a>
                </td>
                <td>
                  <span @click="editRecord(record)">
                    <i
                      role="button"
                      style="color: #ea216c"
                      class="fas fa-edit"
                    ></i> </span
                  >&nbsp;|&nbsp;

                  <span @click="deleteRecord(record.id)">
                    <i
                      role="button"
                      style="color: #ea216c"
                      class="far fa-trash-alt"
                    ></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--PAGINATION START-->
      <div class="row justify-content-between align-items-center" v-if="$route.params.type==='our-programs'">
        <div class="col-md-2">
          <div class="showPages">
            <select
              class="form-control"
              id="selClass"
              v-model="perPage"
              @change="getAll()"
            >
              <option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-8 d-flex justify-content-center">
          <div class="overflow-auto">
            <b-pagination
              v-model="page"
              :total-rows="rows"
              :per-page="perPage"
              limit="3"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              @change="getPage"
            ></b-pagination>
          </div>
        </div>

        <div class="col-md-2">
          <input
            class="form-control search-box page-no-search"
            type="text"
            v-bind:placeholder="page"
            aria-label="search"
            v-model.number="pageNumOnSearch"
            style="height: 38px"
          />

          <button
            class="search-button btn btn-primary search page-no-search-btn"
            @click="search()"
          >
            GO
          </button>
        </div>
      </div>
      <!--/PAGINATION END-->
      <!--SPINNER-->
      <Spinner
        v-if="isLoading"
        message="Loading..."
        size="medium"
        line-fg-color="rgb(222 224 219"
        line-bg-color="rgb(68 66 62)"
      >
      </Spinner>
      <!--/SPINNER-->
    </div>
    <AddModal
     :pageType="title"
     :isTitle="IstableTitle"
     :isShortDescription="IsShortDescription"
     :isLongDescription="IsLongDescription"
     :isImage="IsTableImage"
     />
    <Toaster ref="toaster" />
    <EditModal
      :recordToEdit="recordToEdit"
      :pageType="title"
      :isTitle="IstableTitle"
      :isShortDescription="IsShortDescription"
      :isLongDescription="IsLongDescription"
      :isImage="IsTableImage"
      v-if="recordToEdit != null"
    />
    <DeleteModal
      :msgForModal="msgForModal"
      :apiEndPoint="apiEndPoint"
      :heading="deleteModalHeading"
      :entityId="recordToDelete"
      :isHttpPost="true"
    />
    <view-modal
    :text="viewModalText"
    :modal-title="viewModalTitle"
    v-if="viewModalText != null"></view-modal>
  </div>
</template>

<script>
import axios from "axios";
import Toaster from "../../common/pages/toaster.vue";
// import config from '../config.js';
import Spinner from "vue-simple-spinner";
import AddModal from "./modals/add-viilearn-common-modal.vue";
import DeleteModal from "../../common/modals/delete-modal.vue";
import EditModal from "./modals/edit-viilearn-common-modal.vue";
import ViewModal from "../../common/modals/view-modal.vue";

export default {
  name: "VLearnCommonFile",
  data() {
    return {
      siteUrl : "https://smw-live.azurewebsites.net",
      moduleName: "Partners",
      isLoading: false,
      recordToEdit: null,
      recordToDelete: null,
      msgForModal: null,
      apiEndPoint: null,
      deleteModalHeading: null,
      applicantId: null,
      viewModalText:null,
      viewModalTitle:null,

      list: null,
      filters: {
        countryId: null,
        cityId: null,
        companyName: null,
        to: null,
        from: null,
        categoryId: null,
        genderId: null,
        experienceLevelId: null,
        jobId: null,
        age: null,
        canidateId: null,
        educationId: null,
      },

      cityOptions: null,
      countryOptions: null,
      genderOptions: null,
      degreeOptions: null,
      experienceOptions: null,
      Options: null,
      categoryOptions: null,
      jobOptions: null,
      candidateOptions: null,

      pageNumOnSearch: "",
      filteredText: "",
      rows: 0,
      page: 1,
      options: [
        { text: "Show 2", value: 2 },
        { text: "Show 10", value: 10 },
        { text: "Show 25", value: 25 },
        { text: "Show 50", value: 50 },
        { text: "Show 100", value: 100 },
      ],
      perPage: 25,
    };
  },

  computed:{
    title: function (){
      let title = this.$route.params.type;
        if(title==="knowledge-training-for-everyone")
          return "Knowledge & Training For Everyone";
        else if(title==="our-partners-services-for-corporates")
          return "Our Partners & Services for Corporates";
        else if(title==="skill-her")
          return "Skill Her";
        else if(title==="spare-a-smile")
          return "Spare a Smile";
        else if(title==="be-a-mentor")
          return "Be a Mentor";
        else if(title=== "our-programs")
          return "Our Programs";
        else if(title=== "our-mission")
          return "Our Mission";
        else if(title=== "our-mentor-sessions")
          return "Our Mentor Sessions";
        else if(title=== "education-for-everyone")
          return "Education for Everyone";
        else
          return null;
    },
    IstableTitle(){
       if(this.$route.params.type==='knowledge-training-for-everyone' ||
        this.$route.params.type==='skill-her'){
        return false;
      }
      return true;
    },
    IsShortDescription(){
      /*if(this.$route.params.type==='be-a-mentor'){
        return false;
      }*/
      return true;
    },
     IsLongDescription(){
      if(this.$route.params.type==='education-for-everyone'){
        return false;
      }
      return true;
    },
    IsTableImage(){
      if(this.$route.params.type==='knowledge-training-for-everyone'
       ||
      this.$route.params.type==='skill-her' || this.$route.params.type==='our-mission'
      || this.$route.params.type==='education-for-everyone'
      ){
        return false;
      }
      return true;
    }
  },

  props:{
    type:{
      type:String
    }
  },

  methods: {
    clearFilters() {
      for (var key in this.filters) {
        console.log(key);
        this.filters[key] = null;
      }
    },
    getPage(page) {
      this.page = page;
      this.getAll();
    },

    editRecord(comp) {
      this.recordToEdit = comp;
      console.log("venturePartners", this.recordToEdit);
      setTimeout(() => {
        this.$bvModal.show("EditVenturePartnerModal");
      }, 100);
    },
    deleteRecord(id) {
      this.recordToDelete = id;
      this.msgForModal = "Confirm to delete !";
      this.apiEndPoint = "V_Learn/Delete?id=";
      this.deleteModalHeading = "Delete";
      this.$bvModal.show("DeleteModal");
    },
    ViewText(text,modalTitle){
      this.viewModalText=text;
      this.viewModalTitle=modalTitle;
      this.$bvModal.show("ViewModal");
    },
    getAll() {
      this.isLoading = true;
      this.list=null;
      this.filteredText=null;

      let viiLearnType = null;
      if(this.$route.params.type==="knowledge-training-for-everyone")
          viiLearnType =  "OurViiLearnStory";
        else if(this.$route.params.type==="our-partners-services-for-corporates")
          viiLearnType =  "BuildYouOwnCourses";
        else if(this.$route.params.type==="skill-her")
          viiLearnType =  "SkillHer";
        else if(this.$route.params.type==="spare-a-smile")
          viiLearnType =  "SpareASmile";
        else if(this.$route.params.type==="be-a-mentor")
          viiLearnType =  "BeAMentor";
        else if(this.$route.params.type=== "our-programs")
          viiLearnType =  "OurPrograms";
        else if(this.$route.params.type=== "our-mission")
          viiLearnType =  "OurMission";
        else if(this.$route.params.type==="our-mentor-sessions")
          viiLearnType =  "OurPartner";
        else if(this.$route.params.type==="education-for-everyone")
          viiLearnType = "EducationForEveryOne";
        else
          viiLearnType =  null;

      axios
        .post("/V_Learn/GetAll", {
          PageSize: this.perPage,
          PageNumber: this.page,
          Text: this.filteredText,
          viiLearnType: viiLearnType
        })
        .then((res) => {
          this.isLoading = false;

          if (res.data.status == "Success") {
             const data=[];
            console.log(res);
            this.list = res.data.data;
            this.rows = res.data.totalCount;

               //TAKE ONLY RECORDS OF TYPE 3
            for (let index = 0; index < this.list.length; index++) {
              if(this.list[index].type!==3){

                data.push(this.list[index]);
                console.log("data is",data);
              }
            }

             this.list = data;

          } else if (res.data.status == -1) {
            this.$refs.toaster.showToast("Error", "danger", res.data.msg);
          } else {
            this.list = null;
          }
        });
    },
    getFiltersDropdowns() {
      this.getDropDownValues("city");
      this.getDropDownValues("country");
      this.getDropDownValues("Category");
      this.getDropDownValues("DegreeLevel");
      this.getDropDownValues("ExperienceLevel");
      this.getDropDownValues("Gender");
    },
    getDropDownValues(id) {
      this.isLoading = true;
      axios
        .post("/KeyValuePair/GetDropDownListById", {
          type: id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            let options = res.data.data.map((x) => {
              return {
                text: x.value,
                value: x.key,
              };
            });
            if (id == "city") {
              this.cityOptions = options;
            } else if (id == "country") {
              this.countryOptions = options;
            } else if (id == "Gender") {
              this.genderOptions = options;
            } else if (id == "ExperienceLevel") {
              this.experienceOptions = options;
            } else if (id == "DegreeLevel") {
              this.degreeOptions = options;
            } else if (id == "Category") {
              this.categoryOptions = options;
            }
          } else {
            return null;
          }
        });
    },
    search() {
      this.page = this.pageNumOnSearch;
      this.getAll();
    },
    getCandidateOptions() {
      axios.get("/Employee/GetCandidatesList").then((res) => {
        this.isLoading = false;
        console.log(res);
        if (res.data.status == "Success") {
          this.candidateOptions = res.data.data.map((x) => {
            return {
              text: x.candidates,
              value: x.candidatesId,
            };
          });
        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        } else {
          this.candidateOptions = null;
        }
      });
    },
    getJobOptions() {
      axios.get("/Job/GetAlljobsOfEmployeer").then((res) => {
        this.isLoading = false;
        console.log(res);
        if (res.data.status == "Success") {
          this.jobOptions = res.data.data.map((x) => {
            return {
              text: x.jobTitle,
              value: x.jobId,
            };
          });
        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        } else {
          this.jobOptions = null;
        }
      });
    },
  },

  created() {
    this.getAll();
    //when we reuse a component then it will not be destroyed thus will not created hook be called again n again
    //we need to add a watch on params

    this.$watch(
      ()=>this.$route.params,
      ()=>{
         this.getAll();
      }
    );

  },
  components: {
    Spinner,
    EditModal,
    DeleteModal,
    Toaster,
    AddModal,
    ViewModal
  },
};
</script>

<style scoped>
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
select#class-dropdown {
  width: 80%;
  margin-left: 16%;
}
.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.4;
}
</style>
