<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="xl"
      id="AddVentureModal"
      ref="modal"
      title="Add Venture"
      ok-title="Submit"
      cancel-title="Close"
      @show="resetModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.AddForm != undefined
          ? !this.$refs.AddForm.checkValidity()
          : true
      "
    >
      <form ref="AddForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <!-- <div class="col-md-6">
            <b-form-group label="Title" label-for="Title" autocomplete="off">
              <b-form-input
                id="ventureTitle"
                v-model="requestModel.title"
                :class="{
                  'is-invalid': submitted && $v.requestModel.title.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.title.required"
                class="invalid-feedback"
              >
                Title is required
              </div>
              <div
                v-if="!$v.requestModel.title.minLength"
                class="invalid-feedback"
              >
                Title must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.title.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 25 characters is reached
              </div>
            </b-form-group>
          </div> -->
          <div class="col-md-12">
            <b-form-group
              label="What We Do"
              label-for="WhatWeDo"
              autocomplete="off"
            >
              <b-form-input
                id="WhatWeDo"
                v-model="requestModel.whatWeDo"
                :class="{
                  'is-invalid': submitted && $v.requestModel.whatWeDo.$error,
                }"
                type="text"
                required
              >
              </b-form-input>

              <div
                v-if="submitted && !$v.requestModel.whatWeDo.required"
                class="invalid-feedback"
              >
                WhatWeDo is required
              </div>
              <div
                v-if="!$v.requestModel.whatWeDo.minLength"
                class="invalid-feedback"
              >
                It must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.whatWeDo.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-md-6">
            <b-form-group label="Type" label-for="Type" autocomplete="off">
              <b-form-select
                id="Type"
                v-model="requestModel.whyVentureHer"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.whyVentureHer.$error,
                }"
                :options="whyVentureHer"
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select Type</b-form-select-option
                >
              </b-form-select>
              <div
                v-if="submitted && !$v.requestModel.whyVentureHer.required"
                class="invalid-feedback"
              >
                Type is required
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Short Description"
              label-for="description"
              autocomplete="off"
            >
              <b-form-input
                id="description"
                v-model="requestModel.description"
                :class="{
                  'is-invalid': submitted && $v.requestModel.description.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.description.required"
                class="invalid-feedback"
              >
                Description is required
              </div>
              <div
                v-if="!$v.requestModel.description.minLength"
                class="invalid-feedback"
              >
                Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.description.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 50 characters is reached
              </div>
            </b-form-group>
          </div>
        </div> -->

        <div class="row">
          <!-- <div class="col-md-12">
            <b-form-group
              label="Long Description"
              label-for="longDescription"
              autocomplete="off"
            >
              <b-textarea
                id="longDescription"
                v-model="requestModel.longDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.longDescription.$error,
                }"
                type="text"
              ></b-textarea>

              <div
                v-if="submitted && !$v.requestModel.longDescription.required"
                class="invalid-feedback"
              >
                Long Description is required
              </div>
              <div
                v-if="!$v.requestModel.longDescription.minLength"
                class="invalid-feedback"
              >
                Long Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.longDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div> -->
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Image" label-for="logo" autocomplete="off">
              <b-form-file
                accept="image/jpeg, image/png, image/gif"
                @change="onSelectImage"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                :class="{
                  'is-invalid': submitted && $v.requestModel.image.$error,
                }"
              ></b-form-file>

              <div
                v-if="submitted && !$v.requestModel.image.required"
                class="invalid-feedback"
              >
                Image is required
              </div>
            </b-form-group>
          </div>
          <!-- <div class="col-md-6">
            <b-form-group label="Image" label-for="logo" autocomplete="off">
              <b-form-file
                accept="image/jpeg, image/png, image/gif"
                @change="onSelectImage('image', $event)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                :class="{
                  'is-invalid': submitted && $v.requestModel.image.$error,
                }"
              ></b-form-file>

              <div
                v-if="submitted && !$v.requestModel.image.required"
                class="invalid-feedback"
              >
                Image is required
              </div>
            </b-form-group>
          </div> -->
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "AddVenture",

  data() {
    return {
      moduleName: "Add Venture",
      file1: null,
      requestModel: {
        // title: null,
        whatWeDo: null,
        // description: null,
        // longDescription: null,
        // whyVentureHer: null,
        // logo: null,
        image: null,
      },
      submitted: false,
      // courseTypeOptions: null,
      courseCategoryOptions: null,
      courseTypeOptions: [
        { text: "Train Me", value: 1 },
        { text: "Train The Team", value: 2 },
        { text: "Skill Her", value: 3 },
      ],
      whyVentureHer: [
        { text: "Section1", value: 1 },
        { text: "Section2", value: 2 },
        { text: "Section3", value: 3 },
        { text: "Section4", value: 4 },
      ],
    };
  },
  validations: {
    requestModel: {
      // title: {
      //   required,
      //   minLength: minLength(3),
      //   maxLength: maxLength(25),
      // },
      whatWeDo: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(600),
      },
      // description: {
      //   required,
      //   minLength: minLength(3),
      //   maxLength: maxLength(50),
      // },
      // longDescription: {
      //   required,
      //   minLength: minLength(3),
      //   maxLength: maxLength(1000),
      // },
      //  whyVentureHer: {
      //   required,
      // },
      // logo: {
      //   required,
      // },
      image: {
        required,
      },
    },
  },

  components: {
    Toaster,
  },
  created() {
    this.getDropdowns();
  },
  methods: {
    onSelectImage(e) {
      console.log("ON SELECT CALLED");
      this.requestModel.image = null;
      this.requestModel.image = e.target.files[0];
      console.log("uploadFile : ", e.target.files[0].size);
      console.log("uploadFileName : ", this.requestModel.image.name);

      // Allowing file Size
      var fileSize = 209715;

      if (this.requestModel.image.size > fileSize) {
        // alert('File Size Limit is 200 KB');
        this.$refs.toaster.showToast(
          "Error",
          "danger",
          "File Size Limit is 200 KB"
        );
        e.target.value = "";
        this.requestModel.image = null;
        return false;
      }
    },
    // onSelectImage(type, e) {
    //   console.log("ON SELECT CALLED");
    //   var fileSize = 209715;

    //   if (type === "logo") {
    //     this.requestModel.logo = null;
    //     this.requestModel.logo = e.target.files[0];
    //     console.log("uploadFileName : ", this.requestModel.logo.name);

    //     // Allowing file Size

    //     if (this.requestModel.logo.size > fileSize) {
    //       // alert("File Size Limit is 200 KB");
    //       this.$refs.toaster.showToast(
    //         "Error",
    //         "danger",
    //         "File Size Limit is 200 KB"
    //       );
    //       e.target.value = "";
    //       this.requestModel.logo = null;
    //       return false;
    //     }
    //   }

    //   if (type === "image") {
    //     this.requestModel.image = null;
    //     this.requestModel.image = e.target.files[0];
    //     console.log("uploadFileName : ", this.requestModel.image.name);

    //     // Allowing file Size

    //     if (this.requestModel.image.size > fileSize) {
    //       // alert("File Size Limit is 200 KB");
    //       this.$refs.toaster.showToast(
    //         "Error",
    //         "danger",
    //         "File Size Limit is 200 KB"
    //       );
    //       e.target.value = "";
    //       this.requestModel.image = null;
    //       return false;
    //     }
    //   }

    //   console.log("uploadFile : ", e.target.files[0].size);
    // },
    getDropdowns() {
      this.getCourseCategories();
    },
    getCourseCategories() {
      axios.get("/V_Learn/GetCourseCategory").then((res) => {
        this.isLoading = false;
        if (res.data.status == "Success") {
          let options = res.data.data.map((x) => {
            return {
              text: x.courseCategoryName,
              value: x.courseCategoryId,
            };
          });
          this.courseCategoryOptions = options;
        }
      });
    },
    getDropDownValues(id) {
      this.isLoading = true;
      axios
        .post("/KeyValuePair/GetDropDownListById", {
          type: id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            console.log(res);
            let options = res.data.data.map((x) => {
              return {
                text: x.value,
                value: x.key,
              };
            });
            if (id == "ShehriType") {
              this.shehriTypeOptions = options;
            } else if (id == "CmsCategory") {
              this.cmsCategoryOptions = options;
            }
          } else {
            return null;
          }
        });
    },
    Add() {
      this.isLoading = true;
      const formData = new FormData();
      console.log(this.requestModel);
      // formData.append("title", this.requestModel.title);
      // formData.append("description", this.requestModel.description);
      // formData.append("longDescription", this.requestModel.longDescription);
      // formData.append("whyVentureHer", Number(this.requestModel.whyVentureHer));
      // formData.append("logo", this.requestModel.logo);
      formData.append("whatWeDo", this.requestModel.whatWeDo);
      formData.append("image", this.requestModel.image);

      console.log(formData);

      axios.post("/Shehri/Insert", formData).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          this.$parent.getPage(1);
          this.$bvModal.hide("AddVentureModal");
          console.log(res);
          this.resetModal();
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Venture has been added successfully"
          );
        } else {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "Unable to add venture"
          );
        }
      });
    },
    checkFormValidity() {
      const valid = this.$refs.AddForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      this.requestModel.title = null;
      this.requestModel.whatWeDo = null;
      this.requestModel.description = null;
      this.requestModel.longDescription = null;
      this.requestModel.logo = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }
      this.Add();
    },
  },
};
</script>
