import Vue from 'vue'
import App from './App.vue'
import routes from './js/router.js';
import VueRouter from 'vue-router'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import 'es6-promise/auto'
import axios from 'axios'
import VueAuth from '@websanova/vue-auth'
import VueAxios from 'vue-axios'
import auth from './js/auth'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './custom-styles/custom.css';
import './custom-styles/w3.css';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VSwitch from 'v-switch-case'
import ZoomOnHover from "vue-zoom-on-hover";
import moment from "moment";
import Vuelidate from "vuelidate";

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

import CKEditor from 'ckeditor4-vue';



Vue.use(VueTelInput);
Vue.use( CKEditor );
Vue.use(ZoomOnHover);
Vue.use(VSwitch)
Vue.component("v-select", vSelect);

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueSidebarMenu)


Vue.router = routes
Vue.use(VueRouter)

Vue.config.productionTip = false
//

// Set Vue authentication


// axios.defaults.baseURL = 'http://localhost:57589/api';

// axios.defaults.baseURL = 'https://anaj2-api.itrooscloud.net/api';
// CONNECTED BEFORE
// axios.defaults.baseURL = 'https://demo.itrooscloud.net/api';

// axios.defaults.baseURL = 'http://68.183.182.247:32769/api'
//axios.defaults.baseURL = 'http://localhost:57589/api';


//live deployment azur
 axios.defaults.baseURL = 'https://smw-live.azurewebsites.net/api';

axios.defaults.headers.common['Content-Type'] = 'application/json';



Vue.use(VueAxios, axios)
Vue.use(VueAuth, auth)
Vue.use(require('vue-moment'));
Vue.use(Vuelidate);

Vue.filter('formatDateForDB',function(value){
  if(value){
    return moment(String(value)).format("YYYY-MM-DD HH:MI:SS");
  }
});

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("MMM DD, YYYY, hh:mm:ss a");
  }
});


new Vue({
  render: h => h(App),
  router: routes
  // watch: {
  //   $route: function(to, from) {
  //    console.log(to);
  //    console.log(from);
  //   }
  // }
}).$mount('#app')
