  <template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="AddPackageModal"
      ref="modal"
      title="Add Package"
      ok-title="Submit"
      cancel-title="Close"
      @show="resetModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.AddPackageForm != undefined
          ? !this.$refs.AddPackageForm.checkValidity()
          : true
      "
    >
      <form ref="AddPackageForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-4">
            <!--NAME INPUT-->
            <b-form-group
              label="Name"
              label-for="packageName"
              autocomplete="off"
            >
              <b-form-input
                id="packageName"
                v-model="requestModel.packageName"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              label="Regular Price"
              label-for="regularPrice"
              autocomplete="off"
            >
              <b-form-input
                id="regularPrice"
                v-model="requestModel.regularPrice"
                type="number"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              label="Sale Price"
              label-for="salePrice"
              autocomplete="off"
            >
              <b-form-input
                id="salePrice"
                v-model="requestModel.salePrice"
                type="number"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              label="Expired After"
              label-for="expiredAfter"
              autocomplete="off"
            >
              <b-form-input
                id="Address"
                v-model="requestModel.expiredAfter"
                type="number"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              label="Job Posting Limit"
              label-for="jobPostingLimit"
              autocomplete="off"
            >
              <b-form-input
                id="jobPostingLimit"
                v-model="requestModel.jobPostingLimit"
                type="number"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              label="Featured Job Limit"
              label-for="featuredJobLimit"
              autocomplete="off"
            >
              <b-form-input
                id="featuredJobLimit"
                v-model="requestModel.featuredJobLimit"
                type="number"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <!-- <div class="col-md-4">
            <b-form-group
              label="Unlimited Jobs"
              label-for="unlimitedPosting"
              autocomplete="off"
            >
              <b-form-input
                id="unlimitedPosting"
                v-model="requestModel.unlimitedPosting"
                type="number"
                required
              ></b-form-input>
            </b-form-group>
          </div> -->
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

      <script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
export default {
  name: "AddPackage",
  data() {
    return {
      moduleName: "Add Package",
      Name: null,
      PhoneNumber: null,
      Address: null,
      requestModel: {
       
        packageName: null,
        regularPrice: null,
        salePrice: null,
        expiredAfter: null,
        jobPostingLimit: null,
        unlimitedPosting: null,
        featuredJobLimit: null,
      },
    };
  },

  components: {
    Toaster,
  },
  methods: {
    AddPackage() {
      this.isLoading = true;
      this.requestModel.regularPrice = Number(this.requestModel.regularPrice)
      this.requestModel.salePrice = Number(this.requestModel.salePrice)
      this.requestModel.expiredAfter = Number(this.requestModel.expiredAfter)
      this.requestModel.jobPostingLimit = Number(this.requestModel.jobPostingLimit)
      this.requestModel.featuredJobLimit = Number(this.requestModel.featuredJobLimit)
      console.log(this.requestModel)
      axios.post("/Job/AddPackage",this.requestModel).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Package has been added successfully"
          );
        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        } else {
          this.jobsRequests = null;
        }
      });

      this.$bvModal.hide("AddPackageModal");
    },

    checkFormValidity() {
      const valid = this.$refs.AddPackageForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      this.Address = null;
      this.PhoneNumber = null;
      this.Name = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.AddPackage();
    },
  },
};
</script>
