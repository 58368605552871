  <template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="EditModal"
      ref="modal"
      title="Edit Testimonial"
      ok-title="Submit"
      cancel-title="Close"
      @show="updateModel()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.EditPackageForm != undefined
          ? !this.$refs.EditPackageForm.checkValidity()
          : true
      "
    >
       <form ref="EditPackageForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
         <p class="text-danger"><b>Note:</b> It will not get submitted unless there's some change</p>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!--NAME INPUT-->
            <b-form-group label="Title" label-for="name" autocomplete="off">
              <b-form-input
                id="name"
                v-model="requestModel.title"
                 :class="{
                  'is-invalid': submitted && $v.requestModel.title.$error,
                }"
                type="text"
                required
              ></b-form-input>
                <div
                v-if="submitted && !$v.requestModel.title.required"
                class="invalid-feedback"
              >
                Title is required
              </div>
              <div
                v-if="!$v.requestModel.title.minLength"
                class="invalid-feedback"
              >
                Title must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.title.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Date"
              label-for="datepicker-1"
              autocomplete="off"
            >
              <b-form-input
                type="date"
                v-model="requestModel.date"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Picture"
              label-for="Address"
              autocomplete="off"
            >
              <b-form-file
                accept="image/jpeg, image/png, image/gif"
                v-model="attachedFile"
                :state="Boolean(attachedFile)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-8 image-with-border" v-if="isImageSelected">
            <zoom-on-hover
              :img-normal="this.siteUrl + this.imageUrl"
            ></zoom-on-hover>
          </div>
          <div class="col-md-2"></div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

      <script>
import axios from "axios";
import moment from "moment";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default {
  name: "UpdateEvent",
  data() {
    return {
      moduleName: "Edit Event",
      imageUrl: "",
      isImageSelected: true,
      siteUrl: "",
      attachedFile: null,
       requestModel: {
        id:0,
        title: null,
        date:new Date(),
        image: null,
        type:2
      },
      submitted: false,
    };
  },

  validations: {
    requestModel: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(350),
      }
    }
  },

   watch: {
    attachedFile(newValue, oldValue) {
      // console.log(newValue);
      if (newValue.type.includes("video")) this.isImageSelected = false;
      else this.isImageSelected = true;
      this.IsImageUpdated = true;
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.siteUrl = "";
              this.imageUrl = value;
              // console.log(value);
            })
            .catch(() => {
              this.imageUrl = null;
            });
        } else {
          this.imageUrl = null;
        }
      }
    },
  },
  components: {
    Toaster,
  },

  props: {
    recordToEdit: {
      type: Object,
      default: null,
    },
  },

  methods: {
    Update() {
      this.isLoading = true;

       if (this.requestModel.image) {
        var fileSize = 209715;
        if (this.requestModel.image.size > fileSize) {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "File Size Limit is 200 KB"
          );
          this.requestModel.image = null;
          return false;
        }
      }

      const formData = new FormData();
      console.log(this.requestModel);

      formData.append("Id", this.requestModel.id);
      formData.append("Title", this.requestModel.title);
      formData.append("Date", this.requestModel.date);
      formData.append("Type", this.requestModel.type);

      if (this.siteUrl === "") {
         formData.append("Image", this.attachedFile);
      }
      else{
         formData.append("Image", this.requestModel.image);
      }

      axios.post("/Employee/Update", formData).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          this.$parent.getPage(1);
          this.$bvModal.hide("EditModal");
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Testimonial has been updated successfully"
          );
        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        }
      });

      this.$bvModal.hide("EditModal");
    },

    checkFormValidity() {
      const valid = this.$refs.EditPackageForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    updateModel() {
      this.requestModel = JSON.parse(JSON.stringify(this.recordToEdit));
      this.attachedFile = null;
      // this.siteUrl = "https://smw.demo.itrooscloud.net/";
      this.siteUrl = "https://smw-live.azurewebsites.net";
      this.imageUrl = this.recordToEdit.image;

       this.requestModel.date =
        this.recordToEdit.date === "" ||
        this.recordToEdit.date == null
          ? null
          : moment(String(this.recordToEdit.date)).format("YYYY-MM-DD");
    },
    resetModal() {

    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
       this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
       this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }
      this.Update();
    },
  },
};
</script>
