var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"modal",staticStyle:{"padding":"15px"},attrs:{"size":"lg","id":"EditVentureModal","title":"Edit Venture","ok-title":"Submit","cancel-title":"Close","ok-disabled":this.$refs.EditForm != undefined
        ? !this.$refs.EditForm.checkValidity()
        : true},on:{"show":function($event){return _vm.showModal()},"hidden":function($event){return _vm.resetModal()},"ok":_vm.handleOk}},[_c('form',{ref:"EditForm",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('p',{staticClass:"text-danger"},[_c('b',[_vm._v("Note:")]),_vm._v(" It will not get submitted unless there's some change")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"What We Do","label-for":"WhatWeDo","autocomplete":"off"}},[_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.ventureToEdit.whatWeDo.$error,
              },attrs:{"id":"WhatWeDo","type":"text","required":""},model:{value:(_vm.ventureToEdit.whatWeDo),callback:function ($$v) {_vm.$set(_vm.ventureToEdit, "whatWeDo", $$v)},expression:"ventureToEdit.whatWeDo"}}),(_vm.submitted && !_vm.$v.ventureToEdit.whatWeDo.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" WhatWeDo is required ")]):_vm._e(),(!_vm.$v.ventureToEdit.whatWeDo.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" It must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.ventureToEdit.whatWeDo.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 350 characters is reached ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"What We Do","label-for":"Urdu WhatWeDo","autocomplete":"off"}},[_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.ventureToEdit.urduWhatWeDo.$error,
              },attrs:{"id":"urduWhatWeDo","type":"text","required":""},model:{value:(_vm.ventureToEdit.urduWhatWeDo),callback:function ($$v) {_vm.$set(_vm.ventureToEdit, "urduWhatWeDo", $$v)},expression:"ventureToEdit.urduWhatWeDo"}}),(_vm.submitted && !_vm.$v.ventureToEdit.urduWhatWeDo.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Urdu WhatWeDo is required ")]):_vm._e(),(!_vm.$v.ventureToEdit.urduWhatWeDo.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" It must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.ventureToEdit.urduWhatWeDo.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 350 characters is reached ")]):_vm._e()],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Logo","label-for":"Image","autocomplete":"off"}},[_c('b-form-file',{attrs:{"accept":"image/jpeg, image/png, image/gif, image/webp","state":Boolean(_vm.attachedFile1),"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.attachedFile1),callback:function ($$v) {_vm.attachedFile1=$$v},expression:"attachedFile1"}})],1)],1),_c('div',{staticClass:"col-md-2"}),(_vm.isImageSelected)?_c('div',{staticClass:"col-md-8 image-with-border"},[_c('zoom-on-hover',{attrs:{"img-normal":this.siteUrl1 + this.imageUrl1}})],1):_vm._e(),_c('div',{staticClass:"col-md-2"})])])]),_c('Toaster',{ref:"toaster"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }