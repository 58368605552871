<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="xl"
      id="AddVenturePackageModal"
      ref="modal"
      title="Add Venture Package"
      ok-title="Submit"
      cancel-title="Close"
      @show="resetModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.AddForm != undefined
          ? !this.$refs.AddForm.checkValidity()
          : true
      "
    >
      <form ref="AddForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="Title" label-for="Title" autocomplete="off">
              <b-form-input
                id="ventureTitle"
                v-model="requestModel.title"
                :class="{
                  'is-invalid': submitted && $v.requestModel.title.$error,
                }"
                type="text"
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.title.required"
                class="invalid-feedback"
              >
                Title is required
              </div>
              <div
                v-if="!$v.requestModel.title.minLength"
                class="invalid-feedback"
              >
                Title must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.title.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Urdu Title" label-for="Title" autocomplete="off">
              <b-form-input
                id="ventureTitle"
                v-model="requestModel.Urdutitle"
                :class="{
                  'is-invalid': submitted && $v.requestModel.Urdutitle.$error,
                }"
                type="text"
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.Urdutitle.required"
                class="invalid-feedback"
              >
              Urdu title is required
              </div>
              <div
                v-if="!$v.requestModel.Urdutitle.minLength"
                class="invalid-feedback"
              >
              Urdu title must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.Urdutitle.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              label="Short Description"
              label-for="description"
              autocomplete="off"
            >
              <b-form-input
                id="description"
                v-model="requestModel.description"
                :class="{
                  'is-invalid': submitted && $v.requestModel.description.$error,
                }"
                type="text"
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.description.required"
                class="invalid-feedback"
              >
                Description is required
              </div>
              <div
                v-if="!$v.requestModel.description.minLength"
                class="invalid-feedback"
              >
                Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.description.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Urdu Short Description"
              label-for="description"
              autocomplete="off"
            >
              <b-form-input
                id="description"
                v-model="requestModel.Urdudescription"
                :class="{
                  'is-invalid': submitted && $v.requestModel.Urdudescription.$error,
                }"
                type="text"
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.Urdudescription.required"
                class="invalid-feedback"
              >
              Urdu description is required
              </div>
              <div
                v-if="!$v.requestModel.Urdudescription.minLength"
                class="invalid-feedback"
              >
              Urdu description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.Urdudescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Long Description"
              label-for="longDescription"
              autocomplete="off"
            >

              <ckeditor  v-model="requestModel.longDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.longDescription.$error,
                }"></ckeditor>

              <div
                v-if="submitted && !$v.requestModel.longDescription.required"
                class="invalid-feedback"
              >
                Long Description is required
              </div>
              <div
                v-if="!$v.requestModel.longDescription.minLength"
                class="invalid-feedback"
              >
                Long Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.longDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 5000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Urdu Long Description"
              label-for="longDescription"
              autocomplete="off"
            >

              <ckeditor  v-model="requestModel.UrdulongDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.UrdulongDescription.$error,
                }"></ckeditor>

              <div
                v-if="submitted && !$v.requestModel.UrdulongDescription.required"
                class="invalid-feedback"
              >
              Urdu Long Description is required
              </div>
              <div
                v-if="!$v.requestModel.UrdulongDescription.minLength"
                class="invalid-feedback"
              >
              Urdu Long Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.UrdulongDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 5000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Trial" label-for="trial" autocomplete="off">
              <b-form-input
                id="trial"
                v-model="requestModel.trial"
                :class="{
                  'is-invalid': submitted && $v.requestModel.trial.$error,
                }"
                type="text"
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.trial.required"
                class="invalid-feedback"
              >
                Trial is required
              </div>
            </b-form-group>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Cover"
              label-for="logo"
              autocomplete="off"
            >
              <b-form-file
                accept="image/jpeg, image/png, image/gif, video/mp4"
                @change="onSelectImage"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </div>
        </div> -->
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "AddVenturePackage",
  data() {
    return {
      moduleName: "Add Venture Package",
      file1: null,
      requestModel: {
        title: null,
        Urdutitle: null,
        description: null,
        Urdudescription: null,
        longDescription: null,
        UrdulongDescription: null,
        trial: null,
      },
      submitted: false,
      // courseTypeOptions: null,
      courseCategoryOptions: null,
      courseTypeOptions: [
        { text: "Train Me", value: 1 },
        { text: "Train The Team", value: 2 },
        { text: "Skill Her", value: 3 },
      ],
    };
  },
  validations: {
    requestModel: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(350),
      },
      description: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(1000),
      },
      longDescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(5000),
      },
      Urdutitle: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(350),
      },
      Urdudescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(1000),
      },
      UrdulongDescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(5000),
      },
      trial: {
        required,
      },
    },
  },

  components: {
    Toaster,
  },
  created() {
    this.getDropdowns();
  },
  methods: {
    onSelectImage(e) {
      console.log("ON SELECT CALLED");
      this.requestModel.logo = null;
      this.requestModel.logo = e.target.files[0];
      console.log("uploadFile : ", e.target.files[0].size);
      console.log("uploadFileName : ", this.requestModel.logo.name);

      // Allowing file Size
      var fileSize = 209715;

      if (this.requestModel.logo.size > fileSize) {
        // alert('File Size Limit is 200 KB');
        this.$refs.toaster.showToast(
          "Error",
          "danger",
          "File Size Limit is 200 KB"
        );
        e.target.value = "";
        this.requestModel.logo = null;
        return false;
      }
    },
    getDropdowns() {
      this.getCourseCategories();
    },
    getCourseCategories() {
      axios.get("/V_Learn/GetCourseCategory").then((res) => {
        this.isLoading = false;
        if (res.data.status == "Success") {
          let options = res.data.data.map((x) => {
            return {
              text: x.courseCategoryName,
              value: x.courseCategoryId,
            };
          });
          this.courseCategoryOptions = options;
        }
      });
    },
    getDropDownValues(id) {
      this.isLoading = true;
      axios
        .post("/KeyValuePair/GetDropDownListById", {
          type: id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            console.log(res);
            let options = res.data.data.map((x) => {
              return {
                text: x.value,
                value: x.key,
              };
            });
            if (id == "ShehriType") {
              this.shehriTypeOptions = options;
            } else if (id == "CmsCategory") {
              this.cmsCategoryOptions = options;
            }
          } else {
            return null;
          }
        });
    },
    Add() {
      this.isLoading = true;
      const formData = new FormData();
      console.log(this.requestModel);
      formData.append("title", this.requestModel.title);
      formData.append("UrduTitle", this.requestModel.Urdutitle);
      formData.append("UrduDescription", this.requestModel.Urdudescription);
      formData.append("UrduLongDescription", this.requestModel.UrdulongDescription);
      formData.append("description", this.requestModel.description);
      formData.append("longDescription", this.requestModel.longDescription);
      formData.append("Trial", this.requestModel.trial);
      console.log(formData);

      axios
        .post("/Shehri/AddVenturePackages", this.requestModel)
        .then((res) => {
          this.isLoading = false;

          if (res.data.status == "Success") {
            this.$parent.getPage(1);
            this.resetModal();
            this.$bvModal.hide("AddVenturePackageModal");
            console.log(res);
            this.$refs.toaster.showToast(
              "Sucess",
              "success",
              "Venture Package has been added successfully"
            );
          } else {
            this.$refs.toaster.showToast(
              "Error",
              "danger",
              "Unable to add venture package"
            );
          }
        });
    },

    checkFormValidity() {
      const valid = this.$refs.AddForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      this.requestModel.title = "";
      this.requestModel.description = "";
      this.requestModel.longDescription = "";
      this.requestModel.Urdutitle = "";
      this.requestModel.Urdudescription = "";
      this.requestModel.UrdulongDescription = "";
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }

      this.Add();
    },
  },
};
</script>
