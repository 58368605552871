<template>
  <div>
    <div class="row justify-content-between align-items-center">
      <div class="col-md-7 customHeading">
        <h3>{{ moduleName }}</h3>
      </div>
      <div class="col-md-2 icon-theme-color">
        <div class="row">
          <div class="col-md-8 center-adv-srch" v-b-toggle.filters-collpased>
            <details style="display: inline">
              <summary title="Advanced Search" class="adv-search-p">
                Advanced Search
              </summary>
            </details>
          </div>
          <div
            class="col-md-4 center-adv-srch"
            role="button"
            @click="clearFilters"
          >
            <em
              title="Reset Filters"
              class="fas fa-times fa-sm clear-filter-btn"
            ></em>
          </div>
        </div>
      </div>

      <!---SEARCH BOX-->
      <div class="col-md-2">
        <div class="searchbox input-group" role="group">
          <input
            class="form-control search-box my-search-box"
            type="text"
            placeholder="Name"
            aria-label="search"
            v-model="filteredText"
            @keydown.enter.prevent="getApplicants()"
          />

          <button
            class="search-button btn btn-primary filter"
            @click="getApplicants()"
          >
            <i class="fa fa-search search-icon"></i>
          </button>
        </div>
      </div>
      <!-- <div class="col-md-1 Add-button" style="text-align: center">
        <a href="javascript:void(0)">
          <div class="AddButton">
            <div class="icon">
              <i
                class="fas fa-arrow-alt-circle-right fa-1x"
                style="color: rgb(255, 50, 55)"
              >
              </i>
            </div>
            <div data-text="Add" class="name add-btn" v-b-modal.AddClientModal>
              Add
            </div>
          </div>
        </a>
      </div> -->
    </div>
    <b-collapse id="filters-collpased">
      <div class="row margin-filters">
        <div class="col-md-2">
          <b-form-group
            label=""
            label-for="datepicker-2"
            autocomplete="off"
            style="margin-bottom: 0px"
          >
            <b-form-datepicker
              id="datepicker-2"
              v-model="filters.from"
              placeholder="Choose Start Date"
              local="en"
            ></b-form-datepicker>
          </b-form-group>
        </div>
        <div class="col-md-2">
          <b-form-group
            label=""
            style="margin-bottom: 0px"
            label-for="datepicker-1"
            autocomplete="off"
          >
            <b-form-datepicker
              id="datepicker-1"
              v-model="filters.to"
              placeholder="Choose End Date"
              local="en"
            ></b-form-datepicker>
          </b-form-group>
        </div>
        <div class="col-md-2">
          <!-- <b-form-select-option :value="null"
              >Select Category</b-form-select-option
            > -->
          <b-form-select
            v-model="filters.categoryId"
            :options="categoryOptions"
            class="class-dropdown"
          >
           
          </b-form-select>
        </div>
        <div class="col-md-2">
          <!-- <b-form-select-option :value="null"
              >Select City</b-form-select-option
            > -->
          <b-form-select
            v-model="filters.cityId"
            :options="cityOptions"
            class="class-dropdown"
          >
           
          </b-form-select>
        </div>
        <div class="col-md-2">
          <!-- <b-form-select-option :value="null"
              >Select Candidate</b-form-select-option
            > -->
          <b-form-select
            v-model="filters.canidateId"
            :options="candidateOptions"
            class="class-dropdown"
          >
           
          </b-form-select>
        </div>
        <div class="col-md-2">
          <!-- <b-form-select-option :value="null"
              >Select Education</b-form-select-option
            > -->
          <b-form-select
            v-model="filters.educationId"
            :options="degreeOptions"
            class="class-dropdown"
          >
            
          </b-form-select>
        </div>
      </div>
      <div class="row margin-filters">
        <div class="col-md-2">
          <!-- <b-form-select-option :value="null"
              >Select Experience</b-form-select-option
            > -->
          <b-form-select
            v-model="filters.experienceLevelId"
            :options="experienceOptions"
            class="class-dropdown"
          >
           
          </b-form-select>
        </div>
        <div class="col-md-2">
          <!-- <b-form-select-option :value="null"
              >Select Gender</b-form-select-option
            > -->
          <b-form-select
            v-model="filters.genderId"
            :options="genderOptions"
            class="class-dropdown"
          >
          
          </b-form-select>
        </div>
        <div class="col-md-2">
          <!-- <b-form-select-option :value="null"
              >Select Job</b-form-select-option
            > -->
          <b-form-select
            v-model="filters.jobId"
            :options="jobOptions"
            class="class-dropdown"
          >
           
          </b-form-select>
        </div>
        <div class="col-md-2">
          
          <b-form-select
            v-model="filters.countryId"
            :options="countryOptions"
            class="class-dropdown"
          >
          <!-- <b-form-select-option :value="null"
              >Select Country</b-form-select-option
            > -->
          </b-form-select>
        </div>
        <!-- <div class="col-md-2">
          <b-form-group label="" label-for="Age" autocomplete="off">
            <b-form-input
              id="Age"
              v-model="filters.age"
              placeholder="Enter Age"
              type="text"
            ></b-form-input>
          </b-form-group>
        </div> -->
      </div>
      <div class="row margin-filters">
        <div class="col-md-12" style="text-align: center">
          <button class="btn filter-search-btn" @click="getApplicants()">
            Search
          </button>
        </div>
      </div>
    </b-collapse>
    <div class="row">
      <div class="col">
        <hr
          color="#CACACA"
          style="border-top: 2px solid rgb(202, 202, 202); margin-top: 5px"
        />
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col">
          <table
            class="
              table table-striped table-borderless
              cutom-table
              table-striped table-sm
            "
            style="font-size: 85%"
          >
            <thead>
              <tr>
                <th role="columnheader">Name</th>
                <th role="columnheader">City</th>
                <th role="columnheader">Email</th>
                <!-- <th role="columnheader">Category</th> -->
                <th role="columnheader">Education</th>
                <th role="columnheader">Years of Experience</th>
                <th role="columnheader">Gender</th>
                <th role="columnheader">Job</th>
                <!-- <th role="columnheader">Status</th> -->
                <th role="columnheader">CV</th>
              </tr>
            </thead>
            <tbody>
              <!--IF STUDENTS ARRAY HAS NO VALUE THEN SHOW WITHOUT SHOWING DATA-->
              <tr v-if="applicants == null">
                <td class="center-td" colspan="8">No Data</td>
              </tr>

              <tr v-else v-for="(record, index) in applicants" :key="index">
                <td
                  role="button"
                  @click="showApplicantDetails(record)"
                >
                  {{ record.employeeName }}
                </td>

                <td role="cell">
                  {{ record.city }}
                </td>
                <td role="cell">{{ record.email }}</td>
                <!-- <td role="columnheader">Category</td> -->
                <td role="columnheader">{{record.empEducation != null ? record.empEducation.education:""}}</td>
                <td role="columnheader">{{record.experience != null ? record.experience.value:""}}</td>
                <td role="columnheader">{{record.gender}}</td>
                <td role="columnheader">{{record.jobTitle}}</td>
                <td>
                  <a
                    style="text-decoration: none"
                    :href="
                      record.resume != null &&
                      record.resume != '' &&
                      record.resume != undefined
                        ? siteUrl + record.resume
                        : 'Javascript:void(0)'
                    "
                    target="_blank"
                  >
                    <span title="Download CV">
                      <i
                        role="button"
                        style="color: #ea216c"
                        class="fas fa-download"
                      ></i>
                    </span>
                  </a>
                  <!-- <span @click="deleteRecord(record.id)">
                    <i
                      role="button"
                      style="color: #ea216c"
                      class="far fa-trash-alt"
                    ></i>
                  </span> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--PAGINATION START-->
      <div class="row justify-content-between align-items-center">
        <div class="col-md-2">
          <div class="showPages">
            <select
              class="form-control"
              id="selClass"
              v-model="perPage"
              @change="getApplicants()"
            >
              <option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-8 d-flex justify-content-center">
          <div class="overflow-auto">
            <b-pagination
              v-model="page"
              :total-rows="rows"
              :per-page="perPage"
              limit="3"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              @change="getPage"
            ></b-pagination>
          </div>
        </div>

        <div class="col-md-2">
          <input
            class="form-control search-box page-no-search"
            type="text"
            v-bind:placeholder="page"
            aria-label="search"
            v-model.number="pageNumOnSearch"
            style="height: 38px"
          />

          <button
            class="search-button btn btn-primary search page-no-search-btn"
            @click="search()"
          >
            GO
          </button>
        </div>
      </div>
      <!--/PAGINATION END-->
      <!--SPINNER-->
      <Spinner
        v-if="isLoading"
        message="Loading..."
        size="medium"
        line-fg-color="rgb(222 224 219"
        line-bg-color="rgb(68 66 62)"
      >
      </Spinner>
      <!--/SPINNER-->
    </div>
    <!-- <AddClientModal /> -->
    <Toaster ref="toaster" />
    <ApplicantDetailsModal
      v-if="applicantId != null"
      :applicantId="applicantId"
      :employeeId="employeeId"
    />
    <!-- <EditJobRequest :jobRequestToEdit="recordToEdit" v-if="recordToEdit != null" /> -->
    <DeleteModal
      :msgForModal="msgForModal"
      :apiEndPoint="apiEndPoint"
      :heading="deleteModalHeading"
      :entityId="recordToDelete"
    />
  </div>
</template>

<script>
import axios from "axios";
import Toaster from "../../common/pages/toaster.vue";
// import config from '../config.js';
import Spinner from "vue-simple-spinner";
// import AddClientModal from "./modals/AddClientModal";
import DeleteModal from "../../common/modals/delete-modal.vue";
import ApplicantDetailsModal from "./modals/candidate-details-modal.vue";
// import EditJobRequest from "./modals/edit-job-request-modal.vue";
import _ from "underscore";

export default {
  name: "Applicants",
  data() {
    return {
      siteUrl: "https://smw-live.azurewebsites.net",
      moduleName: "Applicants",
      isLoading: false,
      recordToEdit: null,
      recordToDelete: null,
      msgForModal: null,
      apiEndPoint: null,
      deleteModalHeading: null,
      applicantId: null,
      employeeId:null,
      applicants: null,
      filters: {
        countryId: null,
        cityId: null,
        companyName: null,
        to: null,
        from: null,
        categoryId: null,
        genderId: null,
        experienceLevelId: null,
        jobId: null,
        age: null,
        canidateId: null,
        educationId: null,
        PageSize: null,
        PageNumber: null,
        Text: null,
      },

      cityOptions: null,
      countryOptions: null,
      genderOptions: null,
      degreeOptions: null,
      experienceOptions: null,
      Options: null,
      categoryOptions: null,
      jobOptions: null,
      candidateOptions: null,

      pageNumOnSearch: "",
      filteredText: "",
      rows: 0,
      page: 1,
      options: [
        { text: "Show 2", value: 2 },
        { text: "Show 10", value: 10 },
        { text: "Show 25", value: 25 },
        { text: "Show 50", value: 50 },
        { text: "Show 100", value: 100 },
      ],
      perPage: 25,
    };
  },

  methods: {
    clearFilters() {
      for (var key in this.filters) {
        console.log(key);
        this.filters[key] = null;
      }
    },
    getPage(page) {
      this.page = page;
      this.getApplicants();
    },
    showApplicantDetails(record) {
      this.applicantId = record.applicantId;
      this.employeeId = record.employeeId;
      setTimeout(() => {
        this.$bvModal.show("ApplicantDetailsModal");
      }, 100);
    },
    editRecord(comp) {
      this.recordToEdit = comp;
      // setTimeout(() => {
      //   this.$bvModal.show("EditJobsRequestsModal");
      // }, 100);
    },
    deleteRecord(id) {
      this.recordToDelete = id;
      this.msgForModal = "Confirm to delete !";
      this.apiEndPoint = "client/Delete/";
      this.deleteModalHeading = "Delete";
      this.$bvModal.show("DeleteModal");
    },

    getApplicants() {
      this.isLoading = true;
      this.filters.PageSize = this.perPage;
      this.filters.PageNumber = this.page;
      this.filters.Text = this.filteredText;

      axios.post("/Job/JobApplicantFilter", this.filters).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          console.log(res);
          this.applicants = res.data.data;
          this.rows = res.data.totalCount;
        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        } else {
          this.applicants = null;
        }
      });
    },
    getFiltersDropdowns() {
      this.getDropDownValues("city");
      this.getDropDownValues("country");
      this.getDropDownValues("Category");
      this.getDropDownValues("DegreeLevel");
      this.getDropDownValues("ExperienceLevel");
      this.getDropDownValues("Gender");
    },
    getDropDownValues(id) {
      this.isLoading = true;
      const a1 ={
        text:"Select City",
        value:null,
      };
      const a2 ={
        text:"Select Country",
        value:null,
      };
      const a3 ={
        text:"Select Gender",
        value:null,
      };
      const a4 ={
        text:"Select Experience",
        value:null,
      };
      const a5 ={
        text:"Select Education",
        value:null,
      };
      const a6 ={
        text:"Select Category",
        value:null,
      };
      axios
        .post("/KeyValuePair/GetDropDownListById", {
          type: id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            let options = res.data.data.map((x) => {
              return {
                text: x.value,
                value: x.key,
              };
            });
            let sortedData=[];
            sortedData = _.sortBy( options, 'text');

            console.log(sortedData)

            if (id == "city") {
              this.cityOptions = sortedData;
              this.cityOptions.splice(0, 0, a1);
              this.cityOptions.join();
            } else if (id == "country") {
              
              this.countryOptions = sortedData;
              this.countryOptions.splice(0, 0, a2);
              this.countryOptions.join();

            } else if (id == "Gender") {
              this.genderOptions = options;
              this.genderOptions.splice(0, 0, a3);
              this.genderOptions.join();
            } else if (id == "ExperienceLevel") {
              this.experienceOptions = options;
              this.experienceOptions.splice(0, 0, a4);
              this.experienceOptions.join();
            } else if (id == "DegreeLevel") {
              this.degreeOptions = options;
              this.degreeOptions.splice(0, 0, a5);
              this.degreeOptions.join();
            } else if (id == "Category") {
              this.categoryOptions = options;
              this.categoryOptions.splice(0, 0, a6);
              this.categoryOptions.join();
            }
          } else {
            return null;
          }
        });
    },
    search() {
      this.page = this.pageNumOnSearch;
      this.getApplicants();
    },
    getCandidateOptions() {
      const a1 ={
        text:"Select Candidate",
        value:null,
      };
      axios.get("/Employee/GetCandidatesList").then((res) => {
        this.isLoading = false;
        console.log(res);
        if (res.data.status == "Success") {
          this.candidateOptions = res.data.data.map((x) => {
            return {
              text: x.candidates,
              value: x.candidatesId,
            };
          });

            let sortedData=[];
            sortedData = _.sortBy( this.candidateOptions, 'text');
            this.candidateOptions = sortedData;
            this.candidateOptions.splice(0, 0, a1);
              this.candidateOptions.join();
        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        } else {
          this.candidateOptions = null;
        }
      });
    },
    getJobOptions() {
      const a1 ={
        text:"Select Job",
        value:null,
      };
      axios.get("/Job/GetAlljobsOfEmployeer").then((res) => {
        this.isLoading = false;
        console.log(res);
        if (res.data.status == "Success") {
          this.jobOptions = res.data.data.map((x) => {
            return {
              text: x.jobTitle,
              value: x.jobId,
            };
          });

            let sortedData=[];
            sortedData = _.sortBy( this.jobOptions, 'text');
            this.jobOptions = sortedData;
            this.jobOptions.splice(0, 0, a1);
              this.jobOptions.join();
            console.log(sortedData)

        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        } else {
          this.jobOptions = null;
        }
      });
    },
  },

  created() {
    this.getApplicants();
    this.getFiltersDropdowns();
    this.getCandidateOptions();
    this.getJobOptions();
    // this.getStudents();
    //localStorage.setItem("mytime", Date.now());
  },
  components: {
    Spinner,
    // EditJobRequest,
    DeleteModal,
    Toaster,
    ApplicantDetailsModal,
    // AddClientModal,
  },
};
</script>

<style scoped>
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
select#class-dropdown {
  width: 80%;
  margin-left: 16%;
}
</style>
