<template>
    <div>
      <div class="row justify-content-between align-items-center">
        <div class="col-md-9 customHeading">
          <h3>Question For SheMeanswork</h3>
        </div>
  
        <!--==========Class dropdown============-->
  
        <!---SEARCH BOX-->
        <div class="col-md-2">
          <div class="searchbox input-group" role="group">
            <input
              class="form-control search-box my-search-box"
              type="text"
              placeholder="Name"
              aria-label="search"
              v-model="filteredText"
              @keydown.enter.prevent="getAll()"
            />
  
            <button
              class="search-button btn btn-primary filter"
              @click="getAll()"
            >
              <i class="fa fa-search search-icon"></i>
            </button>
          </div>
        </div>
        <div class="col-md-1 Add-button" style="text-align: center">
          <a href="javascript:void(0)">
            <div class="AddButton">
              <div class="icon">
                <i
                  class="fas fa-arrow-alt-circle-right fa-1x"
                  style="color: rgb(255, 50, 55)"
                >
                </i>
              </div>
              <div data-text="Add" class="name add-btn" v-b-modal.AddQuestionModal>
                Add
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <hr
            color="#CACACA"
            style="border-top: 2px solid rgb(202, 202, 202); margin-top: 5px"
          />
        </div>
      </div>
      <div>
        <div class="row">
          <div class="col">
            <table
              class="
                table table-striped table-borderless
                cutom-table
                table-striped table-sm
              "
              style="font-size: 85%"
            >
              <thead>
                <tr>
                  <th role="columnheader">NO</th>
                  <th role="columnheader">Question</th>
                  <th role="columnheader">Is Employer</th>
                  <th role="columnheader">Actions</th>
                </tr>
              </thead>
              <tbody>
                <!--IF STUDENTS ARRAY HAS NO VALUE THEN SHOW WITHOUT SHOWING DATA-->
                <tr v-if="list == null">
                  <td class="center-td" colspan="5">No Data</td>
                </tr>
  
                <tr v-else v-for="record in list" :key="record.questionId">
                  <td role="cell">{{ record.questionId }}</td>
                  <td role="cell">{{ record.questionValue }}</td>
                  <td role="cell">{{ record.employer }}</td>
                 
                  <td>
                    <span @click="editRecord(record)">
                      <i
                        role="button"
                        style="color: #ea216c"
                        class="fas fa-edit"
                      ></i>
                    </span>&nbsp;|&nbsp;
                    <span @click="deleteRecord(record.questionId)">
                      <i
                        role="button"
                        style="color: #ea216c"
                        class="far fa-trash-alt"
                      ></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  
        <!--PAGINATION START-->
        <div class="row justify-content-between align-items-center">
          <div class="col-md-2">
            <div class="showPages">
              <select
                class="form-control"
                id="selClass"
                v-model="perPage"
                @change="getAll()"
              >
                <option
                  v-for="option in options"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
  
          <div class="col-md-8 d-flex justify-content-center">
            <div class="overflow-auto">
              <b-pagination
                v-model="page"
                :total-rows="rows"
                :per-page="perPage"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                @change="getPage"
              ></b-pagination>
            </div>
          </div>
  
          <div class="col-md-2">
            <input
              class="form-control search-box page-no-search"
              type="text"
              v-bind:placeholder="page"
              aria-label="search"
              v-model.number="pageNumOnSearch"
              style="height: 38px"
            />
  
            <button
              class="search-button btn btn-primary search page-no-search-btn"
              @click="search()"
            >
              GO
            </button>
          </div>
        </div>
        <!--/PAGINATION END-->
        <!--SPINNER-->
        <Spinner
          v-if="isLoading"
          message="Loading..."
          size="medium"
          line-fg-color="rgb(222 224 219"
          line-bg-color="rgb(68 66 62)"
        >
        </Spinner>
        <!--/SPINNER-->
      </div>
      <!-- <AddClientModal /> -->
      <Toaster ref="toaster" />
      <EditCompanyModal
        :recordToEdit="recordToEdit"
        v-if="recordToEdit != null"
      />
      <AddQuestionModal /> 
      <DeleteModal
        :msgForModal="msgForModal"
        :apiEndPoint="apiEndPoint"
        :heading="deleteModalHeading"
        :entityId="recordToDelete"
        :isHttpPost="true"
      />
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import Toaster from "../../common/pages/toaster.vue";
  import AddQuestionModal from "./modals/add-feedback-modal.vue";
  // import config from '../config.js';
  import Spinner from "vue-simple-spinner";
  import EditCompanyModal from "./modals/edit-feedback-modal.vue";
  import DeleteModal from "../../common/modals/delete-modal.vue";
  
  export default {
    name: "feedbacks",
    data() {
      return {
  
        siteUrl : "https://smw-live.azurewebsites.net",
        moduleName: "feedbacks",
        list: [],
        isLoading: false,
        recordToEdit: null,
        recordToDelete: null,
        msgForModal: null,
        apiEndPoint: null,
        deleteModalHeading: null,
  
        jobPostPackageId: null,
        requestModel: {
          QuestionId: null,
          QuestionValue: "",
          Employer: false,
         
        },
        pageNumOnSearch: "",
        filteredText: "",
        rows: 0,
        page: 1,
        options: [
          { text: "Show 2", value: 2 },
          { text: "Show 10", value: 10 },
          { text: "Show 25", value: 25 },
          { text: "Show 50", value: 50 },
          { text: "Show 100", value: 100 },
        ],
        perPage: 25,
      };
    },
  
    methods: {
      getPage(page) {
        this.page = page;
        this.getAll();
      },
      // updateCompanyFeaturedandStatusState(record) {
      //   this.isLoading = true;
      //   let req = {
      //     employeeCompanyId: record.employerCompanyId,
      //     isFeature: record.isFeature,
      //     companyStatus: record.companyStatus == "Active" ? 2 : 1,
      //   };
      //   console.log(req);
      //   axios.post("/Employer/GetQuestion", req).then((res) => {
      //     this.isLoading = false;
      //     if (res.data.status == "Success") {
      //       console.log("success");
      //       // this.rows = res.data.totalRecords;
      //     } else {
      //       this.$refs.toaster.showToast("Error", "danger", res.data.msg);
      //     }
      //   });
      // },
      editRecord(comp) {
        this.recordToEdit = comp;
        setTimeout(() => {
          this.$bvModal.show("EditModal");
        }, 100);
      },
      deleteRecord(id) {
        this.recordToDelete = id;
        this.msgForModal = "Confirm to delete !";
        this.apiEndPoint = "Employee/DeleteQuestion?id=";
        this.deleteModalHeading = "Delete";
        this.$bvModal.show("DeleteModal");
      },
  
      getAll() {
        // this.list.push({Id:1, Title: "Standard", JobsAllowed:100, Price: 5000,Status:1 })
        this.isLoading = true;
         axios.post("/Employee/Getquestions", {
            PageSize: this.perPage,
            PageNumber: this.page,
            // Text: this.filteredText,
            title: this.filteredText,
            Type:"feedbacks"
          }).then((res) => {
          this.isLoading = false;
  
          if (res.data.status == "Success") {
            console.log(res);
            this.list = res.data.data;
            this.rows = res.data.totalCount;
  
            // this.rows = res.data.totalRecords;
          } else if (res.data.status == -1) {
            this.$refs.toaster.showToast("Error", "danger", res.data.msg);
          } else {
            this.list = null;
          }
        });
      },
  
      search() {
        this.page = this.pageNumOnSearch;
        this.getAll();
      },
    },
    created() {
      this.getAll();
      // this.getStudents();
      //localStorage.setItem("mytime", Date.now());
    },
    components: {
      Spinner,
      DeleteModal,
      Toaster,
      AddQuestionModal,
      EditCompanyModal
    },
  };
  </script>
  
  <style scoped>
  button.page-link {
    display: inline-block;
  }
  button.page-link {
    font-size: 20px;
    color: #29b3ed;
    font-weight: 500;
  }
  .offset {
    width: 500px !important;
    margin: 20px auto;
  }
  select#class-dropdown {
    width: 80%;
    margin-left: 16%;
  }
  </style>
  