  <template>
  <div>
    <b-modal
      style="padding: 15px"
      size="md"
      id="DeleteModal"
      ref="modal"
      :title="heading"
      ok-title="Yes"
      cancel-title="No"
      @ok="handleOk"
    >
      <form ref="DeleteUserForm" @submit.stop.prevent="handleSubmit">
        {{ msgForModal }}
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

      <script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../pages/toaster.vue";
export default {
  name: "DeleteModal",
  data() {
    return {
      moduleName: "DeleteModal",
    };
  },
  props: {
    entityId: null,
    heading: null,
    apiEndPoint: null,
    msgForModal: null,
    isHttpPost: null,
  },
  components: {
    Toaster,
  },
  methods: {
    deleteUser() {
      // console.log(this.$auth.user())
      if (this.isHttpPost == null) {
        axios
          .get(this.apiEndPoint + this.entityId)
          .then((res) => {
            if (res.data.status == "Success") {
              this.$refs.toaster.showToast(
                "Success",
                "success",
                "Record has been deleted successfully"
              );
              this.$bvModal.hide("DeleteModal");
              this.$parent.getPage(1);
            } else {
              this.$refs.toaster.showToast("Error", "danger", res.data.msg);
            }
          })
          .catch((err) => {
            console.warn("error during http call", err);
          });
      } else {
        axios
          .post(this.apiEndPoint + this.entityId)
          .then((res) => {
            if (res.data.status == "Success") {
              this.$refs.toaster.showToast(
                "Success",
                "success",
                "Record has been deleted successfully"
              );
              this.$bvModal.hide("DeleteModal");
              this.$parent.getPage(1);
            } else {
              this.$refs.toaster.showToast("Error", "danger", res.data.msg);
            }
          })
          .catch((err) => {
            console.warn("error during http call", err);
          });
      }
    },

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid

      this.deleteUser();

      this.$nextTick(() => {
        //this.$router.go('/phones')
        this.$bvModal.hide("DeleteUserModal");
      });
    },
  },
};
</script>
