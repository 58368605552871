var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"modal",staticStyle:{"padding":"15px"},attrs:{"size":"lg","id":"AddBrandModal","title":"Add Brand","ok-title":"Submit","cancel-title":"Close","ok-disabled":this.$refs.AddBrandForm != undefined
        ? !this.$refs.AddBrandForm.checkValidity()
        : true},on:{"show":function($event){return _vm.resetModal()},"hidden":function($event){return _vm.resetModal()},"ok":_vm.handleOk}},[_c('form',{ref:"AddBrandForm",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Title","label-for":"Title","autocomplete":"off"}},[_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.brand.brandName.$error,
              },attrs:{"id":"brandName","type":"text","required":""},model:{value:(_vm.brand.brandName),callback:function ($$v) {_vm.$set(_vm.brand, "brandName", $$v)},expression:"brand.brandName"}}),(_vm.submitted && !_vm.$v.brand.brandName.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Brand Name is required ")]):_vm._e(),(!_vm.$v.brand.brandName.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Brand Name must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.brand.brandName.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 100 characters is reached ")]):_vm._e()],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Link","label-for":"Link","autocomplete":"off"}},[_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.brand.brandUrl.$error,
              },attrs:{"id":"Link","type":"text","required":""},model:{value:(_vm.brand.brandUrl),callback:function ($$v) {_vm.$set(_vm.brand, "brandUrl", $$v)},expression:"brand.brandUrl"}}),(_vm.submitted && !_vm.$v.brand.brandUrl.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Brand URL is required ")]):_vm._e()],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group",attrs:{"autocomplete":"off"}},[_c('label',{attrs:{"label-for":"Image"}},[_vm._v("Image")]),_c('span',{staticClass:"ml-1 text-muted small"},[_c('i',[_vm._v("(Brand image must be square, and at least 512 pixels wide and tall.)")])]),_c('b-form-file',{class:{
                'is-invalid': _vm.submitted && _vm.$v.brand.brandImage.$error,
              },attrs:{"accept":"image/jpeg, image/png, image/gif","state":Boolean(_vm.brand.brandImage),"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.brand.brandImage),callback:function ($$v) {_vm.$set(_vm.brand, "brandImage", $$v)},expression:"brand.brandImage"}}),(_vm.submitted && !_vm.$v.brand.brandImage.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Brand Image is required ")]):_vm._e()],1)])])])]),_c('Toaster',{ref:"toaster"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }