<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="xl"
      id="AddVenturePartnerModal"
      ref="modal"
      :title="'Add ' + pageType"
      ok-title="Submit"
      cancel-title="Close"
      @show="resetModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.AddForm != undefined
          ? !this.$refs.AddForm.checkValidity()
          : true
      "
    >
      <form ref="AddForm" @submit.stop.prevent="handleSubmit">
        <!--TITLE-->
        <div class="row" v-if="isTitle">
          <div class="col-md-12">
            <b-form-group label="Title" label-for="Title" autocomplete="off">
              <b-form-input
                id="ventureTitle"
                v-model="requestModel.title"
                :class="{
                  'is-invalid': submitted && $v.requestModel.title.$error,
                }"
                type="text"
                required
              ></b-form-input>
              <div
                v-if="submitted && !$v.requestModel.title.required"
                class="invalid-feedback"
              >
                Title is required
              </div>
              <div
                v-if="!$v.requestModel.title.minLength"
                class="invalid-feedback"
              >
                Title must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.title.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <!--SHORT DESCRIPTION-->
        <div class="row" v-if="isShortDescription">
          <div class="col-md-12">
            <b-form-group
              label="Short Description"
              label-for="description"
              autocomplete="off"
            >
              <b-form-input
                id="description"
                v-model="requestModel.description"
                :class="{
                  'is-invalid': submitted && $v.requestModel.description.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.description.required"
                class="invalid-feedback"
              >
                Description is required
              </div>
              <div
                v-if="!$v.requestModel.description.minLength"
                class="invalid-feedback"
              >
                Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.description.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <!--LONG DESCRIPTION-->
        <div class="row" v-if="isLongDescription">
          <div class="col-md-12">
            <b-form-group
              label="Long Description"
              label-for="longDescription"
              autocomplete="off"
            >
              <!-- <b-textarea
                id="longDescription"
                v-model="requestModel.longDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.longDescription.$error,
                }"
                type="text"
                required
              ></b-textarea> -->

              <ckeditor
                v-model="requestModel.longDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.longDescription.$error,
                }"
              ></ckeditor>

              <div
                v-if="submitted && !$v.requestModel.longDescription.required"
                class="invalid-feedback"
              >
                Long Description is required
              </div>
              <div
                v-if="!$v.requestModel.longDescription.minLength"
                class="invalid-feedback"
              >
                Long Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.longDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 5000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <!--IMAGE-->
        <div class="row" v-if="isImage">
          <div class="col-md-12">
            <div class="form-group" autocomplete="off">
              <label label-for="Image">Image</label>
              <span
                v-if="pageType === 'Our Mentor Sessions'"
                class="ml-1 text-muted small"
              >
                <i
                  >(Image must be square, and at least 512 pixels wide and
                  tall.)</i
                >
                <!-- <b-icon icon="question-circle"></b-icon> -->
              </span>
              <b-form-file
                accept="image/jpeg, image/png, image/gif, image/webp"
                @change="onSelectImage"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                :class="{
                  'is-invalid': submitted && $v.requestModel.image.$error,
                }"
              ></b-form-file>

              <div
                v-if="submitted && !$v.requestModel.image.required"
                class="invalid-feedback"
              >
                Image is required
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "Add",
  data() {
    return {
      moduleName: "Add Venture",
      file1: null,
      requestModel: {
        title: null,
        type: 1,
        description: null,
        longDescription: null,
        image: null,
      },
      submitted: false,
      // courseTypeOptions: null,
      courseCategoryOptions: null,
      typeOptions: [
        { text: "Business Services and Partners", value: 1 },
        { text: "Investment and Funding", value: 2 },
        { text: "Why Venture Her", value: 3 }
      ],
       typeOptionsPartner: [
        { text: "Business Services and Partners", value: 1 },
        { text: "Investment and Funding", value: 2 }
      ],
    };
  },
  props: {
    pageType:{
      type:String
    },
    isTitle:{
      type:Boolean
    },
    isShortDescription:{
      type:Boolean
    },
    isLongDescription:{
      type:Boolean
    },
    isImage:{
      type:Boolean
    }
  },
  validations: {
    requestModel: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(350),
      },
      description: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(300),
      },
      longDescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(5000),
      },
      image: {
        required,
      },
    },
  },

  components: {
    Toaster,
  },
  created() {
    this.getDropdowns();
    console.log(this.IsWhyVenture);
    if (this.IsWhyVenture) {
      this.requestModel.type = 3;
    }
  },
  methods: {
    onSelectImage(e) {
      console.log("ON SELECT CALLED");
      this.requestModel.image = null;
      this.requestModel.image = e.target.files[0];
      console.log("uploadFile : ", e.target.files[0].size);
      console.log("uploadFileName : ", this.requestModel.image.name);

      // Allowing file Size
      var fileSize = 209715;

      if (this.requestModel.image.size > fileSize) {
        // alert('File Size Limit is 200 KB');
        this.$refs.toaster.showToast(
          "Error",
          "danger",
          "File Size Limit is 200 KB"
        );
        e.target.value = "";
        this.requestModel.image = null;
        return false;
      }
    },
    getDropdowns() {
      this.getCourseCategories();
    },
    getCourseCategories() {
      axios.get("/V_Learn/GetCourseCategory").then((res) => {
        this.isLoading = false;
        if (res.data.status == "Success") {
          let options = res.data.data.map((x) => {
            return {
              text: x.courseCategoryName,
              value: x.courseCategoryId,
            };
          });
          this.courseCategoryOptions = options;
        }
      });
    },
    getDropDownValues(id) {
      this.isLoading = true;
      axios
        .post("/KeyValuePair/GetDropDownListById", {
          type: id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            console.log(res);
            let options = res.data.data.map((x) => {
              return {
                text: x.value,
                value: x.key,
              };
            });
            if (id == "ShehriType") {
              this.shehriTypeOptions = options;
            } else if (id == "CmsCategory") {
              this.cmsCategoryOptions = options;
            }
          } else {
            return null;
          }
        });
    },
    Add() {
      this.isLoading = true;
      const formData = new FormData();
      console.log(this.requestModel);
      let viiLearnType=null;
      if(this.pageType==="Knowledge & Training For Everyone"){
        viiLearnType=2
      }else if(this.pageType==="Our Partners & Services for Corporates"){
        viiLearnType=3
      }else if(this.pageType==="Skill Her"){
        viiLearnType=4
      }else if(this.pageType==="Spare a Smile"){
        viiLearnType=6
      }else if(this.pageType==="Be a Mentor"){
        viiLearnType=8
      }else if(this.pageType==="Our Programs"){
        viiLearnType=7
      }else if(this.pageType==="Our Mission"){
        viiLearnType=5
      }else if(this.pageType==="Our Mentor Sessions"){
        viiLearnType=9
      }else if(this.pageType==="Education for Everyone"){
        viiLearnType=1
      }
      else
        return null;

      formData.append("Title", this.requestModel.title);
      formData.append("ShortDescription", this.requestModel.description);
      formData.append("LongDescription", this.requestModel.longDescription);
      formData.append("Image", this.requestModel.image);
      formData.append("ViiLearnType", viiLearnType);

      console.log(formData);

      axios.post("/V_Learn/Insert", formData).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          this.$parent.getPage(1);
          this.$bvModal.hide("AddVenturePartnerModal");
          console.log(res);
          if (this.IsWhyVenture) {
            this.$refs.toaster.showToast(
              "Sucess",
              "success",
              this.pageType+" has been added successfully"
            );
          }
        } else {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "Unable to add"
          );
        }
      });
    },

    checkFormValidity() {
      const valid = this.$refs.AddForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      this.requestModel.title=null;
      this.requestModel.description=null;
      this.requestModel.longDescription=null;
      this.requestModel.image=null;
      this.$v.$reset();
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },

    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // stop here if form is invalid
      this.$v.$touch();

      // if (this.$v.$invalid) {
      //   console.log("invalid");
      //   return;
      // }
       if(this.isTitle && this.submitted && this.$v.requestModel.title.$anyError){
        console.log("invalid");
        return;
      }
      if(this.isShortDescription && this.submitted && this.$v.requestModel.description.$anyError){
        console.log("invalid");
        return;
      }
       if(this.isLongDescription && this.submitted && this.$v.requestModel.longDescription.$anyError){
        console.log("invalid");
        return;
      }
       if(this.isImage && this.submitted && this.$v.requestModel.image.$anyError){
        console.log("invalid");
        return;
      }

      this.Add();
    },
  },
};
</script>
