<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="md"
      id="AddDropdownValueModal"
      ref="modal"
      title="Add Dropdown Value"
      ok-title="Submit"
      cancel-title="Close"
      @show="resetModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.AddDropdownForm != undefined
          ? !this.$refs.AddDropdownForm.checkValidity()
          : true
      "
    >
      <form ref="AddDropdownForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Text" label-for="Text" autocomplete="off">
              <b-form-input
                id="Text"
                v-model="Text"
                :class="{'is-invalid':submitted && $v.Text.$error}"
                type="text"
                required
              ></b-form-input>
              <div v-if="submitted && !$v.Text.required" class="invalid-feedback">
               Text is required
             </div>
             <div v-if="!$v.Text.minLength" class="invalid-feedback">
                Text must contain at least 3 characters
             </div>
              <div v-if="!$v.Text.maxLength" class="invalid-feedback">
               The maximum length of 350 characters is reached
             </div>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "AddDropdownValue",
  data() {
    return {
      moduleName: "Add Dropdown Value",
      Text: null,
      submitted: false,
    };
  },
  validations: {
    Text: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(350),
    },
  },
  components: {
    Toaster,
  },
  props: {
    dropDownToAddValue: {
      type: String,
      default: null,
    },
  },
  methods: {
    AddDropdownValue() {
      axios
        .post("/keyvaluepair/AddDropdown", {
          value: this.Text,
          type: this.dropDownToAddValue,
        })
        .then((res) => {
          this.isLoading = false;

          if (res.data.status == "Success") {
            this.$refs.toaster.showToast(
              "Success",
              "success",
              "Dropdown value has been added successfully"
            );
            this.$bvModal.hide("AddDropdownValueModal");
            this.$parent.getDropDownValues();
          } else {
            this.$refs.toaster.showToast("Error", "danger", res.data.msg);
          }
        });
    },

    checkFormValidity() {
      const valid = this.$refs.AddDropdownForm.checkValidity();

      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      this.Text = null;
    },

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }
      this.AddDropdownValue();
    },
  },
};
</script>
