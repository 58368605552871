<template>
  <div>
    <div class="row justify-content-between align-items-center">
      <div class="col-md-7 customHeading">
        <h3>{{ moduleName }}</h3>
      </div>
      <div class="col-md-2 icon-theme-color">
        <div class="row" style="display: none">
          <div class="col-md-8 center-adv-srch" v-b-toggle.filters-collpased>
            <details style="display: inline">
              <summary title="Advanced Search" class="adv-search-p">
                Advanced Search
              </summary>
            </details>
          </div>
          <div
            class="col-md-4 center-adv-srch"
            role="button"
            @click="clearFilters"
          >
            <em
              title="Reset Filters"
              class="fas fa-times fa-sm clear-filter-btn"
            ></em>
          </div>
        </div>
      </div>

      <!---SEARCH BOX-->
      <div class="col-md-2">
        <div class="searchbox input-group" role="group">
          <input
            class="form-control search-box my-search-box"
            type="text"
            placeholder="Title"
            aria-label="search"
            v-model="filteredText"
            @keydown.enter.prevent="getAll()"
          />

          <button
            class="search-button btn btn-primary filter"
            @click="getAll()"
          >
            <i class="fa fa-search search-icon"></i>
          </button>
        </div>
      </div>
      <div class="col-md-1 Add-button" style="text-align: center">
        <a href="javascript:void(0)">
          <div class="AddButton">
            <div class="icon">
              <i
                class="fas fa-arrow-alt-circle-right fa-1x"
                style="color: rgb(255, 50, 55)"
              >
              </i>
            </div>
            <div
              data-text="Add"
              class="name add-btn"
              v-b-modal.AddVenturePackageModal
            >
              Add
            </div>
          </div>
        </a>
      </div>
    </div>
    <b-collapse id="filters-collpased" style="display: none">
      <div class="row margin-filters">
        <div class="col-md-2">
          <b-form-group
            label=""
            label-for="datepicker-2"
            autocomplete="off"
            style="margin-bottom: 0px"
          >
            <b-form-datepicker
              id="datepicker-2"
              v-model="filters.from"
              placeholder="Choose Start Date"
              local="en"
            ></b-form-datepicker>
          </b-form-group>
        </div>
        <div class="col-md-2">
          <b-form-group
            label=""
            style="margin-bottom: 0px"
            label-for="datepicker-1"
            autocomplete="off"
          >
            <b-form-datepicker
              id="datepicker-1"
              v-model="filters.to"
              placeholder="Choose End Date"
              local="en"
            ></b-form-datepicker>
          </b-form-group>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.categoryId"
            :options="categoryOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Category</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.cityId"
            :options="cityOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select City</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.canidateId"
            :options="candidateOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Candidate</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.educationId"
            :options="degreeOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Education</b-form-select-option
            >
          </b-form-select>
        </div>
      </div>
      <div class="row margin-filters">
        <div class="col-md-2">
          <b-form-select
            v-model="filters.experienceLevelId"
            :options="experienceOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Experience</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.genderId"
            :options="genderOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Gender</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.jobId"
            :options="jobOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Job</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select
            v-model="filters.countryId"
            :options="countryOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Country</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-group label="" label-for="Age" autocomplete="off">
            <b-form-input
              id="Age"
              v-model="filters.age"
              placeholder="Enter Age"
              type="text"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row margin-filters">
        <div class="col-md-12" style="text-align: center">
          <button class="btn filter-search-btn" @click="getAll()">
            Search
          </button>
        </div>
      </div>
    </b-collapse>
    <div class="row">
      <div class="col">
        <hr
          color="#CACACA"
          style="border-top: 2px solid rgb(202, 202, 202); margin-top: 5px"
        />
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col">
          <table
            class="
              table table-striped table-borderless
              cutom-table
              table-striped table-sm
            "
            style="font-size: 85%"
          >
            <thead>
              <tr>
                <th role="columnheader">Title</th>
                <th role="columnheader">Short Description</th>
                <th role="columnheader">Long Description</th>
                <th role="columnheader">Actions</th>
              </tr>
            </thead>
            <tbody>
              <!--IF STUDENTS ARRAY HAS NO VALUE THEN SHOW WITHOUT SHOWING DATA-->
              <tr v-if="venturePackages == null">
                <td class="center-td" colspan="11">No Data</td>
              </tr>

              <tr
                v-else
                v-for="(record, index) in venturePackages"
                :key="index"
              >
                <td role="button" @click="showCourseDetails(record.id)">
                  {{ record.title }}
                </td>
                <td role="columnheader">
                  <b-button
                    variant="outline-dark"
                    size="sm"
                    @click="
                      ViewText(record.description, 'View Short Description')
                    "
                  >
                    View Short Description
                  </b-button>
                </td>
                <td role="columnheader">
                  <b-button
                    variant="outline-dark"
                    size="sm"
                    @click="
                      ViewText(
                        record.longDescription,
                        'View Long Description'
                      )
                    "
                  >
                    View Long Description
                  </b-button>
                </td>
                <!-- <td role="columnheader">
                  <a
                    style="text-decoration: none"
                    :href="
                      record.logo != null &&
                      record.logo != '' &&
                      record.logo != undefined
                        ? 'https://smw.demo.itrooscloud.net' +
                          record.logo
                        : 'Javascript:void(0)'
                    "
                    target="_blank"
                  >
                    <span title="View Media">
                      <i
                        role="button"
                        style="color: #ea216c"
                        class="fas fa-download"
                      ></i>
                    </span>
                  </a>
                </td> -->
                <td>
                  <span @click="editRecord(record)">
                    <i
                      role="button"
                      style="color: #ea216c"
                      class="fas fa-edit"
                    ></i> </span
                  >&nbsp;|&nbsp;

                  <span @click="deleteRecord(record.id)">
                    <i
                      role="button"
                      style="color: #ea216c"
                      class="far fa-trash-alt"
                    ></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--PAGINATION START-->
      <div class="row justify-content-between align-items-center">
        <div class="col-md-2">
          <div class="showPages">
            <select
              class="form-control"
              id="selClass"
              v-model="perPage"
              @change="getAll()"
            >
              <option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-8 d-flex justify-content-center">
          <div class="overflow-auto">
            <b-pagination
              v-model="page"
              :total-rows="rows"
              limit="3"
              :per-page="perPage"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              @change="getPage"
            ></b-pagination>
          </div>
        </div>

        <div class="col-md-2">
          <input
            class="form-control search-box page-no-search"
            type="text"
            v-bind:placeholder="page"
            aria-label="search"
            v-model.number="pageNumOnSearch"
            style="height: 38px"
          />

          <button
            class="search-button btn btn-primary search page-no-search-btn"
            @click="search()"
          >
            GO
          </button>
        </div>
      </div>
      <!--/PAGINATION END-->
      <!--SPINNER-->
      <Spinner
        v-if="isLoading"
        message="Loading..."
        size="medium"
        line-fg-color="rgb(222 224 219"
        line-bg-color="rgb(68 66 62)"
      >
      </Spinner>
      <!--/SPINNER-->
    </div>
    <AddVenturePackageModal />
    <Toaster ref="toaster" />
    <EditVenturePackageModal
      :ventureToEdit="recordToEdit"
      v-if="recordToEdit != null"
    />
    <DeleteModal
      :msgForModal="msgForModal"
      :apiEndPoint="apiEndPoint"
      :heading="deleteModalHeading"
      :entityId="recordToDelete"
      :isHttpPost="true"
    />
    <view-modal
      :text="viewModalText"
      :modal-title="viewModalTitle"
      v-if="viewModalText != null"
    ></view-modal>
  </div>
</template>

<script>
import axios from "axios";
import Toaster from "../../common/pages/toaster.vue";
// import config from '../config.js';
import Spinner from "vue-simple-spinner";
import AddVenturePackageModal from "./modals/add-venture-package-modal.vue";
import DeleteModal from "../../common/modals/delete-modal.vue";
import EditVenturePackageModal from "./modals/edit-venture-package-modal.vue";
import ViewModal from "../../common/modals/view-modal.vue";

export default {
  name: "VenturePackages",
  data() {
    return {
      moduleName: "Packages",
      isLoading: false,
      recordToEdit: null,
      recordToDelete: null,
      msgForModal: null,
      apiEndPoint: null,
      deleteModalHeading: null,
      applicantId: null,
      viewModalText: null,
      viewModalTitle: null,

      venturePackages: null,
      filters: {
        countryId: null,
        cityId: null,
        companyName: null,
        to: null,
        from: null,
        categoryId: null,
        genderId: null,
        experienceLevelId: null,
        jobId: null,
        age: null,
        canidateId: null,
        educationId: null,
      },

      cityOptions: null,
      countryOptions: null,
      genderOptions: null,
      degreeOptions: null,
      experienceOptions: null,
      Options: null,
      categoryOptions: null,
      jobOptions: null,
      candidateOptions: null,

      pageNumOnSearch: "",
      filteredText: "",
      rows: 0,
      page: 1,
      options: [
        { text: "Show 2", value: 2 },
        { text: "Show 10", value: 10 },
        { text: "Show 25", value: 25 },
        { text: "Show 50", value: 50 },
        { text: "Show 100", value: 100 },
      ],
      perPage: 25,
    };
  },

  methods: {
    clearFilters() {
      for (var key in this.filters) {
        console.log(key);
        this.filters[key] = null;
      }
    },
    getPage(page) {
      this.page = page;
      this.getAll();
    },

    editRecord(comp) {
      this.recordToEdit = comp;
      console.log("venturePackages", this.recordToEdit);
      setTimeout(() => {
        this.$bvModal.show("EditVenturePackageModal");
      }, 100);
    },
    deleteRecord(id) {
      this.recordToDelete = id;
      this.msgForModal = "Confirm to delete !";
      this.apiEndPoint = "Shehri/DeleteVenturePackages?id=";
      this.deleteModalHeading = "Delete";
      this.$bvModal.show("DeleteModal");
    },
    ViewText(text, modalTitle) {
      this.viewModalText = text;
      this.viewModalTitle = modalTitle;
      this.$bvModal.show("ViewModal");
    },

    getAll() {
      this.isLoading = true;
      axios
        .post("/Shehri/GetVenturePackages", {
          PageSize: this.perPage,
          PageNumber: this.page,
          Text: this.filteredText,
        })
        .then((res) => {
          this.isLoading = false;

          if (res.data.status == "Success") {
            console.log(res);
            this.venturePackages = res.data.data;
            this.rows = res.data.totalCount;
          } else if (res.data.status == -1) {
            this.$refs.toaster.showToast("Error", "danger", res.data.msg);
          } else {
            this.venturePackages = null;
          }
        });
    },
    getFiltersDropdowns() {
      this.getDropDownValues("city");
      this.getDropDownValues("country");
      this.getDropDownValues("Category");
      this.getDropDownValues("DegreeLevel");
      this.getDropDownValues("ExperienceLevel");
      this.getDropDownValues("Gender");
    },
    getDropDownValues(id) {
      this.isLoading = true;
      axios
        .post("/KeyValuePair/GetDropDownListById", {
          type: id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            let options = res.data.data.map((x) => {
              return {
                text: x.value,
                value: x.key,
              };
            });
            if (id == "city") {
              this.cityOptions = options;
            } else if (id == "country") {
              this.countryOptions = options;
            } else if (id == "Gender") {
              this.genderOptions = options;
            } else if (id == "ExperienceLevel") {
              this.experienceOptions = options;
            } else if (id == "DegreeLevel") {
              this.degreeOptions = options;
            } else if (id == "Category") {
              this.categoryOptions = options;
            }
          } else {
            return null;
          }
        });
    },
    search() {
      this.page = this.pageNumOnSearch;
      this.getAll();
    },
    getCandidateOptions() {
      axios.get("/Employee/GetCandidatesList").then((res) => {
        this.isLoading = false;
        console.log(res);
        if (res.data.status == "Success") {
          this.candidateOptions = res.data.data.map((x) => {
            return {
              text: x.candidates,
              value: x.candidatesId,
            };
          });
        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        } else {
          this.candidateOptions = null;
        }
      });
    },
    getJobOptions() {
      axios.get("/Job/GetAlljobsOfEmployeer").then((res) => {
        this.isLoading = false;
        console.log(res);
        if (res.data.status == "Success") {
          this.jobOptions = res.data.data.map((x) => {
            return {
              text: x.jobTitle,
              value: x.jobId,
            };
          });
        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        } else {
          this.jobOptions = null;
        }
      });
    },
  },

  created() {
    this.getAll();
    // this.getFiltersDropdowns();
    // this.getCandidateOptions();
    // this.getJobOptions();
    // this.getStudents();
    //localStorage.setItem("mytime", Date.now());
  },
  components: {
    Spinner,
    EditVenturePackageModal,
    DeleteModal,
    Toaster,
    AddVenturePackageModal,
    ViewModal,
  },
};
</script>

<style scoped>
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
select#class-dropdown {
  width: 80%;
  margin-left: 16%;
}
</style>
