  <template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="CompanyDetailsModal"
      ref="modal"
      title="Company Details"
      ok-only
      cancel-title="Close"
      @show="updateModel()"
      @hidden="resetModal()"
      @ok="$bvModal.hide('CompanyDetailsModal')"
    >
      <div v-if="CompanyDetails != null">
        <div class="row details-heading">
          <div class="col-md-12">
            <span> Basic Information</span>
          </div>
        </div>
        <div class="row details-font">
          <div class="col-md-8">
            <div class="row px-2">
              <div class="col-md-3">
                <b>Name &nbsp;</b><br /><span>{{
                  CompanyDetails.companyName
                }}</span>
              </div>
              <div class="col-md-5">
                <b>Email &nbsp;</b><br /><span>{{
                  CompanyDetails.companyEmail
                }}</span>
              </div>
              <div class="col-md-4">
                <b>NTN &nbsp;</b><br /><span>{{ CompanyDetails.ceoName }}</span>
              </div>
              <div class="col-md-3">
                <b>Industry&nbsp;</b><br /><span>{{
                  CompanyDetails.industryTitle
                }}</span>
              </div>
              <div class="col-md-5">
                <b>Ownership Type &nbsp;</b><br /><span>{{
                  CompanyDetails.ownerShipTypeTitle
                }}</span>
              </div>
              <div class="col-md-4">
                <b>Established &nbsp;</b><br /><span>{{
                  CompanyDetails.establishedInYear
                }}</span>
              </div>
              <div class="col-md-12 text-center mt-3">
                <a
                  :href="CompanyDetails.websiteUrl"
                  target="_blank"
                  class="mr-2"
                >
                  <i class="fas fa-globe-asia fa-2x"></i>
                </a>
                <a
                  :href="CompanyDetails.linkedInLink"
                  target="_blank"
                  class="mr-2"
                >
                  <i class="fab fa-linkedin fa-2x"></i>
                </a>
                <a
                  :href="CompanyDetails.facebookLink"
                  target="_blank"
                  class="mr-2"
                >
                  <i class="fab fa-facebook-square fa-2x"></i>&nbsp;
                </a>
                <a
                  :href="CompanyDetails.twitterLink"
                  target="_blank"
                  class="mr-2"
                >
                  <i class="fab fa-twitter-square fa-2x"></i>&nbsp;
                </a>
                <a
                  :href="CompanyDetails.googlePlusLink"
                  target="_blank"
                  class="mr-2"
                >
                  <i class="fab fa-google-plus-square fa-2x"></i>&nbsp;
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <img :src="siteUrl+CompanyDetails.companyLogoAddress"
             style="max-width:100px; max-height:100px;" alt = "CompanyLogo">
          </div>
          <div class="col-md-12">
            <b>Description &nbsp;</b><br /><span>{{
              CompanyDetails.companyDesciption
            }}</span>
          </div>
        </div>

        <div class="row details-heading">
          <div class="col-md-12">
            <span> Contact Information</span>
          </div>
        </div>
        <div class="row details-font">
          <div class="col-md-4">
            <b>Mobile# &nbsp;</b><br /><span>{{
              CompanyDetails.contactNumber
            }}</span>
          </div>
          <div class="col-md-4">
            <b>Country &nbsp;</b><br /><span>{{
              CompanyDetails.countryName
            }}</span>
          </div>
          <div class="col-md-4">
            <b>City &nbsp;</b><br /><span>{{ CompanyDetails.cityName }}</span>
          </div>
          <div class="col-md-12">
            <b>Address &nbsp;</b><br /><span>{{ CompanyDetails.address }}</span>
          </div>
        </div>
        <!-- <div class="row details-heading">
          <div class="col-md-12">
            <span> Map</span>
          </div>
        </div>
        <div class="row details-font">
          {{CompanyDetails.googleMapIframe}}
        </div> -->
      </div>
    </b-modal>
  </div>
</template>

      <script>
import axios from "axios";
export default {
  name: "CompanyDetails",

  data() {
    return {
      siteUrl:"https://smw-live.azurewebsites.net",
      moduleName: "Company Details",

      CompanyDetails: null,
    };
  },

  props: {
    CompanyId: {
      type: Number,
      default: null,
    },
  },
  methods: {
    getCompanyDetails() {
      axios
        .get("/EmployerCompany/GetAllCompanyById?id=" + this.CompanyId)
        .then((res) => {

          if (res.data.status == "Success") {

            this.CompanyDetails = res.data.data;
          } else this.CompanyDetails = null;
        });
    },
    updateModel() {
      this.CompanyDetails = null;
      this.getCompanyDetails();
    },
    resetModal() {},
  },
};
</script>
