<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="EditModal"
      ref="modal"
      title="Edit Question"
      ok-title="Submit"
      cancel-title="Close"
      @show="updateModel()"
      @hidden="resetModal()"
      @ok="handleOk"

    >
       <form ref="EditPackageForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <!--NAME INPUT-->
            <b-form-group label="Question" label-for="QuestionValue" autocomplete="off">
              <b-form-input
                id="Question"
                v-model="requestModel.QuestionValue"
                 :class="{
                  'is-invalid': submitted && $v.requestModel.QuestionValue.$error,
                }"
                type="text"
               
              ></b-form-input>
             
              <div
                v-if="!$v.requestModel.QuestionValue.minLength"
                class="invalid-Question"
              >
                Question must contain at least 3 characters
              </div>
            </b-form-group>
          </div>
           <div class="col-md-6">
            <b-form-group
              label="Select Question type"
              label-for="isemployeer"
            >
         
            </b-form-group>
            <b-form-select
            name="isemployeer"
            v-model="requestModel.isemployeer"
             id="isEmployer"
            >
            <option :value="false" >Employee</option>
                <option :value="true">Employer</option>
            </b-form-select>
          </div>

        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { minLength } from "vuelidate/lib/validators";

export default {
  name: "UpdateQuestion",
  data() {
    return {
      moduleName: "Edit Question",
      siteUrl: "",
      attachedFile: null,
        requestModel: {
            id: 0,
            QuestionValue: null,
            isemployeer: false,
        },
      submitted: true,
    };
  },

  validations: {
    requestModel: {
        QuestionValue: {
        minLength: minLength(3),
      },
    
    }
  },

   watch: {
    attachedFile() {
      // console.log(newValue);

    },
  },
  components: {
    Toaster,
  },

  props: {
    recordToEdit: {
      type: Object,
      default: null,
    },
  },

  methods: {
    Update() {
      this.isLoading = true;

     // const formData = new FormData();
      console.log(this.requestModel);

      // formData.append("Id", this.requestModel.id);
      // formData.append("Title", this.requestModel.title);
      // formData.append("Date", this.requestModel.date);
      // formData.append("Type", this.requestModel.type);

      // if (this.siteUrl === "") {
      //    formData.append("Image", this.attachedFile);
      // }
      // else{
      //    formData.append("Image", this.requestModel.image);
      // }

      axios.post("/Employee/UpdateQuestion", this.requestModel).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          this.$parent.getPage(1);
          this.$bvModal.hide("EditModal");
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Question has been updated successfully"
          );
        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        }
      });

      this.$bvModal.hide("EditModal");
    },

    checkFormValidity() {
      const valid = true;
      // this.usernameState = valid
      return valid;
    },
    updateModel() {
      this.requestModel = JSON.parse(JSON.stringify(this.recordToEdit));
   
      this.siteUrl = "https://smw-live.azurewebsites.net";
      this.id = this.recordToEdit.id;
       this.requestModel.QuestionValue = this.recordToEdit.questionValue;
        this.requestModel.isemployeer = this.recordToEdit.employer;
    },
    resetModal() {

    },
    handleOk(bvModalEvt) {
      // Prrvent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
       this.submitted = true;
      // Exit when the form isn't valid
     
      this.Update();
    },
  },
};
</script>
