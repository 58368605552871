<template>
  <div>
    <header>
      <nav
        class="navbar navbar-expand-sm navbar-dark fixed-top"
        style="
          margin-bottom: 0;
          margin-top: 0;
          padding-bottom: 1px;
          padding-top: 1px;
          background-color: #ea216c;
        "
      >
        <!-- <button class="navbar-toggler" type="button" style="font-size: 16px;padding: 9px;" onclick="w3_open()">
                    <span>
                        <i class="fas fa-align-justify" style="color: white;"></i>
                    </span>
                </button> -->
        <a
          href="#"
          class="navbar-brand"
          style="font-size: 15px; font-weight: bold; color: white"
        >
          <!-- <img src="../assets/logo.png" class="d-inline-block align-top" style="width: 20px;margin-right: 10px;"> -->
          She Means Work
        </a>
        <!-- <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation" style="font-size: 16px;padding: 9px;">
                    <span>
                        <i class="fa fa-bars" style="color: white;"></i>
                    </span>
                </button> -->
        <div class="navbar-collapse collapse" id="navbarTogglerDemo01" style="">
          <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
            <li class="nav-item">
              <span
                title="Role"
                class="badge"
                style="margin: 10px; color: #ea216c; background: white"
              >
                <span v-if="$auth.user().role">{{ $auth.user().role }}</span>
                <span v-else>{{ $auth.user().user.role }}</span>
              </span>
            </li>

<!--            <li class="nav-item">
              <h6 title="User Name" style="color: white; margin-top: 6px">
                {{ $auth.user().user.fullName }}
              </h6>
            </li>-->
            <li class="nav-item">
              <a
                href="javascript:void(0)"
                title="Logout"
                class="nav-link"
                @click="$auth.logout()"
              >
                <i class="fas fa-sign-out-alt" style="color: white"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: "app",

  methods: {

  },
  mounted() {
    console.log(this.$auth.user().role)
  }
};
</script>
