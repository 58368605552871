<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="EditCompanyModal"
      ref="modal"
      title="Edit Company"
      ok-title="Submit"
      cancel-title="Close"
      @show="showModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.EditCompanyForm != undefined
          ? !this.$refs.EditCompanyForm.checkValidity()
          : true
      "
    >
      <form ref="EditCompanyForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
         <p class="text-danger"><b>Note:</b> It will not get submitted unless there's some change</p>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="Name" label-for="name" autocomplete="off">
              <b-form-input
                id="name"
                v-model="requestModel.companyName"
                :class="{
                  'is-invalid': submitted && $v.requestModel.companyName.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.companyName.required"
                class="invalid-feedback"
              >
                Company name is required
              </div>
              <div
                v-if="!$v.requestModel.companyName.minLength"
                class="invalid-feedback"
              >
                Company name must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.companyName.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 100 characters is reached
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Owner" label-for="owner" autocomplete="off">
              <b-form-input
                id="owner"
                v-model="requestModel.name"
                :class="{
                  'is-invalid': submitted && $v.requestModel.name.$error,
                }"
                type="text"
                required
              ></b-form-input>
              <div
                v-if="submitted && !$v.requestModel.name.required"
                class="invalid-feedback"
              >
                Owner name is required
              </div>
              <div
                v-if="!$v.requestModel.name.minLength"
                class="invalid-feedback"
              >
                Owner name must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.name.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 100 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="City" label-for="city" autocomplete="off">
              <b-form-select
                id="city"
                v-model="requestModel.cityId"
                :options="cities"
                required
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select City</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Category"
              label-for="companyCategory"
              autocomplete="off"
            >
              <b-form-select
                id="companyCategory"
                v-model="requestModel.companyCategoryId"
                :options="companyCategoryOptions"
                required
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select Company Category</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </div>

          <!-- <div class="col-md-6">
            <b-form-group label="City" label-for="Cities" autocomplete="off">
              <b-form-select
                id="Cities"
                v-model="requestModel.cityId"
                :class="{
                  'is-invalid': submitted && $v.requestModel.cityId.$error,
                }"
                required
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select City</b-form-select-option
                >
                <option
                  v-for="option in cities"
                  :key="option.key"
                  :value="option.key"
                >
                  {{ option.value }}
                </option>
              </b-form-select>

              <div
                v-if="submitted && !$v.requestModel.cityId.required"
                class="invalid-feedback"
              >
                City is required
              </div>
            </b-form-group>
          </div> -->
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              label="Website"
              label-for="website"
              autocomplete="off"
            >
              <b-form-input
                id="website"
                v-model="requestModel.website"
                :class="{
                  'is-invalid': submitted && $v.requestModel.website.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.website.required"
                class="invalid-feedback"
              >
                Webiste is required
              </div>
              <div
                v-if="!$v.requestModel.website.minLength"
                class="invalid-feedback"
              >
                Webiste must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.website.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 100 characters is reached
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Email" label-for="email" autocomplete="off">
              <b-form-input
                id="email"
                v-model="requestModel.email"
                type="email"
                required
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="Address" label-for="adress" autocomplete="off">
              <b-form-input
                id="adress"
                v-model="requestModel.address"
                :class="{
                  'is-invalid': submitted && $v.requestModel.address.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.address.required"
                class="invalid-feedback"
              >
                Address is required
              </div>
              <div
                v-if="!$v.requestModel.address.minLength"
                class="invalid-feedback"
              >
                Address must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.address.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Expiry"
              label-for="datepicker-1"
              autocomplete="off"
            >
              <!-- <b-form-datepicker
                id="datepicker-1"
                v-model="requestModel.expireDate"
                placeholder="Choose a date"
                local="en"
                required
              ></b-form-datepicker> -->
              <b-form-input
                type="date"
                v-model="requestModel.expireDate"
                id="expiryDate"
              >
              </b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <b-form-group
              label="Short Description"
              label-for="description"
              autocomplete="off"
            >
              <b-form-input
                id="description"
                v-model="requestModel.shortDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.shortDescription.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.shortDescription.required"
                class="invalid-feedback"
              >
                Short Description is required
              </div>
              <div
                v-if="!$v.requestModel.shortDescription.minLength"
                class="invalid-feedback"
              >
                Short Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.shortDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Contact No"
              label-for="contactno"
              autocomplete="off"
            >
              <!-- <b-form-input
                id="description"
                v-model="requestModel.contactNo"
                :class="{
                  'is-invalid': submitted && $v.requestModel.contactNo.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.contactNo.required"
                class="invalid-feedback"
              >
                Contact no is required
              </div> -->
               <vue-tel-input
                v-bind="vueTellProps"
                v-model="requestModel.contactNo"
              ></vue-tel-input>
              <!-- <div
                v-if="!$v.requestModel.contactNo.minLength"
                class="invalid-feedback"
              >
                Contact no must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.contactNo.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div> -->
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Google Map"
              label-for="googlemap"
              autocomplete="off"
            >
              <b-textarea
                id="googlemap"
                v-model="requestModel.googleMap"
                placeholder='<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3321.3173064911507!2d72.99695511459036!3d33.64893924632387!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df95c7a1a3cbe5%3A0x156312b15ca23972!2sNUST%20Gate%201!5e0!3m2!1sen!2s!4v1644836312617!5m2!1sen!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>'
                :class="{
                  'is-invalid': submitted && $v.requestModel.googleMap.$error,
                }"
                type="text"
                required
              ></b-textarea>

              <div
                v-if="submitted && !$v.requestModel.companyDescription.required"
                class="invalid-feedback"
              >
                Google map is required
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Logo" label-for="logoImage" autocomplete="off">
              <b-form-file
                accept="image/jpeg, image/png, image/gif, image/webp"
                v-model="attachedFile"
                :state="Boolean(attachedFile)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-8 image-with-border" v-if="isImageSelected">
            <zoom-on-hover
              :img-normal="this.siteUrl + this.imageUrl"
            ></zoom-on-hover>
          </div>
          <div class="col-md-2"></div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import moment from "moment";

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default {
  name: "EditCompany",
  data() {
    return {
      moduleName: "Edit Company",
      imageUrl: "",
      isImageSelected: true,
      siteUrl: "",
      attachedFile: null,
      requestModel: {
        id: null,
        companyName: null,
        name: null,
        companyCategory: null,
        email: null,
        website: null,
        address: null,
        expireDate: new Date(),
        companyLogo: null,
        shortDescription: null,
        cityId: null,
        cityName: null,
        googleMap: null,
        contactNo: null,
        // longDescription:null
      },
      vueTellProps: {
        mode: "international",
        defaultCountry: "PK",

        preferredCountries: ["PK"],
        onlyCountries: [],
        ignoredCountries: [],

        wrapperClasses: "",
        inputClasses: "",

        inputOptions: {
          showDialCode: true,
          name: "telephone",
          autocomplete: "off",
          placeholder: "Enter a phone number",
          required: true,
          type: "tel",
          maxlength: 15,
        },
        validCharactersOnly: true,
      },
      cities: null,
      submitted: false,
      // companyTypeOptions: null,
      companyCategoryOptions: null,
      // companyTypeOptions: [
      //   { text: "Train Me", value: 1 },
      //   { text: "Train The Team", value: 2 },
      //   { text: "Skill Her", value: 3 },
      // ],
    };
  },

  validations: {
    requestModel: {
      companyName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      website: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      address: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(1000),
      },
      shortDescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(1000),
      },
      // companyDescription: {
      //   required,
      //   minLength: minLength(3),
      //   maxLength: maxLength(1000),
      // },
      cityId: {
        required,
      },
      cityName: {
        required,
      },
      googleMap: {
        required,
      },
      // contactNo: {
      //   required,
      // },
    },
  },
  components: {
    Toaster,
  },
  mounted() {
    this.getDropdowns();
  },
  props: {
    companyToEdit: {
      type: Object,
      default: null,
    },
  },
  watch: {
    attachedFile(newValue, oldValue) {
      // console.log(newValue);
      if (newValue.type.includes("video")) this.isImageSelected = false;
      else this.isImageSelected = true;
      this.IsImageUpdated = true;
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.siteUrl = "";
              this.imageUrl = value;
              // console.log(value);
            })
            .catch(() => {
              this.imageUrl = null;
            });
        } else {
          this.imageUrl = null;
        }
      }
    },
  },
  methods: {
    getDropdowns() {
      this.getDropDownValues("CmsCompanyCategory");
      this.getDropDownValues("city");
    },

    getDropDownValues(id) {
      this.isLoading = true;
      axios
        .post("/KeyValuePair/GetDropDownListById", {
          type: id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            console.log(res);
            let options = res.data.data.map((x) => {
              return {
                text: x.value,
                value: x.key,
              };
            });
            if (id == "CmsCompanyCategory") {
              this.companyCategoryOptions = options;
            }
            if (id == "city") {
              this.cities = options;
            }
          }
        });
    },
    EditCompany() {
      this.isLoading = true;

      if (this.attachedFile) {
        var fileSize = 209715;
        if (this.attachedFile.size > fileSize) {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "File Size Limit is 200 KB"
          );
          this.attachedFile = null;
          return false;
        }
      }

      const formData = new FormData();
      formData.append("id", this.requestModel.id);
      formData.append("companyName", this.requestModel.companyName);
      formData.append("name", this.requestModel.name);

      formData.append("email", this.requestModel.email);
      formData.append("website", this.requestModel.website);
      formData.append("address", this.requestModel.address);
      formData.append("expireDate", this.requestModel.expireDate);
      formData.append("shortDescription", this.requestModel.shortDescription);
      formData.append("cityId", this.requestModel.cityId);
      formData.append("Categoryid", this.requestModel.companyCategoryId);
      formData.append("googleMap", this.requestModel.googleMap);
      formData.append("ContactNo", this.requestModel.contactNo);

      //Passing names aso
      if (this.companyCategoryOptions) {
        let companyCategoryValue = this.companyCategoryOptions.find(
          (x) => x.value == this.requestModel.companyCategoryId
        );
        if (companyCategoryValue != null)
          this.requestModel.companyCategory = companyCategoryValue.text;
      }

      if (this.cities) {
        let cityValue = this.cities.find(
          (x) => x.value == this.requestModel.cityId
        );
        if (cityValue != null) this.requestModel.cityName = cityValue.text;
      }
      formData.append("cityName", this.requestModel.cityName);
      formData.append("companyCategory", this.requestModel.companyCategory);

      //image field not done
      if (this.siteUrl === "") {
        formData.append("companyLogo", this.attachedFile);
      } else {
        formData.append("companyLogo", this.requestModel.companyLogo);
      }

      axios.post("/shehri/UpdateCompany", formData).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          this.$parent.getPage(1);
          this.$bvModal.hide("EditCompanyModal");
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Company has been edited successfully"
          );
        } else {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "unable to add company"
          );
        }
      });
    },

    checkFormValidity() {
      const valid = this.$refs.EditCompanyForm.checkValidity();
       if(this.requestModel.contactNo==="+92"){
        this.$refs.toaster.showToast(
            "Error",
            "danger",
            "Please add Contact No."
          );
          return;
      }
      // this.usernameState = valid
      return valid;
    },
    resetModal() {},
    showModal() {
      this.requestModel = this.companyToEdit;
      this.attachedFile = null;

      // this.siteUrl = "http://localhost:57589/";
      // this.siteUrl = "https://smw.demo.itrooscloud.net/";
      this.siteUrl = "https://smw-live.azurewebsites.net",
      this.imageUrl = this.companyToEdit.companyLogo;

      console.log("Company category is ", this.companyCategoryOptions);

      if (this.companyCategoryOptions) {
        let companyCategoryValue = this.companyCategoryOptions.find(
          (x) => x.value == this.companyToEdit.companyCategoryId
        );
        if (companyCategoryValue != null)
          this.requestModel.companyCategoryId = companyCategoryValue.value;
      }

      if (this.cities) {
        let cityValue = this.cities.find(
          (x) => x.value == this.companyToEdit.cityId
        );
        if (cityValue != null) this.requestModel.cityId = cityValue.value;
      }

      // this.requestModel.expireDate = this.companyToEdit.expireDate.toISOString().slice(0,10);
      this.requestModel.expireDate =
        this.companyToEdit.expireDate === "" ||
        this.companyToEdit.expireDate == null
          ? null
          : moment(String(this.companyToEdit.expireDate)).format("YYYY-MM-DD");
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }
      this.EditCompany();
    },
  },
};
</script>
