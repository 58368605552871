<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="AddTestimonialModal"
      ref="modal"
      title="Add Testimonial"
      ok-title="Submit"
      cancel-title="Close"
      @show="resetModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.AddPackageForm != undefined
          ? !this.$refs.AddPackageForm.checkValidity()
          : true
      "
    >
      <form ref="AddPackageForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <!--NAME INPUT-->
            <b-form-group label="Name" label-for="name" autocomplete="off">
              <b-form-input
                id="name"
                v-model="requestModel.name"
                :class="{
                  'is-invalid': submitted && $v.requestModel.name.$error,
                }"
                type="text"
                required
              ></b-form-input>
              <div
                v-if="submitted && !$v.requestModel.name.required"
                class="invalid-feedback"
              >
                Name is required
              </div>
              <div
                v-if="!$v.requestModel.name.minLength"
                class="invalid-feedback"
              >
                Name must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.name.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 100 characters is reached
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Designation"
              label-for="designation"
              required
              autocomplete="off"
            >
              <b-form-input
                id="designation"
                v-model="requestModel.designation"
                :class="{
                  'is-invalid': submitted && $v.requestModel.designation.$error,
                }"
                type="text"
                required
              ></b-form-input>
              <div
                v-if="submitted && !$v.requestModel.designation.required"
                class="invalid-feedback"
              >
                Designation is required
              </div>
              <div
                v-if="!$v.requestModel.designation.minLength"
                class="invalid-feedback"
              >
                Designation must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.designation.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 100 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <!--NAME INPUT-->
            <b-form-group
              label="Description"
              label-for="description"
              autocomplete="off"
            >
              <!-- <ckeditor
                v-model="requestModel.description"
                :class="{
                  'is-invalid': submitted && $v.requestModel.description.$error,
                }"
              ></ckeditor> -->
              <b-form-textarea
                id="description"
                v-model="requestModel.description"
                :class="{
                  'is-invalid': submitted && $v.requestModel.description.$error,
                }"
                type="text"
                rows="5"
                required
              ></b-form-textarea>
              <div
                v-if="submitted && !$v.requestModel.description.required"
                class="invalid-feedback"
              >
                Description is required
              </div>
              <div
                v-if="!$v.requestModel.description.minLength"
                class="invalid-feedback"
              >
                Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.description.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 120 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Picture"
              label-for="Address"
              autocomplete="off"
            >
              <b-form-file
                accept="image/jpeg, image/png, image/gif"
                v-model="requestModel.picture"
                :class="{
                  'is-invalid': submitted && $v.requestModel.picture.$error,
                }"
                :state="Boolean(requestModel.picture)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
              <div
                v-if="submitted && !$v.requestModel.picture.required"
                class="invalid-feedback"
              >
                Picture is required
              </div>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "AddPackage",
  data() {
    return {
      moduleName: "Add Package",
      Name: null,
      statusOptions: [
        { text: "Select", value: null || 0, disabled: true },
        //  { text: "Select", value: 0 },
        { text: "Days", value: 1 },
        { text: "Month", value: 2 },
        { text: "Year", value: 3 },
      ],
      PhoneNumber: null,
      Address: null,
      requestModel: {
        name: null,
        designation: null,
        description: null,
        picture: null,
        type:1
      },
      submitted: false,
    };
  },

  validations: {
    requestModel: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      designation: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      description: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(150),
      },
      picture: {
        required,
      },
    },
  },

  components: {
    Toaster,
  },
  methods: {
    Add() {
      this.isLoading = true;

       if (this.requestModel.picture) {
        var fileSize = 209715;
        if (this.requestModel.picture.size > fileSize) {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "File Size Limit is 200 KB"
          );
          this.requestModel.picture = null;
          return false;
        }
      }


      const formData = new FormData();
      console.log(this.requestModel);

      formData.append("Name", this.requestModel.name);
      formData.append("Designation", this.requestModel.designation);
      formData.append("Description", this.requestModel.description);
      formData.append("Image", this.requestModel.picture);
      formData.append("Type", this.requestModel.type);
      
      axios.post("/Employee/Insert", formData).then((res) => {
        this.isLoading = false;
        if (res.data.status == "Success") {
          this.$parent.getPage(1);
          this.$bvModal.hide("AddTestimonialModal");
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Testimonial has been added successfully"
          );
        } else {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "Unable to add testimonial"
          );
        }
      });
    },

    checkFormValidity() {
      const valid = this.$refs.AddPackageForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      this.Address = null;
      this.PhoneNumber = null;
      this.Name = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }
      this.Add();
    },
  },
};
</script>
