<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="AddUserModal"
      ref="modal"
      title="Add User"
      ok-title="Submit"
      cancel-title="Close"
      @show="setData()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.AddUserForm != undefined
          ? !this.$refs.AddUserForm.checkValidity()
          : true
      "
    >
      <form ref="AddUserForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              label="First Name"
              label-for="firstName-input"
              autocomplete="off"
            >
              <b-form-input
                id="firstName-input"
                v-model="FirstName"
                :class="{ 'is-invalid': submitted && $v.FirstName.$error }"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.FirstName.required"
                class="invalid-feedback"
              >
                First Name is required
              </div>
              <div v-if="!$v.FirstName.minLength" class="invalid-feedback">
                First Name must contain at least 3 characters
              </div>
              <div v-if="!$v.FirstName.maxLength" class="invalid-feedback">
                The maximum length of 100 characters is reached
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Last Name"
              label-for="lastName-input"
              autocomplete="off"
            >
              <b-form-input
                id="lastName-input"
                v-model="LastName"
                :class="{ 'is-invalid': submitted && $v.LastName.$error }"
                required
              ></b-form-input>
              <div
                v-if="submitted && !$v.LastName.required"
                class="invalid-feedback"
              >
                Last Name is required
              </div>
              <div v-if="!$v.LastName.minLength" class="invalid-feedback">
                Last Name must contain at least 3 characters
              </div>
              <div v-if="!$v.LastName.maxLength" class="invalid-feedback">
                The maximum length of 100 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <b-form-group
              :state="emailState"
              label="Email"
              label-for="email-input"
              :invalid-feedback="emailInvalidFeedback"
              autocomplete="off"
            >
              <b-form-input
                id="email-input"
                v-model="Email"
                :state="emailState"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              :state="passwordState"
              label="Password"
              label-for="password-input"
              :invalid-feedback="passwordInvalidFeedback"
              autocomplete="off"
            >
              <b-form-input
                id="password-input"
                v-model="Password"
                :state="passwordState"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              :state="confirmPasswordState"
              label="Confirm Password"
              label-for="cpasssword-input"
              :invalid-feedback="confirmPasswordInvalidFeedback"
              autocomplete="off"
            >
              <b-form-input
                id="cpassword-input"
                v-model="ConfirmPassword"
                :state="confirmPasswordState"
                required
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              :state="phoneNumberState"
              label="Phone"
              label-for="phone-input"
              :invalid-feedback="phoneInvalidFeedback"
              autocomplete="off"
            >
              <b-form-input
                id="phone-input"
                v-model="PhoneNumber"
                :state="phoneNumberState"
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-6">
            <b-form-group
              label="Roles"
              label-for="role-input"
              autocomplete="off"
            >
              <b-form-select
                id="role-input"
                v-model="Roles"
                value-field="id"
                text-field="name"
                :options="rolesOption"
                multiple
                required
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select Roles</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

// import config from '../config.js';

export default {
  name: "Users",
  components: {
    Toaster,
  },
  data() {
    return {
      moduleName: "Users",
      Company: null,
      FirstName: "",
      LastName: "",
      Email: "",
      Password: "",
      ConfirmPassword: "",
      PhoneNumber: "",
      Roles: [],
      submitted: false,
      rolesOption: [
        { name: "Hiring Manager", id: 10 },
        { name: "Supervisor", id: 11 },
        { name: "Support Manager", id: 12 },
        { name: "Social Media Manager", id: 13 },
        { name: "Account Manager", id: 14 },
        { name: "Sub Admin", id: 15 },
      ],
    };
  },
  validations: {
    FirstName: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(100),
    },
    LastName: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(100),
    },
  },
  mounted() {},
  computed: {
    emailState() {
      const re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
      return re.test(String(this.Email).toLowerCase());
    },
    emailInvalidFeedback() {
      return "invalid email address";
    },
    passwordState() {
      return this.Password.length >= 5;
    },
    passwordInvalidFeedback() {
      return "password should contains 5 or more characters";
    },
    confirmPasswordState() {
      return this.Password == this.ConfirmPassword;
    },
    confirmPasswordInvalidFeedback() {
      return "password mismatch";
    },
    phoneNumberState() {
      const re = /^[\d]{11}$/;
      return re.test(this.PhoneNumber);
    },
    phoneInvalidFeedback() {
      return "phone number should be 11 digits long";
    },
  },

  methods: {
    setData() {},
    addUser() {
      let request = {
        User: {
          FullName: this.FirstName,
          email: this.Email,
          passwordHash: this.Password,
          phoneNumber: this.PhoneNumber,
        },
        Roles: this.Roles,
      };

      axios
        .post("/user/AddUser", request)
        .then((res) => {

          if (res.data.status == "Success") {
            this.$parent.getPage(1);
            this.$bvModal.hide("AddUserModal");
            // console.log(res);
            this.$refs.toaster.showToast(
              "Sucess",
              "success",
              "User has been added successfully"
            );
          }

          if(res.data.status == "Error"){
             if (res.data.msg != null) {
              this.$refs.toaster.showToast("Error", "danger", res.data.msg);
              return;
            }
          }
        })
        .catch((err) => {
          console.warn("error during http call", err);
        });
    },
    checkFormValidity() {
      const valid = this.$refs.AddUserForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      this.UserName = "";
      this.FirstName = "";
      this.LastName = "";
      this.Email = "";
      this.Password = "";
      (this.ConfirmPassword = ""), (this.PhoneNumber = ""), (this.Roles = null);
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;

      console.log(this.Roles);
      console.log(this.Company);
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }

      this.addUser();
    },
  },
};
</script>
