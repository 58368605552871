<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="EditVenturePackageModal"
      ref="modal"
      title="Edit Video and Webinar"
      ok-title="Submit"
      cancel-title="Close"
      @show="showModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.EditForm != undefined
          ? !this.$refs.EditForm.checkValidity()
          : true
      "
    >
      <form ref="EditForm" @submit.stop.prevent="handleSubmit">
         <div class="row">
         <p class="text-danger"><b>Note:</b> It will not get submitted unless there's some change</p>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Description"
              label-for="description"
              autocomplete="off"
            >
              <b-form-input
                id="description"
                v-model="requestModel[0].description"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <!-- <div style="display: flex; justify-content: flex-end">
          <a href="javascript:void(0)" @click="addRow" title="Add Videos">
            <i class="fa fa-plus green-color icon-size"></i>
          </a>
        </div> -->

        <div class="row" v-for="(video, index) in requestModel" :key="index">
          <div class="col-md-6">
            <b-form-group
              label="Video Title"
              label-for="VideoTitle"
              autocomplete="off"
            >
              <b-form-input
                id="VideoTitle"
                v-model="video.vedioTitle"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Video Link"
              label-for="Title"
              autocomplete="off"
            >
              <b-form-input
                id="ventureTitle"
                v-model="video.yutubeLink"
                placeholder="https://www.youtube.com/embed/Rt_UqUm38BI"
                type="url"
              ></b-form-input>
            </b-form-group>
          </div>

          <!-- <div class="col">
            <b-form-group label="" label-for="icon" autocomplete="off">
              <div class="d-flex bd-highlight mb-3">
                <div class="ml-auto p-2 bd-highlight">
                  <a
                    href="javascript:void(0)"
                    @click="removeVideos(index)"
                    title="Remove Video"
                  >
                    <i
                      class="fa fa-trash icon-size"
                      aria-hidden="true"
                      style="color:red"
                    ></i>
                  </a>
                </div>
              </div>
            </b-form-group>
          </div> -->
        </div>

        <!-- <div class="row">
          <div class="col-md-12">
            <b-form-group label="Trial" label-for="trial" autocomplete="off">
              <b-form-input
                id="trial"
                v-model="recordToEdit.trial"
                :class="{
                  'is-invalid': submitted && $v.recordToEdit.trial.$error,
                }"
                type="text"
              ></b-form-input>

              <div
                v-if="submitted && !$v.recordToEdit.trial.required"
                class="invalid-feedback"
              >
                Trial is required
              </div>
            </b-form-group>
          </div>
        </div> -->
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";


const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default {
  name: "EditVideoAndWebinar",
  data() {
    return {
      moduleName: "Edit Venture Package",
      imageUrl: "",
      isImageSelected: true,
      siteUrl: "",
      attachedFile: null,
       requestModel: [
        {
          description: null,
          vedioTitle: null,
          yutubeLink: null,
          ViiLearnType:10,
          vlearnID:0,
          Id:0
        },
      ],
      submitted: false,
      courseCategoryOptions: null,
      courseTypeOptions: [
        { text: "Train Me", value: 1 },
        { text: "Train The Team", value: 2 },
        { text: "Skill Her", value: 3 },
      ],
       item:null
    };
  },
  // validations: {
  //   recordToEdit: {
  //     title: {
  //       required,
  //       minLength: minLength(3),
  //       maxLength: maxLength(350),
  //     },
  //     description: {
  //       required,
  //       minLength: minLength(3),
  //       maxLength: maxLength(1000),
  //     },
  //     longDescription: {
  //       required,
  //       minLength: minLength(3),
  //       maxLength: maxLength(5000),
  //     },
  //     trial: {
  //       required,
  //     },
  //   },
  // },

  components: {
    Toaster,
  },
  created() {
    this.getCourseCategories();
    // this.recordToEdit.title = this.requestModel.title;
    // this.recordToEdit.whatWeDo = this.requestModel.whatWeDo;
    // this.recordToEdit.description = this.requestModel.description;
    // this.recordToEdit.longDescription = this.requestModel.description;
    // this.recordToEdit.logo = this.requestModel.logo;
  },
  props: {
    recordToEdit: {
      type: Array,
    },
  },
  watch: {
    attachedFile(newValue, oldValue) {
      // console.log(newValue);
      if (newValue.type.includes("video")) this.isImageSelected = false;
      else this.isImageSelected = true;
      this.IsImageUpdated = true;
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.siteUrl = "";
              this.imageUrl = value;
              // console.log(value);
            })
            .catch(() => {
              this.imageUrl = null;
            });
        } else {
          this.imageUrl = null;
        }
      }
    },
  },
  methods: {
     addRow() {
      this.requestModel.push({
        description: null,
        vedioTitle: null,
        yutubeLink: null,
        type: 10,
        Id:0,
        vlearnID:0,
      });
    },
      removeVideos(index){
      this.requestModel.splice(index,1);
    },
    onSelectImage(e) {
      var reader = new FileReader();
      reader.onload = function() {
        var output = document.getElementById("output");
        output.src = reader.result;
      };
      reader.readAsDataURL(e.target.files[0]);

      console.log("ON SELECT CALLED");
      this.recordToEdit.logo = null;
      this.recordToEdit.logo = e.target.files[0];
      console.log("uploadFile : ", e.target.files[0].size);
      console.log("uploadFileName : ", this.recordToEdit.logo.name);
      this.siteUrl = "";

      // Allowing file Size
      var fileSize = 209715;

      if (this.requestModel.logo.size > fileSize) {
        alert("File Size Limit is 200 KB");
        e.target.value = "";
        this.recordToEdit.logo = null;
        return false;
      }
    },
    getCourseCategories() {
      axios.get("/V_Learn/GetCourseCategory").then((res) => {
        this.isLoading = false;
        if (res.data.status == "Success") {
          let options = res.data.data.map((x) => {
            return {
              text: x.courseCategoryName,
              value: x.courseCategoryId,
            };
          });
          this.courseCategoryOptions = options;
        }
      });
    },
    getFiltersDropdowns() {
      this.getDropDownValues("CmsCategory");
      this.getDropDownValues("ShehriType");
    },
    getDropDownValues(id) {
      this.isLoading = true;
      axios
        .post("/KeyValuePair/GetDropDownListById", {
          type: id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            console.log(res);
            let options = res.data.data.map((x) => {
              return {
                text: x.value,
                value: x.key,
              };
            });
            if (id == "ShehriType") {
              this.shehriTypeOptions = options;
            } else if (id == "CmsCategory") {
              this.cmsCategoryOptions = options;
            }
          } else {
            return null;
          }
        });
    },
    Edit() {
      this.isLoading = true;

      //  for (const video of this.requestModel) {
      //   video.Id = this.recordToEdit[0].id;
      //   video.vlearnID =0;
      // }

      this.requestModel[0].ViiLearnType =10;
      
  
      axios.post("/V_Learn/UpdateVLearnVedios", this.requestModel)
        .then((res) => {
          this.isLoading = false;

          if (res.data.status == "Success") {
            this.$parent.getPage(1);
            this.$bvModal.hide("EditVenturePackageModal");
            console.log(res);
            this.$refs.toaster.showToast(
              "Sucess",
              "success",
              "Venture has been edited successfully"
            );
          } else {
            this.$refs.toaster.showToast(
              "Error",
              "danger",
              "Unable to edit Venture"
            );
          }
        });
    },

    checkFormValidity() {
      const valid = this.$refs.EditForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {},
    showModal() {
      this.requestModel=null;
      let data = JSON.parse(JSON.stringify(this.recordToEdit));
      this.requestModel=data;
      this.attachedFile = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.Edit();
    },
  },
};
</script>

<style scoped>
.icon-size {
  font-size: 20px;
  vertical-align: text-top;
}
.ml-auto.p-2.bd-highlight {
  margin-top: 15%;
}
.green-color {
  color: green;
}
svg {
  cursor: pointer;
}
</style>
