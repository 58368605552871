  <template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="EditPackageModal"
      ref="modal"
      title="Edit Package"
      ok-title="Submit"
      cancel-title="Close"
      @show="updateModel()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.EditPackageForm != undefined
          ? !this.$refs.EditPackageForm.checkValidity()
          : true
      "
    >
      <form ref="EditPackageForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
         <p class="text-danger"><b>Note:</b> It will not get submitted unless there's some change</p>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!--NAME INPUT-->
            <b-form-group
              label="Name"
              label-for="packageName"
              autocomplete="off"
            >
              <b-form-input
                id="packageName"
                v-model="requestModel.packageName"
                :class="{'is-invalid':submitted && $v.requestModel.packageName.$error}"
                type="text"
                required
              ></b-form-input>

              <div v-if="submitted && !$v.requestModel.packageName.required" class="invalid-feedback">
               Package name is required
             </div>
             <div v-if="!$v.requestModel.packageName.minLength" class="invalid-feedback">
                Title must contain at least 3 characters
             </div>
              <div v-if="!$v.requestModel.packageName.maxLength" class="invalid-feedback">
               The maximum length of 350 characters is reached
             </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Regular Price"
              label-for="regularPrice"
              autocomplete="off"
            >
              <b-form-input
                id="regularPrice"
                v-model="requestModel.regularPrice"
                type="number"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Sale Price"
              label-for="salePrice"
              autocomplete="off"
            >
              <b-form-input
                id="salePrice"
                v-model="requestModel.salePrice"
                type="number"
                required
              ></b-form-input>
            </b-form-group>
          </div>
           <!-- <div class="col-md-6">
            <b-form-group
              label="Schedule"
              label-for="datepicker-1"
              autocomplete="off"
            >
              <b-form-datepicker
                id="datepicker-1"
                v-model="requestModel.futureDate"
                placeholder="Choose a date"
                local="en"
                required
              ></b-form-datepicker>
            </b-form-group>
          </div> -->

          <div class="col-md-6">
            <b-form-group
              label="Expired After"
              label-for="expiredAfter"
              autocomplete="off"
            >
              <b-form-input
                id="Address"
                v-model="requestModel.expiredAfter"
                type="number"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6" style="margin-top: 2rem">
                <b-form-select
                  v-model="selectedConvertDate"
                  :options="statusOptions"
                  value-field="value"
                  text-field="text"
                >
                  <!-- <b-form-select-option :value="null" disabled hidden
                  >Select</b-form-select-option
                > -->
                </b-form-select>
          </div>
            <div class="col-md-12" style="margin-bottom: 5px">
            <b-form-checkbox
              v-model="requestModel.unlimitedPosting"
              @input="clearLimitsFields()"
              >Unlimited Posting
            </b-form-checkbox>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Job Posting Limit"
              label-for="jobPostingLimit"
              autocomplete="off"
            >
              <b-form-input
                id="jobPostingLimit"
                v-model="requestModel.jobPostingLimit"
                :disabled="requestModel.unlimitedPosting"
                type="number"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Featured Job Limit"
              label-for="featuredJobLimit"
              autocomplete="off"
            >
              <b-form-input
                id="featuredJobLimit"
                v-model="requestModel.featuredJobLimit"
                :disabled="requestModel.unlimitedPosting"
                type="number"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <!-- <div class="col-md-4">
            <b-form-group
              label="Unlimited Jobs"
              label-for="unlimitedPosting"
              autocomplete="off"
            >
              <b-form-input
                id="unlimitedPosting"
                v-model="requestModel.unlimitedPosting"
                type="number"
                required
              ></b-form-input>
            </b-form-group>
          </div> -->
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

      <script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
// import moment from "moment";

export default {
  name: "EditPackage",
  data() {
    return {
      moduleName: "Edit Package",

      requestModel: {
        PackageId:null,      
        packageName: null,
        regularPrice: null,
        salePrice: null,
        expiredAfter: null,
        jobPostingLimit: null,
        featuredJobLimit: null,
        unlimitedPosting: false,
        dateConvert: 1,
        // futureDate: null,
      },
       submitted: false,
        statusOptions: [
        { text: "Select", value: null || 0, disabled: true },
        //  { text: "Select", value: 0 },
        { text: "Days", value: 1 },
        { text: "Month", value: 2 },
        { text: "Year", value: 3 },
      ],
      selectedConvertDate:null
    };
  },
   validations: {
    requestModel: {
      packageName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(350),
      }
    },
  },

  components: {
    Toaster,
  },
  props: {
    packageToEdit: {
      type: Object,
      default: null,
    },
  },
  methods: {
     clearLimitsFields() {
      if (this.requestModel.unlimitedPosting == true) {
        (this.requestModel.jobPostingLimit = null),
          (this.requestModel.featuredJobLimit = null);
      }
    },
    EditPackage() {
      this.isLoading = true;
      this.requestModel.regularPrice = Number(this.requestModel.regularPrice)
      this.requestModel.salePrice = Number(this.requestModel.salePrice)
      this.requestModel.expiredAfter = Number(this.requestModel.expiredAfter)
      this.requestModel.jobPostingLimit = Number(this.requestModel.jobPostingLimit)
      this.requestModel.featuredJobLimit = Number(this.requestModel.featuredJobLimit);


      this. ConvertKeyToValue();
      
      console.log(this.requestModel)
      axios.post("/Job/UpdatePackage",this.requestModel).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Package has been updated successfully"
          );
          this.$bvModal.hide("EditPackageModal");
          this.$parent.getPage(1);
        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        } else {
          this.jobsRequests = null;
        }
      });
    },

    ConvertKeyToValue(){
        let dateConvertValue = this.statusOptions.find(
        (x) => x.value == this.selectedConvertDate
      );
      if (dateConvertValue != null)
        this.requestModel.dateConvert = dateConvertValue.text;

    },

    checkFormValidity() {
      const valid = this.$refs.EditPackageForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    updateModel() {
      console.log(this.packageToEdit)
      this.requestModel = this.packageToEdit;
      this.requestModel.regularPrice = Number(this.requestModel.regularPrice)
      this.requestModel.salePrice = Number(this.requestModel.salePrice)
      this.requestModel.expiredAfter = Number(this.requestModel.expiredAfter)
      this.requestModel.jobPostingLimit = Number(this.requestModel.jobPostingLimit)
      this.requestModel.featuredJobLimit = Number(this.requestModel.featuredJobLimit)
     console.log(this.requestModel);

     
    let dateConvertValue = this.statusOptions.find(
        (x) => x.text == this.packageToEdit.dateConvert
      );
      if (dateConvertValue != null)
        this.requestModel.dateConvert = dateConvertValue.text;
        this.selectedConvertDate = dateConvertValue.value;

        // this.requestModel.futureDate =
        // this.packageToEdit.futureDate === "" ||
        // this.packageToEdit.futureDate == null
        //   ? null
        //   : moment(String(this.packageToEdit.futureDate)).format("YYYY-MM-DD");
    
    },
    resetModal() {
      
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }

      this.EditPackage();
    },
  },
};
</script>
