<template>
  <div>
    <div class="row justify-content-between align-items-center">
      <div class="col-md-9 customHeading">
        <h3>{{ moduleName }}</h3>
      </div>

      <!--==========Class dropdown============-->
      <!-- <div class="col-md-2"> -->
        <!-- <select class="form-control " id="class-dropdown" v-model="selectedSection"
         @change="callFilterModel()">
          <option v-for="classs in Users" :key=classs.classId :value="classs.sectionId">
            {{classs.fullName}}
          </option>
        </select> -->
      <!-- </div> -->

      <!---SEARCH BOX-->
      <div class="col-md-2">
        <div class="searchbox input-group" role="group">
          <input
            class="form-control search-box my-search-box"
            type="text"
            placeholder="Full Name"
            aria-label="search"
            v-model="filteredText"
            @keydown.enter.prevent="getUsers()"
          />

          <button
            class="search-button btn btn-primary filter"
            @click="getUsers()"
          >
            <i class="fa fa-search search-icon"></i>
          </button>
        </div>
      </div>
      <div class="col-md-1 Add-button" style="text-align: center">
        <a href="javascript:void(0)">
          <div class="AddButton">
            <div class="icon">
              <i class="fas fa-arrow-alt-circle-right fa-1x add-btn-color"> </i>
            </div>
            <div data-text="Add" class="name add-btn" v-b-modal.AddUserModal>
              Add
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <hr
          color="#CACACA"
          style="border-top: 2px solid rgb(202, 202, 202); margin-top: 5px"
        />
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col">
          <table
            class="
              table table-striped table-borderless
              cutom-table
              table-striped table-sm
            "
            style="font-size: 85%"
          >
            <thead>
              <tr>
                <th role="columnheader" style="width: 20%">Full Name</th>
                <th role="columnheader" style="width: 10%">Email</th>
                <th role="columnheader" style="width: 10%">Phone</th>
                <th role="columnheader" style="width: 10%">Roles</th>
                <th role="columnheader" style="width: 10%">Action</th>
              </tr>
            </thead>
            <tbody>
              <!--IF STUDENTS ARRAY HAS NO VALUE THEN SHOW WITHOUT SHOWING DATA-->
              <tr v-if="Users.length <= 0">
                <td colspan="7" class="text-danger">no data found!</td>
              </tr>

              <tr v-else v-for="(user, index) in Users" :key="index">
                <td
                  role="button"
                  @click="showUserDetails(user)"
                  style="text-decoration: underline"
                >
                  {{ user.fullName }}
                </td>

                <td role="cell">{{ user.email }}</td>
                <td role="cell">{{ user.phoneNumber }}</td>
                <td role="cell">
                  {{ user.roleNames }}
                </td>
                <td>
                  <span @click="editRecord(user)">
                    <i
                      role="button"
                      style="color: #ea216c"
                      class="fas fa-edit"
                    ></i
                    >&nbsp;|&nbsp;
                  </span>
                  <span @click="deleteRecord(user.userId)">
                    <i
                      role="button"
                      style="color: #ea216c"
                      class="far fa-trash-alt"
                    ></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--PAGINATION START-->
      <div class="row justify-content-between align-items-center">
        <div class="col-md-2">
          <div class="showPages">
            <select
              class="form-control"
              id="selClass"
              v-model="perPage"
              @change="getUsers()"
            >
              <option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-8 d-flex justify-content-center">
          <div class="overflow-auto">
            <b-pagination
              v-model="page"
              :total-rows="rows"
              limit="3"
              :per-page="perPage"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              @change="getPage"
            ></b-pagination>
          </div>
        </div>
        <div class="col-md-2">
          <input
            class="form-control search-box page-no-search"
            type="text"
            v-bind:placeholder="page"
            aria-label="search"
            v-model.number="pageNumOnSearch"
            style="height: 38px"
          />

          <button
            class="search-button btn btn-primary search page-no-search-btn"
            @click="search()"
          >
            GO
          </button>
        </div>
      </div>
      <!--/PAGINATION END-->
      <!--SPINNER-->
      <Spinner
        v-if="isLoading"
        message="Loading..."
        size="medium"
        line-fg-color="rgb(222 224 219"
        line-bg-color="rgb(68 66 62)"
      >
      </Spinner>
      <!--/SPINNER-->
    </div>
    <AddUserModal />
    <Toaster ref="toaster" />
    <DeleteUserModal
      :entityId="userToDelete"
      :msgForModal="msgForModal"
      :apiEndPoint="apiEndPoint"
      :heading="heading"
    />
    <UserDetailsModal v-if="userDetails != null" :userDetails="userDetails" />
    <b-modal
      style="padding: 15px"
      size="lg"
      id="EditUserModal"
      ref="modal"
      title="Edit User"
      ok-title="Submit"
      cancel-title="Close"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.EditUserForm != undefined
          ? !this.$refs.EditUserForm.checkValidity()
          : true
      "
    >
      <form
        ref="EditUserForm"
        v-if="userToEdit != null"
        @submit.stop.prevent="handleSubmit"
      >
       <div class="row">
         <p class="text-danger"><b>Note:</b> It will not get submitted unless there's some change</p>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              label="Full Name"
              label-for="fullname-input"
              autocomplete="off"
            >
              <b-form-input
                id="fullname-input"
                v-model="userToEdit.fullName"
                 :class="{'is-invalid':submitted && $v.userToEdit.fullName.$error}"
                required
              ></b-form-input>

               <div v-if="submitted && !$v.userToEdit.fullName.required" class="invalid-feedback">
               Full Name is required
             </div>
             <div v-if="!$v.userToEdit.fullName.minLength" class="invalid-feedback">
                Full Name must contain at least 3 characters
             </div>
              <div v-if="!$v.userToEdit.fullName.maxLength" class="invalid-feedback">
               The maximum length of 150 characters is reached
             </div>
            </b-form-group>
          </div>

          <div class="col-md-6">
            <b-form-group
              :state="emailState"
              label="Email"
              label-for="email-input"
              :invalid-feedback="emailInvalidFeedback"
              autocomplete="off"
            >
              <b-form-input
                id="email-input"
                v-model="userToEdit.email"
                :state="emailState"
                required
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <!-- <div class="col-md-6">
            <b-form-group
              :state="passwordState"
              label="Password"
              label-for="password-input"
              :invalid-feedback="passwordInvalidFeedback"
              autocomplete="off"
            >
              <b-form-input
                id="password-input"
                v-model="userToEdit.passwordHash"
                :state="passwordState"
                required
              ></b-form-input>
            </b-form-group>
          </div> -->

        <div class="row">
          <div class="col-md-6">
            <b-form-group
              :state="phoneNumberState"
              label="Phone"
              label-for="phone-input"
              :invalid-feedback="phoneInvalidFeedback"
              autocomplete="off"
            >
              <b-form-input
                id="phone-input"
                v-model="userToEdit.phoneNumber"
                :state="phoneNumberState"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              :state="roleState"
              label="Role"
              label-for="role-input"
              autocomplete="off"
            >
              <b-form-select
                multiple
                id="role-input"
                v-model="userToEdit.roles"
                :options="RolesOptions"
                value-field="id"
                text-field="name"
                required
              ></b-form-select>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Spinner from "vue-simple-spinner";
import AddUserModal from "./modals/add-user-modal";
import DeleteUserModal from "../../common/modals/delete-modal.vue";
import UserDetailsModal from "./modals/user-details-modal.vue";
import Toaster from "../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "Users",
  mounted() {
    console.log(this.$auth.user());
  },
  data() {
    return {
      moduleName: "Users",
      Id: null,
      RolesOptions: [
        { name: "Hiring Manager", id: 10 },
        { name: "Supervisor", id: 11 },
        { name: "Support Manager", id: 12 },
        { name: "Social Media Manager", id: 13 },
        { name: "Account Manager", id: 14 },
        { name: "Sub Admin", id: 15 },
      ],
      userDetails: null,
      userToEdit: null,
      userToDelete: null,
      Users: [],
      isLoading: false,

      pageNumOnSearch: "",
      filteredText: "",
      rows: 0,
      page: 1,
      options: [
        { text: "Show 2", value: 2 },
        { text: "Show 10", value: 10 },
        { text: "Show 25", value: 25 },
        { text: "Show 50", value: 50 },
        { text: "Show 100", value: 100 },
      ],
      perPage: 25,
      heading: "Delete User",
      apiEndPoint: "/user/deleteUser?userId=",
      msgForModal: "Confirm to delete",
      submitted: false,
    };
  },
  validations: {
    userToEdit: {
      fullName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(150),
      },
    },
  },
  computed: {
    emailState() {
      const re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
      return re.test(String(this.userToEdit.email).toLowerCase());
    },
    emailInvalidFeedback() {
      return "invalid email address";
    },
    // passwordState() {
    //   return this.userToEdit.passwordHash.length >= 5;
    // },
    // passwordInvalidFeedback() {
    //   return "password should contains 5 or more characters";
    // },

    phoneNumberState() {
      const re = /^[\d]{11}$/;
      return re.test(this.userToEdit.phoneNumber);
    },
    phoneInvalidFeedback() {
      return "phone number should be 11 digits long";
    },
    roleState() {
      return this.userToEdit.roles.length >= 1;
    },
    roleInvalidFeedback() {
      return "please select a role";
    },
  },
  methods: {
    getPage(page) {
      this.page = page;
      this.getUsers();
    },
    showUserDetails(record) {
      this.userDetails = record;
      this.$bvModal.show("UserDetailsModal");
    },
    editUser() {
      const UserObj ={
        UserId : this.userToEdit.userId,
        fullName : this.userToEdit.fullName,
        email: this.userToEdit.email,
        PhoneNumber : this.userToEdit.phoneNumber

      }
      const model = {
        Roles : this.userToEdit.roles,
        User : UserObj
      }

      axios
        .post("/user/UpdateUser", model)
        .then((res) => {
          if (res.data.status == "Success") {
            // this.$parent.getPage(1);
            this.getUsers();
            this.$bvModal.hide("EditUserModal");
            // console.log(res);
            this.$refs.toaster.showToast(
              "Sucess",
              "success",
              "User has been edited successfully"
            );
          }

           if(res.data.status == "Error"){
             if (res.data.msg != null) {
              this.$refs.toaster.showToast("Error", "danger", res.data.msg);
              return;
            }
          }
        })
        .catch((err) => {
          console.warn("error during http call", err);
        });
    },
    checkFormValidity() {
      const valid = this.$refs.EditUserForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },

    resetModal() {
      this.userToEdit = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted=true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
       this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }
      this.editUser();
    },

    // updateModal() {
    //   this.UserName = this.userToEdit.userName;
    //   this.Id = this.userToEdit.id;
    //   this.FullName = this.userToEdit.fullname;
    //   this.Email = this.userToEdit.email;
    //   this.Password = this.userToEdit.password;
    //   this.PhoneNumber = this.userToEdit.phonenumber;
    //   this.Role = this.userToEdit.role;
    // },
    deleteRecord(id) {
      this.userToDelete = id;
      this.$bvModal.show("DeleteModal");
    },
    editRecord(user) {
      // console.log(user);
      this.userToEdit = user;
      setTimeout(() => {
        this.$bvModal.show("EditUserModal");
      }, 1000);
    },
    getUsers() {
      this.isLoading = false;
      axios
        .post("/User/GetAllUser", {
          PageSize:this.perPage,
          PageNumber:this.page,
          Text:this.filteredText
        })
        .then((res) => {
          console.log(res.data);
          this.isLoading = false;
          if (res.data.status == "Success") {
            if (res.data.data != null && res.data.data != undefined) {
              this.Users = res.data.data.map((x) => {
                return {
                  email: x.email,
                  phoneNumber: x.phoneNumber,
                  userId: x.userId,
                  fullName: x.fullName,
                  roleNames: x.assignedRole.map((y) => {
                    return this.RolesOptions.find((x) => x.id == y.role).name;
                  }),
                  roles: x.assignedRole.map((y) => {
                    return y.role;
                  }),
                };
              });

              this.rows = res.data.totalCount;
            } else this.Users = [];
          } else this.Users = [];
        })
        .catch((error) => {
          console.log(error);
          // this.Users.push({
          //     fullName: "adi",
          //     email: "f@gmail.com",
          //     phoneNumber: "1234",
          //     passwordHash:"sdf",
          //     Roles:[10,11,12]
          //   });
        });
    },

    search() {
      this.page = this.pageNumOnSearch;
      this.getUsers();
    },
  },
  created() {
    this.getUsers();
    // this.getStudents();
    //localStorage.setItem("mytime", Date.now());
  },
  components: {
    Spinner,
    AddUserModal,
    DeleteUserModal,
    UserDetailsModal,
    Toaster,
  },
};
</script>

<style scoped>
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
select#class-dropdown {
  width: 80%;
  margin-left: 16%;
}
</style>
