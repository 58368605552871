  <template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="AddPackageModal"
      ref="modal"
      title="Add Package"
      ok-title="Submit"
      cancel-title="Close"
      @show="resetModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.AddPackageForm != undefined
          ? !this.$refs.AddPackageForm.checkValidity()
          : true
      "
    >
      <form ref="AddPackageForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <!--NAME INPUT-->
            <b-form-group
              label="Name"
              label-for="packageName"
              autocomplete="off"
            >
              <b-form-input
                id="packageName"
                v-model="requestModel.packageName"
                :class="{'is-invalid':submitted && $v.requestModel.packageName.$error}"
                type="text"
                required
              ></b-form-input>

              <div v-if="submitted && !$v.requestModel.packageName.required" class="invalid-feedback">
               Package name is required
             </div>
             <div v-if="!$v.requestModel.packageName.minLength" class="invalid-feedback">
                Title must contain at least 3 characters
             </div>
              <div v-if="!$v.requestModel.packageName.maxLength" class="invalid-feedback">
               The maximum length of 350 characters is reached
             </div>

            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Regular Price"
              label-for="regularPrice"
              autocomplete="off"
            >
              <b-form-input
                id="regularPrice"
                v-model="requestModel.regularPrice"
                type="number"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Sale Price"
              label-for="salePrice"
              autocomplete="off"
            >
              <b-form-input
                id="salePrice"
                v-model="requestModel.salePrice"
                type="number"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <!-- <div class="col-md-6">
            <b-form-group
              label="Schedule"
              label-for="datepicker-1"
              autocomplete="off"
            >
              <b-form-datepicker
                id="datepicker-1"
                v-model="requestModel.scheduleDate"
                placeholder="Choose a date"
                local="en"
                required
              ></b-form-datepicker>
            </b-form-group>
          </div> -->
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <b-form-group
                  label="Expired After"
                  label-for="expiredAfter"
                  autocomplete="off"
                >
                  <b-form-input
                    id="expired-after"
                    v-model="requestModel.expiredAfter"
                    type="number"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6" style="margin-top: 2rem">
                <b-form-select
                  v-model="requestModel.dateConvert"
                  :options="statusOptions"
                  value-field="value"
                  text-field="text"
                >
                  <!-- <b-form-select-option :value="null" disabled hidden
                  >Select</b-form-select-option
                > -->
                </b-form-select>
              </div>
            </div>
          </div>
          <div class="col-md-12" style="margin-bottom: 5px">
            <b-form-checkbox
              v-model="requestModel.unlimitedPosting"
              @input="clearLimitsFields()"
              >Unlimited Posting
            </b-form-checkbox>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Job Posting Limit"
              label-for="jobPostingLimit"
              autocomplete="off"
            >
              <b-form-input
                id="jobPostingLimit"
                v-model="requestModel.jobPostingLimit"
                type="number"
                :disabled="requestModel.unlimitedPosting"
                
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Featured Job Limit"
              label-for="featuredJobLimit"
              autocomplete="off"
            >
              <b-form-input
                id="featuredJobLimit"
                v-model="requestModel.featuredJobLimit"
                type="number"
                :disabled="requestModel.unlimitedPosting"
                
              ></b-form-input>
            </b-form-group>
          </div>
          <!-- <div class="col-md-4">
            <b-form-group
              label="Unlimited Jobs"
              label-for="unlimitedPosting"
              autocomplete="off"
            >
              <b-form-input
                id="unlimitedPosting"
                v-model="requestModel.unlimitedPosting"
                type="number"
                required
              ></b-form-input>
            </b-form-group>
          </div> -->
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

      <script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "AddPackage",
  data() {
    return {
      moduleName: "Add Package",
      Name: null,
      statusOptions: [
        { text: "Select", value: null || 0, disabled: true },
        //  { text: "Select", value: 0 },
        { text: "Days", value: 1 },
        { text: "Month", value: 2 },
        { text: "Year", value: 3 },
      ],
      PhoneNumber: null,
      Address: null,
      requestModel: {
        packageName: null,
        regularPrice: null,
        salePrice: null,
        expiredAfter: null,
        jobPostingLimit: null,
        featuredJobLimit: null,
        unlimitedPosting: false,
        dateConvert: 1,
        // scheduleDate: null,
      },
      submitted: false,
    };
  },

    validations: {
    requestModel: {
      packageName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(350),
      }
    },
  },

  components: {
    Toaster,
  },
  methods: {
    clearLimitsFields() {
      if (this.requestModel.unlimitedPosting == true) {
        (this.requestModel.jobPostingLimit = null),
          (this.requestModel.featuredJobLimit = null);
      }
    },
    AddPackage() {
      this.isLoading = true;
      this.requestModel.regularPrice = Number(this.requestModel.regularPrice);
      this.requestModel.salePrice = Number(this.requestModel.salePrice);
      this.requestModel.expiredAfter = Number(this.requestModel.expiredAfter);
      this.requestModel.jobPostingLimit = Number(
        this.requestModel.jobPostingLimit
      );
      this.requestModel.featuredJobLimit = Number(
        this.requestModel.featuredJobLimit
      );
      console.log(this.requestModel);
      axios.post("/Job/AddPackage", this.requestModel).then((res) => {
        this.isLoading = false;
        console.log(res);
        if (res.data.status == "Success") {
          this.$parent.getPage(1);
          this.$bvModal.hide("AddPackageModal");
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Package has been added successfully"
          );
        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        } else {
          this.jobsRequests = null;
        }
      });
    },

    checkFormValidity() {
      const valid = this.$refs.AddPackageForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      this.Address = null;
      this.PhoneNumber = null;
      this.Name = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }

      this.AddPackage();
    },
  },
};
</script>
