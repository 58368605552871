<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="xl"
      id="AddStoryModal"
      ref="modal"
      title="Add Upcoming Events"
      ok-title="Submit"
      cancel-title="Close"
      @show="resetModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.AddStoryForm != undefined
          ? !this.$refs.AddStoryForm.checkValidity()
          : true
      " 
    >
      <form ref="AddStoryForm" @submit.stop.prevent="handleSubmit">
        <div class="row">


          <div class="col-md-6">
            <!--NAME INPUT-->
            <b-form-group label="Title" label-for="title" autocomplete="off">
              <b-form-input
                id="title"
                v-model="requestModel.title"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.title.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.title.required"
                class="invalid-feedback"
              >
                Title is required
              </div>
              <div
                v-if="!$v.requestModel.title.minLength"
                class="invalid-feedback"
              >
                Title must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.title.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>

          </div>
          <div class="col-md-6" dir="rtl">
            <!--NAME INPUT-->
            <b-form-group label="عنوان" label-for="title" autocomplete="off">
              <b-form-input
                id="title"
                v-model="requestModel.urdutitle"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.title.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.title.required"
                class="invalid-feedback"
              >
                Title is required
              </div>
              <div
                v-if="!$v.requestModel.title.minLength"
                class="invalid-feedback"
              >
                Title must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.title.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>

          </div>
        
        </div>
<div class="row"> 
  <div class="col-md-12">
            <b-form-group
              label="Event Date"
              label-for="datepicker-1"
              autocomplete="off"
            >
              <b-form-input
                type="date"
                v-model="requestModel.EventDate"
              ></b-form-input>
            </b-form-group>
          </div>
</div>
        <div class="row">
          <div class="col-md-12">
            <!--NAME INPUT-->
            <b-form-group
              label="Description"
              label-for="description"
              autocomplete="off"
            >
            <ckeditor  v-model="requestModel.description"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.description.$error,
                }"></ckeditor>
              <div
                v-if="
                  submitted && !$v.requestModel.description.required
                "
                class="invalid-feedback"
              >
                 Description is required
              </div>
              <div
                v-if="!$v.requestModel.description.minLength"
                class="invalid-feedback"
              >
                 Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.description.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 5000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row" dir="rtl">
          <div class="col-md-12">
            <!--NAME INPUT-->
            <b-form-group
              label="تفصیل"
              label-for="description"
              autocomplete="off"
            >
            <ckeditor  v-model="requestModel.urdudescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.description.$error,
                }"></ckeditor>
              <div
                v-if="
                  submitted && !$v.requestModel.description.required
                "
                class="invalid-feedback"
              >
                 Description is required
              </div>
              <div
                v-if="!$v.requestModel.description.minLength"
                class="invalid-feedback"
              >
                 Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.description.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 5000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Image" label-for="Address" autocomplete="off">
              <b-form-file
                accept="image/jpeg, image/png, image/gif, image/webp"
                v-model="requestModel.Image"
                :state="Boolean(requestModel.Image)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                
              ></b-form-file>
              <!-- <div v-if="submitted && !$v.requestModel.videoOrImage.required" class="invalid-feedback">
              Image is required
             </div> -->
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "AddStory",
  data() {
    return {
      moduleName: "Add Package",
      file1: null,
      title:"Add Stories",
      requestModel: {
        id:0,
        title:'',
        urdutitle:'',
        description:'',
        urdudescription:'',
        Image:'',
        EventDate: new Date().toISOString().slice(0, 10)
      },
    
      imageDisbaled: false,
      submitted: false,
      cmsCategoryOptions: null,
      shehriTypeOptions: null,
      postTypeOptionsStory: [
        { text: "Featured Post", value: 1 },
        { text: "Latest Articles", value: 2 },
      ],
      postTypeOptionsUpdate: [
        { text: "News of the Day", value: 3 },
         { text: "Featured Stories", value: 4 },
        { text: "Articles & Blogs", value: 5 },
      ],
    };
  },
  props:{
    type:String
  },
  validations: {
    requestModel: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(350),
      },
      description: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(5000),
      },
      EventDate:{
        required,
      }
      // videoOrImage: {
      //   required,
      // },
    },
  },

  components: {
    Toaster,
  },
  created() {
    this.getFiltersDropdowns();
  },
  methods: {
    getFiltersDropdowns() {
      this.getDropDownValues("CmsCategory");
      this.getDropDownValues("ShehriType");
    },
    getDropDownValues(id) {
      this.isLoading = true;
      axios
        .post("/KeyValuePair/GetDropDownListByIds", {
          type: id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            console.log(res);
            let options = res.data.data.map((x) => {
              return {
                text: x.value,
                value: x.key,
              };
            });
            if (id == "ShehriType") {
              this.shehriTypeOptions = options;
            } else if (id == "CmsCategory") {
              this.cmsCategoryOptions = options;
            }
          } else {
            return null;
          }
        });
    },
    AddStory() {
      this.isLoading = true;
      
      if (this.requestModel.Image) {
        var fileSize = 209715;
        if (this.requestModel.Image.size > fileSize) {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "File Size Limit is 200 KB"
          );
          this.requestModel.Image = null;
          return false;
        }
      }

      const formData = new FormData();
      console.log(this.requestModel);

      formData.append("Title", this.requestModel.title);
      formData.append("UrduDescription", this.requestModel.urdudescription);
      formData.append("UrduTitle", this.requestModel.urdutitle);
      formData.append("Description", this.requestModel.description);
      formData.append("EventDate", this.requestModel.EventDate);
     
      // formData.append("IsImageUpdated", this.IsImageUpdated);
      if (this.requestModel.Image) {
        formData.append("Image", this.requestModel.Image);
      }

      axios.post("/Shehri/AddEvent", formData).then((res) => {
        this.isLoading = false;

          this.$bvModal.hide("AddStoryModal");
          this.resetModal();
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Story has been added successfully"
          );
       
      });
    },

    checkFormValidity() {
      const valid = this.$refs.AddStoryForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      this.requestModel.youtubeLink = null;
      this.requestModel.videoOrImage = null;
      this.youtubeDisabled = false;
      this.imageDisbaled = false;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {


      this.AddStory();
    },
  },
};
</script>
