  <template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="EditVoucherModal"
      ref="modal"
      title="Edit Webinar"
      ok-title="Submit"
      cancel-title="Close"
      @show="updateModel()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.EditPackageForm != undefined
          ? !this.$refs.EditPackageForm.checkValidity()
          : true
      "
    >
      <form ref="EditPackageForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <!--NAME INPUT-->
            <b-form-group
              label="Price"
              label-for="packageName"
              autocomplete="off"
            >
              <b-form-input
                id="packageName"
                v-model="requestModel.packageName"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Expiry"
              label-for="regularPrice"
              autocomplete="off"
            >
             <b-form-datepicker id="datepicker-1"  placeholder="Choose a date" local="en" required></b-form-datepicker>
            </b-form-group>
          </div>
         
        
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

      <script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
export default {
  name: "EditPackage",
  data() {
    return {
      moduleName: "Edit Package",

      requestModel: {
        PackageId:null,      
        packageName: null,
        regularPrice: null,
        salePrice: null,
        expiredAfter: null,
        jobPostingLimit: null,
        unlimitedPosting: null,
        featuredJobLimit: null,
      },
    };
  },

  components: {
    Toaster,
  },
  props: {
    packageToEdit: {
      type: Object,
      default: null,
    },
  },
  methods: {
    EditPackage() {
      this.isLoading = true;
      this.requestModel.regularPrice = Number(this.requestModel.regularPrice)
      this.requestModel.salePrice = Number(this.requestModel.salePrice)
      this.requestModel.expiredAfter = Number(this.requestModel.expiredAfter)
      this.requestModel.jobPostingLimit = Number(this.requestModel.jobPostingLimit)
      this.requestModel.featuredJobLimit = Number(this.requestModel.featuredJobLimit)
      console.log(this.requestModel)
      axios.post("/Job/UpdatePackage",this.requestModel).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Package has been added successfully"
          );
        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        } else {
          this.jobsRequests = null;
        }
      });

      this.$bvModal.hide("EditVoucherModal");
    },

    checkFormValidity() {
      const valid = this.$refs.EditPackageForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    updateModel() {
      console.log(this.packageToEdit)
      this.requestModel = this.packageToEdit;
      this.requestModel.regularPrice = Number(this.requestModel.regularPrice)
      this.requestModel.salePrice = Number(this.requestModel.salePrice)
      this.requestModel.expiredAfter = Number(this.requestModel.expiredAfter)
      this.requestModel.jobPostingLimit = Number(this.requestModel.jobPostingLimit)
      this.requestModel.featuredJobLimit = Number(this.requestModel.featuredJobLimit)
     console.log(this.requestModel);
    },
    resetModal() {
      
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.EditPackage();
    },
  },
};
</script>
