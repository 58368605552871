<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="EditVentureModal"
      ref="modal"
      title="Edit Venture"
      ok-title="Submit"
      cancel-title="Close"
      @show="showModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.EditForm != undefined
          ? !this.$refs.EditForm.checkValidity()
          : true
      "
    >
      <form ref="EditForm" @submit.stop.prevent="handleSubmit">
         <div class="row">
         <p class="text-danger"><b>Note:</b> It will not get submitted unless there's some change</p>
        </div>
        <div class="row">
          <!-- <div class="col-md-6">
            <b-form-group label="Title" label-for="Title" autocomplete="off">
              <b-form-input
                id="ventureTitle"
                v-model="ventureToEdit.title"
                :class="{
                  'is-invalid': submitted && $v.ventureToEdit.title.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.ventureToEdit.title.required"
                class="invalid-feedback"
              >
                Title is required
              </div>
              <div
                v-if="!$v.ventureToEdit.title.minLength"
                class="invalid-feedback"
              >
                Title must contain at least 3 characters
              </div>
              <div
                v-if="!$v.ventureToEdit.title.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 25 characters is reached
              </div>
            </b-form-group>
          </div> -->
          <div class="col-md-12">
            <b-form-group
              label="What We Do"
              label-for="WhatWeDo"
              autocomplete="off"
            >
              <b-form-input
                id="WhatWeDo"
                v-model="ventureToEdit.whatWeDo"
                :class="{
                  'is-invalid': submitted && $v.ventureToEdit.whatWeDo.$error,
                }"
                type="text"
                required
              >
              </b-form-input>

              <div
                v-if="submitted && !$v.ventureToEdit.whatWeDo.required"
                class="invalid-feedback"
              >
                WhatWeDo is required
              </div>
              <div
                v-if="!$v.ventureToEdit.whatWeDo.minLength"
                class="invalid-feedback"
              >
                It must contain at least 3 characters
              </div>
              <div
                v-if="!$v.ventureToEdit.whatWeDo.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group
              label="What We Do"
              label-for="Urdu WhatWeDo"
              autocomplete="off"
            >
              <b-form-input
                id="urduWhatWeDo"
                v-model="ventureToEdit.urduWhatWeDo"
                :class="{
                  'is-invalid': submitted && $v.ventureToEdit.urduWhatWeDo.$error,
                }"
                type="text"
                required
              >
              </b-form-input>

              <div
                v-if="submitted && !$v.ventureToEdit.urduWhatWeDo.required"
                class="invalid-feedback"
              >
              Urdu WhatWeDo is required
              </div>
              <div
                v-if="!$v.ventureToEdit.urduWhatWeDo.minLength"
                class="invalid-feedback"
              >
                It must contain at least 3 characters
              </div>
              <div
                v-if="!$v.ventureToEdit.urduWhatWeDo.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-md-6">
            <b-form-group label="Type" label-for="Type" autocomplete="off">
              <b-form-select
                id="Type"
                v-model="ventureToEdit.whyVentureHer"
                :class="{
                  'is-invalid':
                    submitted && $v.ventureToEdit.whyVentureHer.$error,
                }"
                :options="whyVentureHer"
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select Type</b-form-select-option
                >
              </b-form-select>
              <div
                v-if="submitted && !$v.ventureToEdit.whyVentureHer.required"
                class="invalid-feedback"
              >
                Type is required
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Short Description"
              label-for="description"
              autocomplete="off"
            >
              <b-form-input
                id="description"
                v-model="ventureToEdit.description"
                :class="{
                  'is-invalid':
                    submitted && $v.ventureToEdit.description.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.ventureToEdit.description.required"
                class="invalid-feedback"
              >
                Description is required
              </div>
              <div
                v-if="!$v.ventureToEdit.description.minLength"
                class="invalid-feedback"
              >
                Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.ventureToEdit.description.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 50 characters is reached
              </div>
            </b-form-group>
          </div>
        </div> -->
        <!-- <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Long Description"
              label-for="longDescription"
              autocomplete="off"
            >
              <b-textarea
                id="longDescription"
                v-model="ventureToEdit.longDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.ventureToEdit.longDescription.$error,
                }"
                type="text"
                required
              ></b-textarea>

              <div
                v-if="submitted && !$v.ventureToEdit.longDescription.required"
                class="invalid-feedback"
              >
                Long Description is required
              </div>
              <div
                v-if="!$v.ventureToEdit.longDescription.minLength"
                class="invalid-feedback"
              >
                Long Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.ventureToEdit.longDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div> -->
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Logo" label-for="Image" autocomplete="off">
              <!-- <b-form-file
                accept="image/jpeg, image/png, image/gif, video/mp4"
                :state="Boolean(attachedFile1)"
                @change="onSelectImage"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file> -->
              <b-form-file
                accept="image/jpeg, image/png, image/gif, image/webp"
                v-model="attachedFile1"
                :state="Boolean(attachedFile1)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-8 image-with-border" v-if="isImageSelected">
            <zoom-on-hover
              :img-normal="this.siteUrl1 + this.imageUrl1"
            ></zoom-on-hover>
          </div>
          <div class="col-md-2"></div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default {
  name: "EditVenture",
  data() {
    return {
      moduleName: "Edit Venture",
      imageUrl1: "",
      imageUrl2: "",
      isImageSelected: true,
      siteUrl1: "",
      siteUrl2: "",
      attachedFile1: null,
      attachedFile2: null,
      requestModel: {
        // title: null,
        whatWeDo: null,
        urduWhatWeDo:null,
        // description: null,
        // longDescription: null,
        // whyVentureHer: null,
        // logo: null,
        image: null,
      },
      submitted: false,
      courseCategoryOptions: null,
      courseTypeOptions: [
        { text: "Train Me", value: 1 },
        { text: "Train The Team", value: 2 },
        { text: "Skill Her", value: 3 },
      ],
      whyVentureHer: [
        { text: "Section1", value: 1 },
        { text: "Section2", value: 2 },
        { text: "Section3", value: 3 },
        { text: "Section4", value: 4 },
      ],
    };
  },
  validations: {
    ventureToEdit: {
      // title: {
      //   required,
      //   minLength: minLength(3),
      //   maxLength: maxLength(25),
      // },
      whatWeDo: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(600),
      },
      urduWhatWeDo: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(600),
      },
      // description: {
      //   required,
      //   minLength: minLength(3),
      //   maxLength: maxLength(50),
      // },
      // longDescription: {
      //   required,
      //   minLength: minLength(3),
      //   maxLength: maxLength(1000),
      // },
      // whyVentureHer: {
      //   required,
      // },
      // logo: {
      //   required,
      // },
      image: {
        required,
      },
    },
  },

  components: {
    Toaster,
  },
  created() {
    this.getCourseCategories();
    // this.ventureToEdit.title = this.requestModel.title;
    // this.ventureToEdit.whatWeDo = this.requestModel.whatWeDo;
    // this.ventureToEdit.description = this.requestModel.description;
    // this.ventureToEdit.longDescription = this.requestModel.description;
    // this.ventureToEdit.logo = this.requestModel.logo;
  },
  props: {
    ventureToEdit: {
      type: Object,
    },
  },
  watch: {
    attachedFile1(newValue, oldValue) {
      // console.log(newValue);
      if (newValue.type.includes("video")) this.isImageSelected = false;
      else this.isImageSelected = true;
      this.IsImageUpdated = true;
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.siteUrl1 = "";
              this.imageUrl1 = value;
              // console.log(value);
            })
            .catch(() => {
              this.imageUrl1 = null;
            });
        } else {
          this.imageUrl1 = null;
        }
      }
    },
    attachedFile2(newValue, oldValue) {
      // console.log(newValue);
      if (newValue.type.includes("video")) this.isImageSelected = false;
      else this.isImageSelected = true;
      this.IsImageUpdated = true;
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.siteUrl2 = "";
              this.imageUrl2 = value;
              // console.log(value);
            })
            .catch(() => {
              this.imageUrl2 = null;
            });
        } else {
          this.imageUrl2 = null;
        }
      }
    },
  },
  methods: {
    onSelectImage(e) {
      var reader = new FileReader();
      reader.onload = function() {
        var output = document.getElementById("output");
        output.src = reader.result;
      };
      reader.readAsDataURL(e.target.files[0]);

      console.log("ON SELECT CALLED");
      this.ventureToEdit.logo = null;
      this.ventureToEdit.logo = e.target.files[0];
      console.log("uploadFile : ", e.target.files[0].size);
      console.log("uploadFileName : ", this.ventureToEdit.logo.name);
      this.siteUrl1 = "";

      // Allowing file Size
      var fileSize = 209715;

      if (this.requestModel.logo.size > fileSize) {
        alert("File Size Limit is 200 KB");
        e.target.value = "";
        this.ventureToEdit.logo = null;
        return false;
      }
    },
    getCourseCategories() {
      axios.get("/V_Learn/GetCourseCategory").then((res) => {
        this.isLoading = false;
        if (res.data.status == "Success") {
          let options = res.data.data.map((x) => {
            return {
              text: x.courseCategoryName,
              value: x.courseCategoryId,
            };
          });
          this.courseCategoryOptions = options;
        }
      });
    },
    getFiltersDropdowns() {
      this.getDropDownValues("CmsCategory");
      this.getDropDownValues("ShehriType");
    },
    getDropDownValues(id) {
      this.isLoading = true;
      axios
        .post("/KeyValuePair/GetDropDownListById", {
          type: id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            console.log(res);
            let options = res.data.data.map((x) => {
              return {
                text: x.value,
                value: x.key,
              };
            });
            if (id == "ShehriType") {
              this.shehriTypeOptions = options;
            } else if (id == "CmsCategory") {
              this.cmsCategoryOptions = options;
            }
          } else {
            return null;
          }
        });
    },
    Edit() {
      this.isLoading = true;
      if (this.attachedFile1) {
        var fileSize = 209715;
        if (this.attachedFile1.size > fileSize) {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "File Size Limit is 200 KB"
          );
          this.attachedFile1 = null;
          return false;
        }
      }

      const formData = new FormData();
      formData.append("id", this.ventureToEdit.id);
      // formData.append("title", this.ventureToEdit.title);

      // formData.append("description", this.ventureToEdit.description);
      // formData.append("longDescription", this.ventureToEdit.longDescription);
      // formData.append("whyVentureHer", Number(this.requestModel.whyVentureHer));
      formData.append("whatWeDo", this.ventureToEdit.whatWeDo);
      formData.append("UrduWhatWeDo", this.ventureToEdit.urduWhatWeDo);
      formData.append("image", this.ventureToEdit.image); //will remain same

      if (this.siteUrl1 === "") {
        formData.append("image", this.attachedFile1);
      } else {
        formData.append("image", this.ventureToEdit.image);
      }

      //FIRST API CALL
      axios.post("/Shehri/Update", formData).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          //NOW CHECK IF WE NEED O CALL SECOND API
          //second api call

          // this.ventureToEdit.logo = res.data.data.logo;
          // this.uploadImageOnly();
          this.$parent.getPage(1);
          this.$bvModal.hide("EditVentureModal");
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Venture has been edited successfully"
          );
        } else {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "Unable to edit Venture"
          );
        }
      });
    },
    uploadImageOnly() {
      //will remain same
      if (this.siteUrl2 == "") {
        const formData = new FormData();
        formData.append("id", this.ventureToEdit.id);
        formData.append("title", this.ventureToEdit.title);
        formData.append("whatWeDo", this.ventureToEdit.whatWeDo);
        formData.append("UrduWhatWeDo", this.ventureToEdit.urduWhatWeDo);
        formData.append("description", this.ventureToEdit.description);
        formData.append("longDescription", this.ventureToEdit.longDescription);
        formData.append(
          "whyVentureHer",
          Number(this.requestModel.whyVentureHer)
        );
        formData.append("logo", this.ventureToEdit.logo);

        if (this.siteUrl2 === "") {
          formData.append("image", this.attachedFile2);
        } else {
          formData.append("image", this.ventureToEdit.image);
        }

        axios.post("/Shehri/UploadImage", formData).then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            console.log("success");
            this.$parent.getPage(1);
            // this.resetModal();
          }
        });
      } else {
        return;
      }
    },

    checkFormValidity() {
      const valid = this.$refs.EditForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      // this.attachedFile1=null;
      //  this.attachedFile2=null;
      //  this.ventureToEdit.title=null;
      //  this.ventureToEdit.whatWeDo=null;
      //  this.ventureToEdit.description=null;
      //  this.ventureToEdit.longDescription=null;
      //  this.ventureToEdit.image=null;
      //  this.ventureToEdit.logo=null;
    },
    showModal() {
      this.requestModel = JSON.parse(JSON.stringify(this.ventureToEdit));
      this.attachedFile1 = null;
      this.attachedFile2 = null;
      // this.siteUrl1 = "https://smw.demo.itrooscloud.net/";
      // this.siteUrl1 = "https://smw.demo.itrooscloud.net/";
      // this.siteUrl2 = "https://smw.demo.itrooscloud.net/";

      /*this.siteUrl1 = "https://smw.demo.itrooscloud.net/";
      this.siteUrl2 = "https://smw.demo.itrooscloud.net/";*/
      this.siteUrl1 = "https://smw-live.azurewebsites.net/";
      this.siteUrl2 = "https://smw-live.azurewebsites.net/";
      this.imageUrl1 = this.ventureToEdit.image;
      // this.imageUrl2 = this.ventureToEdit.image;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.$v.$touch();

      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }

      this.Edit();
    },
  },
};
</script>
