<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="EditVenturePartnerModal"
      ref="modal"
      :title="'Edit ' + pageType"
      ok-title="Submit"
      cancel-title="Close"
      @show="showModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.EditForm != undefined
          ? !this.$refs.EditForm.checkValidity()
          : true
      "
    >
      <form ref="EditForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <p class="text-danger">
            <b>Note:</b> It will not get submitted unless there's some change
          </p>
        </div>
        <!--TITLE-->
        <div class="row" v-if="isTitle">
          <div class="col-md-12">
            <b-form-group label="Title" label-for="Title" autocomplete="off">
              <b-form-input
                id="ventureTitle"
                v-model="recordToEdit.title"
                :class="{
                  'is-invalid': submitted && $v.recordToEdit.title.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.recordToEdit.title.required"
                class="invalid-feedback"
              >
                Title is required
              </div>
              <div
                v-if="!$v.recordToEdit.title.minLength"
                class="invalid-feedback"
              >
                Title must contain at least 3 characters
              </div>
              <div
                v-if="!$v.recordToEdit.title.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <!--SHORT DESCRIPTION-->
        <div class="row" v-if="isShortDescription">
          <div class="col-md-12">
            <b-form-group
              label="Short Description"
              label-for="description"
              autocomplete="off"
            >
              <b-form-input
                id="description"
                v-model="recordToEdit.shortDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.recordToEdit.shortDescription.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.recordToEdit.shortDescription.required"
                class="invalid-feedback"
              >
                Description is required
              </div>
              <div
                v-if="!$v.recordToEdit.shortDescription.minLength"
                class="invalid-feedback"
              >
                Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.recordToEdit.shortDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <!--LONG DESCRIPTION-->
        <div class="row" v-if="isLongDescription">
          <div class="col-md-12">
            <b-form-group
              label="Long Description"
              label-for="longDescription"
              autocomplete="off"
            >
              <!-- <b-textarea
                id="longDescription"
                v-model="recordToEdit.longDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.recordToEdit.longDescription.$error,
                }"
                type="text"
                required
              ></b-textarea> -->

              <ckeditor
                v-model="recordToEdit.longDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.recordToEdit.longDescription.$error,
                }"
              ></ckeditor>

              <div
                v-if="submitted && !$v.recordToEdit.longDescription.required"
                class="invalid-feedback"
              >
                Long Description is required
              </div>
              <div
                v-if="!$v.recordToEdit.longDescription.minLength"
                class="invalid-feedback"
              >
                Long Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.recordToEdit.longDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 5000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <!--IMAGE-->
        <div class="row" v-if="isImage">
          <div class="col-md-12">
            <div class="form-group" autocomplete="off">
              <label label-for="Image">Image</label>
              <span
                v-if="pageType === 'Our Mentor Sessions'"
                class="ml-1 text-muted small"
              >
                <i
                  >(Image must be square, and at least 512 pixels wide and
                  tall.)</i
                >
                <!-- <b-icon icon="question-circle"></b-icon> -->
              </span>
              <b-form-file
                accept="image/jpeg, image/png, image/gif, image/webp"
                v-model="attachedFile"
                :state="Boolean(attachedFile)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </div>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-8 image-with-border" v-if="isImageSelected">
            <zoom-on-hover
              :img-normal="this.siteUrl + this.imageUrl"
            ></zoom-on-hover>
          </div>
          <div class="col-md-2"></div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default {
  name: "Edit",
  data() {
    return {
      moduleName: "Edit Venture",
      imageUrl: "",
      isImageSelected: true,
      siteUrl: "",
      attachedFile: null,
      requestModel: {
        title: null,
        whatWeDo: null,
        shortDescription: null,
        longDescription: null,
        logo: null,
      },
      submitted: false,
      courseCategoryOptions: null,
      typeOptions: [
        { text: "Business Services and Partners", value: 1 },
        { text: "Investment and Funding", value: 2 },
        { text: "Why Venture Her", value: 3 }
      ],
    };
  },
  validations: {
    recordToEdit: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(350),
      },
      shortDescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(300),
      },
      longDescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(5000),
      },
    },
  },

  components: {
    Toaster,
  },
  created() {
    this.getCourseCategories();
     if (this.IsWhyVenture) {
      this.requestModel.type = 3;
    }
    // this.recordToEdit.title = this.requestModel.title;
    // this.recordToEdit.whatWeDo = this.requestModel.whatWeDo;
    // this.recordToEdit.description = this.requestModel.description;
    // this.recordToEdit.longDescription = this.requestModel.description;
    // this.recordToEdit.logo = this.requestModel.logo;
  },
  props: {
    recordToEdit: {
      type: Object,
    },
    pageType:{
      type:String
    },
    isTitle:{
      type:Boolean
    },
    isShortDescription:{
      type:Boolean
    },
    isLongDescription:{
      type:Boolean
    },
    isImage:{
      type:Boolean
    }
  },
  watch: {
    attachedFile(newValue, oldValue) {
      // console.log(newValue);
      if (newValue.type.includes("video")) this.isImageSelected = false;
      else this.isImageSelected = true;
      this.IsImageUpdated = true;
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.siteUrl = "";
              this.imageUrl = value;
              // console.log(value);
            })
            .catch(() => {
              this.imageUrl = null;
            });
        } else {
          this.imageUrl = null;
        }
      }
    },
  },
  methods: {
    onSelectImage(e) {
      var reader = new FileReader();
      reader.onload = function() {
        var output = document.getElementById("output");
        output.src = reader.result;
      };
      reader.readAsDataURL(e.target.files[0]);

      console.log("ON SELECT CALLED");
      this.recordToEdit.logo = null;
      this.recordToEdit.logo = e.target.files[0];
      console.log("uploadFile : ", e.target.files[0].size);
      console.log("uploadFileName : ", this.recordToEdit.logo.name);
      this.siteUrl = "";

      // Allowing file Size
      var fileSize = 209715;

      if (this.requestModel.logo.size > fileSize) {
        alert("File Size Limit is 200 KB");
        e.target.value = "";
        this.recordToEdit.logo = null;
        return false;
      }
    },
    getCourseCategories() {
      axios.get("/V_Learn/GetCourseCategory").then((res) => {
        this.isLoading = false;
        if (res.data.status == "Success") {
          let options = res.data.data.map((x) => {
            return {
              text: x.courseCategoryName,
              value: x.courseCategoryId,
            };
          });
          this.courseCategoryOptions = options;
        }
      });
    },
    getFiltersDropdowns() {
      this.getDropDownValues("CmsCategory");
      this.getDropDownValues("ShehriType");
    },
    getDropDownValues(id) {
      this.isLoading = true;
      axios
        .post("/KeyValuePair/GetDropDownListById", {
          type: id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            console.log(res);
            let options = res.data.data.map((x) => {
              return {
                text: x.value,
                value: x.key,
              };
            });
            if (id == "ShehriType") {
              this.shehriTypeOptions = options;
            } else if (id == "CmsCategory") {
              this.cmsCategoryOptions = options;
            }
          } else {
            return null;
          }
        });
    },
    Edit() {
      this.isLoading = true;

      if (this.attachedFile) {
        var fileSize = 209715;
        if (this.attachedFile.size > fileSize) {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "File Size Limit is 200 KB"
          );
          this.attachedFile = null;
          return false;
        }
      }

      let viiLearnType=null;
      if(this.pageType==="Knowledge & Training For Everyone"){
        viiLearnType=2
      }else if(this.pageType==="Our Partners & Services for Corporates"){
        viiLearnType=3
      }else if(this.pageType==="Skill Her"){
        viiLearnType=4
      }else if(this.pageType==="Spare a Smile"){
        viiLearnType=6
      }else if(this.pageType==="Be a Mentor"){
        viiLearnType=8
      }else if(this.pageType==="Our Programs"){
        viiLearnType=7
      }else if(this.pageType==="Our Mission"){
        viiLearnType=5
      }else if(this.pageType==="Our Mentor Sessions"){
        viiLearnType=9
      }else if(this.pageType==="Education for Everyone"){
        viiLearnType=1
      }
      else
        return null;

      const formData = new FormData();
      formData.append("Id", this.recordToEdit.id);
      formData.append("Title", this.recordToEdit.title);
      formData.append("ViiLearnType", viiLearnType);
      formData.append("ShortDescription", this.recordToEdit.shortDescription);
      formData.append("LongDescription", this.recordToEdit.longDescription);
      if (this.siteUrl === "") {
        formData.append("Image", this.attachedFile);
      } else {
        formData.append("Image", this.recordToEdit.image);
      }

      axios.post("/V_Learn/Update", formData).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          this.$parent.getPage(1);
          this.$bvModal.hide("EditVenturePartnerModal");
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            this.pageType+" has been edited successfully"
          );
        } else {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "Unable to edit"
          );
        }
      });
    },

    checkFormValidity() {
      const valid = this.$refs.EditForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {},
    showModal() {
      this.requestModel = JSON.parse(JSON.stringify(this.recordToEdit));
      this.attachedFile = null;
      // this.siteUrl = "https://smw.demo.itrooscloud.net/";
      this.siteUrl = "https://smw-live.azurewebsites.net/";
      this.imageUrl = this.recordToEdit.image;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.$v.$touch();

      // if (this.$v.$invalid) {
      //   console.log("invalid");
      //   return;
      // }

        if(this.isTitle && this.submitted && this.$v.recordToEdit.title.$anyError){
        console.log("invalid");
        return;
      }
      if(this.isShortDescription && this.submitted && this.$v.recordToEdit.shortDescription.$anyError){
        console.log("invalid");
        return;
      }
       if(this.isLongDescription && this.submitted && this.$v.recordToEdit.longDescription.$anyError){
        console.log("invalid");
        return;
      }
      //  if(this.isImage && this.submitted && this.$v.recordToEdit.image.$anyError){
      //   console.log("invalid");
      //   return;
      // }

      this.Edit();
    },
  },
};
</script>
