  <template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="JobApplicantsModal"
      ref="modal"
      title="Job Applicants"
      ok-title="Close"
      ok-only
      @show="getApplicants()"
      @ok='$bvModal.hide("JobApplicantsModal")'
    >
      <form ref="AddPackageForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col">
            <table
              class="
                table table-striped table-borderless
                cutom-table
                table-striped table-sm
              "
              style="font-size: 85%"
            >
              <thead>
                <tr>
                  <th role="columnheader">Name</th>
                  <!-- <th role="columnheader">Age</th> -->
                  <th role="columnheader">Gender</th>
                  <th role="columnheader">City</th>
                  <th role="columnheader">Email</th>
                  <!-- <th role="columnheader">Education</th> -->
                  <th role="columnheader">Experience</th>
                  <!-- <th role="columnheader">Job Applied for</th> -->
                  <th role="columnheader">Category</th>
                  <th role="columnheader">Actions</th>
                </tr>
              </thead>

              <tbody>
                <!--IF STUDENTS ARRAY HAS NO VALUE THEN SHOW WITHOUT SHOWING DATA-->
                <tr v-if="applicants == null">
                  <td class="center-td" colspan="10">No Applicant(s) Found</td>
                </tr>

                <tr v-else v-for="(record, index) in applicants" :key="index">
                  <td role="cell">{{ record.name }}</td>
                  <!-- <td role="cell">{{ record.age }}</td> -->
                  <td role="cell">{{ record.gender }}</td>
                  <td role="cell">{{ record.city }}</td>
                  <td role="cell">{{ record.email }}</td>
                  <!-- <td role="cell">{{ record.education }}</td> -->
                  <td role="cell">{{ record.experience }}</td>
                  <!-- <td role="cell">{{ record.jobAppliedFor }}</td> -->
                  <td role="cell">{{ record.category }}</td>

                  <td>
                    <a
                      style="text-decoration: none"
                      :href="
                        record.resume != null &&
                        record.resume != '' &&
                        record.resume != undefined
                          ? siteUrl + record.resume
                          : 'Javascript:void(0)'
                      "
                      target="_blank"
                    >
                      <span title="Download CV">
                        <i
                          role="button"
                          style="color: #ea216c"
                          class="fas fa-download"
                        ></i>
                      </span>
                    </a>
                    <!-- <span @click="deleteRecord(record.id)">
                    <i
                      role="button"
                      style="color: #ea216c"
                      class="far fa-trash-alt"
                    ></i>
                  </span> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

      <script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
export default {
  name: "Applicants",
  data() {
    return {
      siteUrl : "https://smw-live.azurewebsites.net",
      moduleName: "Applicants",
      Name: null,
      applicants: null,
    };
  },

  components: {
    Toaster,
  },
  props: {
    jobId: {
      type: Number,
      default: null,
    },
  },

  methods: {
    getApplicants() {
      this.applicants = null;
      this.isLoading = true;
      axios.get("/Job/JobApplicantByJobId?jobId=" + this.jobId).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          if (res.data.data.length > 0) this.applicants = res.data.data;
          else this.applicants = null;
        } else {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        }
      });
    },

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
    },
  },
};
</script>
