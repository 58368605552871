var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"modal",staticStyle:{"padding":"15px"},attrs:{"size":"lg","id":"EditVenturePartnerModal","title":'Edit ' + _vm.pageType,"ok-title":"Submit","cancel-title":"Close","ok-disabled":this.$refs.EditForm != undefined
        ? !this.$refs.EditForm.checkValidity()
        : true},on:{"show":function($event){return _vm.showModal()},"hidden":function($event){return _vm.resetModal()},"ok":_vm.handleOk}},[_c('form',{ref:"EditForm",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('p',{staticClass:"text-danger"},[_c('b',[_vm._v("Note:")]),_vm._v(" It will not get submitted unless there's some change ")])]),(_vm.isTitle)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Title","label-for":"Title","autocomplete":"off"}},[_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.recordToEdit.title.$error,
              },attrs:{"id":"ventureTitle","type":"text","required":""},model:{value:(_vm.recordToEdit.title),callback:function ($$v) {_vm.$set(_vm.recordToEdit, "title", $$v)},expression:"recordToEdit.title"}}),(_vm.submitted && !_vm.$v.recordToEdit.title.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Title is required ")]):_vm._e(),(!_vm.$v.recordToEdit.title.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Title must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.recordToEdit.title.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 350 characters is reached ")]):_vm._e()],1)],1)]):_vm._e(),(_vm.isShortDescription)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Short Description","label-for":"description","autocomplete":"off"}},[_c('b-form-input',{class:{
                'is-invalid':
                  _vm.submitted && _vm.$v.recordToEdit.shortDescription.$error,
              },attrs:{"id":"description","type":"text","required":""},model:{value:(_vm.recordToEdit.shortDescription),callback:function ($$v) {_vm.$set(_vm.recordToEdit, "shortDescription", $$v)},expression:"recordToEdit.shortDescription"}}),(_vm.submitted && !_vm.$v.recordToEdit.shortDescription.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Description is required ")]):_vm._e(),(!_vm.$v.recordToEdit.shortDescription.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Description must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.recordToEdit.shortDescription.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 1000 characters is reached ")]):_vm._e()],1)],1)]):_vm._e(),(_vm.isLongDescription)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Long Description","label-for":"longDescription","autocomplete":"off"}},[_c('ckeditor',{class:{
                'is-invalid':
                  _vm.submitted && _vm.$v.recordToEdit.longDescription.$error,
              },model:{value:(_vm.recordToEdit.longDescription),callback:function ($$v) {_vm.$set(_vm.recordToEdit, "longDescription", $$v)},expression:"recordToEdit.longDescription"}}),(_vm.submitted && !_vm.$v.recordToEdit.longDescription.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Long Description is required ")]):_vm._e(),(!_vm.$v.recordToEdit.longDescription.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Long Description must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.recordToEdit.longDescription.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 5000 characters is reached ")]):_vm._e()],1)],1)]):_vm._e(),(_vm.isImage)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group",attrs:{"autocomplete":"off"}},[_c('label',{attrs:{"label-for":"Image"}},[_vm._v("Image")]),(_vm.pageType === 'Our Mentor Sessions')?_c('span',{staticClass:"ml-1 text-muted small"},[_c('i',[_vm._v("(Image must be square, and at least 512 pixels wide and tall.)")])]):_vm._e(),_c('b-form-file',{attrs:{"accept":"image/jpeg, image/png, image/gif, image/webp","state":Boolean(_vm.attachedFile),"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.attachedFile),callback:function ($$v) {_vm.attachedFile=$$v},expression:"attachedFile"}})],1)]),_c('div',{staticClass:"col-md-2"}),(_vm.isImageSelected)?_c('div',{staticClass:"col-md-8 image-with-border"},[_c('zoom-on-hover',{attrs:{"img-normal":this.siteUrl + this.imageUrl}})],1):_vm._e(),_c('div',{staticClass:"col-md-2"})]):_vm._e()])]),_c('Toaster',{ref:"toaster"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }