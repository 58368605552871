  <template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="AddBannerModal"
      ref="modal"
      title="Add Banner"
      ok-title="Submit"
      cancel-title="Close"
      @show="resetModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.AddClientForm != undefined
          ? !this.$refs.AddClientForm.checkValidity()
          : true
      "
    >
      <form ref="AddClientForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <!--NAME INPUT-->
            <b-form-group
              label="Page"
              label-for="Page"
              autocomplete="off"
            >
              <b-form-select
                id="role-input"
                v-model="Page"
                value-field="id"
                text-field="name"
                :options="Pages"
                required
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select Page</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Link" label-for="Link" autocomplete="off">
              <b-form-input
                id="Link"
                v-model="Link"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Banner" label-for="Address" autocomplete="off">
              <b-form-file
                accept="image/jpeg, image/png, image/gif"
                v-model="file1"
                :state="Boolean(file1)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

      <script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
export default {
  name: "AddClient",
  data() {
    return {
      moduleName: "Add Management",
      file1: null,
      Name: null,
      Pages: [
        { id: 1, name: "Employer Dashboard" },
        { id: 2, name: "Employer Jobs" },
        { id: 3, name: "Employee Dashboard" },
      ],
      Page: null,
      Link: null,
    };
  },
  components: {
    Toaster,
  },
  methods: {
    AddClient() {
      axios;

      this.$refs.toaster.showToast(
        "Sucess",
        "success",
        "Banner has been added successfully"
      );
      this.$bvModal.hide("AddBannerModal");
    },

    checkFormValidity() {
      const valid = this.$refs.AddClientForm.checkValidity();
      if(this.file1 == null)
      {
         this.$refs.toaster.showToast(
        "Invalid Data",
        "danger",
        "Please select a file"
      );
        return;
      }
      
      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      this.Address = null;
      this.PhoneNumber = null;
      this.Name = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.AddClient();
    },
  },
};
</script>
