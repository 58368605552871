<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="error-template">
          <h1>Oops!</h1>
          <h2>404 Not Found</h2>
          <div class="error-details">
            Sorry, an error has occured, Requested page not found!
          </div>
          <div class="error-actions">
            <button class="btn btn-primary btn-lg" @click="redirectToHome()">
              <span class="glyphicon glyphicon-home"></span>
              Take Me Home
            </button>
            <a
              href="javascript:void(0)"
              @click="redirectToHome()"
              class="btn btn-default btn-lg"
              ><span class="glyphicon glyphicon-envelope"></span> Contact
              Support
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
  methods: {
    redirectToHome: function () {
      this.$router.replace("/");
    },
  },
};
</script>