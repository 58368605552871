<template>
  <div>
    <div class="row justify-content-between align-items-center">
      <div class="col-md-9 customHeading">
        <h3>{{ selectedDropDownId }}</h3>
      </div>

      <!--==========Class dropdown============-->

      <!---SEARCH BOX-->
      <div class="col-md-2">
        <div class="searchbox input-group" role="group">
          <input
            class="form-control search-box my-search-box"
            type="text"
            placeholder="Text"
            aria-label="search"
            v-model="filteredText"
            @keydown.enter.prevent="getDropDownValues()"
          />

          <button
            class="search-button btn btn-primary filter"
            @click="getDropDownValues()"
          >
            <i class="fa fa-search search-icon"></i>
          </button>
        </div>
      </div>
      <div class="col-md-1 Add-button" style="text-align: center">
        <a href="javascript:void(0)">
          <div class="AddButton">
            <div class="icon">
              <i
                class="fas fa-arrow-alt-circle-right fa-1x"
                style="color: rgb(255, 50, 55)"
              >
              </i>
            </div>
            <div
              data-text="Add"
              class="name add-btn"
              v-b-modal.AddDropdownValueModal
            >
              Add
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <hr
          color="#CACACA"
          style="border-top: 2px solid rgb(202, 202, 202); margin-top: 5px"
        />
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col">
          <table
            class="
              table table-striped table-borderless
              cutom-table
              table-striped table-sm
            "
            style="font-size: 85%"
          >
            <thead>
              <tr>
                <th role="columnheader">#</th>
                <th role="columnheader">Text</th>

                <th role="columnheader">Is Active</th>
                <th role="columnheader">Action</th>
                <!-- <th role="columnheader">Action</th> -->
              </tr>
            </thead>
            <tbody>
              <!--IF STUDENTS ARRAY HAS NO VALUE THEN SHOW WITHOUT SHOWING DATA-->
              <tr v-if="dropDownValues == null">
                <td class="center-td" colspan="5">No Data</td>
              </tr>

              <tr v-else v-for="(record, index) in dropDownValues" :key="index">
                <!-- <td role="cell">{{ record.Page }}</td> -->

                <td role="cell">{{ index + 1 }}</td>
                <td role="cell">
                  <input
                    type="text"
                    v-model="record.value"
                    :disabled="record.isDisabledInput"
                    :id="'drp-text' + index"
                  />
                  <button
                    class="btn btn-sm btn-primary"
                    :hidden="record.isDisabledInput"
                    @click="updateDropdown()"
                  >
                    Save
                  </button>
                </td>
                <td>
                  <b-form-checkbox
                    switch
                    v-model="record.isDeleted"
                    :value="false"
                    :unchecked-value="true"
                    @input="editStatus(record)"
                  >
                  </b-form-checkbox>
                </td>
                <td>
                  <span @click="editDropdownText(record)">
                    <i
                      title="Edit Text"
                      role="button"
                      style="color: #ea216c"
                      class="fas fa-edit"
                    ></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--PAGINATION START-->
      <div class="row justify-content-between align-items-center">
        <div class="col-md-2">
          <div class="showPages">
            <select
              class="form-control"
              id="selClass"
              v-model="perPage"
              @change="getDropDownValues()"
            >
              <option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-8 d-flex justify-content-center">
          <div class="overflow-auto">
            <b-pagination
              v-model="page"
              :total-rows="rows"
              :per-page="perPage"
              limit="3"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              @change="getPage"
            ></b-pagination>
          </div>
        </div>

        <div class="col-md-2">
          <input
            class="form-control search-box page-no-search"
            type="text"
            v-bind:placeholder="page"
            aria-label="search"
            v-model.number="pageNumOnSearch"
            style="height: 38px"
          />

          <button
            class="search-button btn btn-primary search page-no-search-btn"
            @click="search()"
          >
            GO
          </button>
        </div>
      </div>
      <!--/PAGINATION END-->
      <!--SPINNER-->
      <Spinner
        v-if="isLoading"
        message="Loading..."
        size="medium"
        line-fg-color="rgb(222 224 219"
        line-bg-color="rgb(68 66 62)"
      >
      </Spinner>
      <!--/SPINNER-->
    </div>
    <AddDropdownValueModal :dropDownToAddValue="selectedDropDownId" />
    <Toaster ref="toaster" />

    <DeleteModal
      :msgForModal="msgForModal"
      :apiEndPoint="apiEndPoint"
      :heading="deleteModalHeading"
      :entityId="recordToDelete"
    />
  </div>
</template>

<script>
import axios from "axios";
import Toaster from "../../common/pages/toaster.vue";
// import config from '../config.js';
import Spinner from "vue-simple-spinner";
import AddDropdownValueModal from "./modals/add-dropdownvalue-modal.vue";
import DeleteModal from "../../common/modals/delete-modal.vue";
import _ from "underscore";

export default {
  name: "dropdownsmanagement",
  data() {
    return {
      moduleName: "",
      dropDownValues: [],
      isLoading: false,
      recordToEdit: null,
      recordToEditStatus: null,
      recordToDelete: null,
      msgForModal: null,
      apiEndPoint: null,
      deleteModalHeading: null,

      selectedDropDownId: null,

      pageNumOnSearch: "",
      filteredText: "",
      rows: 0,
      page: 1,
      options: [
        { text: "Show 2", value: 2 },
        { text: "Show 10", value: 10 },
        { text: "Show 25", value: 25 },
        { text: "Show 50", value: 50 },
        { text: "Show 100", value: 100 },
      ],
      perPage: 25,
    };
  },

  methods: {
    getPage(page) {
      this.page = page;
      this.getDropDownValues();
    },

    editStatus(rec) {
      this.recordToEditStatus = rec;
      this.updateDropdownStatus();
    },
    editDropdownText(rec) {
      if (this.recordToEdit != null) {
        this.recordToEdit.isDisabledInput = true;
      }
      this.recordToEdit = rec;
      rec.isDisabledInput = false;

      // setTimeout(() => {
      //   this.$bvModal.show("EditdropDownValuesModal");
      // }, 100);
    },
    deleteRecord(id) {
      this.recordToDelete = id;
      this.msgForModal = "Confirm to delete !";
      this.apiEndPoint = "client/Delete/";
      this.deleteModalHeading = "Delete";
      this.$bvModal.show("DeleteModal");
    },

    getDropDownValues() {
      
      this.isLoading = true;
      

      axios
        .post("/KeyValuePair/GetDropDownListById", {
          type: this.selectedDropDownId,
          PageSize: this.perPage,
          PageNumber: this.page,
          Text: this.filteredText,
        })
        .then((res) => {
          this.isLoading = false;

          if (res.data.status == "Success") {
           
            console.log(res);
            this.dropDownValues = res.data.data.map((x) => {
              return {
                type: x.type,
                value: x.value,
                key: x.key,
                isDeleted: x.isDeleted,
                isDisabledInput: true, // to make dropdowns non editable added against each value
              };
            });

             this.rows = res.data.totalCount;

             if(this.selectedDropDownId==="City" || this.selectedDropDownId==="Country"){
              let sortedData=[];
              sortedData = _.sortBy( this.dropDownValues, 'value');
              this.dropDownValues=sortedData;
             }
            
          } else {
            this.$refs.toaster.showToast("Error", "danger", res.data.msg);
            this.dropDownValues = null;
          }
        });
    },
    updateDropdown() {
      this.isLoading = true;
      axios
        .post("/KeyValuePair/updatedropdown", {
          type: this.recordToEdit.type,
          value: this.recordToEdit.value,
          key:this.recordToEdit.key
        })
        .then((res) => {
          this.isLoading = false;

          if (res.data.status == "Success") {
            this.$refs.toaster.showToast(
              "Success",
              "success",
              "Dropdown has been updated successfully"
            );
            this.getDropDownValues();
          } else {
            this.$refs.toaster.showToast("Error", "danger", res.data.msg);
            this.dropDownValues = null;
          }
        });
    },
    updateDropdownStatus() {
      console.log(this.recordToEditStatus);
      this.isLoading = true;
       console.log("asdfasdf")
      axios
        .post("/KeyValuePair/UpdateStatus", {
          key: this.recordToEditStatus.key,
          isDeleted:this.recordToEditStatus.isDeleted
        })
        .then((res) => {
          this.isLoading = false;

          if (res.data.status == "Success") {
            // this.$refs.toaster.showToast(
            //   "Success",
            //   "success",
            //   "Dropdown has been updated successfully"
            // );
            console.log("Dropdown status has been updated successfully");
            this.getDropDownValues();
          } else {
            this.$refs.toaster.showToast("Error", "danger", res.data.msg);
            this.dropDownValues = null;
          }
        });
    },

    search() {
      this.page = this.pageNumOnSearch;
      this.getDropDownValues();
    },
  },
  mounted() {
    this.selectedDropDownId = this.$route.params.id;
    this.getDropDownValues();

      //when we reuse a component then it will not be destroyed thus will not created hook be called again n again
    //we need to add a watch on params

     this.$watch(
      ()=>this.$route.params,
      ()=>{
         this.filteredText=null;
         this.getDropDownValues();
        
      }
    )

  },
  watch: {
    "$route.params.id"(newId, oldId) {
      console.log("new id" + oldId);
      console.log("new id" + newId);
      this.selectedDropDownId = newId;
      this.getDropDownValues();
    },
  },

  components: {
    Spinner,
    AddDropdownValueModal,
    DeleteModal,
    Toaster,
  },
};
</script>

<style scoped>
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
select#class-dropdown {
  width: 80%;
  margin-left: 16%;
}
</style>