  <template>
  <div>
    <b-modal
      style="padding: 15px"
      size="xl"
      id="AddCourseModal"
      ref="modal"
      title="Add Course"
      ok-title="Submit"
      cancel-title="Close"
      @show="resetModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.AddCourseForm != undefined
          ? !this.$refs.AddCourseForm.checkValidity()
          : true
      "
    >
      <form ref="AddCourseForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              label="Title"
              label-for="courseTitle"
              autocomplete="off"
            >
              <b-form-input
                id="courseTitle"
                v-model="requestModel.courseTitle"
                :class="{
                  'is-invalid': submitted && $v.requestModel.courseTitle.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.courseTitle.required"
                class="invalid-feedback"
              >
                Course title is required
              </div>
              <div
                v-if="!$v.requestModel.courseTitle.minLength"
                class="invalid-feedback"
              >
                Course title must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.courseTitle.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Category"
              label-for="courseCategory"
              autocomplete="off"
            >
              <b-form-select
                id="courseCategory"
                v-model="requestModel.courseCategory"
                :options="courseCategoryOptions"
                required
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select Course Category</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              label="Type"
              label-for="courseType"
              autocomplete="off"
            >
              <b-form-select
                id="courseType"
                v-model="requestModel.courseType"
                :options="courseTypeOptions"
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select Course Type</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Lectures"
              label-for="totalLectures"
              autocomplete="off"
            >
              <b-form-input
                id="totalLectures"
                v-model="requestModel.totalLectures"
                type="number"
                required
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              label="Hours"
              label-for="totalHours"
              autocomplete="off"
            >
              <b-form-input
                id="totalHours"
                v-model="requestModel.totalHours"
                type="number"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Short Description"
              label-for="courseShortDescription"
              autocomplete="off"
            >
              <b-form-input
                id="courseShortDescription"
                v-model="requestModel.courseShortDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.courseShortDescription.$error,
                }"
                type="text"
                required
              ></b-form-input>
              <div
                v-if="
                  submitted && !$v.requestModel.courseShortDescription.required
                "
                class="invalid-feedback"
              >
                Description is required
              </div>
              <div
                v-if="!$v.requestModel.courseShortDescription.minLength"
                class="invalid-feedback"
              >
                Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.courseShortDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              label="Instructor Name"
              label-for="instructorName"
              autocomplete="off"
            >
              <b-form-input
                id="instructorName"
                v-model="requestModel.instructorName"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.instructorName.$error,
                }"
                type="text"
                required
              ></b-form-input>
              <div
                v-if="submitted && !$v.requestModel.instructorName.required"
                class="invalid-feedback"
              >
                Instructor Name is required
              </div>
              <div
                v-if="!$v.requestModel.instructorName.minLength"
                class="invalid-feedback"
              >
                Instructor title must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.instructorName.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 100 characters is reached
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Instructor Facebook"
              label-for="instructorFBLink"
              autocomplete="off"
            >
              <b-form-input
                id="instructorFBLink"
                v-model="requestModel.instructorFBLink"
                type="text"
              ></b-form-input>
              <!-- <div v-if="submitted && !$v.requestModel.instructorFBLink.required" class="invalid-feedback">
               Instructor Name is required
             </div>
             <div v-if="!$v.requestModel.instructorFBLink.minLength" class="invalid-feedback">
              Instructor title must contain at least 3 characters
             </div>
              <div v-if="!$v.requestModel.instructorFBLink.maxLength" class="invalid-feedback">
               The maximum length of 1000 characters is reached
             </div> -->
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Long Description"
              label-for="description"
              autocomplete="off"
            >
              <ckeditor
                v-model="requestModel.courseDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.courseDescription.$error,
                }"
              ></ckeditor>
              <!-- <b-textarea
                id="description"
                v-model="requestModel.courseDescription"
                 :class="{'is-invalid':submitted && $v.requestModel.courseDescription.$error}"
                type="text"
                required
              ></b-textarea> -->
              <div
                v-if="submitted && !$v.requestModel.courseDescription.required"
                class="invalid-feedback"
              >
                Long Description is required
              </div>
              <div
                v-if="!$v.requestModel.courseDescription.minLength"
                class="invalid-feedback"
              >
                Long Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.courseDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 5000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Cover"
              label-for="courseImage"
              autocomplete="off"
            >
              <b-form-file
                accept="image/jpeg, image/png, image/gif, image/webp"
                v-model="requestModel.courseImage"
                :class="{
                  'is-invalid': submitted && $v.requestModel.courseImage.$error,
                }"
                :state="Boolean(requestModel.courseImage)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
              <div
                v-if="submitted && !$v.requestModel.courseImage.required"
                class="invalid-feedback"
              >
                Image is required
              </div>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

      <script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "AddCourse",
  data() {
    return {
      moduleName: "Add Package",
      file1: null,
      requestModel: {
        courseTitle: null,
        courseCategory: null,
        courseType: null,
        totalLectures: null,
        totalHours: null,
        courseShortDescription: null,
        instructorName: null,
        instructorFBLink: null,
        courseDescription: null,
        courseImage: null,
        // longDescription:null
      },
      submitted: false,
      // courseTypeOptions: null,
      courseCategoryOptions: null,
      courseTypeOptions: [
        { text: "Individual Courses", value: 1 },
        { text: "Courses With Partners", value: 2 },
        { text: "Skill Her", value: 3 },
      ],
    };
  },
    validations: {
    requestModel: {
      courseTitle: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(350),
      },
      courseShortDescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(500),
      },
      instructorName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      //  instructorFBLink: {
      //   required,
      //   minLength: minLength(3),
      //   maxLength: maxLength(1000),
      // },
      courseDescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(5000),
      },
      courseImage: {
        required,
      },
    },
  },


  components: {
    Toaster,
  },
  created() {
    this.getDropdowns();
  },
  methods: {
    getDropdowns() {
      this.getCourseCategories();
    },
    getCourseCategories() {
      axios.get("/V_Learn/GetCourseCategory").then((res) => {
        this.isLoading = false;
        if (res.data.status == "Success") {
          let options = res.data.data.map((x) => {
            return {
              text: x.courseCategoryName,
              value: x.courseCategoryId,
            };
          });
          this.courseCategoryOptions = options;
        }
      });
    },
    getDropDownValues(id) {
      this.isLoading = true;
      axios
        .post("/KeyValuePair/GetDropDownListById", {
          type: id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            console.log(res);
            let options = res.data.data.map((x) => {
              return {
                text: x.value,
                value: x.key,
              };
            });
            if (id == "ShehriType") {
              this.shehriTypeOptions = options;
            } else if (id == "CmsCategory") {
              this.cmsCategoryOptions = options;
            }
          } else {
            return null;
          }
        });
    },
    AddCourse() {
      this.isLoading = true;
      const formData = new FormData();
      console.log(this.requestModel);

       var fileSize = 209715;
      if (this.requestModel.courseImage.size > fileSize) {
         this.$refs.toaster.showToast(
            "Error",
            "danger",
            "File Size Limit is 200 KB"
          );
        this.requestModel.courseImage = null;
        return false;
      }

      formData.append("courseImage", this.requestModel.courseImage);
      formData.append("courseDescription", this.requestModel.courseDescription);
      formData.append("instructorFBLink", this.requestModel.instructorFBLink);
      formData.append("instructorName", this.requestModel.instructorName);
      formData.append(
        "courseShortDescription",
        this.requestModel.courseShortDescription
      );
      formData.append("totalHours", this.requestModel.totalHours);
      formData.append("totalLectures", this.requestModel.totalLectures);

      // formData.append("IsImageUpdated", this.IsImageUpdated);
      formData.append("courseType", this.requestModel.courseType);
      formData.append("courseCategoryId", this.requestModel.courseCategory);
      formData.append("courseTitle", this.requestModel.courseTitle);
      axios.post("/v_learn/AddCourse", formData).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          this.$parent.getPage(1);
          this.$bvModal.hide("AddCourseModal");
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Course has been added successfully"
          );
        } else {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "unable to add course"
          );
        }
      });
    },

    checkFormValidity() {
      const valid = this.$refs.AddCourseForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {},
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.$v.$touch();

      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }
      this.AddCourse();
    },
  },
};
</script>
