<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="xl"
      id="AddStoryModal"
      ref="modal"
      :title="type==='stories'?'Add Story':'Add Update'"
      ok-title="Submit"
      cancel-title="Close"
      @show="resetModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.AddStoryForm != undefined
          ? !this.$refs.AddStoryForm.checkValidity()
          : true
      "
    >
      <form ref="AddStoryForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-12" v-if="type==='stories'">
            <!--NAME INPUT-->
            <b-form-group
              label="Category"
              label-for="category"
              autocomplete="off"
            >
              <b-form-select
                id="category"
                v-model="requestModel.category"
                :options="cmsCategoryOptions"
                required
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select Category</b-form-select-option
                >
              </b-form-select>
              <!-- <div
                v-if="submitted && !$v.requestModel.category.required"
                class="invalid-feedback"
              >
                Category is required
              </div> -->
            </b-form-group>
          </div>
        

          
        </div>
        <div class="row">
          <div :class="type==='stories'? 'col-md-6' : 'col-md-6'">
            <!--NAME INPUT-->
            <b-form-group label="Title" label-for="title" autocomplete="off">
              <b-form-input
                id="title"
                v-model="requestModel.descriptionTitle"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.descriptionTitle.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.descriptionTitle.required"
                class="invalid-feedback"
              >
                Title is required
              </div>
              <div
                v-if="!$v.requestModel.descriptionTitle.minLength"
                class="invalid-feedback"
              >
                Title must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.descriptionTitle.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>
            </div>
            <div :class="type==='stories'? 'col-md-6' : 'col-md-6'" dir="rtl">
            <!--NAME INPUT-->
            <b-form-group label="عنوان" label-for="title" autocomplete="off">
              <b-form-input
                id="title"
                v-model="requestModel.urdudescriptionTitle"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.descriptionTitle.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.descriptionTitle.required"
                class="invalid-feedback"
              >
                Title is required
              </div>
              <div
                v-if="!$v.requestModel.descriptionTitle.minLength"
                class="invalid-feedback"
              >
                Title must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.descriptionTitle.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>
            </div>
        </div>
        <div class="row">
          <!-- <div class="col-md-6">
           
            <b-form-group label="Type" label-for="type" autocomplete="off">
              <b-form-select
                id="type"
                v-model="requestModel.type"
                :class="{
                  'is-invalid': submitted && $v.requestModel.type.$error,
                }"
                :options="shehriTypeOptions"
                required
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select Type</b-form-select-option
                >
              </b-form-select>

              <div
                v-if="submitted && !$v.requestModel.type.required"
                class="invalid-feedback"
              >
                Type is required
              </div>
            </b-form-group>
          </div> -->
          <div class="col-md-6">
            <!--NAME INPUT-->
            <b-form-group
              label="Post Type"
              label-for="title"
              autocomplete="off"
            >
              <b-form-select
                id="posttype"
                v-model="requestModel.postType"
                :class="{
                  'is-invalid': submitted && $v.requestModel.postType.$error,
                }"
                :options="type==='stories' ? postTypeOptionsStory : postTypeOptionsUpdate"
                required
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select Post Type</b-form-select-option
                >
              </b-form-select>
              <div
                v-if="submitted && !$v.requestModel.postType.required"
                class="invalid-feedback"
              >
                Post type is required
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Expiry Date"
              label-for="datepicker-1"
              autocomplete="off"
            >
              <b-form-input
                type="date"
                v-model="requestModel.expiryDate"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <!--NAME INPUT-->
            <b-form-group
              label="Youtube Link"
              label-for="youtube"
              autocomplete="off"
            >
              <b-form-input
                id="youtube"
                v-model="requestModel.youtubeLink"
                placeholder="https://www.youtube.com/embed/Rt_UqUm38BI"
                type="url"
                :disabled="
                  requestModel.videoOrImage ? (youtubeDisabled = true) : false
                "
              ></b-form-input>
            </b-form-group>
          </div>
           
           
        </div>
        <div class="row">
           <div class="col-md-12">
            <b-form-group
              label="Short Description"
              label-for="shortDescription"
              autocomplete="off"
            >
              <b-form-input
                id="youtube"
                v-model="requestModel.shortDescription"
                type="text"
                :class="{
                  'is-invalid': submitted && $v.requestModel.shortDescription.$error,
                }"
              ></b-form-input>
              
              <div
                v-if="submitted && !$v.requestModel.shortDescription.required"
                class="invalid-feedback"
              >
                Short Description is required
              </div>
              <div
                v-if="!$v.requestModel.shortDescription.minLength"
                class="invalid-feedback"
              >
                Short Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.shortDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row" dir="rtl">
           <div class="col-md-12">
            <b-form-group
              label="مختصر تفصیل"
              label-for="shortDescription"
              autocomplete="off"
            >
              <b-form-input
                id="youtube"
                v-model="requestModel.urdushortDescription"
                type="text"
                :class="{
                  'is-invalid': submitted && $v.requestModel.shortDescription.$error,
                }"
              ></b-form-input>
              
              <div
                v-if="submitted && !$v.requestModel.shortDescription.required"
                class="invalid-feedback"
              >
                Short Description is required
              </div>
              <div
                v-if="!$v.requestModel.shortDescription.minLength"
                class="invalid-feedback"
              >
                Short Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.shortDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <!--NAME INPUT-->
            <b-form-group
              label="Long Description"
              label-for="description"
              autocomplete="off"
            >
            <ckeditor  v-model="requestModel.categoryDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.categoryDescription.$error,
                }"></ckeditor>
              <!-- <b-textarea
                id="description"
                v-model="requestModel.categoryDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.categoryDescription.$error,
                }"
                type="text"
                required
              ></b-textarea> -->

              <div
                v-if="
                  submitted && !$v.requestModel.categoryDescription.required
                "
                class="invalid-feedback"
              >
                Long Description is required
              </div>
              <div
                v-if="!$v.requestModel.categoryDescription.minLength"
                class="invalid-feedback"
              >
                Long Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.categoryDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 5000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row" dir="rtl">
          <div class="col-md-12">
            <!--NAME INPUT-->
            <b-form-group
              label="لمبی تفصیل"
              label-for="description"
              autocomplete="off"
            >
            <ckeditor  v-model="requestModel.urducategoryDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.categoryDescription.$error,
                }"></ckeditor>
              <!-- <b-textarea
                id="description"
                v-model="requestModel.categoryDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.categoryDescription.$error,
                }"
                type="text"
                required
              ></b-textarea> -->

              <div
                v-if="
                  submitted && !$v.requestModel.categoryDescription.required
                "
                class="invalid-feedback"
              >
                Long Description is required
              </div>
              <div
                v-if="!$v.requestModel.categoryDescription.minLength"
                class="invalid-feedback"
              >
                Long Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.categoryDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 5000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Image" label-for="Address" autocomplete="off">
              <b-form-file
                accept="image/jpeg, image/png, image/gif, image/webp"
                v-model="requestModel.videoOrImage"
                :state="Boolean(requestModel.videoOrImage)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                :disabled="
                  requestModel.youtubeLink ? (imageDisbaled = true) : false
                "
              ></b-form-file>
              <!-- <div v-if="submitted && !$v.requestModel.videoOrImage.required" class="invalid-feedback">
              Image is required
             </div> -->
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "AddStory",
  data() {
    return {
      moduleName: "Add Package",
      file1: null,
      title:"Add Stories",
      requestModel: {
        category: null,
        categoryDescription: null,
        urducategoryDescription: null,
        descriptionTitle: null,
        urdudescriptionTitle: null,
        numberofDays: null,
        postType: null,
        // type: null,
        urdushortDescription:"",
        videoOrImage: null,
        categoryTitle: "",
        youtubeLink: null,
        expiryDate: new Date().toISOString().slice(0, 10)
      },
      youtubeDisabled: false,
      imageDisbaled: false,
      submitted: false,
      cmsCategoryOptions: null,
      shehriTypeOptions: null,
      postTypeOptionsStory: [
        { text: "Featured Post", value: 1 },
        { text: "Latest Articles", value: 2 },
      ],
      postTypeOptionsUpdate: [
        { text: "News of the Day", value: 3 },
         { text: "Featured Stories", value: 4 },
        { text: "Articles & Blogs", value: 5 },
      ],
    };
  },
  props:{
    type:String
  },
  validations: {
    requestModel: {
      descriptionTitle: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(350),
      },
      // type: {
      //   required,
      // },
      // category: {
      //   required,
      // },
      postType: {
        required,
      },
      categoryDescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(5000),
      },
      shortDescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(200),
      },
      // videoOrImage: {
      //   required,
      // },
    },
  },

  components: {
    Toaster,
  },
  created() {
    this.getFiltersDropdowns();
  },
  methods: {
    getFiltersDropdowns() {
      this.getDropDownValues("CmsCategory");
      this.getDropDownValues("ShehriType");
    },
    getDropDownValues(id) {
      this.isLoading = true;
      axios
        .post("/KeyValuePair/GetDropDownListByIds", {
          type: id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            console.log(res);
            let options = res.data.data.map((x) => {
              return {
                text: x.value,
                value: x.key,
              };
            });
            if (id == "ShehriType") {
              this.shehriTypeOptions = options;
            } else if (id == "CmsCategory") {
              this.cmsCategoryOptions = options;
            }
          } else {
            return null;
          }
        });
    },
    AddStory() {
      this.isLoading = true;

      if (this.requestModel.videoOrImage) {
        var fileSize = 209715;
        if (this.requestModel.videoOrImage.size > fileSize) {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "File Size Limit is 200 KB"
          );
          this.requestModel.videoOrImage = null;
          return false;
        }
      }

      const formData = new FormData();
      console.log(this.requestModel);

      formData.append("CategoryTitle", this.requestModel.descriptionTitle);
      formData.append("DescriptionTitle", this.requestModel.descriptionTitle);
      if(this.type==="stories"){
        formData.append("CategoryId", this.requestModel.category);
      }
      
      // formData.append("TypeId", this.requestModel.type);
      formData.append("PostType", this.requestModel.postType);
      formData.append("LongDescription", this.requestModel.categoryDescription);
      formData.append("ShortDescription", this.requestModel.shortDescription);
      formData.append("expiryDate", this.requestModel.expiryDate);
      formData.append("urducategoryDescription", this.requestModel.urducategoryDescription);
      formData.append("urdushortDescription", this.requestModel.urdushortDescription);
      formData.append("urdudescriptionTitle", this.requestModel.urdudescriptionTitle);
      
      formData.append(
        "CategoryDescription",
        this.requestModel.categoryDescription
      );

      // formData.append("IsImageUpdated", this.IsImageUpdated);
      if (this.requestModel.videoOrImage) {
        formData.append("VideoOrImage", this.requestModel.videoOrImage);
      }
      if (this.requestModel.youtubeLink) {
        formData.append("YoutubeLink", this.requestModel.youtubeLink);
      }

      axios.post("/Shehri/AddPost", formData).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          this.$parent.getPage(1);
          this.$bvModal.hide("AddStoryModal");
          this.resetModal();
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Story has been added successfully"
          );
        } else {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "unable to add story"
          );
        }
      });
    },

    checkFormValidity() {
      const valid = this.$refs.AddStoryForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      this.requestModel.youtubeLink = null;
      this.requestModel.videoOrImage = null;
      this.youtubeDisabled = false;
      this.imageDisbaled = false;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.$v.$touch();

      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }
        if(this.requestModel.category===null && this.type==="stories"){
         this.$refs.toaster.showToast(
            "Error",
            "danger",
            "Please add Category"
          );
          return;
      }

      this.AddStory();
    },
  },
};
</script>
