  <template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="EditEmailTemplateModal"
      ref="modal"
      title="Edit Email Template"
      ok-title="Submit"
      cancel-title="Close"
      @hidden="resetModal()"
      @show="updateModel()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.EditEmailTemplateForm != undefined
          ? !this.$refs.EditEmailTemplateForm.checkValidity()
          : true
      "
    >
      <form ref="EditEmailTemplateForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-12">
            <!--NAME INPUT-->
            <b-form-group label="Subject" label-for="Subject" autocomplete="off">
              <b-form-input
                id="Subject"
                v-model="jobRequestToEdit.subject"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-12">
             <b-form-group
                label=""
                label-for="Body"
                autocomplete="off"
              >
                <b-form-textarea
                  id="body"
                  v-model="jobRequestToEdit.body"
                  rows="2"
                 
                  max-rows="6"
                  required
                ></b-form-textarea>
              </b-form-group>
          </div>
         
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

      <script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
export default {
  name: "EditPackage",
  data() {
    return {
      moduleName: "Edit Package",
      Title: null,
      Id: null,
      
      JobsAllowed: null,
      Price:null,
      Status: false,
      statusOptions: [
        { text: "Active", value: 1 },
        { text: "InActive", value: 0 },
      ],
    };
  },
  components: {
    Toaster,
  },

  props: {
    jobRequestToEdit: {
      type: Object,
      default: null,
    },
  },
  methods: {
    AddClient() {
      axios
        .post("/Adds/UpdateEmailTemplate", this.jobRequestToEdit)
        .then((res) => {
          console.log(res);
          if (res.data.status == 1) {
            this.$refs.toaster.showToast(
              "Sucess",
              "success",
              "Email Template has been updated successfully"
            );
            this.$bvModal.hide("EditEmailTemplateModal");
            this.$parent.getPage();
          } else if (res.data.status == -1) {
            this.$refs.toaster.showToast("Error", "danger", res.data.msg);
          }
        })
        .catch((err) => {
          console.warn("error during http call", err);
        });
    },

    checkFormValidity() {
      const valid = this.$refs.EditEmailTemplateForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      this.Address = null;
      this.PhoneNumber = null;
      this.Name = null;
    },
    updateModel() {
     console.log(this.jobRequestToEdit)
      this.Id = this.jobRequestToEdit.Id
        this.Title = this.jobRequestToEdit.Title
        this.JobsAllowed = this.jobRequestToEdit.JobsAllowed
        this.Price = this.jobRequestToEdit.Price
        this.Status = this.jobRequestToEdit.Status
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.AddClient();
    },
  },
};
</script>
