<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="AddFaqModal"
      ref="modal"
      title="Add FAQ"
      ok-title="Submit"
      cancel-title="Close"
      @show="resetModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.AddFaqForm != undefined
          ? !this.$refs.AddFaqForm.checkValidity()
          : true
      "
    >
      <form ref="AddFaqForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <!--NAME INPUT-->
            <b-form-group
              label="Question"
              label-for="question"
              autocomplete="off"
            >
              <b-form-input
                id="question"
                v-model="requestModel.question"
                :class="{'is-invalid':submitted && $v.requestModel.question.$error}"
                type="text"
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.question.required"
                class="invalid-feedback"
              >
                Question is required
              </div>
              <div
                v-if="!$v.requestModel.question.minLength"
                class="invalid-feedback"
              >
                Question must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.question.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <!--NAME INPUT-->
            <b-form-group
              label="Category"
              label-for="faqCategory"
              autocomplete="off"
            >
              <b-form-select
                id="faqCategory"
                v-model="requestModel.faqCategory"
                 :class="{'is-invalid':submitted && $v.requestModel.faqCategory.$error}"
                :options="categoryOptions"
                required
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select Category</b-form-select-option
                >6
              </b-form-select>
              <div
                v-if="submitted && !$v.requestModel.faqCategory.required"
                class="invalid-feedback"
              >
                Category is required
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Answer" label-for="answer" autocomplete="off">
              <ckeditor
                v-model="requestModel.answer"
                :class="{'is-invalid':submitted && $v.requestModel.answer.$error}"></ckeditor>
              <!-- <b-textarea
                id="answer"
                v-model="requestModel.answer"
                :class="{'is-invalid':submitted && $v.requestModel.answer.$error}"
                required
              ></b-textarea> -->
              <div
                v-if="submitted && !$v.requestModel.answer.required"
                class="invalid-feedback"
              >
                Answer is required
              </div>
              <div
                v-if="!$v.requestModel.answer.minLength"
                class="invalid-feedback"
              >
                Answer must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.answer.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "AddFAQ",
  data() {
    return {
      moduleName: "Add FAQ",
      requestModel: {
        question: null,
        answer: null,
        faqCategory: null,
      },
      submitted: false,
      categoryOptions: [
        { text: "Course", value: 1 },
        { text: "MentorShipPrograms", value: 2 },
        { text: "BecomeThePartner", value: 3 },
      ],
    };
  },
 validations: {
    requestModel: {
      question:{
        required,
        minLength: minLength(3),
        maxLength: maxLength(350),
      },
      answer:{
        required,
        minLength: minLength(3),
        maxLength: maxLength(1000),
      },
      faqCategory: {
        required,
      },
    },
  },

  components: {
    Toaster,
  },
  // getDropdowns() {
  //   this.getCourseCategories();
  // },
  // getFaqCategories() {
  //   axios.get("/V_Learn/GetCourseCategory").then((res) => {
  //     this.isLoading = false;
  //     if (res.data.status == "Success") {
  //       let options = res.data.data.map((x) => {
  //         return {
  //           text: x.courseCategoryName,
  //           value: x.courseCategoryId,
  //         };
  //       });
  //       this.categoryOptions = options;
  //     }
  //   });
  // },
  methods: {
    AddFaq() {
      this.isLoading = true;
      axios.post("/v_learn/AddFaq", this.requestModel).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          this.$parent.getPage(1);
          this.$bvModal.hide("AddFaqModal");
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "FAQ has been added successfully"
          );
        } else {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        }
      });
    },

    checkFormValidity() {
      const valid = this.$refs.AddFaqForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {},
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }
      this.AddFaq();
    },
  },
};
</script>
