var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"modal",staticStyle:{"padding":"15px"},attrs:{"size":"lg","id":"AddCompanyModal","title":"Add Company","ok-title":"Submit","cancel-title":"Close","ok-disabled":this.$refs.AddCompanyForm != undefined
        ? !this.$refs.AddCompanyForm.checkValidity()
        : true},on:{"show":function($event){return _vm.resetModal()},"hidden":function($event){return _vm.resetModal()},"ok":_vm.AddCompany}},[_c('form',{ref:"AddCompanyForm",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.AddCompany.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name","autocomplete":"off"}},[_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.requestModel.companyName.$error,
              },attrs:{"id":"name","type":"text","required":""},model:{value:(_vm.requestModel.companyName),callback:function ($$v) {_vm.$set(_vm.requestModel, "companyName", $$v)},expression:"requestModel.companyName"}}),(_vm.submitted && !_vm.$v.requestModel.companyName.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Company name is required ")]):_vm._e(),(!_vm.$v.requestModel.companyName.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Company name must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.requestModel.companyName.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 100 characters is reached ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Owner","label-for":"owner","autocomplete":"off"}},[_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.requestModel.name.$error,
              },attrs:{"id":"owner","type":"text","required":""},model:{value:(_vm.requestModel.name),callback:function ($$v) {_vm.$set(_vm.requestModel, "name", $$v)},expression:"requestModel.name"}}),(_vm.submitted && !_vm.$v.requestModel.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Owner name is required ")]):_vm._e(),(!_vm.$v.requestModel.name.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Owner name must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.requestModel.name.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 100 characters is reached ")]):_vm._e()],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"City","label-for":"Cities","autocomplete":"off"}},[_c('b-form-select',{class:{
                'is-invalid': _vm.submitted && _vm.$v.requestModel.cityId.$error,
              },attrs:{"id":"Cities","required":""},model:{value:(_vm.requestModel.cityId),callback:function ($$v) {_vm.$set(_vm.requestModel, "cityId", $$v)},expression:"requestModel.cityId"}},[_c('b-form-select-option',{attrs:{"value":null,"disabled":"","hidden":""}},[_vm._v("Select City")]),_vm._l((_vm.cities),function(option){return _c('option',{key:option.key,domProps:{"value":option.key}},[_vm._v(" "+_vm._s(option.value)+" ")])})],2),(_vm.submitted && !_vm.$v.requestModel.cityId.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" City is required ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Category","label-for":"companyCategory","autocomplete":"off"}},[_c('b-form-select',{attrs:{"id":"companyCategory","options":_vm.companyCategoryOptions,"required":""},model:{value:(_vm.requestModel.companyCategory),callback:function ($$v) {_vm.$set(_vm.requestModel, "companyCategory", $$v)},expression:"requestModel.companyCategory"}},[_c('b-form-select-option',{attrs:{"value":null,"disabled":"","hidden":""}},[_vm._v("Select Company Category")])],1)],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Website","label-for":"website","autocomplete":"off"}},[_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.requestModel.website.$error,
              },attrs:{"id":"website","type":"text","required":""},model:{value:(_vm.requestModel.website),callback:function ($$v) {_vm.$set(_vm.requestModel, "website", $$v)},expression:"requestModel.website"}}),(_vm.submitted && !_vm.$v.requestModel.website.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Webiste is required ")]):_vm._e(),(!_vm.$v.requestModel.website.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Webiste must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.requestModel.website.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 100 characters is reached ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email","autocomplete":"off"}},[_c('b-form-input',{attrs:{"id":"email","type":"email","required":""},model:{value:(_vm.requestModel.email),callback:function ($$v) {_vm.$set(_vm.requestModel, "email", $$v)},expression:"requestModel.email"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Address","label-for":"adress","autocomplete":"off"}},[_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.requestModel.address.$error,
              },attrs:{"id":"adress","type":"text","required":""},model:{value:(_vm.requestModel.address),callback:function ($$v) {_vm.$set(_vm.requestModel, "address", $$v)},expression:"requestModel.address"}}),(_vm.submitted && !_vm.$v.requestModel.address.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Address is required ")]):_vm._e(),(!_vm.$v.requestModel.address.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Address must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.requestModel.address.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 1000 characters is reached ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Expiry","label-for":"datepicker-1","autocomplete":"off"}},[_c('b-form-input',{attrs:{"type":"date"},model:{value:(_vm.requestModel.expireDate),callback:function ($$v) {_vm.$set(_vm.requestModel, "expireDate", $$v)},expression:"requestModel.expireDate"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Short Description","label-for":"description","autocomplete":"off"}},[_c('b-form-input',{class:{
                'is-invalid':
                  _vm.submitted && _vm.$v.requestModel.shortDescription.$error,
              },attrs:{"id":"description","type":"text","required":""},model:{value:(_vm.requestModel.shortDescription),callback:function ($$v) {_vm.$set(_vm.requestModel, "shortDescription", $$v)},expression:"requestModel.shortDescription"}}),(_vm.submitted && !_vm.$v.requestModel.shortDescription.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Short Description is required ")]):_vm._e(),(!_vm.$v.requestModel.shortDescription.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Short Description must contain at least 3 characters ")]):_vm._e(),(!_vm.$v.requestModel.shortDescription.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The maximum length of 1000 characters is reached ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Contact No","label-for":"contactno","autocomplete":"off"}},[_c('vue-tel-input',_vm._b({model:{value:(_vm.requestModel.contactNo),callback:function ($$v) {_vm.$set(_vm.requestModel, "contactNo", $$v)},expression:"requestModel.contactNo"}},'vue-tel-input',_vm.vueTellProps,false))],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Google Map","label-for":"googlemap","autocomplete":"off"}},[_c('b-textarea',{class:{
                'is-invalid': _vm.submitted && _vm.$v.requestModel.googleMap.$error,
              },attrs:{"id":"googlemap","placeholder":"<iframe src=\"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3321.3173064911507!2d72.99695511459036!3d33.64893924632387!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df95c7a1a3cbe5%3A0x156312b15ca23972!2sNUST%20Gate%201!5e0!3m2!1sen!2s!4v1644836312617!5m2!1sen!2s\" width=\"600\" height=\"450\" style=\"border:0;\" allowfullscreen=\"\" loading=\"lazy\"></iframe>","type":"text","rows":"3","required":""},model:{value:(_vm.requestModel.googleMap),callback:function ($$v) {_vm.$set(_vm.requestModel, "googleMap", $$v)},expression:"requestModel.googleMap"}}),(_vm.submitted && !_vm.$v.requestModel.googleMap.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Google map is required ")]):_vm._e()],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Logo","label-for":"companyLogo","autocomplete":"off"}},[_c('b-form-file',{class:{
                'is-invalid': _vm.submitted && _vm.$v.requestModel.companyLogo.$error,
              },attrs:{"accept":"image/jpeg, image/png, image/gif, image/webp","state":Boolean(_vm.requestModel.companyLogo),"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.requestModel.companyLogo),callback:function ($$v) {_vm.$set(_vm.requestModel, "companyLogo", $$v)},expression:"requestModel.companyLogo"}}),(_vm.submitted && !_vm.$v.requestModel.companyLogo.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Company Logo is required ")]):_vm._e()],1)],1)])])]),_c('Toaster',{ref:"toaster"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }