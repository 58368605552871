  <template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="EditFaqModal"
      ref="modal"
      title="Edit FAQ"
      ok-title="Submit"
      cancel-title="Close"
      @show="show()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.EditFaqForm != undefined
          ? !this.$refs.EditFaqForm.checkValidity()
          : true
      "
    >
      <form
        ref="EditFaqForm"
        @submit.stop.prevent="handleSubmit"
        v-if="requestModel != null"
      >
       <div class="row">
         <p class="text-danger"><b>Note:</b> It will not get submitted unless there's some change</p>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!--NAME INPUT-->
            <b-form-group
              label="Question"
              label-for="question"
              autocomplete="off"
            >
              <b-form-input
                id="question"
                v-model="requestModel.question"
                :class="{'is-invalid':submitted && $v.requestModel.question.$error}"
                type="text"
                required
              ></b-form-input>
               <div
                v-if="submitted && !$v.requestModel.question.required"
                class="invalid-feedback"
              >
                Question is required
              </div>
              <div
                v-if="!$v.requestModel.question.minLength"
                class="invalid-feedback"
              >
                Question must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.question.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <!--NAME INPUT-->
            <b-form-group
              label="Category"
              label-for="faqCategory"
              autocomplete="off"
            >
              <b-form-select
                id="faqCategory"
                v-model="requestModel.faqCategory"
                 :class="{'is-invalid':submitted && $v.requestModel.faqCategory.$error}"
                :options="categoryOptions"
                required
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select Category</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Answer" label-for="answer" autocomplete="off">
              <ckeditor
               v-model="requestModel.answer"
              :class="{'is-invalid':submitted && $v.requestModel.answer.$error}"></ckeditor>
              <!-- <b-textarea
                id="answer"
                v-model="requestModel.answer"
                :class="{'is-invalid':submitted && $v.requestModel.answer.$error}"
                required
              ></b-textarea> -->

               <div
                v-if="submitted && !$v.requestModel.answer.required"
                class="invalid-feedback"
              >
                Answer is required
              </div>
              <div
                v-if="!$v.requestModel.answer.minLength"
                class="invalid-feedback"
              >
                Answer must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.answer.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

      <script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "EditFAQ",
  data() {
    return {
      moduleName: "Edit FAQ",
      requestModel: {
        question: null,
        answer: null,
        faqId: null,
        faqCategory: null,
      },
      categoryOptions: [
        { text: "Course", value: 1 },
        { text: "MentorShipPrograms", value: 2 },
        { text: "BecomeThePartner", value: 3 },
      ],
       submitted: false,
    };
  },

  validations: {
    requestModel: {
      question: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(350),
      },
      answer: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(1000),
      },
      faqCategory: {
        required,
        
      },
    },
  },


  components: {
    Toaster,
  },
  props: {
    faqToEdit: {
      type: Object,
      default: null,
    },
  },
  // getDropdowns() {
  //   this.getCourseCategories();
  // },
  // getFaqCategories() {
  //   axios.get("/V_Learn/GetCourseCategory").then((res) => {
  //     this.isLoading = false;
  //     if (res.data.status == "Success") {
  //       let options = res.data.data.map((x) => {
  //         return {
  //           text: x.courseCategoryName,
  //           value: x.courseCategoryId,
  //         };
  //       });
  //       this.categoryOptions = options;
  //     }
  //   });
  // },
  methods: {
    EditFaq() {
      this.isLoading = true;
      axios.post("/v_learn/UpdateFaq", this.requestModel).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          this.$parent.getPage(1);
          this.$bvModal.hide("EditFaqModal");
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "FAQ has been edited successfully"
          );
        } else {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        }
      });
    },

    checkFormValidity() {
      const valid = this.$refs.EditFaqForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    show() {
      this.requestModel = JSON.parse(JSON.stringify(this.faqToEdit)) ;
      let catText = this.requestModel.faqCategory;
      let category = this.categoryOptions.find((x) => x.text == catText);
      this.requestModel.faqCategory = category != null ? category.value : null;
    },
    resetModal() {},
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
        this.$v.$touch();

      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }
      this.EditFaq();
    },
  },
};
</script>
