 <template>
  <div />
</template>
 <script>
export default {
  name: "Toaster",
  data() {
    return {
      moduleName: "Toaster",
    };
  },
  methods: {
    showToast(title, variant, msg) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        autoHideDelay: 5000,
      });
    },
  },
};
</script>
