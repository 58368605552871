<template>
    <div>
        <div class="container-fluid main-content">
            <div class="main-panel">
                <div :class="'content-wrapper ' + paddingClass">
                    <div class = "inner-div" >
                        <router-view/>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Content',

props:
{
    paddingClass:{
        Type:String,
        default:null
    }
}
}
</script>