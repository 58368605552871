<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="feedbacksModal"
      ref="modal"
      title="feedbacks"
      ok-title="Submit"
      cancel-title="Close"
      @show="updateModel()"
      @hidden="resetModal()"
      @ok="handleOk"

    >

    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";

export default {
  name: "UpdateQuestion",
  data() {
    return {
      moduleName: "Edit Question",
      siteUrl: "",
      attachedFile: null,
        requestModel: {
            id: 0,
            QuestionValue: null,
            isemployeer: false,
        },
       questionslist:[],
       Answerlist:[],
      submitted: true,
    };
  },



   watch: {
    attachedFile() {
      // console.log(newValue);

    },
  },
  components: {
    Toaster,
  },

  props: {
    recordToEdit: {
      type: Object,
      default: null,
    },
  },

  methods: {
    getelemet(id) {
      this.isLoading = true;


      axios.post("/Employee/GetAnswers", id).then((res) => {
        this.isLoading = false;

       this.questionslist = res.data.data.data;
       this.Answerlist = res.data.data.data1;
       this.questionslist.concat(this.Answerlist);
      });

      this.$bvModal.hide("EditModal");
    },

    checkFormValidity() {
      const valid = true;
      // this.usernameState = valid
      return valid;
    },
    updateModel() {
      this.requestModel = JSON.parse(JSON.stringify(this.recordToEdit));
   
      this.siteUrl = "https://smw-live.azurewebsites.net";
      this.id = this.recordToEdit.id;
     
    },
    resetModal() {

    },
    handleOk(bvModalEvt) {
      // Prrvent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
       this.submitted = true;
      // Exit when the form isn't valid
     
      this.Update();
    },
  },
  beforeMount(){
    this.getelemet(this.id)
 },
};
</script>
