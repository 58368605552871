<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="xl"
      id="AddVenturePartnerModal"
      ref="modal"
      :title="title"
      ok-title="Submit"
      cancel-title="Close"
      @show="resetModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.AddForm != undefined
          ? !this.$refs.AddForm.checkValidity()
          : true
      "
    >
      <form ref="AddForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="Title" label-for="Title" autocomplete="off">
              <b-form-input
                id="ventureTitle"
                v-model="requestModel.title"
                :class="{
                  'is-invalid': submitted && $v.requestModel.title.$error,
                }"
                type="text"
                required
              ></b-form-input>
              <div
                v-if="submitted && !$v.requestModel.title.required"
                class="invalid-feedback"
              >
                Title is required
              </div>
              <div
                v-if="!$v.requestModel.title.minLength"
                class="invalid-feedback"
              >
                Title must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.title.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6" style="direction: rtl;">
            <b-form-group label="Urdu Title" label-for="Title" autocomplete="off">
              <b-form-input
                id="ventureTitle"
                v-model="requestModel.urdutitle"
                :class="{
                  'is-invalid': submitted && $v.requestModel.urdutitle.$error,
                }"
                type="text"
                required
              ></b-form-input>
              <div
                v-if="submitted && !$v.requestModel.urdutitle.required"
                class="invalid-feedback"
              >
                Title is required
              </div>
              <div
                v-if="!$v.requestModel.urdutitle.minLength"
                class="invalid-feedback"
              >
                Title must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.urdutitle.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 350 characters is reached
              </div>
            </b-form-group>
          </div>
         
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Type" label-for="Type" autocomplete="off">
              <b-form-select v-if="IsWhyVenture"
                id="Type"
                v-model="requestModel.type"
                :options="typeOptions"
                :disabled="IsWhyVenture"
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select Course Type</b-form-select-option
                >
              </b-form-select>

              <b-form-select v-else-if="IsVenturePartner"
                id="Type"
                v-model="requestModel.type"
                :options="typeOptionsPartner"
                :disabled="IsWhyVenture"
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select Course Type</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Short Description"
              label-for="description"
              autocomplete="off"
            >
              <b-form-input
                id="description"
                v-model="requestModel.description"
                :class="{
                  'is-invalid': submitted && $v.requestModel.description.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.description.required"
                class="invalid-feedback"
              >
                Description is required
              </div>
              <div
                v-if="!$v.requestModel.description.minLength"
                class="invalid-feedback"
              >
                Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.description.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" style="direction: rtl;">
            <b-form-group
              label="Urdu Short Description"
              label-for="description"
              autocomplete="off"
            >
              <b-form-input
                id="description"
                v-model="requestModel.urdudescription"
                :class="{
                  'is-invalid': submitted && $v.requestModel.urdudescription.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.urdudescription.required"
                class="invalid-feedback"
              >
                Description is required
              </div>
              <div
                v-if="!$v.requestModel.urdudescription.minLength"
                class="invalid-feedback"
              >
                Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.urdudescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Long Description"
              label-for="longDescription"
              autocomplete="off"
            >
              <!-- <b-textarea
                id="longDescription"
                v-model="requestModel.longDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.longDescription.$error,
                }"
                type="text"
                required
              ></b-textarea> -->

              <ckeditor v-model="requestModel.longDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.longDescription.$error,
                }"></ckeditor>

              <div
                v-if="submitted && !$v.requestModel.longDescription.required"
                class="invalid-feedback"
              >
                Long Description is required
              </div>
              <div
                v-if="!$v.requestModel.longDescription.minLength"
                class="invalid-feedback"
              >
                Long Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.longDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 5000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" >
            <b-form-group
              label="Urdu Long Description"
              label-for="longDescription"
              autocomplete="off"
            >
              <!-- <b-textarea
                id="longDescription"
                v-model="requestModel.longDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.longDescription.$error,
                }"
                type="text"
                required
              ></b-textarea> -->

              <ckeditor style="direction: rtl;" v-model="requestModel.urdulongDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.urdulongDescription.$error,
                }"></ckeditor>

              <div
                v-if="submitted && !$v.requestModel.urdulongDescription.required"
                class="invalid-feedback"
              >
                Long Description is required
              </div>
              <div
                v-if="!$v.requestModel.urdulongDescription.minLength"
                class="invalid-feedback"
              >
                Long Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.urdulongDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 5000 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Cover" label-for="image" autocomplete="off">
              <b-form-file
                accept="image/jpeg, image/png, image/gif, image/webp"
                @change="onSelectImage"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                :class="{
                  'is-invalid': submitted && $v.requestModel.image.$error,
                }"
              ></b-form-file>

              <div
                v-if="submitted && !$v.requestModel.image.required"
                class="invalid-feedback"
              >
                Image is required
              </div>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "AddVenturePartner",
  data() {
    return {
      moduleName: "Add Venture",
      file1: null,
      requestModel: {
        title: null,
        urdutitle:null,
        type: 1,
        description: null,
        urdudescription:null,
        longDescription: null,
        urdulongDescription: null,
        image: null,
      },
      submitted: false,
      // courseTypeOptions: null,
      courseCategoryOptions: null,
      typeOptions: [
        { text: "Business Services and Partners", value: 1 },
        { text: "Investment and Funding", value: 2 },
        { text: "Why Venture Her", value: 3 }
      ],
       typeOptionsPartner: [
        { text: "Business Services and Partners", value: 1 },
        { text: "Investment and Funding", value: 2 },
        { text: "Advocates", value: 4 }
      ],
    };
  },
  props: {
    IsWhyVenture: {
      type: Boolean,
      default: false,
    },
    IsVenturePartner: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
    },
  },
  validations: {
    requestModel: {
      urdutitle:{
        required,
        minLength: minLength(3),
        maxLength: maxLength(350),
      },
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(350),
      },
      description: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(1000),
      },
      urdudescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(1000),
      },
      longDescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(5000),
      },
      urdulongDescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(5000),
      }
      
    },
  },

  components: {
    Toaster,
  },
  created() {
    this.getDropdowns();
    console.log(this.IsWhyVenture);
    if (this.IsWhyVenture) {
      this.requestModel.type = 3;
    }
  },
  methods: {
    onSelectImage(e) {
      console.log("ON SELECT CALLED");
      this.requestModel.image = null;
      this.requestModel.image = e.target.files[0];
      console.log("uploadFile : ", e.target.files[0].size);
      console.log("uploadFileName : ", this.requestModel.image.name);

      // Allowing file Size
      var fileSize = 209715;

      if (this.requestModel.image.size > fileSize) {
        // alert('File Size Limit is 200 KB');
        this.$refs.toaster.showToast(
          "Error",
          "danger",
          "File Size Limit is 200 KB"
        );
        e.target.value = "";
        this.requestModel.image = null;
        return false;
      }
    },
    getDropdowns() {
      this.getCourseCategories();
    },
    getCourseCategories() {
      axios.get("/V_Learn/GetCourseCategory").then((res) => {
        this.isLoading = false;
        if (res.data.status == "Success") {
          let options = res.data.data.map((x) => {
            return {
              text: x.courseCategoryName,
              value: x.courseCategoryId,
            };
          });
          this.courseCategoryOptions = options;
        }
      });
    },
    getDropDownValues(id) {
      this.isLoading = true;
      axios
        .post("/KeyValuePair/GetDropDownListById", {
          type: id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            console.log(res);
            let options = res.data.data.map((x) => {
              return {
                text: x.value,
                value: x.key,
              };
            });
            if (id == "ShehriType") {
              this.shehriTypeOptions = options;
            } else if (id == "CmsCategory") {
              this.cmsCategoryOptions = options;
            }
          } else {
            return null;
          }
        });
    },
    Add() {
      this.isLoading = true;
      const formData = new FormData();
      console.log(this.requestModel);
      formData.append("title", this.requestModel.title);
      formData.append("UrduTitle", this.requestModel.urdutitle);
      formData.append("type", this.requestModel.type);
      formData.append("description", this.requestModel.description);
      formData.append("UrduDescription", this.requestModel.urdudescription);
      formData.append("longDescription", this.requestModel.longDescription);
      formData.append("UrduLongDescription", this.requestModel.urdulongDescription);
      formData.append("image", this.requestModel.image);

      console.log(formData);

      axios.post("/Shehri/AddVenturePartner", formData).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          this.$parent.getPage(1);
          this.$bvModal.hide("AddVenturePartnerModal");
          console.log(res);
          if (this.IsWhyVenture) {
            this.$refs.toaster.showToast(
              "Sucess",
              "success",
              "Why Venture Her has been added successfully"
            );
          } else if (this.IsVenturePartner) {
            this.$refs.toaster.showToast(
              "Sucess",
              "success",
              "Venture Partner has been added successfully"
            );
          }
        } else {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "Unable to add venture"
          );
        }
      });
    },

    checkFormValidity() {
      const valid = this.$refs.AddForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {},
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },

    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }
      this.Add();
    },
  },
};
</script>
