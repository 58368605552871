<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="EditBannerModal"
      ref="modal"
      title="Edit Ad"
      ok-title="Submit"
      cancel-title="Close"
      @show="updateModel()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.EditBannerForm != undefined
          ? !this.$refs.EditBannerForm.checkValidity()
          : true
      "
    >
      <form ref="EditBannerForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
         <p class="text-danger"><b>Note:</b> It will not get submitted unless there's some change</p>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Link" label-for="Link" autocomplete="off">
              <b-form-input
                id="Link"
                v-model="bannerToEdit.bannerUrl"
                type="url"
              ></b-form-input>
              <div
                v-if="submitted && !$v.bannerToEdit.bannerUrl.required"
                class="invalid-feedback"
              >
                Banner URL is required!
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Banner" label-for="Address" autocomplete="off">
              <b-form-file
                accept="image/jpeg, image/png, image/gif"
                v-model="file1"
                :state="Boolean(file1)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-8 image-with-border">
            <zoom-on-hover
              :img-normal="this.siteUrl + this.imageUrl"
            ></zoom-on-hover>
          </div>
          <div class="col-md-2"></div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
import Toaster from "../../../common/pages/toaster.vue";
import { required } from "vuelidate/lib/validators";

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  name: "EditBanner",
  data() {
    return {
      moduleName: "Edit Banner",
      file1: null,
      siteUrl: "",
      IsImageUpdated: false,
      imageUrl: "",
      submitted: false,
    };
  },
  validations: {
    bannerToEdit: {
      bannerUrl: {
        required,
      },
    },
  },
  components: {
    Toaster,
  },
  props: {
    bannerToEdit: {
      type: Object,
      default: null,
    },
  },
  watch: {
    file1(newValue, oldValue) {
      this.IsImageUpdated = true;
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.siteUrl = "";
              this.imageUrl = value;
            })
            .catch(() => {
              this.imageUrl = null;
            });
        } else {
          this.imageUrl = null;
        }
      }
    },
  },
  methods: {
    EditBanner() {
      console.log(this.bannerToEdit);
      // this.checkImageSize();

      const formData = new FormData();

      formData.append("AddsId", this.bannerToEdit.addsId);
      formData.append("BannerUrl", this.bannerToEdit.bannerUrl);
      formData.append("Title", this.bannerToEdit.title);
      formData.append("IsImageUpdated", this.IsImageUpdated);
       if (this.siteUrl === "") {
         formData.append("AddImage", this.file1);
      } else {
         formData.append("AddImage", this.bannerToEdit.addImage);
      }
      console.log(formData);
      this.isLoading = true;
      axios.post("/Adds/UpdateAdds", formData).then((res) => {
        this.isLoading = false;
        if (res.data.status == "Success") {
          this.$refs.toaster.showToast("Success", "success", res.data.msg);
          this.$parent.getPage(1);
          this.$bvModal.hide("EditBannerModal");
        } else {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        }
      });
    },

    checkImageSize() {
      if (this.file1) {
        var fileSize = 209715;
        if (this.file1.size > fileSize) {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "File Size Limit is 200 KB"
          );
          this.file1 = null;
          return false;
        }
      }
    },

    checkFormValidity() {
      const valid = this.$refs.EditBannerForm.checkValidity();
      // if (this.file1 == null) {
      //   this.$refs.toaster.showToast(
      //     "Invalid Data",
      //     "danger",
      //     "Please select a file"
      //   );
      //   return;
      // }

      // this.usernameState = valid
      return valid;
    },
    updateModel() {
      this.file1 = null;
      // this.siteUrl = "https://smw.demo.itrooscloud.net/";
      this.siteUrl = "https://smw-live.azurewebsites.net/";
      this.imageUrl = this.bannerToEdit.addImage;
    },
    resetModal() {},
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.$v.$touch();

      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }

      this.EditBanner();
    },
  },
};
</script>
