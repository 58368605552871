<template>
  <sidebar-menu
    :menu="menu"
    @toggle-collapse="onToggleCollapse"
    :collapsed="collapsed"
    theme="white-theme"
    width="224px"
  />
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      dropdowns: [],

      menu: [
        {
          header: true,
          title: "Main Navigation",
          hiddenOnCollapse: true,
        },

        {
          href: "/dashboard",
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
          // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
        },
        {
          href: "/companies",
          title: "Companies",
          icon: "fa fa-briefcase",
          // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
        },
        {
          href: "/users",
          title: "Users",
          icon: "fa fa-user",
          // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
        },
        {
          href: "/candidates",
          title: "Candidates",
          icon: "fa fa-user",
          // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
        },
        {
          href: "/packages",
          title: "Packages",
          icon: "fas fa-briefcase",
          // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
        },
        {
          href: "/adds-management",
          title: "Ads Management",
          icon: "fas fa-flag",
          // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
        },
        {
          href: "/brands-management",
          title: "Brands Management",
          icon: "fas fa-flag",
          // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
        },
        // {
        //   href: "/email-template-management",
        //   title: "Email Templates",
        //   icon: "fas fa-envelope",
        //   // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
        // },
        {
          title: "Dropdowns",
          icon: "fas fa-cog",
          child: this.dropdowns,
          // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
        },
        {
          title: "Feedback",
          icon: "fas fa-briefcase",
          child: [
            {
              href: "/feedbacks",
              title: "Questions",
            },
            {
              href: "/feedbacksmw",
              title: "SMW Feedback",
            },
          ],
          // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
        },
        {
          title: "Jobs",
          icon: "fas fa-briefcase",
          child: [
            {
              href: "/jobs-requests",
              title: "Jobs Requests",
            },
            {
              href: "/jobs-posted",
              title: "Posted Jobs",
            },
            {
              href: "/job-applicants",
              title: "Applicants",
            },
          ],
          // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
        },
        {
          title: "Harshehar",
          icon: "fas fa-folder-open",
          child: [
            {
              href: "/stories",
              title: "Stories",
              // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
            },
             {
              href: "/stories/newsofday",
              title: "Updates",
              // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
            },
            {
              href: "/directory-companies",
              title: "Directory",
              // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
            },
            {
              href: "/UpcomingEvents",
              title: "UpcomingEvents",
              // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
            },
          ],
        },
        {
          title: "V-Learn",
          icon: "fas fa-chalkboard-teacher",
          child: [
              {
              href: "/courses",
              title: "Courses",
              // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
            },
             /*{
              href: "/viilearn/education-for-everyone",
              title: "Education for Everyone",
              // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
            },*/
            {
              href: "/viilearn/knowledge-training-for-everyone",
              title: "Knowledge & Training",
              // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
            },
            {
              href: "/viilearn/our-partners-services-for-corporates",
              title: "Partners & Services",
              // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
            },
            {
              href: "/viilearn/skill-her",
              title: "Skill Her",
              // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
            },
            {
              href: "/viilearn/spare-a-smile",
              title: "Spare a Smile",
              // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
            },
            {
              href: "/viilearn/be-a-mentor",
              title: "Be a Mentor",
              // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
            },
             {
              href: "/viilearn/our-programs",
              title: "Our Programs",
              // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
            },
             {
              href: "/viilearn/our-mission",
              title: "Our Mission ",
              // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
            },
            {
              href: "/viilearn/our-mentor-sessions",
              title: "Our Mentor Sessions",
              // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
            },
            {
              href: "/videos-and-webinars",
              title: "Videos and Webinars"
            }
          ],
        },
        {
          title: "Venture(Her)",
          icon: "fas fa-handshake",
          child: [
            {
              href: "/ventures",
              title: "Ventures",
              // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
            },
             {
              href: "/why-venture-her",
              title: "Why Venture Her",
              // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
            },
            {
              href: "/venture-packages",
              title: "Packages",
              // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
            },
             {
              href: "/venture-partners",
              title: "Partners",
              // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
            },
            {
              href: "/venture-services",
              title: "Services",
              // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
            },

          ],
        },
        {
          href: "/testimonials",
          title: "Testimonials",
          icon: "fa fa-comment",
          // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
        },
         {
          href: "/events",
          title: "Events",
          icon: "fa fa-calendar-week",
          // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
        },
        // {
        //   href: "/vouchers",
        //   title: "Vouchers",
        //   icon: "fas fa-money-bill-wave",
        //   // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
        // },
        // {
        //   href: "/webinars",
        //   title: "Webinars",
        //   icon: "fas fa-handshake",
        //   // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
        // },

        // {
        //   href: "/events",
        //   title: "Events",
        //   icon: "far fa-calendar-alt",
        //   // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
        // },

        // {
        //   href: "/franchise",
        //   title: "Franchise",
        //   icon: "fas fa-sitemap",
        //   // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
        // },
        // {
        //   href: "/testimonials",
        //   title: "Testimonials",
        //   icon: "fas fa-thumbs-up",
        //   // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
        // },
        {
          href: "/FAQ",
          title: "FAQ",
          icon: "fas fa-reply-all",
          // hidden: this.$auth.check(["Admin","Manager"]) ? false:true
        },
      ],
    };
  },
  computed: {},
  methods: {
    onToggleCollapse(collapse) {
      this.$emit("sideBarToggled", collapse);
    },
  },
  created() {
    // let model = {
    //   UserId: this.$auth.user().id,
    // };
    axios
      .post("/KeyValuePair/GetDropDownNameList")
      .then((res) => {
        console.log(res);
        if (res.data.status == "Success") {
          console.log(res.data);
          this.dropdowns = res.data.data.map((x) => {
            return {
              href: "/dropdowns" + "/" + x,
              title: x,
            };
          });
          let dropDownsIndex = this.menu.findIndex(
            (x) => x.title == "Dropdowns"
          );

          var filteredDropDown = this.dropdowns.filter(function(dropdown){
            return (dropdown.title!="ShehriType" && dropdown.title!="CauseOfDisability");
          });

          this.dropdowns = filteredDropDown;

          if (dropDownsIndex != -1)
            this.menu[dropDownsIndex].child = this.dropdowns;
          console.log(this.dropdowns);

          // let roleFlag = this.$auth.check(["Admin","Manager"]) ? false:true;
          // let routeRecord ={
          //   href: 'dashboard',
          //   title: 'Dashboard',
          //   icon: 'fa fa-tachometer-alt',
          //   hidden: roleFlag
          // }
          // this.userMenu.push(routeRecord);
          // console.log(res.data)
        } else {
          this.$bvModal.msgBoxOk("Error! " + res.data.msg, {
            title: "Confirmation",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            headerClass: "p-10 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          });
        }
      })
      .catch((err) => {
        console.warn("error during http call", err);
      });
  },

  props: {
    // Sidebar Collapse state
    collapsed: {
      type: Boolean,
      default: false,
    },

    // Sidebar width (expanded)
    width: {
      type: String,
      default: "350px",
    },

    // Sidebar width (collapsed)
    widthCollapsed: {
      type: String,
      default: "50px",
    },

    // Keep only one child opened at a time (first level only)
    showOneChild: {
      type: Boolean,
      default: false,
    },

    // Keep all child open
    showChild: {
      type: Boolean,
      default: false,
    },

    // Sidebar right to left
    rtl: {
      type: Boolean,
      default: false,
    },

    // Make sidebar relative to the parent (by default the sidebar is relative to the viewport)
    relative: {
      type: Boolean,
      default: false,
    },

    // Hide toggle collapse btn
    hideToggle: {
      type: Boolean,
      default: false,
    },

    // Sidebar theme (available themes: 'white-theme')
    theme: {
      type: String,
      default: "",
    },

    // Disable hover on collapse mode
    disableHover: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
