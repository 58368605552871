<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="AddQuestionModal"
      ref="modal"
      title="Add Question"
      ok-title="Submit"
      cancel-title="Close"
      @show="resetModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.AddQuestionForm != undefined
          ? !this.$refs.AddQuestionForm.checkValidity()
          : true
      "
    >
      <form ref="AddQuestionForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <!--NAME INPUT-->
            <b-form-group label="Question" label-for="QuestionValue" autocomplete="off">
              <b-form-input
                id="Question"
                v-model="requestModel.QuestionValue"
                 :class="{
                  'is-invalid': submitted && $v.requestModel.QuestionValue.$error,
                }"
                type="text"
                required
              ></b-form-input>
                <div
                v-if="submitted && !$v.requestModel.QuestionValue.required"
                class="invalid-Question"
              >
              Question is required
              </div>
              <div
                v-if="!$v.requestModel.QuestionValue.minLength"
                class="invalid-Question"
              >
                Question must contain at least 3 characters
              </div>
            </b-form-group>
          </div>
           <div class="col-md-6">
            <b-form-group
              label="Select Question type"
              label-for="isemployeer"
            >
         
            </b-form-group>
            <b-form-select
            name="isemployeer"
            v-model="requestModel.isemployeer"
             id="isEmployer"
            >
            <option :value="false" >Employee</option>
                <option :value="true">Employer</option>
            </b-form-select>
            <!-- <select name="isemployeer" v-model="requestModel.isemployeer" id="isEmployer">
                <option :value="false" >Employee</option>
                <option :value="true">Employer</option>
            </select> -->
          </div>

        </div>
        
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  name: "AddPackage",
  data() {
    return {
      moduleName: "Add Question",
      requestModel: {
        QuestionValue: null,
        isemployeer: false,
      },
      submitted: false,
    };
  },

  validations: {
    requestModel: {
        QuestionValue: {
        required,
        minLength: minLength(3),
      },
      isemployeer: {
        required,
      },
    },
  },

  components: {
    Toaster,
  },
  methods: {
    Add() {
      this.isLoading = true;
    //   const formData = new FormData();
    //   console.log(this.requestModel);

    //   formData.append("QuestionValue", this.requestModel.Question);
    //   formData.append("isemployeer", this.requestModel.isEmployer);
      
      axios.post("/Employee/InsertQuestion", this.requestModel).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          this.$parent.getPage(1);
          this.$bvModal.hide("AddQuestionModal");
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Question has been added successfully"
          );
        } else if (res.data.status == -1) {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        } else {
          this.jobsRequests = null;
        }
      });
    },

    checkFormValidity() {
      const valid = this.$refs.AddQuestionForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      this.requestModel.QuestionValue = null;
      this.requestModel.isemployeer = false;
    },
    handleOk() {
      // PrQuestion modal from closing
    
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }
      this.Add();
    },
  },
};
</script>
