<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="AddBrandModal"
      ref="modal"
      title="Add Brand"
      ok-title="Submit"
      cancel-title="Close"
      @show="resetModal()"
      @hidden="resetModal()"
      @ok="handleOk"
      :ok-disabled="
        this.$refs.AddBrandForm != undefined
          ? !this.$refs.AddBrandForm.checkValidity()
          : true
      "
    >
      <form ref="AddBrandForm" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Title" label-for="Title" autocomplete="off">
              <b-form-input
                id="brandName"
                v-model="brand.brandName"
                :class="{
                  'is-invalid': submitted && $v.brand.brandName.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.brand.brandName.required"
                class="invalid-feedback"
              >
                Brand Name is required
              </div>
              <div
                v-if="!$v.brand.brandName.minLength"
                class="invalid-feedback"
              >
                Brand Name must contain at least 3 characters
              </div>
              <div
                v-if="!$v.brand.brandName.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 100 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <!-- <div class="col-md-6">
           
            <b-form-group
              label="Page"
              label-for="Page"
              autocomplete="off"
            >
              <b-form-select
                id="role-input"
                v-model="Page"
                value-field="id"
                text-field="name"
                :options="Pages"
                required
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select Page</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </div> -->
          <div class="col-md-12">
            <b-form-group label="Link" label-for="Link" autocomplete="off">
              <b-form-input
                id="Link"
                v-model="brand.brandUrl"
                :class="{
                  'is-invalid': submitted && $v.brand.brandUrl.$error,
                }"
                type="text"
                required
              ></b-form-input>
              <div
                v-if="submitted && !$v.brand.brandUrl.required"
                class="invalid-feedback"
              >
                Brand URL is required
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" autocomplete="off">
              <label label-for="Image">Image</label>
              <span class="ml-1 text-muted small">
                <i
                  >(Brand image must be square, and at least 512 pixels wide and
                  tall.)</i
                >
                <!-- <b-icon icon="question-circle"></b-icon> -->
              </span>
              <b-form-file
                accept="image/jpeg, image/png, image/gif"
                v-model="brand.brandImage"
                :class="{
                  'is-invalid': submitted && $v.brand.brandImage.$error,
                }"
                :state="Boolean(brand.brandImage)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>

              <div
                v-if="submitted && !$v.brand.brandImage.required"
                class="invalid-feedback"
              >
                Brand Image is required
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "AddBrand",
  data() {
    return {
      moduleName: "Add Brand",
      brand: {
        brandName: null,
        brandImage: null,
        brandUrl: null,
      },
      file1: null,
      Name: null,
      Pages: [
        { id: 1, name: "Employer Dashboard" },
        { id: 2, name: "Employer Jobs" },
        { id: 3, name: "Employee Dashboard" },
      ],
      Page: null,
      Link: null,
      submitted: false,
    };
  },

  validations: {
    brand: {
      brandName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      brandImage: {
        required,
      },
      brandUrl: {
        required,
      },
    },
  },
  components: {
    Toaster,
  },
  methods: {
    Add() {
      this.isLoading = true;

       if (this.brand.brandImage) {
        var fileSize = 209715;
        if (this.brand.brandImage.size > fileSize) {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "File Size Limit is 200 KB"
          );
          this.brand.brandImage = null;
          return false;
        }
      }

      const formData = new FormData();
      console.log(this.requestModel);
      formData.append("brandName", this.brand.brandName);
      formData.append("brandUrl", this.brand.brandUrl);
      formData.append("brandImage", this.brand.brandImage);

      console.log(formData);
      axios.post("/Adds/InsertBrand", formData).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          this.$parent.getPage(1);
          this.$bvModal.hide("AddBrandModal");
          console.log(res);

          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Brand has been added successfully"
          );
          this.$bvModal.hide("AddBrandModal");
        } else {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "Unable to add brand"
          );
        }
      });
    },

    checkFormValidity() {
      const valid = this.$refs.AddBrandForm.checkValidity();
      // if (this.file1 == null) {
      //   this.$refs.toaster.showToast(
      //     "Invalid Data",
      //     "danger",
      //     "Please select a file"
      //   );
      //   return;
      // }

      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      this.Address = null;
      this.PhoneNumber = null;
      this.Name = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }

      this.Add();
    },
  },
};
</script>
