<template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="AddCompanyModal"
      ref="modal"
      title="Add Company"
      ok-title="Submit"
      cancel-title="Close"
      @show="resetModal()"
      @hidden="resetModal()"
      @ok="AddCompany"
      :ok-disabled="
        this.$refs.AddCompanyForm != undefined
          ? !this.$refs.AddCompanyForm.checkValidity()
          : true
      "
    >
      <form ref="AddCompanyForm" @submit.stop.prevent="AddCompany">
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="Name" label-for="name" autocomplete="off">
              <b-form-input
                id="name"
                v-model="requestModel.companyName"
                :class="{
                  'is-invalid': submitted && $v.requestModel.companyName.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.companyName.required"
                class="invalid-feedback"
              >
                Company name is required
              </div>
              <div
                v-if="!$v.requestModel.companyName.minLength"
                class="invalid-feedback"
              >
                Company name must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.companyName.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 100 characters is reached
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Owner" label-for="owner" autocomplete="off">
              <b-form-input
                id="owner"
                v-model="requestModel.name"
                :class="{
                  'is-invalid': submitted && $v.requestModel.name.$error,
                }"
                type="text"
                required
              ></b-form-input>
              <div
                v-if="submitted && !$v.requestModel.name.required"
                class="invalid-feedback"
              >
                Owner name is required
              </div>
              <div
                v-if="!$v.requestModel.name.minLength"
                class="invalid-feedback"
              >
                Owner name must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.name.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 100 characters is reached
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!--NAME INPUT-->
            <b-form-group label="City" label-for="Cities" autocomplete="off">
              <b-form-select
                id="Cities"
                v-model="requestModel.cityId"
                :class="{
                  'is-invalid': submitted && $v.requestModel.cityId.$error,
                }"
                required
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select City</b-form-select-option
                >
                <option
                  v-for="option in cities"
                  :key="option.key"
                  :value="option.key"
                >
                  {{ option.value }}
                </option>
              </b-form-select>

              <div
                v-if="submitted && !$v.requestModel.cityId.required"
                class="invalid-feedback"
              >
                City is required
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Category"
              label-for="companyCategory"
              autocomplete="off"
            >
              <b-form-select
                id="companyCategory"
                v-model="requestModel.companyCategory"
                :options="companyCategoryOptions"
                required
              >
                <b-form-select-option :value="null" disabled hidden
                  >Select Company Category</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              label="Website"
              label-for="website"
              autocomplete="off"
            >
              <b-form-input
                id="website"
                v-model="requestModel.website"
                :class="{
                  'is-invalid': submitted && $v.requestModel.website.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.website.required"
                class="invalid-feedback"
              >
                Webiste is required
              </div>
              <div
                v-if="!$v.requestModel.website.minLength"
                class="invalid-feedback"
              >
                Webiste must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.website.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 100 characters is reached
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Email" label-for="email" autocomplete="off">
              <b-form-input
                id="email"
                v-model="requestModel.email"
                type="email"
                required
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="Address" label-for="adress" autocomplete="off">
              <b-form-input
                id="adress"
                v-model="requestModel.address"
                :class="{
                  'is-invalid': submitted && $v.requestModel.address.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.address.required"
                class="invalid-feedback"
              >
                Address is required
              </div>
              <div
                v-if="!$v.requestModel.address.minLength"
                class="invalid-feedback"
              >
                Address must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.address.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Expiry"
              label-for="datepicker-1"
              autocomplete="off"
            >
              <!-- <b-form-datepicker
                id="datepicker-1"
                v-model="requestModel.expireDate"
                placeholder="Choose a date"
                local="en"
                required
              ></b-form-datepicker> -->
              <b-form-input
                type="date"
                v-model="requestModel.expireDate"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <b-form-group
              label="Short Description"
              label-for="description"
              autocomplete="off"
            >
              <b-form-input
                id="description"
                v-model="requestModel.shortDescription"
                :class="{
                  'is-invalid':
                    submitted && $v.requestModel.shortDescription.$error,
                }"
                type="text"
                required
              ></b-form-input>

              <div
                v-if="submitted && !$v.requestModel.shortDescription.required"
                class="invalid-feedback"
              >
                Short Description is required
              </div>
              <div
                v-if="!$v.requestModel.shortDescription.minLength"
                class="invalid-feedback"
              >
                Short Description must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.shortDescription.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Contact No"
              label-for="contactno"
              autocomplete="off"
            >
              <!-- <b-form-input
                id="description"
                v-model="requestModel.contactNo"
                :class="{
                  'is-invalid': submitted && $v.requestModel.contactNo.$error,
                }"
                type="text"
                required
              ></b-form-input> -->
              <vue-tel-input
                v-bind="vueTellProps"
                v-model="requestModel.contactNo"
              ></vue-tel-input>

              <!-- <div
                v-if="submitted && !$v.requestModel.contactNo.required"
                class="invalid-feedback"
              >
                Contact no is required
              </div> -->
              <!-- <div
                v-if="!$v.requestModel.contactNo.minLength"
                class="invalid-feedback"
              >
                Contact no must contain at least 3 characters
              </div>
              <div
                v-if="!$v.requestModel.contactNo.maxLength"
                class="invalid-feedback"
              >
                The maximum length of 1000 characters is reached
              </div> -->
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Google Map"
              label-for="googlemap"
              autocomplete="off"
            >
              <b-textarea
                id="googlemap"
                v-model="requestModel.googleMap"
                placeholder='<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3321.3173064911507!2d72.99695511459036!3d33.64893924632387!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df95c7a1a3cbe5%3A0x156312b15ca23972!2sNUST%20Gate%201!5e0!3m2!1sen!2s!4v1644836312617!5m2!1sen!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>'
                :class="{
                  'is-invalid': submitted && $v.requestModel.googleMap.$error,
                }"
                type="text"
                rows="3"
                required
              ></b-textarea>

              <div
                v-if="submitted && !$v.requestModel.googleMap.required"
                class="invalid-feedback"
              >
                Google map is required
              </div>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Logo"
              label-for="companyLogo"
              autocomplete="off"
            >
              <b-form-file
                accept="image/jpeg, image/png, image/gif, image/webp"
                v-model="requestModel.companyLogo"
                :class="{
                  'is-invalid': submitted && $v.requestModel.companyLogo.$error,
                }"
                :state="Boolean(requestModel.companyLogo)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>

              <div
                v-if="submitted && !$v.requestModel.companyLogo.required"
                class="invalid-feedback"
              >
                Company Logo is required
              </div>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import axios from "axios";
// import config from '../config.js';
import Toaster from "../../../common/pages/toaster.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "AddCompany",
  data() {
    return {
      moduleName: "Add Company",
      file1: null,
      requestModel: {
        companyName: null,
        name: null,
        companyCategory: null,
        email: null,
        website: null,
        address: null,
        expireDate: new Date().toISOString().slice(0, 10),
        // companyDescription: null,
        companyLogo: null,
        shortDescription: null,
        cityId: null,
        googleMap: null,
        contactNo: null,
        // longDescription:null
      },
      vueTellProps: {
        mode: "international",
        defaultCountry: "PK",

        preferredCountries: ["PK"],
        onlyCountries: [],
        ignoredCountries: [],

        wrapperClasses: "",
        inputClasses: "",

        inputOptions: {
          showDialCode: true,
          name: "telephone",
          autocomplete: "off",
          placeholder: "Enter a phone number",
          required: true,
          type: "tel",
          maxlength: 15,

        },
        // dropdownOptions:{
        //   showDialCodeInSelection : true},
        validCharactersOnly: true,
      },
      cities: null,
      submitted: false,
      // companyTypeOptions: null,
      companyCategoryOptions: null,
      // companyTypeOptions: [
      //   { text: "Train Me", value: 1 },
      //   { text: "Train The Team", value: 2 },
      //   { text: "Skill Her", value: 3 },
      // ],
    };
  },
  validations: {
    requestModel: {
      companyName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      website: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      address: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(1000),
      },
      shortDescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(1000),
      },
      // companyDescription: {
      //   required,
      //   minLength: minLength(3),
      //   maxLength: maxLength(1000),
      // },
      cityId: {
        required,
      },
      companyLogo: {
        required,
      },
      googleMap: {
        required,
      },
      // contactNo: {
      //   required,
      // },
    },
  },

  components: {
    Toaster,
  },
  created() {
    this.getDropdowns();
  },
  methods: {
    getCityList() {
      this.cities = null;
      let model = { type: "city" };

      axios
        .post("/KeyValuePair/GetDropDownListByIds", model)
        .then((res) => {
          this.cities = res.data.data;
          console.log("cities : ", this.cities);
        })
        .catch((error) => {
          console.log("Errorrrrrr : ", error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            console.log("Error : Sorry there is no response");
          }
        });
    },

    getDropdowns() {
      this.getDropDownValues("CmsCompanyCategory");
      this.getCityList();
    },

    getDropDownValues(id) {
      this.isLoading = true;
      axios
        .post("/KeyValuePair/GetDropDownListByIds", {
          type: id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            console.log(res);
            let options = res.data.data.map((x) => {
              return {
                text: x.value,
                value: x.key,
              };
            });
            if (id == "CmsCompanyCategory") {
              this.companyCategoryOptions = options;
            }
          }
        });
    },
    AddCompany() {
      
      this.isLoading = true;

      var fileSize = 509715;
      if (this.requestModel.companyLogo.size > fileSize) {
        this.$refs.toaster.showToast(
          "Error",
          "danger",
          "File Size Limit is 500 KB"
        );
        this.requestModel.companyLogo = null;
        return false;
      }

      // console.log("date is", this.requestModel.expireDate.toISOString().slice(0,10));
      //  console.log("date is", this.requestModel.expireDate.toISOString());

      const formData = new FormData();
      formData.append("companyName", this.requestModel.companyName);
      formData.append("name", this.requestModel.name);
      formData.append("companyCategory", this.requestModel.companyCategory);
      formData.append("email", this.requestModel.email);
      formData.append("website", this.requestModel.website);
      formData.append("address", this.requestModel.address);
      // formData.append("expireDate", this.requestModel.expireDate.toISOString()); used for bootstrap datepicker
      formData.append("expireDate", this.requestModel.expireDate);
      formData.append("Categoryid", this.requestModel.companyCategory);
      formData.append("companyLogo", this.requestModel.companyLogo);
      formData.append("shortDescription", this.requestModel.shortDescription);
      formData.append("cityId", this.requestModel.cityId);
      formData.append("googleMap", this.requestModel.googleMap);
      formData.append("ContactNo", this.requestModel.contactNo);

      axios.post("/shehri/AddCompany", formData).then((res) => {
        this.isLoading = false;

        if (res.data.status == "Success") {
          this.$parent.getPage(1);
          //this.$bvModal.hide("AddCompanyModal");
          //this.resetModal();
          console.log(res);
          this.$refs.toaster.showToast(
            "Sucess",
            "success",
            "Company has been added successfully"
          );
        } else {
          this.$refs.toaster.showToast(
            "Error",
            "danger",
            "unable to add company"
          );
        }
      });
    },

    checkFormValidity() {
      const valid = this.$refs.AddCompanyForm.checkValidity();
      if (this.requestModel.contactNo === "+92") {
        this.$refs.toaster.showToast(
          "Error",
          "danger",
          "Please add Contact No."
        );
        return;
      }
      // this.usernameState = valid
      return valid;
    },
    resetModal() {
      
      this.requestModel.companyName= null;
      this.requestModel.name= null,
      this.requestModel.companyCategory= null;
      this.requestModel.email= null;
      this.requestModel.website= null;
      this.requestModel.address= null;
      this.requestModel.expireDate= new Date().toISOString().slice(0, 10);
        // companyDescription: null,
        this.requestModel.companyLogo= null;
        this.requestModel.shortDescription= null;
        this.requestModel.cityId= null;
        this.requestModel.googleMap= null;
        this.requestModel.contactNo= null;
        // longDescription:null
      },
    },
    handleOk(bvModalEvt) {
      
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
     
      this.submitted = true;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      }

      this.AddCompany();
    },
  };

</script>
