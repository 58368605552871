  <template>
  <div>
    <b-modal
      style="padding: 15px"
      size="lg"
      id="UserDetailsModal"
      ref="modal"
      title="User Details"
      ok-only
      cancel-title="Close"
      @show="updateModel()"
      @hidden="resetModal()"
      @ok='$bvModal.hide("UserDetailsModal")'
    >
      
      <div class="row">
        <div class = "col-md-4">
          <span>Name: &nbsp;</span>{{userDetails.name}}
        </div>
        <div class = "col-md-4">
          <span>City: &nbsp;</span>{{userDetails.name}}
        </div>
        <div class = "col-md-4">
          <span>Owner: &nbsp;</span>{{userDetails.name}}
        </div>  
      </div>
        <div class="row">
        <div class = "col-md-4">
          <span>Email: &nbsp;</span>{{userDetails.name}}
        </div>
        <div class = "col-md-4">
          <span>Status: &nbsp;</span>{{userDetails.name}}
        </div>
        <div class = "col-md-4">
          <span>Name: &nbsp;</span>{{userDetails.name}}
        </div>  
      </div>
        <div class="row">
        <div class = "col-md-4">
          <span>Name: &nbsp;</span>{{userDetails.name}}
        </div>
        <div class = "col-md-4">
          <span>Name: &nbsp;</span>{{userDetails.name}}
        </div>
        <div class = "col-md-4">
          <span>Name: &nbsp;</span>{{userDetails.name}}
        </div>  
      </div>
      
    </b-modal>
  </div>
</template>

      <script>
// import config from '../config.js';
export default {
  name: "UserDetails",
  data() {
    return {
      moduleName: "User Details",

      requestModel: null,
    };
  },

  props: {
    userDetails: {
      type: Object,
      default: null,
    },
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.userDetailsForm.checkValidity();
      // this.usernameState = valid
      return valid;
    },
    updateModel() {
      console.log(this.userDetails);
      this.requestModel = this.userDetails;
    },
    resetModal() {},
   
  },
};
</script>
