<template>
  <div>
    <div class="row justify-content-between align-items-center">
      <div class="col-md-7 customHeading">
        <h3>{{ moduleName }}</h3>
      </div>
      <div class="col-md-2 icon-theme-color">
        <div class="row">
          <div class="col-md-8 center-adv-srch" v-b-toggle.filters-collpased>
            <details style="display: inline">
              <summary title="Advanced Search" class="adv-search-p">
                Advanced Search
              </summary>
            </details>
          </div>
          <div
            class="col-md-4 center-adv-srch"
            role="button"
            @click="clearFilters"
          >
            <em
              title="Reset Filters"
              class="fas fa-times fa-sm clear-filter-btn"
            ></em>
          </div>
        </div>
      </div>
      <!--==========Class dropdown============-->

      <!---SEARCH BOX-->
      <div class="col-md-2">
        <div class="searchbox input-group" role="group">
          <input
            class="form-control search-box my-search-box"
            type="text"
            placeholder="Name"
            aria-label="search"
            v-model="filters.Text"
          />

          <button
            class="search-button btn btn-primary filter"
            @click="getCompanies()"
          >
            <i class="fa fa-search search-icon"></i>
          </button>
        </div>
      </div>
      <!-- <div class="col-md-1 Add-button" style="text-align: center">
        <a href="javascript:void(0)">
          <div class="AddButton">
            <div class="icon">
              <i
                class="fas fa-arrow-alt-circle-right fa-1x"
                style="color: rgb(255, 50, 55)"
              >
              </i>
            </div>
            <div data-text="Add" class="name add-btn" v-b-modal.AddCompanyModal>
              Add
            </div>
          </div>
        </a>
      </div> -->
    </div>
    <b-collapse id="filters-collpased">
      <div class="row margin-filters">
        <div class="col-md-3">
          <b-form-select
            v-model="filters.cityId"
            :options="cityOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select City</b-form-select-option
            >
          </b-form-select>
        </div>
        <div class="col-md-3">
          <b-form-select
            v-model="filters.countryId"
            :options="countryOptions"
            class="class-dropdown"
          >
            <b-form-select-option :value="null"
              >Select Country</b-form-select-option
            >
          </b-form-select>
        </div>

        <div class="col-md-3" style="text-align: center">
          <button class="btn filter-search-btn" @click="getCompanies()">
            Search
          </button>
        </div>
      </div>
    </b-collapse>
    <div class="row">
      <div class="col">
        <hr
          color="#CACACA"
          style="border-top: 2px solid rgb(202, 202, 202); margin-top: 5px"
        />
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col">
          <table
            class="
              table table-striped table-borderless
              cutom-table
              table-striped table-sm
            "
            style="font-size: 85%"
          >
            <thead>
              <tr>
                <th role="columnheader">Name</th>
                 <th role="columnheader">NTN</th>
                <th role="columnheader">City</th>
                <th role="columnheader">Address</th>
                <th role="columnheader">Contact #</th>
                <th role="columnheader">Email</th>
                <th role="columnheader">Is Featured</th>
                <th role="columnheader">Status</th>
                <!-- <th role="columnheader">Actions</th> -->
              </tr>
            </thead>
            <tbody>
              <!--IF STUDENTS ARRAY HAS NO VALUE THEN SHOW WITHOUT SHOWING DATA-->
              <tr v-if="companies == null">
                <td class="center-td" colspan="5">No Data</td>
              </tr>

              <tr v-else v-for="record in companies" :key="record.Id">
                <td role="button" @click="showCompanyDetails(record)">
                  {{ record.companyName }}
                </td>
                <td role="cell">{{ record.ceoName }}</td>
                <td role="cell">{{ record.cityName }}</td>
                <td role="cell">{{ record.address }}</td>
                 <td role="cell">{{ record.contactNumber }}</td>
                <td role="cell">{{ record.companyEmail }}</td>
               
                <td role="cell">
                  <b-form-checkbox
                    switch
                    v-model="record.isFeature"
                    @input="updateCompanyFeaturedandStatusState(record)"
                  >
                  </b-form-checkbox>
                </td>
                <td role="cell">
                  <b-form-checkbox
                    v-model="record.companyStatus"
                    @input="updateCompanyFeaturedandStatusState(record)"
                    value="Active"
                    unchecked-value="Suspended"
                  >
                    {{ record.companyStatus }}
                  </b-form-checkbox>
                </td>
                <!-- <td> -->
                <!-- <span @click="editRecord(record)">
                    <i
                      role="button"
                      style="color: #ea216c"
                      class="fas fa-eye"
                    ></i>
                  </span> -->
                <!-- <span @click="deleteRecord(record.id)">
                    <i
                      role="button"
                      style="color: #ea216c"
                      class="far fa-trash-alt"
                    ></i>
                  </span> -->
                <!-- </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--PAGINATION START-->
      <div class="row justify-content-between align-items-center">
        <div class="col-md-2">
          <div class="showPages">
            <select
              class="form-control"
              id="selClass"
              v-model="perPage"
              @change="getCompanies()"
            >
              <option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-8 d-flex justify-content-center">
          <div class="overflow-auto">
            <b-pagination
              v-model="page"
              :total-rows="rows"
              limit="3"
              :per-page="perPage"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              @change="getPage"
            ></b-pagination>
          </div>
        </div>

        <div class="col-md-2">
          <input
            class="form-control search-box page-no-search"
            type="text"
            v-bind:placeholder="page"
            aria-label="search"
            v-model.number="pageNumOnSearch"
            style="height: 38px"
          />

          <button
            class="search-button btn btn-primary search page-no-search-btn"
            @click="search()"
          >
            GO
          </button>
        </div>
      </div>
      <!--/PAGINATION END-->
      <!--SPINNER-->
      <Spinner
        v-if="isLoading"
        message="Loading..."
        size="medium"
        line-fg-color="rgb(222 224 219"
        line-bg-color="rgb(68 66 62)"
      >
      </Spinner>
      <!--/SPINNER-->
    </div>
    <!-- <AddClientModal /> -->
    <Toaster ref="toaster" />
    <EditCompanyModal
      :packageToEdit="recordToEdit"
      v-if="recordToEdit != null"
    />
    <AddCompanyModal />
    <CompanyDetailsModal v-if="companyId != null" :CompanyId="companyId" />
    <DeleteModal
      :msgForModal="msgForModal"
      :apiEndPoint="apiEndPoint"
      :heading="deleteModalHeading"
      :entityId="recordToDelete"
    />
  </div>
</template>

<script>
import axios from "axios";
import Toaster from "../../common/pages/toaster.vue";
// import config from '../config.js';
import Spinner from "vue-simple-spinner";
import AddCompanyModal from "./modals/add-company-modal.vue";
import DeleteModal from "../../common/modals/delete-modal.vue";
import EditCompanyModal from "./modals/edit-company-modal.vue";
import CompanyDetailsModal from "./modals/company-details-modal.vue";
import _ from "underscore";

export default {
  name: "Companies",
  data() {
    return {
      moduleName: "Companies",
      companies: [],
      isLoading: false,
      recordToEdit: null,
      companyId: null,
      recordToDelete: null,
      msgForModal: null,
      apiEndPoint: null,
      deleteModalHeading: null,
      cityOptions: null,
      countryOptions: null,
      pageNumOnSearch: "",

      page: 1,
      options: [
        { text: "Show 2", value: 2 },
        { text: "Show 10", value: 10 },
        { text: "Show 25", value: 25 },
        { text: "Show 50", value: 50 },
        { text: "Show 100", value: 100 },
      ],
      rows:0,
      perPage: 25,
      filters: {
        countryId: null,
        cityId: null,
        companyName: null,
        PageSize: null,
        PageNumber: null,
        Text: null,
      },
    };
  },

  methods: {
    clearFilters() {
      for (var key in this.filters) {
        console.log(key);
        this.filters[key] = null;
      }
    },
    getFiltersDropdowns() {
      this.getDropDownValues("city");
      this.getDropDownValues("country");
    },
    getDropDownValues(id) {
      this.isLoading = true;
      axios
        .post("/KeyValuePair/GetDropDownListById", {
          type: id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.status == "Success") {
            let options = res.data.data.map((x) => {
              return {
                text: x.value,
                value: x.key,
              };
            });

            let sortedData=[];
            sortedData = _.sortBy( options, 'text');
            options=sortedData;
            console.log(sortedData)

            if (id == "city") {
              this.cityOptions = options;
             


            } else if (id == "country") {
              this.countryOptions = options;

           
            }
          } else {
            return null;
          }
        });
    },
    getPage(page) {
      this.page = page;
      this.getCompanies();
    },
    showCompanyDetails(record) {
      this.companyId = record.employerCompanyId;
      setTimeout(() => {
        this.$bvModal.show("CompanyDetailsModal");
      }, 100);
    },
    updateCompanyFeaturedandStatusState(record) {
      this.isLoading = true;
      let req = {
        employeeCompanyId: record.employerCompanyId,
        isFeature: record.isFeature,
        companyStatus:
          record.companyStatus == "Active"
            ? 2
            :1
            // : record.companyStatus == "Suspended"
            // ? 1
            // : "",
      };
      // console.log(req);
      axios.post("/EmployerCompany/UpdateCompanyStatus", req).then((res) => {
        this.isLoading = false;
        if (res.data.status == "Success") {
          console.log("success");
          // this.rows = res.data.totalRecords;
        } else {
          this.$refs.toaster.showToast("Error", "danger", res.data.msg);
        }
      });
    },
    editRecord(comp) {
      this.recordToEdit = comp;
      setTimeout(() => {
        this.$bvModal.show("EditCompanyModal");
      }, 100);
    },
    deleteRecord(id) {
      this.recordToDelete = id;
      this.msgForModal = "Confirm to delete !";
      this.apiEndPoint = "client/Delete/";
      this.deleteModalHeading = "Delete";
      this.$bvModal.show("DeleteModal");
    },

    getCompanies() {
      this.isLoading = true;
      this.filters.PageSize = this.perPage;
      this.filters.PageNumber = this.page;

      console.log(this.filters);
      axios
        .post("/EmployerCompany/EmployerCompanyFilter", this.filters)
        .then((res) => {
          this.isLoading = false;

          if (res.data.status == "Success") {
            // console.log(res);
            this.companies = res.data.data;
            // this.rows = res.data.length;
            this.rows = res.data.totalCount;
          } else if (res.data.status == -1) {
            this.$refs.toaster.showToast("Error", "danger", res.data.msg);
          } else {
            this.companies = null;
          }
        });
    },

    search() {
      this.page = this.pageNumOnSearch;
      this.getCompanies();
    },
  },
  computed: {
  //   sortedArray() {
  //     if(this.cityOptions!=null){

      
	// 	let sortedRecipes = this.cityOptions;
		
	// 	sortedRecipes = sortedRecipes.sort((a,b) => {
	// 		let fa = a.value.toLowerCase(), fb = b.value.toLowerCase();
	// 		if (fa < fb) {
	// 			return -1
	// 		}
	// 		if (fa > fb) {
	// 			return 1
	// 		}
	// 		return 0
	// 	})

  //   return sortedRecipes;
  //   }
  //   return null;
	// }
    // rows: function() {
    //   if (this.companies) {
    //      return this.companies.length;
    //   }
    //   return 0;
    // },
  },
  created() {
    this.getCompanies();
    this.getFiltersDropdowns();
    // this.getStudents();
    //localStorage.setItem("mytime", Date.now());
  },
  components: {
    Spinner,
    EditCompanyModal,
    DeleteModal,
    Toaster,
    AddCompanyModal,
    CompanyDetailsModal,
  },
};
</script>

<style scoped>
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
select#class-dropdown {
  width: 80%;
  margin-left: 16%;
}
</style>
